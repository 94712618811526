import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { Container, Button, Message } from 'semantic-ui-react';
import { sendNdaForSigning } from 'api-operations/myExfluency/myExfluency';
import { hasErrorCode } from 'logger/error-guards';
import { toast } from 'react-toastify';
const StyledDiv = styled.div `
  display: flex;
  justify-content: center;
  width: 40%;
  padding: 4rem 0;
  margin: auto;
`;
const StyledButton = styled(Button) `
  width: 100%;
`;
const Nda = () => {
    const [isSending, setIsSending] = useState(false);
    const [isError, setIsError] = useState(null);
    const { t } = useTranslation('common');
    const store = useStore();
    const { ndaSigned } = store.getState().userProfile;
    const sendNda = async () => {
        setIsSending(true);
        try {
            await sendNdaForSigning();
            setIsError(false);
            toast.success(t('notification.photoUploaded'));
        }
        catch (error) {
            if (hasErrorCode(413, error)) {
                toast.error(t('notification.photoTooLarge'));
            }
            else {
                toast.error(t('notification.error'));
            }
            setIsError(true);
        }
        setIsSending(false);
    };
    return React.createElement(Container, null,
        ndaSigned ? React.createElement(Message, { success: true, content: t('settings.ndaMessagePositive') })
            : React.createElement(React.Fragment, null,
                React.createElement(Message, { warning: true, content: t('settings.ndaMessageNegative') }),
                React.createElement(StyledDiv, null,
                    React.createElement(StyledButton, { primary: true, content: t('settings.sendNda'), onClick: sendNda, loading: isSending, disabled: isError === false || isSending }))),
        isError !== null && (isError ? React.createElement(Message, { error: true, content: t('settings.sendNdaError') })
            : React.createElement(Message, { success: true, content: t('settings.sendNdaSuccess') })));
};
export default Nda;
