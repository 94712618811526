import React, { useState, useEffect } from 'react';
import { Button, Container, Modal } from 'semantic-ui-react';
import RolesTable from './RolesTable';
import { getMyUserRoles } from 'api-operations/myExfluency/myExfluency';
import ErrorModal from 'errors/components/ErrorModal';
import LegalDocument from 'public/LegalDocument';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ExfluencyLoader from 'helpers/components/loader/ExfluencyLoader';
const LintBtn = styled.a `
  display: block;
  cursor: pointer;
`;
const MyRoles = () => {
    const [userRoles, setUserRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [legalDocumentType, setLegalDocumentType] = useState(undefined);
    const [errorWindowMessage, setErrorWindowMessage] = useState(null);
    const { t } = useTranslation('common');
    const fetchData = async () => {
        try {
            const res = await getMyUserRoles();
            setUserRoles(res);
        }
        catch (error) {
            setErrorWindowMessage(null);
            return;
        }
        setLoading(false);
    };
    useEffect(() => {
        fetchData();
    }, []);
    const handleLegalDocumentOpen = (type) => {
        setLegalDocumentType(type);
    };
    const handleLegalDocumentClose = () => {
        setLegalDocumentType(undefined);
    };
    if (errorWindowMessage !== null)
        return React.createElement(ErrorModal, { ...errorWindowMessage, onClose: () => window.location.reload() });
    if (loading)
        return React.createElement(ExfluencyLoader, null);
    return React.createElement(React.Fragment, null,
        React.createElement(Container, null,
            React.createElement(RolesTable, { userRoles: userRoles }),
            userRoles.includes('ROLE_BUYER') && React.createElement(React.Fragment, null,
                React.createElement(LintBtn, { onClick: () => handleLegalDocumentOpen('GENERAL_TERMS') }, t('legalDocument.GENERAL_TERMS')),
                React.createElement(LintBtn, { onClick: () => handleLegalDocumentOpen('PRIVACY_POLICY_REQUESTERS') }, t('legalDocument.PRIVACY_POLICY_REQUESTERS'))),
            userRoles.some(role => ['ROLE_ENHANCER', 'ROLE_TRUST_MINER', 'ROLE_REDACTOR'].includes(role)) && React.createElement(React.Fragment, null,
                React.createElement(LintBtn, { onClick: () => handleLegalDocumentOpen('GENERAL_TERMS_SERVICE_PROVIDERS') }, t('legalDocument.GENERAL_TERMS_SERVICE_PROVIDERS')),
                React.createElement(LintBtn, { onClick: () => handleLegalDocumentOpen('PRIVACY_POLICY_COMMUNITY_MEMBERS') }, t('legalDocument.PRIVACY_POLICY_COMMUNITY_MEMBERS')))),
        React.createElement(Modal, { open: !!legalDocumentType, onClose: handleLegalDocumentClose },
            React.createElement(Modal.Content, null, legalDocumentType && React.createElement(LegalDocument, { legalDocument: legalDocumentType })),
            React.createElement(Modal.Actions, null,
                React.createElement(Button, { content: 'close', onClick: handleLegalDocumentClose }))));
};
export default MyRoles;
