import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import { useLogger } from "logger/Logger";
import { saveDownloadSuggestions } from "./utils";
import { requestOutputFilesGeneration } from "api-operations/requester/stage";
const btnStyle = {
    height: '40px',
    marginBottom: '2px',
    fontSize: '0.9em',
    padding: '2px 10px'
};
const DownloadFileButton = ({ file, showName, datatestid, icon, activeStage, downloadCallback, downloadError }) => {
    const { isReady, stageType, stageId, fileType } = file;
    const [isFileDownloading, setFileDownloading] = useState(false);
    const [isFileGenerating, setFileGenerating] = useState(false);
    const [isFileReady, setFileReady] = useState(isReady);
    const { t } = useTranslation('common');
    const { logger } = useLogger();
    const downloadFile = async () => {
        try {
            setFileDownloading(true);
            await downloadCallback(stageId, fileType);
            logger.info(`User - Downloaded ${fileType} file`);
            if (activeStage)
                saveDownloadSuggestions(activeStage, { ...file, fileType });
        }
        catch (e) {
            try {
                await generateFile();
                await downloadCallback(stageId, fileType);
            }
            catch (e2) {
                if (downloadError)
                    downloadError();
                logger.error(`User - failed to download ${fileType} file`, e);
            }
        }
        finally {
            setFileDownloading(false);
        }
    };
    const generateFile = async () => {
        try {
            if (fileType !== 'CSV' && fileType !== 'TCERT') {
                setFileGenerating(true);
                await requestOutputFilesGeneration(stageId, fileType);
                setFileReady(true);
            }
        }
        catch (e) {
            logger.error(`User - failed to generate file`, e);
        }
        finally {
            setFileGenerating(false);
        }
    };
    const getFileLabel = (stageType, fileType, ready) => {
        const label = ready ? t(`file.${stageType}`) : t(`requestFile.${stageType}`);
        if (!showName || ['RESULT', 'TCERT', 'UPLOADED', 'CSV'].includes(fileType))
            return label;
        return label + "\n " + t(`file.${fileType}`);
    };
    return (React.createElement(React.Fragment, null, isFileReady
        ? React.createElement(Button, { style: btnStyle, icon: icon, fluid: true, color: "blue", onClick: () => downloadFile(), content: getFileLabel(stageType, fileType, true), loading: isFileDownloading || isFileGenerating, disabled: isFileDownloading, "data-testid": `${datatestid}_download_${fileType}${stageId}` })
        : React.createElement(Button, { style: btnStyle, icon: icon, fluid: true, color: "grey", onClick: () => generateFile(), content: getFileLabel(stageType, fileType, false), loading: isFileDownloading || isFileGenerating, disabled: isFileDownloading })));
};
export default DownloadFileButton;
