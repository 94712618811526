import { createTerminology } from "api-operations/editor/editor";
import CreateTermModal from "./CreateTermModal";
import i18next from "i18next";
import EditorModule from "../EditorModule";
import { v4 as uuidv4 } from 'uuid';
import { HTTPLogger } from "logger/HTTPLoggerStatic";
import { IconNgBuilder } from "editorNextGen/icons";
import { definedTermSvg } from "editorNextGen/icons/definedTerm";
import { forbiddenSvg } from "editorNextGen/icons/forbidden";
export default class TermCreationModule extends EditorModule {
    constructor(editor) {
        super(editor);
        this.moduleId = "TermCreationModule";
        this.createTermModal = new CreateTermModal();
        this.editor.domNode.appendChild(this.createTermModal);
        this.selectionToolsDefinedTermButton = new IconNgBuilder(definedTermSvg, this.editor.selectionTools)
            .setIconFill('stroke').setOnClick(() => this.createDefinedTerm())
            .setId('term').build();
        this.selectionToolsForbiddenPhraseButton = new IconNgBuilder(forbiddenSvg, this.editor.selectionTools)
            .setOnClick(() => this.createForbiddenTerm())
            .setId('forbidden').build();
    }
    unload() {
        this.createTermModal.remove();
        this.selectionToolsDefinedTermButton.remove();
        this.selectionToolsForbiddenPhraseButton.remove();
    }
    createDefinedTerm(range = this.editor.selectionTools.lastRange) {
        this.createTerminology(i18next.t("editor.createTermModal.definedTerm"), i18next.t("editor.createTermModal.explanation"), "TERM", range);
    }
    createForbiddenTerm(range = this.editor.selectionTools.lastRange) {
        this.createTerminology(i18next.t("editor.createTermModal.forbiddenPhrase"), i18next.t("editor.createTermModal.justification"), "FORBIDDEN", range);
    }
    createTerminology(header, description, type, range = this.editor.selectionTools.lastRange) {
        if (!range)
            return;
        this.createTermModal.header = header;
        this.createTermModal.descriptionLabelText = description;
        this.createTermModal.open = true;
        this.createTermModal.callback = () => {
            var _a, _b, _c;
            const term = {
                id: uuidv4(),
                type: type,
                targetText: range.toString().toLocaleLowerCase(this.editor.content.lang),
                sourceText: this.createTermModal.sourceText,
                description: this.createTermModal.description,
                creationSource: 'WORKFLOW',
                count: (((_c = (_b = (_a = this.editor.activeAsset) === null || _a === void 0 ? void 0 : _a.sourceText) === null || _b === void 0 ? void 0 : _b.toLocaleLowerCase()) === null || _c === void 0 ? void 0 : _c.match(new RegExp(this.createTermModal.sourceText.toLocaleLowerCase(), 'g'))) || []).length
            };
            this.editor.insertTerminology(range, term);
            this.createTerm(term);
        };
    }
    async createTerm(term) {
        try {
            const newSubAsset = {
                "id": term.id,
                "type": term.type,
                "sourceText": term.sourceText,
                "text": term.targetText,
                "description": term.description
            };
            await createTerminology(this.editor.assignmentId, newSubAsset);
            HTTPLogger.info(`User - Created term - ${term.type}`);
            this.editor.commit(this.editor.activeAsset);
            const assets = Array.from(this.editor.content.querySelectorAll("xfl-new-editor-asset"));
            setTimeout(() => {
                this.editor.fetchAssetsTerminology(assets.map(e => e.assetId));
            }, 1000);
        }
        catch (error) {
            this.editor.setState({ error });
            HTTPLogger.error(`User - Failed to create term - ${term.type}`, error);
        }
    }
}
