import Keycloak from "keycloak-js";
const _kc = Keycloak({ url: window.location.origin + "/auth", clientId: "exfluency-frontend", realm: "exfluency-realm" });
const initKeycloak = (onAuthenticatedCallback) => {
    _kc.init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/auth/silent-check-sso.html',
        pkceMethod: 'S256'
    })
        .then((authenticated) => {
        if (!authenticated && !unauthenticatedPath()) {
            doLogin();
        }
        onAuthenticatedCallback();
    })
        .catch(console.error);
};
const unauthenticatedPath = () => ['/logout', '/forgot-password', '/signup', '/resend-link', '/terms-of-service', '/privacy-notice', '/api/auth/signup/active'].some(e => e === window.location.pathname)
    || window.location.pathname.includes('/verify-email') || window.location.pathname.includes('/reset-password')
    || window.location.pathname.includes('/auth')
    || window.location.pathname.includes('/translation-certificate');
const doLogin = _kc.login;
const doLogout = _kc.logout;
const getToken = () => _kc.token;
const isLoggedIn = () => !!_kc.token;
const updateToken = (successCallback) => _kc.updateToken(10)
    .then(successCallback)
    .catch(doLogin);
const getUsername = () => { var _a; return (_a = _kc.profile) === null || _a === void 0 ? void 0 : _a.username; };
const getTokenUserInfo = () => {
    const token = _kc.tokenParsed;
    return {
        id: (token === null || token === void 0 ? void 0 : token.exfluencyId) || '',
        login: (token === null || token === void 0 ? void 0 : token.preferred_username) || '',
        name: (token === null || token === void 0 ? void 0 : token.given_name) || '',
        surname: (token === null || token === void 0 ? void 0 : token.family_name) || '',
        email: (token === null || token === void 0 ? void 0 : token.email) || '',
        currency: (token === null || token === void 0 ? void 0 : token.exfluencyCurrency) || '',
        timezone: (token === null || token === void 0 ? void 0 : token.exfluencyTimezone) || 'Europe/Warsaw'
    };
};
const hasRole = (role) => _kc.hasResourceRole(role, 'exfluency-workflow');
/**
 * Check if user has any from given roles
 *
 * @param roles - array of roles to check
 * @returns true when user has any role from array.
 */
const hasAnyRole = (roles) => {
    if (roles.length === 0)
        return false;
    return roles.some(role => hasRole(role));
};
/**
 * Check if user has all following roles
 *
 * @param roles - array of roles to check
 * @returns true when user has all following roles.
 */
const hasRoles = (roles) => {
    if (roles.length === 0)
        return false;
    return roles.every(role => hasRole(role));
};
const UserService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    getUsername,
    hasRole,
    hasRoles,
    hasAnyRole,
    unauthenticatedPath,
    getTokenUserInfo
};
export default UserService;
