import React from 'react';
import { Button, Icon, Loader } from 'semantic-ui-react';
import styled from 'styled-components';
const FileWrapper = styled.div `
  display: flex;
  justify-content: space-between;
  padding: .2rem;
`;
const FileName = styled.div `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: .2rem;
  cursor: ${({ readonly }) => readonly ? 'pointer' : 'auto'};
  text-decoration: ${({ readonly }) => readonly ? 'underline' : 'none'};
`;
const StyledLoader = styled(Loader) `
  &&&& {
    padding-right: 5rem;
    &:after {
      border-color: #767676 #ddd #ddd;
    }
  }
`;
const IconNoMargin = styled(Icon) `
  margin: 0 !important; 
`;
const FileRecord = ({ stageAttachment, uploadingFile, deleteUploadedFile, downloadUploadedFile, readonly = true }) => {
    return (React.createElement("div", null,
        React.createElement(FileWrapper, null, stageAttachment && React.createElement(React.Fragment, null,
            React.createElement(FileName, { readonly: readonly, onClick: () => downloadUploadedFile && downloadUploadedFile(stageAttachment.fileId, stageAttachment.name) },
                React.createElement(Icon, { color: 'black', name: 'file outline' }),
                stageAttachment.name,
                readonly && React.createElement(Icon, { color: 'black', name: 'attach' })),
            !readonly && React.createElement(Button, { inverted: true, color: "red", size: "mini", onClick: () => deleteUploadedFile && deleteUploadedFile(stageAttachment.fileId) },
                React.createElement(IconNoMargin, { name: 'trash alternate outline' })))),
        uploadingFile && React.createElement(FileWrapper, null,
            React.createElement(FileName, null,
                React.createElement(Icon, { color: 'black', name: 'file outline' }),
                uploadingFile),
            React.createElement(StyledLoader, { active: true, inline: true, inverted: true, size: "small" }))));
};
export default FileRecord;
