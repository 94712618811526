import React from 'react';
import { withTranslation } from 'react-i18next';
import { Header, Icon, Segment, Statistic, Table } from 'semantic-ui-react';
import ErrorModal from 'errors/components/ErrorModal';
import Star from 'helpers/components/Star';
import ExfluencyLoader from 'helpers/components/loader/ExfluencyLoader';
import { getTrustChainData } from 'api-operations/trustChain';
import { Styled } from 'StyledComponents';
class TrustChainView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorWindowMessage: null,
            loading: true,
            trustChainData: null,
            sortOrders: {
                languagePairs: {
                    column: 'score',
                    direction: 'descending'
                },
                taxonomyKeys: {
                    column: 'score',
                    direction: 'descending'
                }
            }
        };
    }
    async componentDidMount() {
        await this.loadData();
    }
    async loadData() {
        const { match } = this.props;
        try {
            const res = await getTrustChainData(match.params.userName);
            this.setState({
                trustChainData: res,
                loading: false
            });
        }
        catch (error) {
            this.setState({ errorWindowMessage: error });
        }
    }
    getSortDirection(table, column) {
        const { sortOrders } = this.state;
        return table == 'languagePairs'
            ? (sortOrders.languagePairs.column == column ? sortOrders.languagePairs.direction : undefined)
            : (sortOrders.taxonomyKeys.column == column ? sortOrders.taxonomyKeys.direction : undefined);
    }
    switchDirection(direction) {
        return direction == 'ascending' ? 'descending' : 'ascending';
    }
    handleSort(table, column) {
        const { sortOrders, trustChainData } = this.state;
        sortOrders[table].direction = sortOrders[table].column == column
            ? this.switchDirection(sortOrders[table].direction)
            : 'ascending';
        sortOrders[table].column = column;
        const tableToSort = table == 'languagePairs' ? trustChainData.languagePairs : trustChainData.taxonomyKeys;
        tableToSort.sort(this.compareRecords(column, sortOrders[table].direction));
        this.setState({ sortOrders, trustChainData });
    }
    compareRecords(column, direction) {
        switch (column) {
            case 'languageFrom':
            case 'languageTo':
                return (a, b) => this.compareLanguages(a[column], b[column], direction);
            case 'score':
                return (a, b) => this.compareScores(a[column], b[column], direction);
            default:
                return (a, b) => (direction == 'ascending' ? a[column] < b[column] : a[column] > b[column]) ? -1 : 1;
        }
    }
    compareLanguages(a, b, direction) {
        const { t } = this.props;
        return (direction == 'ascending' ? t(`languages.${a}`) < t(`languages.${b}`) : t(`languages.${a}`) > t(`languages.${b}`)) ? -1 : 1;
    }
    compareScores(a, b, direction) {
        if (a === null && b === null)
            return 0;
        if (a === null && b !== null)
            return 1;
        if (b === null && a !== null)
            return -1;
        return (direction == 'ascending' ? a < b : a > b) ? -1 : 1;
    }
    render() {
        const { match, t } = this.props;
        const { errorWindowMessage, loading, trustChainData } = this.state;
        if (loading) {
            return React.createElement(ExfluencyLoader, null);
        }
        if (errorWindowMessage !== null) {
            return React.createElement(ErrorModal, { ...errorWindowMessage, onClose: () => window.location.reload() });
        }
        return React.createElement(React.Fragment, null,
            React.createElement("h1", null, match.params.userName ? t('trustChain.usersTrustChain', { userName: match.params.userName }) : t('trustChain.yourTrustChain')),
            trustChainData && React.createElement(React.Fragment, null,
                React.createElement(Segment, { textAlign: "center" },
                    React.createElement(Statistic, null,
                        React.createElement(Statistic.Value, null,
                            React.createElement(Star, { value: trustChainData.exfluenscore ? trustChainData.exfluenscore / 9.0 : null }),
                            "\u00A0",
                            trustChainData.exfluenscore ? trustChainData.exfluenscore.toFixed(1) : '---',
                            React.createElement(Styled.StatisticSecondary, null, trustChainData.exfluenscore !== null && '/9')))),
                trustChainData.exfluenscore === undefined && React.createElement(Segment, { placeholder: true },
                    React.createElement(Header, { icon: true },
                        React.createElement(Icon, { name: "language" }),
                        t("trustChain.noEntriesWithinRecent12Months"))),
                trustChainData.exfluenscore !== null && React.createElement(React.Fragment, null,
                    React.createElement("h2", null, t("trustChain.languages")),
                    React.createElement(Table, { sortable: true },
                        React.createElement(Table.Header, null,
                            React.createElement(Table.Row, null,
                                React.createElement(Table.HeaderCell, { width: 6, textAlign: "center", sorted: this.getSortDirection("languagePairs", "languageFrom"), onClick: () => this.handleSort('languagePairs', 'languageFrom') }, t('trustChain.sourceLanguage')),
                                React.createElement(Table.HeaderCell, { width: 6, textAlign: "center", sorted: this.getSortDirection("languagePairs", "languageTo"), onClick: () => this.handleSort('languagePairs', 'languageTo') }, t('trustChain.targetLanguage')),
                                React.createElement(Table.HeaderCell, { width: 2, textAlign: "center", sorted: this.getSortDirection("languagePairs", "assetCount"), onClick: () => this.handleSort('languagePairs', 'assetCount') }, t('trustChain.assetsTranslated')),
                                React.createElement(Table.HeaderCell, { width: 2, textAlign: "center", sorted: this.getSortDirection("languagePairs", "score"), onClick: () => this.handleSort('languagePairs', 'score') }))),
                        React.createElement(Table.Body, null, trustChainData.languagePairs.map(({ languageFrom, languageTo, assetCount, score }) => React.createElement(Table.Row, { key: `${languageFrom}->${languageTo}` },
                            React.createElement(Table.Cell, null, t(`languages.${languageFrom}`)),
                            React.createElement(Table.Cell, null, t(`languages.${languageTo}`)),
                            React.createElement(Table.Cell, { textAlign: "center" }, assetCount),
                            React.createElement(Table.Cell, { textAlign: "center" },
                                React.createElement(Star, { value: score && score / 9.0 }),
                                "\u00A0",
                                score !== null ? score.toFixed(1) : "---",
                                score !== null && React.createElement(Styled.TranslucentSpan, null, "/9")))))),
                    React.createElement("h2", null, t("trustChain.skillsetNodes")),
                    React.createElement(Table, { sortable: true },
                        React.createElement(Table.Header, null,
                            React.createElement(Table.Row, null,
                                React.createElement(Table.HeaderCell, { width: 12, textAlign: "center", sorted: this.getSortDirection("taxonomyKeys", "taxonomyKey"), onClick: () => this.handleSort('taxonomyKeys', 'taxonomyKey') }, t('trustChain.skillsetNode')),
                                React.createElement(Table.HeaderCell, { width: 2, textAlign: "center", sorted: this.getSortDirection("taxonomyKeys", "assetCount"), onClick: () => this.handleSort('taxonomyKeys', 'assetCount') }, t('trustChain.assetsTranslated')),
                                React.createElement(Table.HeaderCell, { width: 2, textAlign: "center", sorted: this.getSortDirection("taxonomyKeys", "score"), onClick: () => this.handleSort('taxonomyKeys', 'score') }))),
                        React.createElement(Table.Body, null, trustChainData.taxonomyKeys.map(({ taxonomyKey, assetCount, score }) => React.createElement(Table.Row, { key: taxonomyKey },
                            React.createElement(Table.Cell, null, taxonomyKey),
                            React.createElement(Table.Cell, { textAlign: "center" }, assetCount),
                            React.createElement(Table.Cell, { textAlign: "center" },
                                React.createElement(Star, { value: score && score / 9.0 }),
                                "\u00A0",
                                score !== null ? score.toFixed(1) : "---",
                                score !== null && React.createElement(Styled.TranslucentSpan, null, "/9")))))))));
    }
}
export default withTranslation('common')(TrustChainView);
