import { getPaginationParamsString } from './../common';
import axios from 'axios';
import { downloadFile } from 'api-operations/common';
// Temporary, until we get rid of perspective and merge executors' dashbords into one.
export function perspectiveToStageList(perspective) {
    switch (perspective) {
        case 'enhancer': return ['ENHANCEMENT', 'CORRECTION'];
        case 'trustminer': return ['TRUST_MINING', 'CORRECTION'];
        case 'redactor': return ['REDACTION', 'FINAL_REDACTION', 'CORRECTION'];
        default: return ['CORRECTION'];
    }
}
export async function claimAssignment(assignmentId) {
    await axios.put(`/api/workflow/${assignmentId}/accept`);
}
export async function passAssignment(assignmentId) {
    await axios.put(`/api/workflow/${assignmentId}/pass`);
}
export async function getAssignmentReport(assignmentId) {
    const response = await axios.get(`/api/assignment/assignment/${assignmentId}/report`);
    return response.data;
}
export const getAssignments = async (stages, status, paginationFilterOptions) => {
    const response = await axios.get(`api/workflow/executor?stages=${stages.join(",")}&status=${status}&${getPaginationParamsString(paginationFilterOptions)}`);
    return response.data;
};
export async function getAssignmentsCsv(stages, pagiantionOptions) {
    await downloadFile(`/api/workflow/executor/csv?stages=${stages.join(",")}&${getPaginationParamsString(pagiantionOptions)}`);
}
export async function getAssignmentsSummary(stages, pagiantionOptions) {
    const response = await axios.get(`/api/workflow/executor/summary?stages=${stages.join(",")}&${getPaginationParamsString(pagiantionOptions)}`);
    return response.data;
}
export async function rejectAssignmentCancel(assignmentID) {
    await axios.post(`/api/workflow/assignment/${assignmentID}/rejectCancel`);
}
export async function confirmAssignmentCancellation(assignmentID) {
    await axios.post(`/api/workflow/assignment/${assignmentID}/confirmCancel`);
}
export async function getAssignmentSections(assignmentId) {
    const response = await axios.get(`/api/workflow/executor/${assignmentId}/jobSections`);
    return response.data;
}
