import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';
import { parseTimestamp } from 'helpers/DateHandler';
import { parsePrice } from 'helpers/utils';
const UserCurrency = styled.div `
  font-size: .7rem;
`;
const CurrencyWrapper = styled.div `
  max-width: 10rem;
  min-width: 6rem;
`;
const PriceWithExchangeRatePopup = ({ cost, customCurrencyCost, exchangeRate }) => {
    const { t } = useTranslation('common');
    return React.createElement(Popup, { hoverable: true, trigger: React.createElement(CurrencyWrapper, null,
            React.createElement("div", null,
                cost.currency,
                " ",
                parsePrice(cost.value)),
            customCurrencyCost && React.createElement(UserCurrency, null,
                "(",
                customCurrencyCost.currency,
                " ~",
                parsePrice(customCurrencyCost.value),
                ")")), disabled: !customCurrencyCost },
        React.createElement("div", null,
            t('dashboard.exchangeRate'),
            ": ",
            React.createElement("b", null, exchangeRate === null || exchangeRate === void 0 ? void 0 : exchangeRate.rate)),
        React.createElement("div", null,
            t('dashboard.lastUpdate'),
            " ",
            parseTimestamp(exchangeRate === null || exchangeRate === void 0 ? void 0 : exchangeRate.date),
            "."),
        React.createElement("div", null,
            React.createElement("b", null, t('dashboard.exchangeNotice'))));
};
export default PriceWithExchangeRatePopup;
