import React, { useEffect } from 'react';
import styled from 'styled-components';
import TaxonomySelector from 'helpers/components/taxonomySelector/TaxonomySelector';
import { useLogger } from 'logger/Logger';
const SkillsetNode = styled.div `
  display: flex;
	flex-direction: column;
  align-items: stretch;
    
  & > div {
    width: 20rem;
    align-self: center;
    padding: .3em;
    border: .1em solid $exfluency-blue;
    border-radius: .2em;
  }
`;
const Taxonomy = ({ setTaxonomy, taxonomy }) => {
    const { logger } = useLogger();
    useEffect(() => {
        logger.info('User - Entered select taxonomy step');
    }, []);
    return React.createElement(SkillsetNode, null,
        React.createElement(TaxonomySelector, { value: taxonomy ? taxonomy : '', dialogHeader: "Select subject-matter nodes", placeholder: "Select subject-matter nodes", onChange: setTaxonomy, dialogOpen: true }));
};
export default Taxonomy;
