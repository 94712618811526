import axios from "axios";
export async function getMyGatedComunities(isMember) {
    const response = await axios.get(`/api/groups_management/communities/my`, {
        params: { isMember }
    });
    return response.data;
}
export async function leaveGatedCommunity(communityId) {
    await axios.put(`/api/groups_management/communities/${communityId}/leave`);
}
export async function requestMembershipToGatedCommunity(communityId) {
    await axios.post(`/api/groups_management/communities/${communityId}/requestMembership`);
}
export async function getGatedComunitiesImNotMember() {
    const response = await axios.get('/api/groups_management/communities/notMember');
    return response.data;
}
