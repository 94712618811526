import './style.scss';
export default class InfiniteScrollLoader extends HTMLElement {
    constructor(direction) {
        super();
        this.loader = document.createElement("div");
        this.loadMoreContainer = document.createElement("div");
        this.text = document.createElement("p");
        this.onCallback = () => { };
        this.contentEditable = 'false';
        this.className = `${direction} hidden`;
        const icon = document.createElement('i');
        icon.className = 'icon redo';
        this.loadMoreContainer.className = 'loadMore hidden';
        this.loader.className = 'loader';
        this.loader.innerHTML = '<div></div><div></div><div></div><div></div>';
        this.text.className = 'text';
        this.text.textContent = 'Click to load more';
        this.loadMoreContainer.appendChild(icon);
        this.loadMoreContainer.appendChild(this.text);
        this.appendChild(this.loader);
        this.appendChild(this.loadMoreContainer);
        this.addEventListener('click', this.loadMore);
    }
    loadMore() {
        this.onCallback();
        this.loaderHidden = false;
        this.loadHidden = true;
        this.callback = undefined;
    }
    set callback(callback) {
        if (!callback) {
            this.style.cursor = 'auto';
            return;
        }
        this.loadMoreContainer.classList.remove('hidden');
        this.loaderHidden = true;
        this.hidden = true;
        this.style.cursor = 'pointer';
        this.onCallback = callback;
    }
    set hidden(value) {
        value
            ? this.classList.add('hidden')
            : this.classList.remove('hidden');
    }
    set loaderHidden(value) {
        value
            ? this.loader.classList.add('hidden')
            : this.loader.classList.remove('hidden');
    }
    set loadHidden(value) {
        value
            ? this.loadMoreContainer.classList.add('hidden')
            : this.loadMoreContainer.classList.remove('hidden');
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-infinite-scroll-loader", InfiniteScrollLoader));
