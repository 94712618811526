import axios from "axios";
export async function getReputationBoard(scope, groupId, sourceLanguage, targetLanguage, skillsetNode) {
    const url = new URL(new URL(window.location.href).origin);
    let mode = "";
    if (sourceLanguage !== null && targetLanguage !== null) {
        mode = "/language";
        url.searchParams.append("sourceLanguage", sourceLanguage);
        url.searchParams.append("targetLanguage", targetLanguage);
    }
    else if (skillsetNode !== null) {
        mode = "/skillsetNode";
        url.searchParams.append("skillsetNode", skillsetNode);
    }
    url.pathname = `/api/assignment/reputationBoard/${scope}${groupId ? `/${groupId}` : ''}${mode}`;
    const response = await axios.get(url.toString());
    return response.data;
}
