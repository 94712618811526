import React from "react";
import moment from "moment";
import { Button, Popup } from "semantic-ui-react";
const INVALID_AFTER = 30;
const JobPreviewButton = ({ job, validate = false, onClick }) => {
    const id = job.lastAccessibleAssignmentId || job.jobLastAccessibleAssignmentId;
    const isOutdated = validate
        ? moment().isAfter(moment(job.deliveryDate || job.dueDate).add(INVALID_AFTER, 'days'))
        : false;
    const label = isOutdated
        ? `Preview is not available after ${INVALID_AFTER} days of completion`
        : "Show in editor";
    return React.createElement(React.Fragment, null, id
        ? React.createElement(Popup, { basic: true, position: "top center", hoverable: true, content: label, trigger: React.createElement("span", null,
                React.createElement(Button, { icon: "eye", disabled: isOutdated, "data-testid": 'preview_btn', onClick: () => onClick(id) })) }) : null);
};
export default JobPreviewButton;
