import axios from 'axios';
export async function login(username, password) {
    await axios.post('/api/auth/login', { username: username, password: password });
}
export async function checkLoginFail(username) {
    const response = await axios.get(`/api/auth/login/failed/${username}`);
    return response.data;
}
export async function resendEmailActivationLink(username) {
    await axios.get(`/api/auth/login/resend-activation/${username}`);
}
export async function signup(creds) {
    await axios.post('/api/auth/signup', creds);
}
export async function availability(creds) {
    const response = await axios.post('/api/auth/signup/availability', creds);
    return response.data;
}
export async function changePassword(oldPassword, newPassword) {
    await axios.post('/api/auth/password/change', { oldPassword, newPassword });
}
export async function logout() {
    await axios.post('/api/auth/logout');
}
export async function verifyEmail(token) {
    await axios.get(`/api/auth/signup/verify`, {
        params: { token }
    });
}
export async function sendPasswordForgotMail(userName, mail, redirectURL) {
    await axios.post(`/api/auth/password/forgot/send`, undefined, {
        params: { userName, mail, redirectURL }
    });
}
export async function setNewPasswordAfterForgetting(token, password) {
    await axios.post(`/api/auth/password/forgot/change`, password, {
        params: { token }
    });
}
export async function checkIfSignUpIsActive() {
    const response = await axios.post(`/api/auth/signup/active`);
    return response.data;
}
