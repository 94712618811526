import styled from "styled-components";
export const Card = styled.div `
    font-family: 'Inter';
    height: 260px;
    min-width: 230px; // prevents strange behavior when width: 230px card will colapse if content is overflowed
    width: 230px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: ${({ error }) => error ? '1px solid #EC5351' : 'none'};
  `;
export const CardContent = styled.div `
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0px 2px 8px rgba(9, 30, 66, 0.08);
    
  `;
export const Progress = styled.progress `
    appearance: none;
    width: 100%;
    border-radius:  8px 8px 0px 0px;
    background-color: #F4F5F7;
    border: none;
    overflow: hidden;
    height: 4px;
  
    &:indeterminate {
      @keyframes indeterminate-progress-animation {
        from {
          margin-left: -2rem
        }
  
        to {
            margin-left: 100%
          }
        }
        
      &::-moz-progress-bar {
        width: 2rem;
        animation: indeterminate-progress-animation 1s ease infinite;
        transition: none;
      }
  
      &::-webkit-progress-value {
        width: 2rem;
        animation: indeterminate-progress-animation 1s ease infinite;
        transition: none;
      }
    }
  
    &::-moz-progress-bar {
      background-color: #3A65F6;
      border-radius:  8px 8px 0px 0px;
      animation: indeterminate-progress-animation 0s ease 0;
      /* Seems like a bug in firefox – won't stop animating without this */
    }
  
    &::-webkit-progress-value {
      background-color: #3A65F6;
      border-radius:  8px 8px 0px 0px;
      animation: indeterminate-progress-animation 0s ease 0;
      /* Seems like a bug in firefox – won't stop animating without this */
    }
  
    &::-webkit-progress-bar {
      height: 4px;
      background-color: transparent;
    }
  `;
export const Content = styled.div `
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: 12px 16px 10px;
  `;
export const FileName = styled.div `
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #42526E;
    word-wrap: break-word;
  `;
export const Stage = styled.div `
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #BFC4CF;
  `;
export const Username = styled.div `
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #8C97A8;
  `;
export const DueDate = styled.div `
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${({ state }) => state === 'left' ? '#8C97A8' : state === 'near deadline' ? '#EE9C3C' : '#EC5351'};

    path {
      fill: ${({ state }) => state === 'left' ? '#8C97A8' : state === 'near deadline' ? '#EE9C3C' : '#EC5351'};
    }
  `;
export const Footer = styled.div `
    box-sizing: border-box;
    height: 48px;
    padding: 8px 16px;
    border-top: 1px solid #E4E5EC;
  `;
export const IconsWrapper = styled.div `
    display: flex;
    justify-content: space-between;
  `;
export const IconWrapper = styled.span `
    cursor: pointer;
  `;
export const ArrowWrapper = styled.div `
    &:hover {
      animation-name: example;
      animation-duration: .8s;
      animation-timing-function: ease-out; 
      animation-delay: 0;
      animation-direction: alternate;
      animation-iteration-count: infinite;
    }

  @keyframes example {
    from {transform: translateX(0px)}
    to {transform: translateX(10px)}
  }
  `;
export const CancelAssignment = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  height: 88px;
  padding: 8px;
  background: #FFF8F8;
  border-top: 1px solid #EC5351;
  border-radius: 4px 4px 0 0;
  color: #EC5351;
`;
export const ResponseButton = styled.button `
  padding: 2px 12px;
  background: #3A65F6;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #254ED9;
  }
`;
export const FileNameOneLine = styled.div `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
