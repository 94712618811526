import React, { useState, useEffect } from 'react';
import { Container } from 'semantic-ui-react';
import { getMyLanguageSkills } from 'api-operations/myExfluency/myExfluency';
import SkillsTable from './SkillsTable';
import ErrorModal from 'errors/components/ErrorModal';
import ExfluencyLoader from 'helpers/components/loader/ExfluencyLoader';
const MySkills = () => {
    const [userLanguageSkills, setUserLanguageSkills] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorWindowMessage, setErrorWindowMessage] = useState(null);
    const fetchData = async () => {
        try {
            const res = await getMyLanguageSkills();
            setUserLanguageSkills(res);
        }
        catch (error) {
            setErrorWindowMessage(null);
            return;
        }
        setLoading(false);
    };
    useEffect(() => {
        fetchData();
    }, []);
    if (errorWindowMessage !== null)
        return React.createElement(ErrorModal, { ...errorWindowMessage, onClose: () => window.location.reload() });
    if (loading)
        return React.createElement(ExfluencyLoader, null);
    return React.createElement(Container, null,
        React.createElement(SkillsTable, { userLanguageSkills: userLanguageSkills }));
};
export default MySkills;
