import moment from "moment";
import i18next from "i18next";
export const getImagePathByRole = (role) => {
    switch (role) {
        case 'ROLE_ENHANCER':
            return require('assets/images/menu/exfluency_Enhancers.svg');
        case 'ROLE_POLISHER':
            return require('assets/images/menu/exfluency_Polisher.svg');
        case 'ROLE_BUYER':
            return require('assets/images/menu/exfluency_Consumer.svg');
        case 'ROLE_LANGUAGE_MINER':
            return require('assets/images/menu/exfluency_Miner.svg');
        case 'ROLE_TRUST_MINER':
            return require('assets/images/menu/exfluency_TrustMiner.svg');
        case 'ROLE_REDACTOR':
            return require('assets/images/menu/exfluency_Redactor.svg');
    }
    return '';
};
export const showDateCountdown = (dueDate, deliveryDate, showAppendix = true, precison = 2) => {
    if (!dueDate)
        return '';
    const MINUTES_IN_DAY = 1440;
    const MINUTES_IN_HOUR = 60;
    const createDate = (values) => {
        const tempValues = values;
        showAppendix && tempValues.push(appendix);
        return tempValues.join(' ');
    };
    let diffMinutes = Math.floor(moment.duration(moment(dueDate).diff(deliveryDate ? deliveryDate : moment())).asMinutes());
    const timeLeft = [];
    const appendix = diffMinutes >= 0 ? 'left' : 'overdue';
    diffMinutes = Math.abs(diffMinutes);
    if (diffMinutes / MINUTES_IN_DAY > 1) {
        timeLeft.push(Math.floor(diffMinutes / MINUTES_IN_DAY) + ' day(s)');
        diffMinutes = diffMinutes % MINUTES_IN_DAY;
    }
    if (timeLeft.length == precison)
        return createDate(timeLeft);
    if (diffMinutes / MINUTES_IN_HOUR > 1) {
        timeLeft.push(Math.floor(diffMinutes / MINUTES_IN_HOUR) + ' hour(s)');
        diffMinutes = diffMinutes % MINUTES_IN_HOUR;
    }
    if (timeLeft.length == precison)
        return createDate(timeLeft);
    if (timeLeft.length <= precison) {
        timeLeft.push(diffMinutes + ' minute(s)');
    }
    return createDate(timeLeft);
};
export const secondsToReadableUnits = (seconds) => {
    const SECONDS_IN_MINUTE = 60;
    const SECONDS_IN_HOUR = 3600;
    const SECONDS_IN_DAY = 86400;
    if (seconds < 60)
        return '0 minute(s)';
    let diffSeconds = seconds;
    const timeLeft = [];
    let isDaysPresent = false;
    if (diffSeconds / SECONDS_IN_DAY >= 1) {
        timeLeft.push(Math.floor(diffSeconds / SECONDS_IN_DAY) + ' day(s)');
        diffSeconds = diffSeconds % SECONDS_IN_DAY;
        isDaysPresent = true;
    }
    if (diffSeconds / SECONDS_IN_HOUR >= 1) {
        timeLeft.push(Math.floor(diffSeconds / SECONDS_IN_HOUR) + ' hour(s)');
        diffSeconds = diffSeconds % SECONDS_IN_HOUR;
    }
    if (diffSeconds / SECONDS_IN_MINUTE >= 1 && !isDaysPresent) {
        timeLeft.push(Math.floor(diffSeconds / SECONDS_IN_MINUTE) + ' minute(s)');
        diffSeconds = diffSeconds % SECONDS_IN_MINUTE;
    }
    return timeLeft.join(' ');
};
export const getOptionsDropdownFromArray = (array, translationPath) => array.map(ele => ({ value: ele, key: ele, text: translationPath ? i18next.t(`${translationPath}.${ele}`) : ele }));
export const getDashboardOptionsFromStorage = (tableIdentifier, key, options) => {
    var _a;
    const storageValue = JSON.parse(localStorage.getItem(`${tableIdentifier}PaginationOptions`) || JSON.stringify({ key: options[0] }))[key];
    if (options.find(option => option.value === storageValue)) {
        return storageValue;
    }
    return ((_a = options[0]) === null || _a === void 0 ? void 0 : _a.value) || '';
};
export const rowsOptions = [
    { key: '5', text: '5', value: 5 },
    { key: '10', text: '10', value: 10 },
    { key: '25', text: '25', value: 25 },
    { key: '50', text: '50', value: 50 }
];
export const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const parsePrice = (price) => (Math.round(price * 100) / 100).toFixed(2);
export const isSubDomainContains = (subDomains) => subDomains.some(subDomain => window.location.hostname.split('.').slice(0, -2).join('.').includes(subDomain));
/**
 *
 * @param value String to trim
 * @param length Overall returned string (without "...") and near whitespaces. Default 30
 * @param secondPartLength length after "...". Default 8
 * @returns Trimed string
 */
export const trimString = (value, length = 30, secondPartLength = 8) => {
    return value.length > length
        ? (value.substring(0, length - secondPartLength).trim() + "..." + value.slice(value.length - secondPartLength).trim())
        : value;
};
/**
 *
 * @param currentDate Point in time from which will outcome be decided
 * @param startDate Start date
 * @param endDate  End date
 * @param percent Value 0-1 to define offset
 * @returns Representing if currentDate is within correct date range based on provided percent
 */
export const isDateNearDeadline = (currentDate, startDate, endDate, percent) => {
    const diff = moment(endDate).unix() - moment(startDate).unix();
    const newDiff = diff * percent;
    let newDate = moment(endDate);
    newDate.subtract(newDiff, 's');
    newDate = moment(newDate.toString());
    return moment(currentDate).isBetween(newDate, moment(endDate));
};
/**
 *
 * @param currentDate Point in time from which will outcome be decided
 * @param startDate Start date
 * @param endDate End date
 * @param percent Value 0-1 to define offset
 * @returns 'left' | 'near deadline' | 'overdue'
 */
export const remainingDateState = (currentDate, startDate, endDate, percent) => {
    if (moment(currentDate).isAfter(endDate))
        return 'overdue';
    if (isDateNearDeadline(currentDate, startDate, endDate, percent))
        return 'near deadline';
    if (moment(currentDate).isBefore(endDate))
        return 'left';
    return 'left';
};
/**
 * Format file size to human readable text
 *
 * @param size File size in Bytes
 */
export function formatFileSize(size) {
    if (size < 10000) {
        return `${size} B`;
    }
    else if (size < 1000000) {
        return `${(size / 1000).toFixed(undefined)} KB`;
    }
    return `${(size / 1000000).toFixed(2)} MB`;
}
