import React, { useState, useEffect } from 'react';
import { Redirect, Route, useParams, useRouteMatch, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Switch, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Icon, List, Menu, Modal } from 'semantic-ui-react';
import ErrorModal from 'errors/components/ErrorModal';
import MembersPane from './membersPane/MembersPane';
import ExfluencyLoader from 'helpers/components/loader/ExfluencyLoader';
import ReputationBoard from 'reputationBoard/ReputationBoard';
import Contact from './contact/Contact';
import CompanyAssignments from './earnings/CompanyAssignments';
import CompanyJobs from './expenses/CompanyJobs';
import Invoices from './invoices/Invoices';
import { saveCompanyCurrency, saveCompanyExchangeRate } from 'reduxStore/actions/actions';
import { changeCompanyName, getCompanyInfo } from 'api-operations/company/admin';
import CompanyChangeNameModal from './CompanyChangeNameModal';
import { toast } from 'react-toastify';
const H2 = styled.h2 `
  text-align: center;
`;
const StyledNavLink = styled(NavLink) `
  &&&&:hover {
   background-color: rgba(0,0,0,.03)
  }

  &&&&.active {
    background-color: rgba(0,0,0,.05);
  }
`;
const CompanyPanel = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [companyInfo, setCompanyInfo] = useState(null);
    const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
    const [companyNameChangeModal, setCompanyNameChangeModal] = useState(false);
    const [errorWindowMessage, setErrorWindowMessage] = useState(null);
    const params = useParams();
    const { path, url } = useRouteMatch();
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation('common');
    useEffect(() => {
        (async () => {
            await getCompany();
            setIsLoading(false);
        })();
    }, []);
    const getCompany = async () => {
        try {
            const company = await getCompanyInfo();
            setCompanyInfo(company);
            dispatch(saveCompanyExchangeRate(company.exchangeRate));
            dispatch(saveCompanyCurrency(company.currency));
            if (company.billingData) {
                const { billingInfo, contactPerson, paymentMethod } = company.billingData;
                if (!(billingInfo && contactPerson && paymentMethod)) {
                    setIsBillingModalOpen(true);
                }
            }
        }
        catch (error) {
            setErrorWindowMessage(error);
        }
    };
    const handleChangeCompanyName = async (newName) => {
        try {
            await changeCompanyName(params.id, newName);
            setCompanyInfo(prevState => ({ ...prevState, companyName: newName }));
            toast.success(t('notification.companyNameChanged'));
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
    };
    const getListIcon = (state) => React.createElement(List.Icon, { name: state ? 'check' : 'x', color: state ? 'green' : 'red' });
    if (errorWindowMessage !== null)
        return React.createElement(ErrorModal, { ...errorWindowMessage, onClose: () => window.location.reload() });
    if (isLoading)
        return React.createElement(ExfluencyLoader, null);
    if (companyInfo === null)
        return React.createElement(Redirect, { to: '/companies' });
    const isAdmin = companyInfo.profile == 'ORGANIZATION_ADMIN';
    if (!companyInfo)
        return React.createElement(Redirect, { to: '/companies' });
    return React.createElement(React.Fragment, null,
        React.createElement("h2", null,
            t('companies.company'),
            " - ",
            isAdmin
                ? React.createElement("span", { style: { cursor: 'pointer' }, onClick: () => setCompanyNameChangeModal(true) },
                    companyInfo.companyName,
                    " ",
                    React.createElement(Icon, { size: 'small', name: 'edit' }))
                : companyInfo.companyName),
        React.createElement(Menu, { stackable: true },
            isAdmin && React.createElement(Menu.Item, { as: StyledNavLink, to: `${url}/billing` }, t('companies.billing')),
            React.createElement(Menu.Item, { as: StyledNavLink, to: `${url}/members` }, t('groupsCommon.members')),
            React.createElement(Menu.Item, { as: StyledNavLink, to: `${url}/reputation-board` }, t('reputationBoard.reputationBoard')),
            isAdmin && React.createElement(Menu.Item, { as: StyledNavLink, to: `${url}/expenses` }, t('companies.expenses')),
            isAdmin && React.createElement(Menu.Item, { as: StyledNavLink, to: `${url}/earnings` }, t('companies.earnings')),
            isAdmin && React.createElement(Menu.Item, { as: StyledNavLink, to: `${url}/invoices` }, t('companies.invoices'))),
        React.createElement(Switch, null,
            React.createElement(Route, { path: `${path}/members` },
                React.createElement(MembersPane, { isAdmin: isAdmin })),
            React.createElement(Route, { path: `${path}/reputation-board` },
                React.createElement(ReputationBoard, { scope: 'company', groupId: params.id })),
            isAdmin && React.createElement(Route, { exact: true, path: `${path}/expenses` },
                React.createElement(CompanyJobs, null)),
            isAdmin && React.createElement(Route, { exact: true, path: `${path}/earnings` },
                React.createElement(CompanyAssignments, null)),
            isAdmin && React.createElement(Route, { exact: true, path: `${path}/invoices` },
                React.createElement(Invoices, null)),
            isAdmin && React.createElement(Route, { exact: true, path: `${path}/billing` },
                React.createElement(Contact, null)),
            React.createElement(Redirect, { from: `${path}/*`, to: `${path}${isAdmin ? '/billing' : '/members'}` }),
            React.createElement(Redirect, { from: `${path}`, to: `${path}${isAdmin ? '/billing' : '/members'}` })),
        !!companyInfo.billingData && React.createElement(Modal, { open: isBillingModalOpen },
            React.createElement(Modal.Header, null, t('companies.missingInfoHeader')),
            React.createElement(Modal.Content, null,
                t('companies.missingInfoDescription'),
                React.createElement("br", null),
                React.createElement(List, null,
                    React.createElement(List.Item, null,
                        getListIcon(companyInfo.billingData.billingInfo),
                        React.createElement(List.Content, null, t('companies.billingInfo'))),
                    React.createElement(List.Item, null,
                        getListIcon(companyInfo.billingData.contactPerson),
                        React.createElement(List.Content, null, t('companies.contactPerson'))),
                    React.createElement(List.Item, null,
                        getListIcon(companyInfo.billingData.paymentMethod),
                        React.createElement(List.Content, null, t('companies.payYou'))))),
            React.createElement(Modal.Actions, null,
                React.createElement(Button, { negative: true, onClick: () => setIsBillingModalOpen(false) }, t('common.cancel')),
                React.createElement(Button, { positive: true, onClick: () => { history.push(`${url}/billing`); setIsBillingModalOpen(false); } }, t('common.proceed')))),
        React.createElement(CompanyChangeNameModal, { open: companyNameChangeModal, setOpen: setCompanyNameChangeModal, onChange: handleChangeCompanyName }));
};
export default CompanyPanel;
