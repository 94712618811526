import React, { useState, useEffect, useRef } from 'react';
import { Grid, Pagination, Select } from 'semantic-ui-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getDashboardOptionsFromStorage, rowsOptions } from 'helpers/utils';
const PaginationWrapper = styled.div `
  text-align: center;
  padding: 20px 0;
`;
const StyledGrid = styled(Grid) `
  margin: 0 0 10px 0 !important;

  &&&& { 
  .row .column {
    padding-left: 0 !important;
  }

  .row:not(:first-child) {
    padding-top: 0 !important;
  }

  .row .column:last-child {
    padding-right: 0 !important;
  }
}
`;
const PagedExpenses = ({ children, totalResults, fetchData, tableIdentifier }) => {
    const { t } = useTranslation('common');
    const [limit, setLimit] = useState(getDashboardOptionsFromStorage(tableIdentifier, 'limit', rowsOptions));
    const activePage = useRef(1);
    useEffect(() => {
        localStorage.setItem(`${tableIdentifier}PaginationOptions`, JSON.stringify({ limit }));
    }, [limit]);
    useEffect(() => {
        activePage.current = 1;
        handlePageChange();
    }, [limit, activePage, fetchData]);
    const handlePageChange = (active) => {
        const offset = (active ? active : activePage.current) * limit - limit;
        fetchData && fetchData({ limit, offset });
        activePage.current = active ? active : activePage.current;
    };
    const totalPages = Math.ceil(totalResults / limit);
    return React.createElement(React.Fragment, null,
        React.createElement(StyledGrid, { verticalAlign: 'bottom', stackable: true, padded: true },
            React.createElement(Grid.Row, { columns: 16 },
                React.createElement(Grid.Column, { width: 1 },
                    React.createElement("label", null, t('pagination.rowsPerPage')),
                    React.createElement(Select, { name: 'rowsPerPage', onChange: (_, data) => { setLimit(data.value); }, options: rowsOptions, value: limit, fluid: true })))),
        children,
        totalResults > 0 && React.createElement(PaginationWrapper, null,
            React.createElement(Pagination, { totalPages: totalPages, activePage: activePage.current, onPageChange: (_, data) => handlePageChange(data.activePage) })));
};
export default PagedExpenses;
