import i18next from 'i18next';
class StatusBar extends HTMLElement {
    constructor() {
        super();
        this.section = document.createElement("div");
        this.assignment = document.createElement("div");
        this.appendChild(this.assignment);
        this.appendChild(this.section);
        this.totalAssetsValue = this.createStatusBarSection(`${i18next.t('editorStatusBar.totalAssets')}: `);
    }
    createStatusBarSection(label, section) {
        const sectionEl = document.createElement("div");
        const labelEl = document.createElement("span");
        labelEl.textContent = label;
        const valueEl = document.createElement("span");
        sectionEl.appendChild(labelEl);
        sectionEl.appendChild(valueEl);
        section ? this.section.appendChild(sectionEl) : this.assignment.appendChild(sectionEl);
        return valueEl;
    }
    get totalAssets() {
        return parseInt(this.totalAssetsValue.textContent || "");
    }
    set totalAssets(assets) {
        this.totalAssetsValue.textContent = assets.toString();
    }
}
export default StatusBar;
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-statusbar", StatusBar));
