import i18next from "i18next";
import EditorModule from "../EditorModule";
import AttachmentsPanel from "./AttachmentsPanel";
import "./style.scss";
import { createDivider, IconNgBuilder } from "editorNextGen/icons";
import { attahcmentSvg } from "editorNextGen/icons/attachment";
export default class AttachmentsModule extends EditorModule {
    constructor(editor) {
        super(editor);
        this.moduleId = "AttachmentsModule";
        this.section = document.createElement("section");
        this.section.className = "attachmentsSection";
        editor.rightSidePanel.appendChild(this.section);
        const heading = document.createElement("div");
        heading.className = "flex align-center widget-header";
        new IconNgBuilder(attahcmentSvg, heading).build();
        const title = document.createElement("span");
        title.textContent = i18next.t("editor.attachments.attachments");
        heading.appendChild(title);
        this.section.appendChild(heading);
        createDivider(this.editor.header.toolBar);
        new IconNgBuilder(attahcmentSvg, this.editor.header.toolBar)
            .setOnClick(() => { this.showAttachments = !this.showAttachments; })
            .setToggable(true)
            .isActive(true)
            .build();
        this.section.appendChild(new AttachmentsPanel(this.editor.attachments, this.editor.stageId));
    }
    get showAttachments() {
        var _a, _b;
        return (_b = ((_a = this.section) === null || _a === void 0 ? void 0 : _a.style.display) == "") !== null && _b !== void 0 ? _b : false;
    }
    set showAttachments(showAttachments) {
        this.section.style.display = showAttachments ? "" : "none";
    }
    unload() {
        var _a;
        (_a = this.section) === null || _a === void 0 ? void 0 : _a.remove();
    }
}
