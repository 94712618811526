import React from 'react';
export const MenuCloseIcon = React.createElement("svg", { width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { filter: "url(#filter0_d_50_46236)" },
        React.createElement("path", { d: "M4 15C4 8.37258 9.37258 3 16 3C22.6274 3 28 8.37258 28 15C28 21.6274 22.6274 27 16 27C9.37258 27 4 21.6274 4 15Z", fill: "white" }),
        React.createElement("path", { d: "M17 19L13 15L17 11", stroke: "#42526E", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })),
    React.createElement("defs", null,
        React.createElement("filter", { id: "filter0_d_50_46236", x: "0", y: "0", width: "32", height: "32", filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB" },
            React.createElement("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
            React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
            React.createElement("feOffset", { dy: "1" }),
            React.createElement("feGaussianBlur", { stdDeviation: "2" }),
            React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.08 0" }),
            React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_50_46236" }),
            React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_50_46236", result: "shape" }))));
