import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18next from 'i18next';
import store from './reduxStore/store';
import common_en from 'translations/en/common.json';
import filesUpload from 'dashboards/buyer/buyerDashboard/UploadJob/droparea/translations/en/filesUpload.json';
import { HTTPLogger } from 'logger/HTTPLogger';
import { LoggerProvider } from 'logger/Logger';
import { TokenizationProvider } from 'providers/TokenizationProvider';
i18next.use(LanguageDetector).init({
    interpolation: { escapeValue: false },
    lng: 'en',
    fallbackLng: 'en',
    defaultNS: 'common',
    resources: {
        en: {
            common: common_en,
            filesUpload: filesUpload
        }
    }
});
const AppProviders = ({ children }) => {
    const logger = new HTTPLogger();
    return React.createElement(Provider, { store: store },
        React.createElement(LoggerProvider, { logger: logger },
            React.createElement(TokenizationProvider, null,
                React.createElement(I18nextProvider, { i18n: i18next },
                    React.createElement(ToastContainer, { toastStyle: { borderRadius: '10px' }, position: "top-right", autoClose: 5000, limit: 5, hideProgressBar: false, newestOnTop: false, closeOnClick: true, rtl: false, draggable: false, pauseOnHover: true }),
                    children))));
};
export default AppProviders;
