import './style.scss';
export default class Tooltip extends HTMLElement {
    constructor() {
        super();
        this.open = false;
        this._content = document.createElement('div');
        this._content.className = 'content';
        this.appendChild(this._content);
        this.addEventListener('mouseenter', () => this.showTooltip(true));
        this.addEventListener('mouseleave', () => this.showTooltip(false));
    }
    showTooltip(show) {
        this._content.classList.toggle('active', show);
    }
    set content(value) {
        if (value instanceof HTMLElement) {
            this._content.innerHTML = '';
            this._content.appendChild(value);
            return;
        }
        this._content.innerHTML = value;
    }
}
export class Popup extends HTMLElement {
    constructor() {
        super();
        this.open = false;
        this._content = document.createElement('div');
        this._content.className = `content`;
        this.appendChild(this._content);
    }
    showTooltip(refElement, event, position) {
        this._content.classList.toggle('active', true);
        this.setTooltipPosition(refElement, event, position);
    }
    hide() {
        this._content.classList.toggle('active', false);
    }
    set content(value) {
        if (value instanceof HTMLElement) {
            this._content.innerHTML = '';
            this._content.appendChild(value);
            return;
        }
        this._content.innerHTML = value;
    }
    setTooltipPosition(el, event, position) {
        const rect = Array.from(el.getClientRects())
            .find(rect => event.clientX >= rect.left
            && event.clientX < rect.right
            && event.clientY >= rect.top
            && event.clientY < rect.bottom) || Array.from(el.getClientRects())[0];
        if (!rect)
            return;
        this.style.left = position == 'bottom' ? rect.left - (this.offsetWidth / 2) + 'px' : rect.right + 10 + 'px';
        this.style.top = position == 'bottom' ? rect.bottom + 10 + 'px' : rect.top - 2 + 'px';
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-popup-ele", Popup));
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-tooltip-ele", Tooltip));
