import { Modal } from "editorNextGen/components/Modal";
import './style.scss';
export default class UploadAfterDocumentModal extends Modal {
    constructor() {
        super();
        this.content = document.createElement("div");
        this.descriptionElement = document.createElement("div");
        this.headerElement = document.createElement("div");
        this.callback = () => { };
        this.classList.add("small");
        this.content.classList.add("content", "ui");
        this.headerElement.className = 'header';
        this.descriptionElement.className = 'description';
        this.appendChild(this.content);
        this.content.appendChild(this.headerElement);
        this.content.appendChild(this.descriptionElement);
    }
    set header(value) {
        this.headerElement.textContent = value;
    }
    set description(value) {
        this.descriptionElement.textContent = value;
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-upload-after-document-modal", UploadAfterDocumentModal));
