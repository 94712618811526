import { getSupersedingAssignment, uploadJobSectionRedactedDocument } from "api-operations/editor/editor";
import i18next from "i18next";
import { HTTPLogger } from "logger/HTTPLoggerStatic";
import EditorModule from "../EditorModule";
import UploadDocumentModal from "./UploadDocumentModal";
import { ExfluencyLoader } from "editorNextGen/components/loader";
const NEW_ASSIGNMENT_REQUEST_INTERVAL = 3000;
export default class UploadDocumentModule extends EditorModule {
    constructor(editor) {
        super(editor);
        this.moduleId = "SupersedeModule";
        this.headerUploadDocumentButton = document.createElement("button");
        this.fileInput = document.createElement("input");
        this.uploadDocumentModal = new UploadDocumentModal(this.fileInput);
        editor.domNode.appendChild(this.uploadDocumentModal);
        this.fileInput.type = 'file';
        this.fileInput.style.display = 'none';
        this.fileInput.accept = '.docx,.xlsx,.pptx,.json,.xlf,.odt,.ods,.odp,.mqxliff,.csv';
        this.fileInput.onchange = (e) => {
            var _a;
            const file = e.target.files[0];
            this.uploadDocumentModal.callback = () => this.uploadRedactedFile(file);
            this.uploadDocumentModal.newFileName = file.name;
            this.uploadDocumentModal.oldFileName = ((_a = this.editor.section) === null || _a === void 0 ? void 0 : _a.name) || '';
            this.uploadDocumentModal.open = true;
        };
        this.headerUploadDocumentButton.textContent = editor.isFinalRedactionStage
            ? i18next.t("editorHeader.uploadFile")
            : i18next.t("editorHeader.uploadAndEdit");
        this.headerUploadDocumentButton.className = "ui button" + (editor.isFinalRedactionStage ? " primary" : " primary--light");
        this.headerUploadDocumentButton.addEventListener("click", () => { this.fileInput.click(); });
        this.editor.sectionHeader.finishBar.appendChild(this.headerUploadDocumentButton);
    }
    unload() {
        this.headerUploadDocumentButton.remove();
    }
    async uploadRedactedFile(file) {
        var _a, _b, _c, _d;
        if (!((_a = this.editor.section) === null || _a === void 0 ? void 0 : _a.id))
            return;
        const loader = new ExfluencyLoader(true);
        (_b = this.editor.domNode) === null || _b === void 0 ? void 0 : _b.appendChild(loader);
        try {
            await uploadJobSectionRedactedDocument(this.editor.assignmentId, this.editor.section.id, file, this.uploadDocumentModal.warning);
            HTTPLogger.info('User - Uploaded redacted file');
        }
        catch (error) {
            HTTPLogger.error('User - Failed to upload redacted file', error);
            if (error.response.status == '400') {
                this.uploadDocumentModal.errorMessage = error.response.data.message;
            }
            if (error.response.status == '422') {
                this.uploadDocumentModal.warningMessage = error.response.data.message;
            }
            (_c = this.editor.domNode) === null || _c === void 0 ? void 0 : _c.removeChild(loader);
            return;
        }
        if (this.editor.stage === 'REDACTION') {
            const { history } = this.editor.props;
            (_d = this.editor.contentEditionModule) === null || _d === void 0 ? void 0 : _d.clearWorkTimeInterval();
            const intervalId = setInterval(async () => {
                var _a;
                const response = await getSupersedingAssignment(this.editor.assignmentId);
                if (!response)
                    return;
                this.editor.assignmentId = response;
                this.editor.reloadAssetsAfterFileUpload();
                history.replace({ pathname: `/editor/${response}` });
                (_a = this.editor.contentEditionModule) === null || _a === void 0 ? void 0 : _a.setWorkTimeInterval();
                clearInterval(intervalId);
            }, NEW_ASSIGNMENT_REQUEST_INTERVAL);
        }
        if (this.editor.stage === 'FINAL_REDACTION') {
            this.editor.fileUploadFinalRedaction();
        }
        HTTPLogger.info('User - Opened finish assignment modal');
    }
}
