/* eslint-disable no-control-regex */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Divider, Form, Icon, Input, Message, Modal, Popup } from 'semantic-ui-react';
import { Styled } from 'StyledComponents';
import { setUserCurrency, updateMyProfile, updateOutOfOfficeStatus, updateAcceptCharityStatus } from 'api-operations/myExfluency/myExfluency';
import SelectMemo from 'helpers/SelectMemo';
import Avatar from 'helpers/components/Avatar';
import Cropper from 'helpers/components/Cropper';
import { toast } from 'react-toastify';
import { emailRegex } from 'helpers/utils';
import { useDispatch } from 'react-redux';
import { saveUserCurrency, saveUserExchangeRate, saveUserOOOStatus } from 'reduxStore/actions/actions';
import store from 'reduxStore/store';
import { getLatestExchangeRate } from 'api-operations/common';
import { useTokenization } from 'providers/TokenizationProvider';
import { deleteEthereumAddress, updateEthereumAddress } from 'api-operations/langCoinWalletAddress/langCoinWalletAddress';
const StyledForm = styled(Form) `
  border: 0;
`;
const StyledButton = styled(Button) `
  width: 35%;
`;
const ButtonWrapper = styled.div `
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 40px 0 10px 0;
`;
const StyledLogin = styled.div `
  text-align: center;
  color: black;
  font-size: 1.4rem;
  padding: 20px;
`;
const ImageWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > *{
    padding: 0.6rem;
  }
`;
const SwitchWrapper = styled.div `
  height: 100%;
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: left;
  align-items: flex-end;
  padding-bottom: .7rem;
  padding-left: 3rem;
`;
const SwitchsWrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
const StyledDivider = styled(Divider) `
  padding-top: 1.8rem;

  &.ui.horizontal.divider:after {
    background-position: left 1em top 0;
   }

  &.ui.horizontal.divider:before {
    background-position: right 1em top 0;
  }

  @-moz-document url-prefix() {
   &.ui.horizontal.divider:after {
    background-position: left 1em top 50%;
   }

   &.ui.horizontal.divider:before {
    background-position: right 1em top 50%;
   }
  }
`;
const UserInfoForm = ({ userInfo, initialEthereumAddress, ethereumAddressDisabled }) => {
    const profile = store.getState().userProfile;
    const [firstName, setFirstName] = useState(userInfo.name || '');
    const [surname, setSurname] = useState(userInfo.surname || '');
    const [email, setEmail] = useState(userInfo.email || '');
    const [ethereumAddress, setEthereumAddress] = useState(initialEthereumAddress || '');
    const [timezone, setTimezone] = useState(userInfo.timezone || '');
    const [linkedin, setLinkedin] = useState(userInfo.linkedin || '');
    const [outOfOffice, setOutOfOffice] = useState(userInfo.outOfOffice || false);
    const [acceptCharity, setAcceptCharity] = useState(userInfo.acceptCharity || false);
    const [currency, setCurrency] = useState(profile.currency);
    const [isCurrencyModalOpen, setIsCurrencyModalOpen] = useState(false);
    const [responseStatus, setResponseStatus] = useState('');
    const [updateMessage, setUpdateMessage] = useState(null);
    const [invalidFirstName, setInvalidFirstName] = useState(true);
    const [invalidSurname, setInvalidSurname] = useState(true);
    const [invalidEmail, setInvalidEmail] = useState(true);
    const [invalidLinkedin, setInvalidLinkedin] = useState(true);
    const [errorMsg, setErrorMsg] = useState(null);
    const [image, setImage] = useState(null);
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const { isTokenizationEnabled } = useTokenization();
    const tmpCurrency = useRef(null);
    const tmpEthereumAddress = useRef(initialEthereumAddress);
    useEffect(() => {
        if (firstName !== '' && firstName !== null) {
            if (!/^[A-Za-z_-\s]{2,60}$/.test(firstName)) {
                setInvalidFirstName(true);
            }
            else {
                setInvalidFirstName(false);
            }
        }
        else {
            setInvalidFirstName(false);
        }
    }, [firstName]);
    useEffect(() => {
        if (surname !== '' && surname !== null) {
            if (!/^[A-Za-z_-\s]{2,60}$/.test(surname)) {
                setInvalidSurname(true);
            }
            else {
                setInvalidSurname(false);
            }
        }
        else {
            setInvalidSurname(false);
        }
    }, [surname]);
    useEffect(() => {
        if (!emailRegex.test(email)) {
            setInvalidEmail(true);
        }
        else {
            setInvalidEmail(false);
        }
    }, [email]);
    useEffect(() => {
        if (linkedin !== '' && linkedin !== null) {
            if (!/^https:\/\/[a-z]{2,3}\.linkedin\.com\/.*$/.test(linkedin)) {
                setInvalidLinkedin(true);
            }
            else {
                setInvalidLinkedin(false);
            }
        }
        else {
            setInvalidLinkedin(false);
        }
    }, [linkedin]);
    const updateUserInfo = async () => {
        let anythingUpdated = false;
        if (!(userInfo.name === firstName
            && userInfo.surname === surname
            && userInfo.email === email
            && userInfo.timezone === timezone
            && userInfo.outOfOffice === outOfOffice
            && userInfo.linkedin === linkedin)) {
            setUpdateMessage(null);
            if (invalidFirstName) {
                setErrorMsg(t('accountDescriptions.name'));
                return;
            }
            if (invalidSurname) {
                setErrorMsg(t('accountDescriptions.surname'));
                return;
            }
            if (invalidEmail) {
                setErrorMsg(t('accountDescriptions.email'));
                return;
            }
            if (invalidLinkedin) {
                setErrorMsg(t('accountDescriptions.linkedin'));
                return;
            }
            setErrorMsg(null);
            const settings = {
                name: firstName,
                surname: surname,
                email: email.toLowerCase(),
                timezone: timezone,
                outOfOffice: outOfOffice || false,
                linkedin: linkedin,
                acceptCharity: acceptCharity
            };
            try {
                await updateMyProfile(settings);
            }
            catch (error) {
                toast.error(t('notification.error'));
                setUpdateMessage('Something went wrong.');
                setResponseStatus('ERROR');
                return;
            }
            anythingUpdated = true;
            userInfo.name = firstName;
            userInfo.surname = surname;
            userInfo.email = email;
            userInfo.timezone = timezone;
            userInfo.outOfOffice = outOfOffice;
            userInfo.linkedin = linkedin;
        }
        if ((tmpEthereumAddress.current || '') !== ethereumAddress) {
            try {
                if (ethereumAddress == '') {
                    await deleteEthereumAddress();
                }
                else {
                    await updateEthereumAddress(ethereumAddress);
                }
                tmpEthereumAddress.current = ethereumAddress;
                anythingUpdated = true;
            }
            catch (error) {
                if (error.response) {
                    if (error.response.status == 409) { //conflict
                        toast.error(t('notification.ethereumAddressAlreadyUsed'));
                    }
                    else if (error.response.status == 400) { //bad request
                        toast.error(t('notification.ethereumAddressValidationFailed'));
                    }
                    else {
                        toast.error(t('notification.ethereumAddressUpdateFailed'));
                    }
                }
                setUpdateMessage('Something went wrong.');
                setResponseStatus('ERROR');
            }
        }
        if (anythingUpdated) {
            setUpdateMessage('Your information was successfully updated.');
            setResponseStatus('SUCCESS');
        }
    };
    const updateOOOStatus = async (status) => {
        try {
            await updateOutOfOfficeStatus(status);
            dispatch(saveUserOOOStatus(status));
        }
        catch (error) {
            toast.error(t('notification.error'));
            return;
        }
        toast.success(t('notification.oOOSuccess'));
        setOutOfOffice(status);
    };
    const updateAcceptCharityState = async (status) => {
        try {
            await updateAcceptCharityStatus(status);
            setAcceptCharity(status);
            toast.success(t('notification.acceptCharitySuccess'));
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
    };
    const handleUserInfoSubmit = () => {
        updateUserInfo();
    };
    const getProfileImageAfterSet = (image) => {
        setImage(image);
    };
    const handleCurrencyOpen = (data) => {
        setIsCurrencyModalOpen(true);
        tmpCurrency.current = data;
    };
    const handleCurrencyChange = async () => {
        if (tmpCurrency.current) {
            try {
                await setUserCurrency(tmpCurrency.current);
                if (tmpCurrency.current !== 'EUR') {
                    const res = await getLatestExchangeRate('EUR', tmpCurrency.current);
                    dispatch(saveUserExchangeRate(res));
                }
                else {
                    dispatch(saveUserExchangeRate());
                }
                dispatch(saveUserCurrency(tmpCurrency.current));
                toast.success(t('notification.informationUpdated'));
            }
            catch (error) {
                toast.error(t('notification.error'));
            }
            setCurrency(tmpCurrency.current);
        }
        setIsCurrencyModalOpen(false);
    };
    return React.createElement(React.Fragment, null,
        React.createElement(StyledForm, { autoComplete: "off" },
            React.createElement(StyledDivider, { horizontal: true },
                React.createElement(Styled.H2, null,
                    React.createElement(Icon, { name: "info circle" }),
                    t('settings.info'))),
            React.createElement(ImageWrapper, null,
                React.createElement(Avatar, { name: userInfo.login, customImg: image ? image : null }),
                React.createElement(Cropper, { getProfileImageAfterSet: getProfileImageAfterSet })),
            React.createElement(StyledLogin, null,
                React.createElement("h2", null, userInfo.login)),
            React.createElement(Form.Group, null,
                React.createElement(Form.Field, { width: 5 }),
                React.createElement(Form.Field, { width: 4 }),
                React.createElement(Form.Field, { width: 3 },
                    React.createElement(SelectMemo, { value: currency, setValue: handleCurrencyOpen, option: "currencies", label: "userCurrency", fluid: false, labelIcon: React.createElement(Popup, { position: 'top center', hoverable: true, trigger: React.createElement(Icon, { name: 'question circle outline', color: "grey", style: { cursor: 'pointer' } }) },
                            React.createElement("div", null,
                                t('dashboard.exchangeRateNotice'),
                                React.createElement("a", { href: "https://www.ecb.europa.eu/stats/policy_and_exchange_rates/euro_reference_exchange_rates/html/index.en.html", target: "_blank", rel: "noreferrer" }, t('dashboard.exchangeRateLink')))) })),
                React.createElement(Form.Field, { width: 4 },
                    React.createElement(SwitchsWrapper, null,
                        React.createElement(SwitchWrapper, null,
                            React.createElement(Checkbox, { fluid: true, toggle: true, label: t('settings.outOfOfficeExplanation'), checked: outOfOffice, onChange: (e, { checked }) => updateOOOStatus(checked) })),
                        React.createElement(SwitchWrapper, null,
                            React.createElement(Checkbox, { fluid: true, toggle: true, label: t('settings.acceptCharity'), checked: acceptCharity, onChange: (e, { checked }) => updateAcceptCharityState(checked) }))))),
            React.createElement(Form.Group, { widths: 2 },
                React.createElement(Form.Field, { error: invalidFirstName },
                    React.createElement("label", { htmlFor: "name" }, t('settings.name')),
                    React.createElement(Input, { type: "text", value: firstName, onChange: (e, data) => setFirstName(data.value) })),
                React.createElement(Form.Field, { error: invalidSurname },
                    React.createElement("label", { htmlFor: "surname" }, t('settings.lastName')),
                    React.createElement(Input, { type: "text", value: surname, onChange: (e, data) => setSurname(data.value) }))),
            React.createElement(Form.Group, { widths: 2 },
                React.createElement(Form.Field, { error: invalidEmail },
                    React.createElement("label", { htmlFor: "email" }, t('settings.email')),
                    React.createElement(Input, { type: "email", value: email, onChange: (e, data) => setEmail(data.value) })),
                isTokenizationEnabled && React.createElement(Form.Field, null,
                    React.createElement("label", { htmlFor: "ethereum" }, t('settings.ethereumAddress')),
                    React.createElement(Input, { type: "text", value: ethereumAddress, disabled: ethereumAddressDisabled, onChange: (e, data) => setEthereumAddress(data.value) }))),
            React.createElement(Form.Group, { widths: 2 },
                React.createElement(Form.Field, null,
                    React.createElement(SelectMemo, { search: true, value: timezone, setValue: setTimezone, option: "timezones", label: "timezone" })),
                React.createElement(Form.Field, { error: invalidLinkedin },
                    React.createElement("label", null,
                        React.createElement(Icon, { name: "linkedin", color: "blue" }),
                        t('settings.linkedin')),
                    React.createElement(Input, { type: "text", value: linkedin, onChange: (e, data) => setLinkedin(data.value) }))),
            errorMsg !== null && React.createElement(Message, { className: 'negative', content: errorMsg }),
            updateMessage !== null && React.createElement(Message, { className: responseStatus === 'SUCCESS' ? 'positive' : 'negative', content: updateMessage }),
            React.createElement(ButtonWrapper, null,
                React.createElement(StyledButton, { primary: true, content: "Update profile", onClick: handleUserInfoSubmit }))),
        React.createElement(Modal, { open: isCurrencyModalOpen },
            React.createElement(Modal.Content, null, t('settings.modalCurrencyChange')),
            React.createElement(Modal.Actions, null,
                React.createElement(Button, { negative: true, content: "Cancel", onClick: () => setIsCurrencyModalOpen(false) }),
                React.createElement(Button, { positive: true, content: "Accept", onClick: () => handleCurrencyChange() }))));
};
export default UserInfoForm;
