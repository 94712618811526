import { groupOptions } from './AssetTooltip';
import { getParentNodesUntilNode, getTooltipOptionForNodes } from "./utils";
import { TerminologyPopup } from "./modules/terminology/TerminologyPopup";
import i18next from "i18next";
export default class TerminologyElement extends HTMLElement {
    constructor(term, textToReplace, editor, isSourceTextTerm, allTermsForAsset, contentEditable = true) {
        super();
        this.editor = editor;
        this.content = document.createElement("span");
        this.term = term;
        this.isSourceTextTerm = isSourceTextTerm;
        this.contentEditable = (!isSourceTextTerm).toString();
        this.similarTerms = allTermsForAsset
            .filter(t => (term.sourceText.toLocaleLowerCase().indexOf(t.sourceText.toLocaleLowerCase()) >= 0 && isSourceTextTerm)
            || (term.targetText.toLocaleLowerCase().indexOf(t.targetText.toLocaleLowerCase()) >= 0 && !isSourceTextTerm));
        const subassetToSpan = (text) => {
            const span = document.createElement("span");
            span.appendChild(document.createTextNode(text || ""));
            return span;
        };
        const createSubassetSpan = () => {
            return subassetToSpan(textToReplace);
        };
        this.content.className = "content";
        this.content.appendChild(createSubassetSpan());
        this.appendChild(this.content);
        this.addEventListener("mouseenter", this.showTooltip.bind(this));
        this.addEventListener("mouseleave", (e) => {
            const related = e.relatedTarget;
            const tooltip = document.querySelector('.editorTooltip');
            if (related !== tooltip && !tooltip.contains(related)) {
                this.getTooltip().hideTooltip();
            }
        });
    }
    hideTooltip() {
        this.getTooltip().hideTooltip();
    }
    showTooltip(event) {
        const arr = getParentNodesUntilNode(this, 'content');
        this.getTooltip().showTooltipForGroups(arr[arr.length - 1], this.getTooltipProps(event), event);
    }
    getTooltipContent() {
        return new TerminologyPopup(this.term, this.editor.sourceLanguage, this.editor.targetLanguage);
    }
    getTooltipProps(event) {
        return this.getNestedNodes(this, event);
    }
    getNestedNodes(node, event) {
        var _a, _b, _c, _d, _e;
        let arr = getParentNodesUntilNode(node, 'content');
        if (!((_b = (_a = this.editor) === null || _a === void 0 ? void 0 : _a.formattingModule) === null || _b === void 0 ? void 0 : _b.active)) {
            arr = arr.filter(node => node instanceof TerminologyElement);
        }
        const options = {
            removeSubAsset: (_c = this.editor) === null || _c === void 0 ? void 0 : _c.removeSubAsset.bind(this.editor),
            // editAnonymizedSubAsset: this.editor?.tokenEditionModule?.editAnonymizedSubAsset.bind(this.editor),
            removeFormatting: this.isSourceTextTerm ? undefined : (_d = this.editor) === null || _d === void 0 ? void 0 : _d.removeFormatting.bind(this.editor),
            mouseOver: (universalTag) => {
                var _a;
                (_a = this.editor) === null || _a === void 0 ? void 0 : _a.setFormattingSelection(universalTag);
            },
            mouseLeave: () => {
                var _a;
                (_a = this.editor) === null || _a === void 0 ? void 0 : _a.setFormattingSelection();
            }
        };
        const termsForTooltip = this.similarTerms.sort((t1, t2) => t2.sourceText.length - t1.sourceText.length).map(t => {
            return {
                header: i18next.t(`editor.subAssets.${t.type == 'FORBIDDEN' ? t.type : t.creationSource}`),
                options: [{
                        header: i18next.t(`editor.subAssets.${t.type == 'FORBIDDEN' ? t.type : t.creationSource}`),
                        content: new TerminologyPopup(t, this.editor.sourceLanguage, this.editor.targetLanguage)
                    }],
                disableHoverEfect: true
            };
        });
        const formattingForTooltip = groupOptions(getTooltipOptionForNodes(arr, false, (_e = this.editor) === null || _e === void 0 ? void 0 : _e.editable, event, options).filter(o => o !== null), false);
        return [...termsForTooltip, ...formattingForTooltip];
    }
    getTooltip() {
        return document.querySelector("xfl-new-editor-tooltip");
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-terminology", TerminologyElement));
