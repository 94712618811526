import { createDivider, IconNgBuilder } from 'editorNextGen/icons';
import { arrowBackSvg } from 'editorNextGen/icons/arrowBack';
import './styles.scss';
class Header extends HTMLElement {
    constructor(editor) {
        super();
        this.headerRow = document.createElement("div");
        this.toolBar = document.createElement("div");
        this.titleLabel = document.createElement("h1");
        this.finishBar = document.createElement("div");
        this.detailsSection = document.createElement("div");
        this.editor = editor;
        this.headerRow.className = "headerRow";
        this.appendChild(this.headerRow);
        new IconNgBuilder(arrowBackSvg, this.detailsSection)
            .setIconFill('stroke')
            .setOnClick(() => this.editor.exitEditor())
            .build();
        createDivider(this.detailsSection);
        this.detailsSection.className = "flex align-center";
        this.headerRow.appendChild(this.detailsSection);
        this.headerRow.appendChild(this.toolBar);
        this.toolBar.className = "flex align-center flex-grow-1 jc-flex-end flex-gap-8";
        this.headerRow.appendChild(this.finishBar);
        this.finishBar.className = "flex align-center";
    }
    toggleHeaderSection() {
        this.detailsSection.classList.toggle("hidden");
    }
}
export default Header;
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-header", Header));
