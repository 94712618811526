import { changeJobSectionAssetsType, changeSubAssetSubtypes } from "api-operations/editor/editor";
import i18next from "i18next";
import { HTTPLogger } from "logger/HTTPLoggerStatic";
import { v4 as uuidv4 } from 'uuid';
import EditorModule from "../EditorModule";
import CreateTokenModal from "./CreateTokenModal";
import IconButton from "editorNextGen/components/IconButton";
import { createDivider, IconNgBuilder } from "editorNextGen/icons";
import { doNotTranslateSvg } from "editorNextGen/icons/mark";
import { anonymizeSvg } from "editorNextGen/icons/anonymize";
import { getAssetForRange, getNodesToText, getSanitizedHTML } from "editorNextGen/utils";
import "./style.scss";
import { ExfluencyLoader } from "editorNextGen/components/loader";
export default class TokenEditionModule extends EditorModule {
    constructor(editor) {
        super(editor);
        this.moduleId = "TokenEditionModule";
        this.createTokenModal = new CreateTokenModal();
        this.headerNoTranslationButton = new IconButton("icon quote right", i18next.t("editorHeader.noTranslation"));
        this.editor.domNode.appendChild(this.createTokenModal);
        new IconNgBuilder(doNotTranslateSvg, this.editor.sectionHeader.toolBar)
            .setOnClick(() => { this.markAllAssetsAsNoTranslation(); })
            .setTooltip(i18next.t('editorHeader.newNoTranslation'))
            .setIconFill('stroke').build();
        createDivider(this.editor.sectionHeader.toolBar);
        this.selectionToolsNoTranslationButton = new IconNgBuilder(doNotTranslateSvg, this.editor.selectionTools)
            .setOnClick(() => this.markAsNoTranslation())
            .setTooltip(i18next.t('editor.selectionTool.noTranslation'))
            .setIconFill("stroke")
            .setId('doNotTranslate').build();
        this.selectionToolsAnonymizeButton = new IconNgBuilder(anonymizeSvg, this.editor.selectionTools)
            .setOnClick(() => this.anonymize())
            .setTooltip(i18next.t('editor.selectionTool.anonymize'))
            .setIconFill("stroke")
            .setId('anonymize').build();
    }
    unload() {
        this.createTokenModal.remove();
        this.headerNoTranslationButton.remove();
        this.selectionToolsAnonymizeButton.remove();
        this.selectionToolsNoTranslationButton.remove();
        this.editor.content.querySelectorAll("xfl-new-editor-asset button.untranslated")
            .forEach(b => b.remove());
        this.editor.content.querySelectorAll("xfl-new-editor-subasset i.edit")
            .forEach(i => i.remove());
    }
    assetCreated(assetElement, asset = undefined) {
        new IconNgBuilder(doNotTranslateSvg, assetElement.toolBar)
            .setTooltip(i18next.t('editor.selectionTool.noTranslation'))
            .setOnClick(() => {
            const range = document.createRange();
            range.selectNodeContents(assetElement.contentDiv);
            this.markAsNoTranslation(range, () => (assetElement._setCompleted(true)));
        }).build();
    }
    subassetCreated(subAsset) {
        if (!["ANONYMIZED", "NO_TRANSLATION"].includes(subAsset.type)) {
            return;
        }
        if (subAsset.type == "ANONYMIZED") {
            const editButton = document.createElement("i");
            editButton.className = "small icon action edit";
            editButton.addEventListener("click", () => this.editAnonymizedSubAsset(subAsset));
        }
    }
    markAllAssetsAsNoTranslation() {
        const subAssetType = "NO_TRANSLATION";
        this.createTokenModal.header = i18next.t("editor.createTokenModal.noTranslationAllAssets");
        this.createTokenModal.justificationVisible = true;
        this.createTokenModal.subtypeVisible = false;
        this.createTokenModal.validate();
        this.createTokenModal.open = true;
        this.createTokenModal.callback = () => { this.changeAssetsType(subAssetType, this.createTokenModal.justification); };
    }
    async changeAssetsType(type, description = undefined) {
        const loader = new ExfluencyLoader(true);
        this.editor.domNode.appendChild(loader);
        try {
            const { assignmentId, section } = this.editor;
            if (!section)
                return;
            await changeJobSectionAssetsType(assignmentId, section.id, type, description);
            this.editor.reloadAssets();
            HTTPLogger.info(`User - Changed all assets type - ${type}`);
            this.editor.finishLoading();
        }
        catch (error) {
            this.editor.setState({ error });
            HTTPLogger.error(`User - Failed to change assets type - ${type}`, error);
        }
    }
    anonymize(range = this.editor.selectionTools.lastRange, callback = () => { }) {
        const subAssetType = "ANONYMIZED";
        if (!range)
            return;
        this.createTokenModal.header = i18next.t("editor.createTokenModal.anonymize");
        this.createTokenModal.justificationVisible = false;
        this.createTokenModal.subtypeVisible = true;
        this.createTokenModal.open = true;
        const text = getSanitizedHTML(getNodesToText(Array.from(range.cloneContents().childNodes)));
        this.createTokenModal.callback = () => {
            const subAsset = {
                id: uuidv4(),
                assetId: getAssetForRange(range).assetId,
                type: subAssetType,
                text: text,
                sourceText: text,
                subtypes: this.createTokenModal.subtypes
            };
            this.editor.insertSubAsset(range, subAsset);
            callback();
        };
    }
    markAsNoTranslation(range = this.editor.selectionTools.lastRange, callback = () => { }) {
        const subAssetType = "NO_TRANSLATION";
        if (!range)
            return;
        this.createTokenModal.header = i18next.t("editor.createTokenModal.noTranslation");
        this.createTokenModal.justificationVisible = true;
        this.createTokenModal.subtypeVisible = false;
        this.createTokenModal.open = true;
        const content = range.extractContents();
        const text = getSanitizedHTML(getNodesToText(Array.from(content.childNodes)));
        range.insertNode(content);
        this.createTokenModal.callback = () => {
            var _a;
            const subAsset = {
                id: uuidv4(),
                assetId: (_a = getAssetForRange(range)) === null || _a === void 0 ? void 0 : _a.assetId,
                type: subAssetType,
                text: text,
                sourceText: text,
                description: this.createTokenModal.justification,
                subtypes: new Set()
            };
            callback();
            this.editor.insertSubAsset(range, subAsset);
        };
    }
    editAnonymizedSubAsset(subAsset) {
        if (subAsset.type != "ANONYMIZED") {
            return;
        }
        const { assignmentId, content, section } = this.editor;
        if (!section)
            return;
        this.createTokenModal.header = i18next.t("editor.createTokenModal.anonymize");
        this.createTokenModal.justificationVisible = false;
        this.createTokenModal.subtypeVisible = true;
        this.createTokenModal.open = true;
        this.createTokenModal.subtypes = new Set(subAsset.subtypes);
        this.createTokenModal.validate();
        this.createTokenModal.callback = async () => {
            subAsset.subtypes = this.createTokenModal.subtypes;
            try {
                await changeSubAssetSubtypes(subAsset.subAssetId, this.createTokenModal.subtypes);
            }
            catch (error) {
                HTTPLogger.error('User - Failed to change subAssetSubtypes', error);
            }
            document.querySelectorAll(`xfl-new-editor-subasset[subAssetId='${subAsset.subAssetId}']`).forEach((elem) => {
                elem.subtypes = subAsset.subtypes;
            });
        };
    }
}
