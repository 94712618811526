import React from 'react';
import { useHistory } from 'react-router-dom';
import UserService from 'UserService';
const PreventWhenLogged = ({ children }) => {
    if (UserService.unauthenticatedPath() && UserService.isLoggedIn()) {
        useHistory().push('/');
    }
    return React.createElement(React.Fragment, null, children);
};
export default PreventWhenLogged;
