import React, { createContext, useContext } from 'react';
const initialValue = {
    logger: {
        send: () => { },
        debug: () => { },
        info: () => { },
        warn: () => { },
        error: () => { },
        browserInfo: () => { }
    }
};
export const LoggerContext = createContext(initialValue);
export const LoggerProvider = ({ children, logger }) => (React.createElement(LoggerContext.Provider, { value: { logger } }, children));
export const useLogger = () => useContext(LoggerContext);
