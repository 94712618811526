import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react';
import { sendPasswordForgotMail } from 'api-operations/auth';
import ErrorModal from 'errors/components/ErrorModal';
import Background from 'Home/components/Background';
import LegalFooter from 'Home/components/LegalFooter';
import { hasErrorCode } from 'logger/error-guards';
import { isSubDomainContains } from 'helpers/utils';
const ImgContainer = styled.div `
  display: flex;
  justify-content: center;
`;
const ReturnToLogin = styled(Link) `
display: flex;
justify-content: flex-start;
font-size: 0.8rem;
margin-top: 0.5rem;
cursor: pointer;
`;
const SendPasswordReset = () => {
    const [username, setUsername] = useState('');
    const [mail, setMail] = useState('');
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [badUser, setBadUser] = useState(false);
    const [errorWindowMessage, setErrorWindowMessage] = useState(null);
    const { t } = useTranslation('common');
    const history = useHistory();
    const handleSubmit = async () => {
        setLoading(true);
        setBadUser(false);
        try {
            const params = new URLSearchParams(window.location.search);
            const redirectURL = params.get('redirectURL');
            await sendPasswordForgotMail(username.toLowerCase(), mail.toLowerCase(), redirectURL);
            setEmailSent(true);
            setTimeout(function () {
                redirectURL !== null ? window.location.replace(redirectURL) : history.push('/');
            }, 10000);
        }
        catch (error) {
            if (hasErrorCode(401, error)) {
                setBadUser(true);
            }
            else {
                setErrorWindowMessage(error);
            }
        }
        setLoading(false);
    };
    if (errorWindowMessage !== null) {
        return React.createElement(ErrorModal, { ...errorWindowMessage, onClose: () => window.location.reload() });
    }
    return (React.createElement(Background, null,
        React.createElement(Grid, { textAlign: "center", style: { height: '100vh' }, verticalAlign: "middle" },
            React.createElement(Grid.Column, { style: { maxWidth: 450 } },
                React.createElement(ImgContainer, null, isSubDomainContains(['ua']) ? React.createElement(Image, { src: require(`assets/images/ua/logo.svg`), alt: "Exfluency" }) : React.createElement(Image, { src: require(`assets/images/exfluency/logo.svg`), alt: "Exfluency" })),
                React.createElement(Header, { as: "h1", color: "black", textAlign: "center" }, t('signin.forgotPasswordHeader')),
                React.createElement(Form, { size: "large", onSubmit: handleSubmit },
                    React.createElement(Segment, null,
                        React.createElement("div", null, t('signin.typeUserOrPass')),
                        React.createElement(Form.Input, { autoFocus: true, fluid: true, icon: "user", iconPosition: "left", onChange: (e, data) => setUsername(data.value), value: username, placeholder: t('settings.login') }),
                        React.createElement(Form.Input, { autoFocus: true, fluid: true, icon: "mail", iconPosition: "left", onChange: (e, data) => setMail(data.value), value: mail, placeholder: t('settings.email') }),
                        React.createElement(Button, { loading: loading, disabled: loading, primary: true, fluid: true, size: "large", type: "submit" }, t('signin.forgotPasswordHeader')),
                        React.createElement(ReturnToLogin, { to: "/" }, t('signin.signin')))),
                emailSent && React.createElement(Message, { info: true },
                    " ",
                    t('signin.forgotPasswordMailSent'),
                    " "),
                badUser && React.createElement(Message, { error: true },
                    " ",
                    t('signin.badUser'),
                    " "))),
        React.createElement(LegalFooter, null)));
};
export default SendPasswordReset;
