import { createDivider, IconNgBuilder } from "editorNextGen/icons";
import { clockSvg } from "editorNextGen/icons/clock";
import { showDateCountdown } from "helpers/utils";
import moment from "moment";
export class DueDetails extends HTMLElement {
    constructor(time) {
        super();
        this.time = time;
        this.label = document.createElement("span");
        this.updateTextContent = () => {
            this.label.textContent = (moment().isBefore(this.time) ? 'Due: in ' : 'Overdue: for ') + showDateCountdown(this.time, undefined, false);
            setTimeout(this.updateTextContent, 30000);
        };
    }
    connectedCallback() {
        const container = document.createElement("div");
        container.className = "flex align-center";
        createDivider(container);
        new IconNgBuilder(clockSvg, container).build();
        this.updateTextContent();
        container.appendChild(this.label);
        this.appendChild(container);
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-due-details", DueDetails));
