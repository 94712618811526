import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
const Header = styled.h3 `
  color: #42526E;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
`;
const Description = styled.div `
  color: #8C97A8;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;
const Label = ({ maxFileSize, supportedFormats }) => {
    const { t } = useTranslation('common');
    return React.createElement("div", null,
        React.createElement(Header, null, t('common.dragOrUpload')),
        React.createElement(Description, null,
            React.createElement("div", null,
                t(`common.supportedFormat${supportedFormats.length > 1 ? 's' : ''}`),
                ": ",
                supportedFormats.join(', ')),
            React.createElement("div", null,
                t('common.sizeLimit'),
                ": ",
                maxFileSize)));
};
export default Label;
