import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react';
import { resendEmailActivationLink } from 'api-operations/auth';
import ErrorModal from 'errors/components/ErrorModal';
import Background from 'Home/components/Background';
import LegalFooter from 'Home/components/LegalFooter';
import { isSubDomainContains } from 'helpers/utils';
const ImgContainer = styled.div `
  display: flex;
  justify-content: center;
`;
const ResendLink = () => {
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(false);
    const [responseStatus, setResponseStatus] = useState('');
    const [errorWindowMessage] = useState(null);
    const { t } = useTranslation('common');
    const resendActivationLink = async () => {
        setLoading(true);
        try {
            await resendEmailActivationLink(username.toLowerCase());
            setResponseStatus(t('signin.emailResendSuccess'));
        }
        catch (error) {
            setResponseStatus(t('signin.emailResendFailed'));
        }
        setLoading(false);
    };
    if (errorWindowMessage !== null) {
        return React.createElement(ErrorModal, { ...errorWindowMessage, onClose: () => window.location.reload() });
    }
    return (React.createElement(Background, null,
        React.createElement(Grid, { textAlign: "center", style: { height: '100vh' }, verticalAlign: "middle" },
            React.createElement(Grid.Column, { style: { maxWidth: 450 } },
                React.createElement(ImgContainer, null, isSubDomainContains(['ua']) ? React.createElement(Image, { src: require(`assets/images/ua/logo.svg`), alt: "Exfluency" }) : React.createElement(Image, { src: require(`assets/images/exfluency/logo.svg`), alt: "Exfluency" })),
                React.createElement(Header, { as: "h1", color: "black", textAlign: "center" }, t('signin.resend')),
                React.createElement(Form, { size: "large", onSubmit: () => { resendActivationLink(); } },
                    React.createElement(Segment, null,
                        React.createElement(Form.Input, { autoFocus: true, fluid: true, icon: "user", iconPosition: "left", onChange: (e, data) => setUsername(data.value), value: username, placeholder: t('settings.login') }),
                        React.createElement(Button, { loading: loading, disabled: loading, primary: true, fluid: true, size: "large", type: "submit" }, t('signin.resend')))),
                responseStatus !== '' && (React.createElement(Message, { error: true },
                    responseStatus,
                    ' ')))),
        React.createElement(LegalFooter, null)));
};
export default ResendLink;
