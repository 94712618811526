import React, { useState, useEffect, useRef } from 'react';
import { Loader, Message, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { Styled } from 'StyledComponents';
import ErrorModal from 'errors/components/ErrorModal';
import { getAssignments, perspectiveToStageList } from 'api-operations/executor/executor';
import { useLogger } from 'logger/Logger';
import NotYetAcceptedJobsRow from './Row';
const NotYetAcceptedJobs = ({ perspective }) => {
    const [assignments, setAssignments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorWindowMessage, setErrorWindowMessage] = useState(null);
    const [highlightAssignmentId, setHighlightAssignmentId] = useState(undefined);
    const [isAssignmentNotFound, setIsAssignmentNotFound] = useState(false);
    const intervalId = useRef();
    const { logger } = useLogger();
    const { t } = useTranslation('common');
    useEffect(() => {
        setIsAssignmentNotFound(false);
        setHighlightAssignmentId(undefined);
        (async () => {
            await getData(true);
            intervalId.current = setInterval(async () => {
                await getData();
            }, 20000);
        })();
        return () => {
            clearInterval(intervalId.current);
        };
    }, [perspective]);
    const getData = async (checkAssignmentExistence = false) => {
        try {
            if (document.visibilityState == "hidden")
                return;
            const response = await getAssignments(perspectiveToStageList(perspective), 'PENDING', { limit: 20 });
            setAssignments(response.results);
            if (checkAssignmentExistence) {
                checkIfAssignmentExist(response.results);
            }
        }
        catch (error) {
            setErrorWindowMessage(error);
            logger.error('System - Failed to fetch pending assignments', error);
            return;
        }
        setIsLoading(false);
    };
    const checkIfAssignmentExist = (assignments) => {
        if (!queryString.parse(location.search).assignment)
            return;
        if (assignments.find(assignment => assignment.id === queryString.parse(location.search).assignment)) {
            setHighlightAssignmentId(queryString.parse(location.search).assignment);
            return;
        }
        setIsAssignmentNotFound(true);
    };
    if (errorWindowMessage !== null)
        return React.createElement(ErrorModal, { ...errorWindowMessage, onClose: () => window.location.reload() });
    if (isLoading)
        return React.createElement(Loader, { inverted: true, content: "Loading" });
    return React.createElement("div", null,
        isAssignmentNotFound && React.createElement(Message, { error: true }, t('dashboard.projectTaken')),
        assignments.length > 0 && React.createElement(Styled.H3, null, t('dashboard.jobsPendingAcceptance')),
        assignments.length > 0 && React.createElement(Table, { celled: true, color: "orange", selectable: true, striped: true },
            React.createElement(Table.Header, null,
                React.createElement(Table.Row, null,
                    React.createElement(Table.HeaderCell, null),
                    React.createElement(Table.HeaderCell, null, t('dashboard.requester')),
                    React.createElement(Table.HeaderCell, null, t('dashboard.projectName')),
                    React.createElement(Table.HeaderCell, null, t('dashboard.dueDate')),
                    React.createElement(Table.HeaderCell, null, t('dashboard.languages')),
                    React.createElement(Table.HeaderCell, null, t('dashboard.estimatedPrice')),
                    perspective === "redactor" && React.createElement(Table.HeaderCell, null, t('dashboard.stageType')),
                    React.createElement(Table.HeaderCell, null, t('dashboard.actions')))),
            React.createElement(Table.Body, null, assignments.map(assignment => React.createElement(NotYetAcceptedJobsRow, { key: assignment.id, assignment: assignment, perspective: perspective })))));
};
export default NotYetAcceptedJobs;
