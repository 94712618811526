import { claimAssignment, passAssignment } from "api-operations/executor/executor";
import i18next from "i18next";
import EditorModule from "../EditorModule";
export default class PendingAssignmentModule extends EditorModule {
    constructor(editor) {
        super(editor);
        this.moduleId = "PendingAssignmentModule";
        this.rejectAssignmentButton = document.createElement("button");
        this.acceptAssignmentButton = document.createElement("button");
        this.rejectAssignmentButton.textContent = i18next.t("editorHeader.rejectAssignment");
        this.rejectAssignmentButton.className = "ui button secondary";
        this.rejectAssignmentButton.addEventListener("click", this.rejectAssignment.bind(this));
        editor.header.finishBar.appendChild(this.rejectAssignmentButton);
        this.acceptAssignmentButton.textContent = i18next.t("editorHeader.acceptAssignment");
        this.acceptAssignmentButton.className = "ui button primary";
        this.acceptAssignmentButton.addEventListener("click", this.acceptAssignment.bind(this));
        editor.header.finishBar.appendChild(this.acceptAssignmentButton);
    }
    unload() {
        this.rejectAssignmentButton.remove();
        this.acceptAssignmentButton.remove();
    }
    async acceptAssignment() {
        try {
            await claimAssignment(this.editor.assignmentId);
            window.location.reload();
        }
        catch (error) {
            if (error.response.status === 404) {
                window.location.reload();
            }
        }
    }
    rejectAssignment() {
        passAssignment(this.editor.assignmentId);
        const { history } = this.editor.props;
        history.push({
            pathname: `/`
        });
    }
}
