/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import FileUploadLabel from "./FileUploadLabel";
import styled from "@emotion/styled";
import { Button, Container as MuiContainer } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./style.css";
import FilesContainer from "./FilesContainer";
import { useLogger } from "logger/Logger";
export const acceptableFileFormats = [
    '.docx',
    '.json',
    '.xlsx',
    '.xlf',
    '.odt',
    '.odp',
    '.mqxliff',
    '.pptx',
    '.pdf',
    '.html',
    '.idml',
    '.properties'
];
export const acceptableFileTypes = [
    'application/pdf',
    'application/json',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/html',
    '.json',
    '.xlsx',
    '.xlf',
    '.odt',
    '.mqxliff',
    '.pptx',
    '.html',
    '.idml',
    '.properties'
];
export const acceptableZipFileTypes = [
    '.zip',
    'application/zip',
    'application/x-zip',
    'application/octet-stream',
    'application/x-zip-compressed'
];
export function formatFileSize(size) {
    if (size === undefined)
        return "";
    if (size < 10000) {
        return `${size} B`;
    }
    else if (size < 100000) {
        return `${(size / 1000).toFixed(2)} KB`;
    }
    return `${(size / 1000000).toFixed(2)} MB`;
}
export function isZipFile({ type }) {
    return acceptableZipFileTypes.includes(type);
}
export function isFileTypeSupported({ type, name }, acceptableFileTypes) {
    var _a;
    const extension = '.' + ((_a = name.split('.').pop()) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase());
    return type ? acceptableFileTypes.includes(type) : acceptableFileTypes.includes(extension);
}
const CloseIcon = () => React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M4.74697 4.74717C4.94513 4.54901 5.2139 4.43768 5.49414 4.43768C5.77438 4.43768 6.04315 4.54901 6.24131 4.74717L19.6903 18.1962C19.8885 18.3943 19.9998 18.6631 19.9998 18.9433C19.9998 19.2236 19.8885 19.4924 19.6903 19.6905C19.4922 19.8887 19.2234 20 18.9432 20C18.6629 20 18.3941 19.8887 18.196 19.6905L4.74697 6.2415C4.54881 6.04334 4.43749 5.77458 4.43749 5.49433C4.43749 5.21409 4.54881 4.94533 4.74697 4.74717Z", fill: "#42526E" }),
    React.createElement("path", { d: "M4.74717 18.1962C4.54901 18.3944 4.43768 18.6631 4.43768 18.9434C4.43768 19.2236 4.54901 19.4924 4.74717 19.6905C4.94533 19.8887 5.21409 20 5.49434 20C5.77458 20 6.04334 19.8887 6.2415 19.6905L19.6905 6.24151C19.8887 6.04335 20 5.77459 20 5.49435C20 5.21411 19.8887 4.94534 19.6905 4.74718C19.4924 4.54902 19.2236 4.43769 18.9433 4.43769C18.6631 4.43769 18.3943 4.54902 18.1962 4.74718L4.74717 18.1962Z", fill: "#42526E" }));
const Description = styled.div `
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 16px;
`;
const UploadButton = styled(Button) `
  background-color: #ECEFFB !important;
  color: #42526E !important;
`;
const RemoveFiles = styled.button `
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px 8px 8px;
  gap: 4px;
  border-radius: 4px;
  background-color: transparent;

  width: 165px;
  height: 40px;

  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #42526E;
  z-index: 888;

  &:hover {
    background: #ECEFFB;
  }
`;
export const MAX_FILE_SIZE = 100 * 1000 * 1000;
const consumeEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
};
const FilesUpload = ({ uploadedFiles, ...props }) => {
    const [files, setFiles] = useState(uploadedFiles.map(file => ({ uploadedFile: file })));
    const [isDragOver, setDragOver] = useState(false);
    const { logger } = useLogger();
    const { t } = useTranslation('filesUpload');
    const handleChange = (e) => {
        const { files } = e.target;
        if (!files)
            return;
        Array.from(files).forEach((file) => {
            readFile(file);
        });
    };
    const handleRemoveFile = useCallback(async (fileId) => {
        setFiles(prevState => prevState.filter(({ toUploadFile, uploadedFile }) => (uploadedFile ? uploadedFile.id : toUploadFile === null || toUploadFile === void 0 ? void 0 : toUploadFile.id) !== fileId));
    }, [files, setFiles]);
    useEffect(() => {
        props.setFiles(files.map(({ uploadedFile }) => uploadedFile).filter(file => file)); // strange bug, even after filter, it's still shows undefined as type
    }, [files]);
    const handleFileUpload = useCallback((fileId, response) => {
        setFiles((prevState) => {
            const arr = [];
            prevState.forEach(({ toUploadFile, uploadedFile }) => {
                if ((uploadedFile ? uploadedFile.id : toUploadFile === null || toUploadFile === void 0 ? void 0 : toUploadFile.id) !== fileId) {
                    arr.push({ toUploadFile, uploadedFile });
                    return;
                }
                if (Array.isArray(response)) {
                    arr.push(...response.map(file => ({ uploadedFile: file })));
                    return;
                }
                arr.push({ toUploadFile, uploadedFile: response });
            });
            const uploadedFilesBefore = prevState.filter(({ uploadedFile }) => uploadedFile !== undefined).map(item => { var _a; return (_a = item.uploadedFile) === null || _a === void 0 ? void 0 : _a.id; });
            const uploadedFilesAfter = arr.filter(({ uploadedFile }) => uploadedFile !== undefined).map(item => { var _a; return (_a = item.uploadedFile) === null || _a === void 0 ? void 0 : _a.id; });
            logger.info(`User - uploaded new file in ${props.placing} file upload, uploaded files before ${uploadedFilesBefore}, after ${uploadedFilesAfter}`);
            return [...new Map(arr.map(item => { var _a, _b; return [((_a = item.uploadedFile) === null || _a === void 0 ? void 0 : _a.id) || ((_b = item.toUploadFile) === null || _b === void 0 ? void 0 : _b.id), item]; })).values()];
        });
    }, [files, setFiles]);
    const readFile = (file) => {
        setFiles(prevState => {
            if (prevState.filter(f => { var _a; return ((_a = f.uploadedFile) === null || _a === void 0 ? void 0 : _a.name) == file.name; }).length > 0)
                return prevState;
            return [...prevState, {
                    toUploadFile: {
                        file, id: uuidv4()
                    }
                }];
        });
    };
    const traverseFileTree = (item, path = '') => {
        if (item.isFile) {
            // Get file
            item.file(function (file) {
                if (!isFileTypeSupported(file, props.fileTypes))
                    return;
                readFile(file);
            });
        }
        else if (item.isDirectory) {
            // Get folder contents
            const dirReader = item.createReader();
            dirReader.readEntries(function (entries) {
                for (let i = 0; i < entries.length; i++) {
                    traverseFileTree(entries[i], path + item.name + "/");
                }
            });
        }
    };
    const onDrop = (e) => {
        consumeEvent(e);
        const { items } = e.dataTransfer;
        for (let i = 0; i < items.length; i++) {
            // webkitGetAsEntry is where the magic happens
            const item = items[i].webkitGetAsEntry();
            if (item) {
                traverseFileTree(item);
            }
        }
        setDragOver(false);
    };
    const onDragEnter = (e) => {
        consumeEvent(e);
    };
    const onDragOver = (e) => {
        consumeEvent(e);
        setDragOver(true);
    };
    const onDragLeave = (e) => {
        consumeEvent(e);
        setDragOver(false);
    };
    const removeAllFiles = () => {
        setFiles([]);
        logger.info(`${props.placing} file upload - remove all files clicked`);
    };
    return React.createElement(MuiContainer, { className: "file-upload" },
        React.createElement("input", { type: "file", onChange: handleChange, id: "file-upload", multiple: true, hidden: true, accept: props.fileTypes.join(', ') }),
        React.createElement(Description, null,
            !files.length && React.createElement(FileUploadLabel, { maxFileSize: formatFileSize(MAX_FILE_SIZE), fileFormats: props.fileFormats.join(', ') }),
            !!files.length && React.createElement(FilesContainer, { files: files, handleRemoveFile: handleRemoveFile, handleFileUpload: handleFileUpload, expectedLanguage: props.languageFrom, placing: props.placing, uploadFiles: props.uploadFiles }),
            !!files.length && React.createElement(RemoveFiles, { onClick: () => removeAllFiles() },
                React.createElement(CloseIcon, null),
                t('removeAllFiles')),
            React.createElement(UploadButton, null, t('uploadFiles'))),
        React.createElement("label", { htmlFor: "file-upload", style: { ...(isDragOver ? { border: '1px solid black' } : {}) }, onDrop: onDrop, onDragOver: onDragOver, onDragEnter: onDragEnter, onDragLeave: onDragLeave }));
};
export default FilesUpload;
