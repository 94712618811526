import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useParams } from 'react-router-dom';
import { Button, Card, Grid, Image, Popup, Search, Select } from "semantic-ui-react";
import { addUserToCompany } from "api-operations/company/admin";
import PublicProfile from "helpers/components/PublicProfile";
import { getImagePathByRole } from 'helpers/utils';
import { toast } from 'react-toastify';
import { getUsersNotInCompany } from 'api-operations/company/member';
const RolesDiv = styled.div `
  display: flex;
  flex-direction: row;
  align-content: center;
`;
const AddMember = () => {
    const [userName, setUserName] = useState('');
    const [userRole, setUserRole] = useState('');
    const [searchData, setSearchData] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const { t } = useTranslation('common');
    const params = useParams();
    const addNewUser = async () => {
        const userToAdd = [{
                userName,
                profile: userRole
            }];
        try {
            await addUserToCompany(params.id, userToAdd);
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
        setUserName('');
    };
    const search = useCallback(_.debounce(async (value) => {
        try {
            const res = await getUsersNotInCompany(value);
            const newResults = res.map(u => ({
                title: u.login,
                roles: u.roles,
                communities: u.communities
            }));
            setSearchData(newResults);
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
        setSearchLoading(false);
    }, 1000), []);
    const handleSearchChange = (__, { value }) => {
        setSearchLoading(true);
        setUserName(value || '');
        if (!value) {
            setUserName('');
            setSearchData([]);
            setSearchLoading(false);
        }
        else {
            search(value);
        }
    };
    const resultRenderer = ({ title, roles, communities }) => (React.createElement(Card, { centered: true, fluid: true, color: communities.length > 0 ? 'red' : undefined },
        React.createElement(Card.Content, null,
            React.createElement(Card.Header, null,
                title,
                React.createElement(PublicProfile, { userName: title })),
            React.createElement(RolesDiv, null, roles.map((r) => {
                switch (r.replace('ROLE_', '').toLowerCase()) {
                    case 'buyer':
                        return (React.createElement(Popup, { content: t(`userRoles.${r}`), trigger: React.createElement(Image, { src: getImagePathByRole('ROLE_BUYER') }) }));
                    case 'enhancer':
                        return (React.createElement(Popup, { content: t(`userRoles.${r}`), trigger: React.createElement(Image, { src: getImagePathByRole('ROLE_ENHANCER') }) }));
                    case 'trust_miner':
                        return (React.createElement(Popup, { content: t(`userRoles.${r}`), trigger: React.createElement(Image, { src: getImagePathByRole('ROLE_TRUST_MINER') }) }));
                    case 'language_miner':
                        return (React.createElement(Popup, { content: t(`userRoles.${r}`), trigger: React.createElement(Image, { src: getImagePathByRole('ROLE_LANGUAGE_MINER') }) }));
                    case 'polisher':
                        return (React.createElement(Popup, { content: t(`userRoles.${r}`), trigger: React.createElement(Image, { src: getImagePathByRole('ROLE_POLISHER') }) }));
                    default:
                        return null;
                }
            }))),
        communities.length > 0
            && React.createElement(Card.Content, null,
                React.createElement(Card.Header, null, t(`companies.belongingUserCommunities`)),
                communities.map((com) => React.createElement("p", { key: com }, com)))));
    const handleResultSelect = (__, { result }) => setUserName(result.title);
    const roleOptions = [{
            key: 'admin',
            value: 'ORGANIZATION_ADMIN',
            text: 'Admin'
        },
        {
            key: 'member',
            value: 'ORGANIZATION_MEMBER',
            text: 'Member'
        }];
    return React.createElement(React.Fragment, null,
        React.createElement("h2", null, t(`companies.addMember`)),
        React.createElement("h4", null, t(`companies.warning`)),
        React.createElement(Grid, { columns: 3, divided: true },
            React.createElement(Grid.Row, null,
                React.createElement(Grid.Column, null,
                    React.createElement(Search, { fluid: true, value: userName, results: searchData, onSearchChange: handleSearchChange, onResultSelect: handleResultSelect, loading: searchLoading, resultRenderer: resultRenderer })),
                React.createElement(Grid.Column, null,
                    React.createElement(Select, { onChange: (_, data) => setUserRole(data.value), placeholder: t(`groupsCommon.selectRole`), options: roleOptions })),
                React.createElement(Grid.Column, null,
                    React.createElement(Button, { primary: true, disabled: userRole === '' || userName === ''
                            || searchData.length === 0
                            || searchData.filter((u) => u.title === userName).length === 0, onClick: addNewUser }, t(`companies.addUser`))))));
};
export default AddMember;
