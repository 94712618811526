import i18next from "i18next";
import _ from "lodash";
import sanitizeHtml from "sanitize-html";
import { removeCaretGuards } from "./AssetElement";
import SubAssetElement from "./SubAssetElement";
import { TOOLTIP_ID } from "./Tooltip";
import UniversalTagElement, { InescapableTextNode } from "./UniversalTag";
import TerminologyElement from "./TerminologyElement";
export function consumeEvent(event) {
    event.preventDefault();
    event.stopPropagation();
}
export function sameAsset(node1, node2) {
    var _a;
    return ((_a = ancestorOfType(node1, "xfl-new-editor-asset")) === null || _a === void 0 ? void 0 : _a.contains(node2)) || false;
}
export function previousOfType(element) {
    let currentElement = element;
    while (currentElement && currentElement.previousElementSibling) {
        if (currentElement.previousElementSibling.tagName == element.tagName) {
            return currentElement.previousElementSibling;
        }
        currentElement = currentElement.previousElementSibling;
    }
    return null;
}
export function nextOfType(element) {
    let currentElement = element;
    while (currentElement && currentElement.nextElementSibling) {
        if (currentElement.nextElementSibling.tagName == element.tagName) {
            return currentElement.nextElementSibling;
        }
        currentElement = currentElement.nextElementSibling;
    }
    return null;
}
export function ancestorOfType(element, type) {
    const tagName = type.toUpperCase();
    let currentElement = element;
    while (currentElement && currentElement.parentElement) {
        if (currentElement.parentElement.tagName == tagName) {
            return currentElement.parentElement;
        }
        currentElement = currentElement.parentElement;
    }
    return null;
}
export function getTranslationLevelIconName(level) {
    switch (level) {
        case "OCR": return "camera";
        case "MT": return "microchip";
        case "ENTERED": return "pencil";
        case "ENHANCED": return "eye";
        case "HUMAN_PARITY": return "thumbs up";
        case "PARSED": return "file";
        case "REDACTED": return "eye";
        case "VERIFIED": return "gem";
        case "NO_TRANSLATION": return "clipboard check";
        default: return "";
    }
}
export function getProblemTypeIconName(type) {
    switch (type) {
        case "ERROR": return "warning circle";
        case "WARN": return "warning sign";
        default: return "";
    }
}
export function isAlphanumeric(str) {
    return /^[\p{L}\p{N}]$/u.test(str);
}
export function isScriptoContinua(lang) {
    return ['zh_hans', 'zh_hant', 'zh_hk', 'zh_sg', 'ja', 'th'].includes(lang);
}
export function isLetter(str) {
    return /^\p{L}$/u.test(str);
}
export function positionToRect(element, rect, relativeElement) {
    const relativeRect = relativeElement.getBoundingClientRect();
    const x = rect.x - relativeRect.left + rect.width / 2 - element.clientWidth / 2;
    const yOver = rect.top - relativeRect.top - element.clientHeight;
    const yUnder = rect.top - relativeRect.top + rect.height;
    // 16 pixels of margin to consider .5em and add similar margin to the top
    const under = (rect.top - element.clientHeight) < document.querySelector("xfl-new-editor-header").clientHeight + 16;
    element.style.left = `${Math.max(0, Math.min(x, relativeRect.width - element.clientWidth))}px`;
    element.style.top = under ? `calc(${yUnder}px + .5em)` : `calc(${yOver}px - .5em)`;
}
export function isTouchscreen() {
    return matchMedia("(pointer: coarse)").matches;
}
export function firstSelectionRange() {
    var _a;
    return (_a = window.getSelection()) === null || _a === void 0 ? void 0 : _a.getRangeAt(0);
}
export function regexTermSearch(termText) {
    return new RegExp(`([\\s.,?!)\\]}]|^)${termText}(?=[\\s.,?!([{\\/]|$)`, "gi");
}
export function getAssetForRange(range) {
    return ancestorOfType(range.startContainer, "xfl-new-editor-asset");
}
export function roundPosition(value, roundTo) {
    return Math.floor(value / roundTo) * roundTo;
}
export const isScrollThresholdReached = (scrollHeight, scrollTop, clientHeight, forwardThresholdPercent, backwardThresholdPercent) => {
    return (scrollTop + clientHeight) / scrollHeight > forwardThresholdPercent
        || (scrollTop - clientHeight) / scrollHeight < backwardThresholdPercent;
};
export const isForwardScrollHasPriority = (scrollHeight, scrollTop, clientHeight, forwardThresholdPercent, backwardThresholdPercent) => {
    const forward = (scrollTop + clientHeight) / scrollHeight - forwardThresholdPercent;
    const backward = (scrollTop - clientHeight) / scrollHeight - backwardThresholdPercent;
    return Math.abs(forward) <= Math.abs(backward);
};
export const showEditorTooltip = (el, text, keyboardShortcut = []) => {
    const tooltip = document.getElementById(TOOLTIP_ID);
    tooltip && tooltip.showTooltip(el, text, keyboardShortcut);
};
export const getTooltipTopPosition = (top, offsetHeight) => {
    let topPosition;
    if (top < offsetHeight) {
        topPosition = top + 23;
    }
    else {
        topPosition = top - offsetHeight + 1;
    }
    return topPosition;
};
export const chooseActiveSection = (sections) => {
    var _a;
    return (_a = sections.find(({ totalAssets, completedAssets, hasResultFile }) => totalAssets !== completedAssets || !hasResultFile)) !== null && _a !== void 0 ? _a : (sections.length > 0 ? sections[0] : null);
};
export const calculateProgress = (total, completed) => {
    return completed / (completed + (total - completed));
};
export const getNodesToText = (nodes) => {
    return removeCaretGuards(nodes.map((node) => {
        return node instanceof SubAssetElement
            ? node.assetContentText()
            : node instanceof UniversalTagElement
                ? getNodesToText(node.nodes)
                : node instanceof InescapableTextNode
                    ? node.textContent
                    : node instanceof Text
                        ? _.escape(node.textContent || '')
                        : getNodesToText(Array.from(node.childNodes));
    })
        .join(""));
};
export const getTooltipOptionForNodes = (nodes, isValidStage, editable, event, { removeFormatting, removeSubAsset, mouseOver, mouseLeave }) => {
    return nodes
        .filter(node => node instanceof UniversalTagElement || node instanceof SubAssetElement || node instanceof TerminologyElement)
        .map((node) => {
        if (node instanceof UniversalTagElement) {
            const obj = { header: i18next.t(`editor.formatting.tooltipHeader`), content: `ID no. ${node.id}` };
            if (editable) {
                obj.onDelete = removeFormatting && (() => removeFormatting && removeFormatting(node));
                obj.onMouseEnter = () => mouseOver && mouseOver(node);
                obj.onMouseLeave = () => mouseLeave && mouseLeave(node);
            }
            return obj;
        }
        if (node instanceof SubAssetElement) {
            const isValidSubAssetType = ["ANONYMIZED", "NO_TRANSLATION"].includes((node === null || node === void 0 ? void 0 : node.type) || '');
            const obj = { header: i18next.t(`editor.subAssets.${node.type}`), content: node.getTooltipContent() };
            if (isValidStage && isValidSubAssetType && editable) {
                obj.onDelete = () => { removeSubAsset && removeSubAsset(event, node); };
                // if(node?.type == 'ANONYMIZED') {
                //   obj.onEdit = () => {editAnonymizedSubAsset && editAnonymizedSubAsset(node)}
                // }
            }
            return obj;
        }
        return null;
    })
        .filter(node => node);
};
export const getParentNodesUntilNode = (node, className) => {
    let arr = [];
    let currentNode = node;
    while ((currentNode === null || currentNode === void 0 ? void 0 : currentNode.className) !== className && currentNode !== null) {
        arr.push(currentNode);
        currentNode = currentNode ? currentNode.parentElement : null;
    }
    return arr;
};
export const getSanitizedHTML = (text) => {
    if (!text)
        return '';
    return sanitizeHtml(text, {
        allowedTags: ["universal-tag", "xfl-new-editor-subasset", "xfl-new-editor-terminology"],
        disallowedTagsMode: "escape",
        allowedAttributes: {
            ["universal-tag"]: ["id"]
        }
    });
};
export function validateJustification(justification) {
    const trimmedJustification = justification.trim();
    if (!trimmedJustification) {
        return "EMPTY";
    }
    if (trimmedJustification.length < 10) {
        return "LENGTH";
    }
    return "OK";
}
