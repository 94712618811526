import { paginationParamsSerializer } from 'api-operations/common';
import axios from 'axios';
export async function getMyProfile() {
    const response = await axios.get('/api/my_exfluency/settings');
    return response.data;
}
export async function updateMyProfile(settings) {
    await axios.put('/api/my_exfluency/settings', settings);
}
export async function updateOutOfOfficeStatus(status) {
    await axios.post(`/api/my_exfluency/settings/ooo`, JSON.stringify(status), {
        params: { outOfOffice: status }
    });
}
export async function updateAcceptCharityStatus(acceptCharity) {
    await axios.put(`/api/my_exfluency/settings/acceptCharity`, undefined, {
        params: { acceptCharity }
    });
}
export async function setUserCurrency(currency) {
    await axios.put(`/api/my_exfluency/settings/currency`, undefined, {
        params: { currency }
    });
}
export async function getMyUserProfile() {
    const response = await axios.get('/api/my_exfluency/settings/profile');
    return response.data;
}
export async function getPublicProfile(userName) {
    const response = await axios.get(`/api/my_exfluency/settings/publicProfile/${userName}`);
    return response.data;
}
export async function getMyUserRoles() {
    const response = await axios.get('/api/my_exfluency/settings/roles');
    return response.data;
}
export async function updateUserRoles(newRoles) {
    const response = await axios.put('/api/my_exfluency/settings/roles', newRoles);
    return response.data;
}
export async function getMyLanguageSkills() {
    const response = await axios.get('/api/my_exfluency/settings/skills');
    return response.data;
}
export async function addLanguageSkill(newSkill) {
    const response = await axios.post('/api/my_exfluency/settings/skills', newSkill);
    return response.data;
}
export async function deleteLanguageSkill(newSkill) {
    const response = await axios.delete('/api/my_exfluency/settings/skills/delete', { data: newSkill });
    return response.data;
}
export async function changeLanguageSkillPrice(newSkill) {
    await axios.post('/api/my_exfluency/settings/skills/changePrice', newSkill);
}
export async function getMyGenres() {
    const response = await axios.get('/api/my_exfluency/settings/genres');
    return response.data;
}
export async function addGenre(genre) {
    const response = await axios.post('/api/my_exfluency/settings/genres', genre);
    return response.data;
}
export async function deleteGenre(genre) {
    const response = await axios.delete('/api/my_exfluency/settings/genres/delete', { data: genre });
    return response.data;
}
export async function queryTaxonomy(query) {
    const response = await axios.get('/api/my_exfluency/taxonomy', {
        params: { query }
    });
    return response.data;
}
export async function sendNdaForSigning() {
    await axios.post('/api/my_exfluency/settings/sendNda');
}
export async function uploadPhoto(photo) {
    const response = await axios.post('/api/my_exfluency/settings/photo', photo);
    return response.data;
}
export async function getAvatar(username, size) {
    return await axios.get(`/api/my_exfluency/settings/photo/${username}`, {
        params: { size: size },
        responseType: "arraybuffer"
    });
}
export async function updateLastActivityDate() {
    await axios.post('/api/my_exfluency/activity');
}
export async function getAssignments(paginationFilterOptions) {
    const response = await axios.get(`/api/my_exfluency/xfl/assignments`, {
        params: { ...paginationFilterOptions },
        paramsSerializer: params => paginationParamsSerializer(params)
    });
    return response.data;
}
export async function getTerminology(paginationFilterOptions) {
    const response = await axios.get(`/api/my_exfluency/xfl/terminology`, {
        params: { ...paginationFilterOptions },
        paramsSerializer: params => paginationParamsSerializer(params)
    });
    return response.data;
}
