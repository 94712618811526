import axios from "axios";
import FileSaver from "file-saver";
// export const axiosRetryInstance = axios.create({ headers: { 'Content-Type': 'application/json' } })
// axiosRetry(axiosRetryInstance, {
//   retries: 3,
//   retryCondition: _error => _error.isAxiosError,
//   retryDelay: retryCount => retryCount * 1000
// })
// axiosRetry(axios, {
//   retries: 3,
//   retryCondition: _error => _error.isAxiosError,
//   retryDelay: retryCount => retryCount * 1000
// })
export async function getLatestExchangeRate(from, to) {
    const response = await axios.get(`/api/currency/latest`, {
        params: { from, to }
    });
    return response.data;
}
export const getPaginationParamsString = (paginationFilterOptions) => (Object.keys(paginationFilterOptions).map((field) => {
    if (typeof paginationFilterOptions[field] == 'object') {
        return `${field}=${paginationFilterOptions[field].length ? convertToString(paginationFilterOptions[field]) : ''}`;
    }
    const tmpObjVal = paginationFilterOptions[field];
    return (undefined !== tmpObjVal && tmpObjVal !== null && tmpObjVal !== '') ? field + '=' + convertToString(paginationFilterOptions[field]) : null; // Must be compared to these types beacuse of boolean values
})
    .filter(field => field).join('&'));
const convertToString = (value) => typeof value === 'object' ? value.join(',') : value;
export function paginationParamsSerializer(params) {
    return Object.entries(Object.assign({}, params))
        .filter((param) => param[1] !== undefined && param[1].length !== 0)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
}
export async function downloadFile(path, params = {}) {
    const response = await axios.get(path, { responseType: 'blob', params: params });
    const contentType = response.headers['content-type'];
    const [, fileName] = response.headers['content-disposition'].split(';')[1].split('=');
    FileSaver.saveAs(new Blob([response.data], { type: contentType }), fileName);
}
export async function downloadFileWithName(path, params = {}, fileName) {
    const response = await axios.get(path, { responseType: 'blob', params: params });
    const contentType = response.headers['content-type'];
    FileSaver.saveAs(new Blob([response.data], { type: contentType }), fileName);
}
