import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Styled } from 'StyledComponents';
import NotYetAcceptedAssignments from './NotYetAcceptedAssignments';
import FinishedAssignments from './finishedAssignments';
import { NdaNotification, OutOfOfficeNotification } from 'dashboards/myProjects/Notifications';
import Assignments from 'dashboards/shared/assignmentsInProgress';
const MyProjectsDashboard = ({ perspective }) => {
    const { t } = useTranslation('common');
    const store = useStore();
    const { outOfOffice, ndaSigned, roles } = store.getState().userProfile;
    const { path } = useRouteMatch();
    const [header, setHeader] = useState();
    useEffect(() => {
        switch (perspective) {
            case 'enhancer':
                setHeader(t('dashboard.myEnhancementProjects'));
                break;
            case 'trustminer':
                setHeader(t('dashboard.myTrustMiningProjects'));
                break;
            case 'redactor':
                setHeader(t('dashboard.myRedactionProjects'));
                break;
            default:
                setHeader(t('dashboard.myProjects'));
        }
    }, [perspective]);
    const showNotification = (ndaSigned, roles) => {
        return !ndaSigned && !((roles.length === 2 || roles.length === 1) && roles.includes('ROLE_BUYER'));
    };
    return React.createElement(React.Fragment, null,
        showNotification(ndaSigned, roles)
            ? React.createElement(NdaNotification, null)
            : React.createElement("div", null,
                React.createElement(Styled.H1, null, header),
                outOfOffice && React.createElement(OutOfOfficeNotification, null)),
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: `${path}` },
                React.createElement(Assignments, { perspective: perspective }),
                React.createElement(NotYetAcceptedAssignments, { perspective: perspective }),
                React.createElement(FinishedAssignments, { perspective: perspective })),
            React.createElement(Redirect, { from: `${path}/*`, to: `${path}` })));
};
export default MyProjectsDashboard;
