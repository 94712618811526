import i18next from "i18next";
import { Modal } from "editorNextGen/components/Modal";
import { IconNgBuilder } from "editorNextGen/icons";
import { anonymizeSvg } from "editorNextGen/icons/anonymize";
import { forbiddenSvg } from "editorNextGen/icons/forbidden";
import { doNotTranslateSvg } from "editorNextGen/icons/mark";
import { termsSvg } from "editorNextGen/icons/terms";
export default class HelpModal extends Modal {
    constructor() {
        super();
        this.content = document.createElement("div");
        this.header = i18next.t("editor.helpModal.help");
        this.content.classList.add("content");
        this.appendChild(this.content);
        this.style.width = "800px";
        const column1 = document.createElement("div");
        column1.appendChild(this.createIconsSection());
        column1.appendChild(this.createAssetDescritpionSection());
        this.content.appendChild(column1);
        const column2 = document.createElement("div");
        column2.appendChild(this.createShortcutSection());
        this.content.appendChild(column2);
        this.actions = [{
                text: i18next.t("editor.helpModal.close"),
                classList: ['secondary'],
                callback: () => { this.open = false; }
            }];
    }
    createIconsSection() {
        const section = document.createElement("section");
        const subtitle = document.createElement("h3");
        subtitle.innerText = "Icons";
        section.appendChild(subtitle);
        section.appendChild(this.createIconDescritpion(anonymizeSvg, "stroke", "Anonymized value"));
        section.appendChild(this.createIconDescritpion(forbiddenSvg, "fill", "Forbidden phrase"));
        section.appendChild(this.createIconDescritpion(doNotTranslateSvg, "stroke", "Non-translated phrase"));
        section.appendChild(this.createIconDescritpion(termsSvg, "fill", "Defined term"));
        return section;
    }
    createIconDescritpion(icon, iconFill, label) {
        const container = document.createElement("div");
        container.className = "helpEntry";
        new IconNgBuilder(icon, container).setIconFill(iconFill).build();
        const description = document.createElement("span");
        description.innerText = label;
        container.appendChild(description);
        return container;
    }
    createShortcutSection() {
        const section = document.createElement("section");
        const subtitle = document.createElement("h3");
        subtitle.innerText = i18next.t("editor.helpModal.shortcuts.title");
        section.appendChild(subtitle);
        const command = this.isMacOsCommandBtn() ? '⌘' : 'Crtl';
        section.appendChild(this.createShortcutEntry(i18next.t("editor.helpModal.shortcuts.sourceText"), [command, 's']));
        section.appendChild(this.createShortcutEntry(i18next.t("editor.helpModal.shortcuts.sourceText"), [command, 'click']));
        section.appendChild(this.createShortcutEntry(i18next.t("editor.helpModal.shortcuts.nextAsset"), [command, '↓']));
        section.appendChild(this.createShortcutEntry(i18next.t("editor.helpModal.shortcuts.previousAsset"), [command, '↑']));
        section.appendChild(this.createShortcutEntry(i18next.t("editor.helpModal.shortcuts.nextUncompletedAsset"), [command, '↵']));
        section.appendChild(this.createShortcutEntry(i18next.t("editor.helpModal.shortcuts.openEdition"), [command, '↵']));
        section.appendChild(this.createShortcutEntry(i18next.t("editor.helpModal.shortcuts.search"), [command, 'f']));
        return section;
    }
    createShortcutEntry(operation, keys) {
        const container = document.createElement("div");
        container.className = "helpEntry";
        const label = document.createElement("span");
        label.innerText = operation;
        label.className = "flex-grow-1";
        container.appendChild(label);
        keys.forEach((k) => {
            const key = document.createElement("span");
            key.innerText = k;
            key.className = "keyboardShortcut";
            container.appendChild(key);
        });
        return container;
    }
    isMacOsCommandBtn() {
        var _a;
        const platform = ((_a = window.navigator) === null || _a === void 0 ? void 0 : _a.platform) || 'Win64';
        const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
        return macosPlatforms.indexOf(platform) !== -1;
    }
    createAssetDescritpionSection() {
        const section = document.createElement("section");
        const subtitle = document.createElement("h3");
        subtitle.innerText = i18next.t("editor.helpModal.subassetHighlighting.title");
        section.appendChild(subtitle);
        section.appendChild(this.createAssetDecriptionEntry(i18next.t("editor.helpModal.subassetHighlighting.term"), "subasset"));
        section.appendChild(this.createAssetDecriptionEntry(i18next.t("editor.helpModal.subassetHighlighting.comment"), "subasset comment"));
        return section;
    }
    createAssetDecriptionEntry(text, assetClassName) {
        const container = document.createElement("div");
        container.className = "helpEntry";
        const label = document.createElement("span");
        label.className = assetClassName;
        label.textContent = text;
        container.appendChild(label);
        return container;
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-help-modal", HelpModal));
