export const TOOLTIP_ID = "baseEditorTooltip";
export class EditorTooltip extends HTMLElement {
    constructor() {
        super();
        this.contnet = document.createElement("span");
        this.keyboardShortcut = document.createElement("div");
        this.id = TOOLTIP_ID;
    }
    connectedCallback() {
        this.appendChild(this.contnet);
        this.appendChild(this.keyboardShortcut);
    }
    showTooltip(refElement, text, keyboardShortcut = []) {
        this.contnet.innerText = text;
        this.setTooltipPosition(refElement);
        while (this.keyboardShortcut.firstChild) {
            this.keyboardShortcut.removeChild(this.keyboardShortcut.firstChild);
        }
        keyboardShortcut.forEach((key) => {
            const entry = document.createElement("span");
            entry.className = "keyboardShortcut";
            entry.innerText = key;
            this.keyboardShortcut.appendChild(entry);
        });
        this.classList.add("visible");
        refElement.addEventListener("mouseleave", () => {
            this.hideTooltip();
        });
    }
    hideTooltip() {
        this.classList.remove("visible");
    }
    setTooltipPosition(el) {
        const [rect] = Array.from(el.getClientRects());
        if (!rect)
            return;
        this.classList.add("visible");
        const extraSpace = (localStorage.getItem("menuExpanded") == "true") ? 100 : 0;
        this.style.left = rect.left < 200
            ? `0px`
            : `${rect.left - extraSpace - this.offsetWidth}px`;
        this.style.top = `${rect.top + el.offsetHeight + 4}px`;
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-tooltip", EditorTooltip));
