/* eslint-disable react/no-unescaped-entities */
import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Button, Modal } from 'semantic-ui-react';
import { uploadPhoto } from 'api-operations/myExfluency/myExfluency';
import { toast } from 'react-toastify';
const CropImage = ({ getProfileImageAfterSet = () => { } }) => {
    const [image, setImage] = useState(undefined);
    const [cropper, setCropper] = useState();
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef(null);
    const { t } = useTranslation('common');
    const onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        }
        else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        setIsModalOpened(true);
        reader.readAsDataURL(files[0]);
    };
    const sendCroppedAvatar = async () => {
        if (typeof cropper !== "undefined" && cropper) {
            setIsLoading(true);
            const dataToSend = cropper.getCroppedCanvas().toDataURL('image/png');
            try {
                const res = await uploadPhoto(dataToSend);
                getProfileImageAfterSet(res);
            }
            catch (error) {
                toast.error(t('notification.error'));
            }
            setIsLoading(false);
            setIsModalOpened(false);
        }
    };
    const onButtonImageClick = () => {
        if (fileInputRef && fileInputRef.current)
            fileInputRef.current.click();
    };
    const onModalClose = () => {
        setIsModalOpened(false);
        setImage(undefined);
        setCropper(null);
        setIsLoading(false);
    };
    return React.createElement("div", { className: "App" },
        React.createElement("div", null,
            React.createElement("input", { type: "file", accept: "image/png, image/jpeg, image/jpg", ref: fileInputRef, onChange: onChange, hidden: true }),
            React.createElement(Button, { onClick: onButtonImageClick, primary: true }, t('settings.uploadNewAvatar'))),
        React.createElement(Modal, { open: isModalOpened, size: "small" },
            React.createElement(Modal.Content, null,
                React.createElement(Cropper, { style: { height: 400, width: "100%" }, initialAspectRatio: 1, aspectRatio: 1, preview: ".img-preview", src: image, viewMode: 1, minCropBoxHeight: 100, minCropBoxWidth: 100, minCanvasHeight: 500, minContainerWidth: 500, background: false, responsive: true, autoCropArea: 1, cropBoxResizable: true, checkOrientation: false, onInitialized: (instance) => {
                        setCropper(instance);
                    } })),
            React.createElement(Modal.Actions, null,
                React.createElement(Button, { onClick: onModalClose, size: "tiny" }, t('settings.cancel')),
                React.createElement(Button, { primary: true, onClick: sendCroppedAvatar, size: "tiny", loading: isLoading }, t('settings.setNewAvatar')))));
};
export default CropImage;
