import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
const Footer = styled.footer `
  display: flex;
  background-color: rgba(0, 0, 0, 0);
  justify-content: center;
`;
const List = styled.ul `
  display: flex;
  justify-content: center;
  width: 50%;
  list-style-type: none;
  margin: 0;

  li {
    padding: 0 0.5rem 0 0;

    &:after {
      content: '|';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0 0 0 0.5rem;
    }

    &:last-child:after {
      content: '';
    }
  }
`;
const StyledLink = styled(Link) `
  color: #000000;
  text-decoration: underline;

  &:visited {
    color: #000000;
  }

  &:hover {
    color: #363535;
  }
`;
const LegalFooter = () => {
    var _a;
    const { t } = useTranslation('common');
    return (React.createElement(Footer, null,
        React.createElement(List, null,
            React.createElement("li", null, t('signin.copyright')),
            React.createElement("li", null,
                t('signin.version'),
                " ", (_a = process.env.EXFLUENCY_VERSION) !== null && _a !== void 0 ? _a : 'devel'),
            React.createElement("li", null,
                React.createElement(StyledLink, { target: "_blank", to: "/terms-of-service" }, t('termsAndConditions.termsOfService'))),
            React.createElement("li", null,
                React.createElement(StyledLink, { target: "_blank", to: "/privacy-notice" }, t('termsAndConditions.privacyNotice'))))));
};
export default LegalFooter;
