import IconButton from "editorNextGen/components/IconButton";
import HelpModal from "./HelpModal";
import EditorModule from "../EditorModule";
import "./style.scss";
export default class HelpModule extends EditorModule {
    constructor(editor) {
        super(editor);
        this.moduleId = "HelpModule";
        this.helpModal = new HelpModal();
        this.helpButton = new IconButton("question");
        editor.domNode.appendChild(this.helpModal);
        this.helpButton.classList.add("helpFAB");
        editor.domNode.appendChild(this.helpButton);
        this.helpButton.addEventListener("click", () => { this.helpModal.open = true; });
    }
    unload() {
        this.helpModal.remove();
        this.helpButton.remove();
    }
}
