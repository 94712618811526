import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import { parseTimestamp } from 'helpers/DateHandler';
const CompanyAssignmentInfo = ({ assignment }) => {
    const { t } = useTranslation('common');
    return React.createElement(React.Fragment, null,
        React.createElement(Table, { basic: "very", collapsing: true, compact: "very" },
            React.createElement(Table.Body, null,
                React.createElement(Table.Row, null,
                    React.createElement(Table.Cell, null, t('dashboard.genre')),
                    React.createElement(Table.Cell, null, t(`genre.${assignment.genre}`))),
                React.createElement(Table.Row, null,
                    React.createElement(Table.Cell, null, t('dashboard.uploadDate')),
                    React.createElement(Table.Cell, null, parseTimestamp(assignment.uploadDate))),
                assignment.dueDate && React.createElement(Table.Row, null,
                    React.createElement(Table.Cell, null, t('dashboard.dueDate')),
                    React.createElement(Table.Cell, null, parseTimestamp(assignment.dueDate))),
                assignment.deliveryDate && React.createElement(Table.Row, null,
                    React.createElement(Table.Cell, null, t('dashboard.deliveryDate')),
                    React.createElement(Table.Cell, null, parseTimestamp(assignment.deliveryDate))))));
};
export default CompanyAssignmentInfo;
