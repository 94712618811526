// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".newEditorPanel xfl-new-editor-section-header{display:flex;flex-direction:column}.newEditorPanel xfl-new-editor-section-header .headerRow{display:flex;flex-direction:row;align-items:center;justify-content:flex-start;box-sizing:border-box;padding:8px 16px 8px 0}.newEditorPanel xfl-new-editor-section-header .headerRow .spacer{min-width:40px;display:inline-block;text-align:center}", "",{"version":3,"sources":["webpack://./src/editorNextGen/components/sectionHeader/styles.scss"],"names":[],"mappings":"AAEI,8CACE,YAAA,CACA,qBAAA,CAEA,yDACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,0BAAA,CACA,qBAAA,CACA,sBAAA,CAEA,iEACE,cAAA,CACA,oBAAA,CACA,iBAAA","sourcesContent":["@import '../../theme.scss';\n.newEditorPanel {\n    xfl-new-editor-section-header {  \n      display: flex;\n      flex-direction: column;\n\n      .headerRow {\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        justify-content: flex-start;\n        box-sizing: border-box;\n        padding: 8px 16px 8px 0;\n  \n        .spacer {\n          min-width: 40px;\n          display: inline-block;\n          text-align: center;\n        }\n      }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newEditorPanel": "newEditorPanel",
	"headerRow": "headerRow",
	"spacer": "spacer"
};
export default ___CSS_LOADER_EXPORT___;
