import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popup, Button, Modal } from 'semantic-ui-react';
import { StageAttachmentModal } from 'helpers/components/fileUpload/FileUploaderModal';
import { toast } from 'react-toastify';
import { requestAssignmentCancellation } from 'api-operations/requester/assignment';
import { useLogger } from 'logger/Logger';
const ActionButtonInner = ({ assignment, stage }) => {
    const [cancelAssignmentModalActive, setCancelAssignmentModalActive] = useState(false);
    const [activeAssignment, setActiveAssignment] = useState('');
    const [modalConfirmLoading, setModalConfirmLoading] = useState(false);
    const [isCancelPending, setIsCancelPending] = useState(false);
    const [addAttachmentsModalOpen, setAddAttachmentsModalOpen] = useState(false);
    const { t } = useTranslation('common');
    const { logger } = useLogger();
    const cancelAssignment = (id) => {
        setCancelAssignmentModalActive(true);
        setActiveAssignment(id);
        logger.info('User - Opened cancel assignment modal');
    };
    const confirmCancelAssignment = async () => {
        setModalConfirmLoading(true);
        try {
            await requestAssignmentCancellation(activeAssignment);
            logger.info('User - Requested assignment cancellation');
        }
        catch (error) {
            logger.error('User - Failed to request assignment cancellation', error);
            toast.error(t('notification.error'));
        }
        setModalConfirmLoading(false);
        setCancelAssignmentModalActive(false);
        setIsCancelPending(true);
    };
    const handleAttachmentsModalOpen = () => {
        setAddAttachmentsModalOpen(true);
        logger.info('User - Opened attachments job modal');
    };
    return React.createElement(React.Fragment, null,
        assignment.status === 'ACCEPTED' && !isCancelPending && assignment.stageType != 'CORRECTION'
            && React.createElement(Popup, { basic: true, position: "top center", hoverable: true, on: ['hover'], trigger: React.createElement(Button, { basic: true, color: 'red', icon: "trash alternate outline", "data-testid": `${assignment.id}_cancel`, onClick: () => { cancelAssignment(assignment.id); } }) },
                React.createElement(Popup.Content, null, t('dashboard.cancelAssignment'))),
        ['IN_PROGRESS', 'WAITING', 'PENDING_ACCEPTANCE', 'STARTED', 'NO_EXECUTORS'].includes(stage.stageStatus) && React.createElement(Popup, { basic: true, position: "top center", hoverable: true, on: ['hover'], trigger: React.createElement(Button, { color: 'orange', icon: "attach", onClick: () => handleAttachmentsModalOpen() }) },
            React.createElement(Popup.Content, null, t('dashboard.addAttachments'))),
        React.createElement(Modal, { open: cancelAssignmentModalActive, size: "small", onClose: () => { setCancelAssignmentModalActive(false); } },
            React.createElement(Modal.Content, null,
                " ",
                t('dashboard.cancelAreYouSureRequester'),
                " "),
            React.createElement(Modal.Actions, null,
                React.createElement(Button, { primary: true, size: "tiny", onClick: () => { setCancelAssignmentModalActive(false); } }, t('dashboard.declineCancel')),
                React.createElement(Button, { onClick: () => confirmCancelAssignment(), size: "tiny", loading: modalConfirmLoading }, t('dashboard.confirmCancel')))),
        React.createElement(StageAttachmentModal, { open: addAttachmentsModalOpen, setOpen: setAddAttachmentsModalOpen, stageId: stage.id, stageAttachments: stage.attachments, header: stage.stageType }));
};
export default ActionButtonInner;
