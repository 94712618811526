import axios from "axios";
export async function uploadJobInputFile(file, uploadProgressCallback) {
    const form = new FormData();
    form.append('file', file);
    const response = await axios.post(`/api/v2/job-expiring-files`, form, {
        onUploadProgress(progressEvent) {
            uploadProgressCallback(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }
    });
    return response.data;
}
export async function uploadJobInputZipFile(file, uploadProgressCallback) {
    const form = new FormData();
    form.append('file', file);
    const response = await axios.post(`/api/v2/job-expiring-files/unzip`, form, {
        onUploadProgress(progressEvent) {
            uploadProgressCallback(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }
    });
    return response.data;
}
export async function deleteFile(fileId) {
    await axios.delete(`/api/workflow/expiring-files/${fileId}`);
}
