import { styled } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
const Header = styled('h3')({
    color: '#42526E',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '32px'
});
const Description = styled('div')({
    color: '#8C97A8',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px'
});
const LabelDiv = styled('div')({
    margin: '0 auto'
});
const FileUploadLabel = ({ maxFileSize, fileFormats }) => {
    const { t } = useTranslation('filesUpload');
    return React.createElement(LabelDiv, null,
        React.createElement(Header, null, t('dragOrUpload')),
        React.createElement(Description, null,
            React.createElement("div", null,
                t('supportedFormats'),
                ": ",
                fileFormats),
            React.createElement("div", null,
                t('sizeLimit'),
                ": ",
                maxFileSize)));
};
export default FileUploadLabel;
