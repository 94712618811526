import { SAVE_COMPANY_CURRENCY, SAVE_COMPANY_EXCHANGE_RATE, SAVE_USER_CURRENCY, SAVE_USER_EXCHANGE_RATE, SAVE_USER_OOO_STATUS, SAVE_USER_PROFILE } from '../types/types';
const initialState = {
    userProfile: { login: '', name: '', surname: '', email: '', roles: [], ndaSigned: false, company: null, outOfOffice: false, currency: '' },
    company: { currency: 'EUR' }
};
export function reducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_USER_PROFILE:
            return {
                ...state,
                userProfile: action.payload
            };
        case SAVE_USER_CURRENCY:
            return {
                ...state,
                userProfile: { ...state.userProfile, currency: action.payload }
            };
        case SAVE_USER_EXCHANGE_RATE:
            return {
                ...state,
                userProfile: { ...state.userProfile, exchangeRate: action.payload }
            };
        case SAVE_USER_OOO_STATUS:
            return {
                ...state,
                userProfile: { ...state.userProfile, outOfOffice: action.payload }
            };
        case SAVE_COMPANY_EXCHANGE_RATE:
            return {
                ...state,
                company: { ...state.company, exchangeRate: action.payload }
            };
        case SAVE_COMPANY_CURRENCY:
            return {
                ...state,
                company: { ...state.company, currency: action.payload }
            };
        default:
            return state;
    }
}
