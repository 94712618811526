import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';
const { div } = styled;
export const Paper = styled.div `
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  border: 1px solid #F4F5F7;
  box-shadow: 0px 2px 8px rgba(9, 30, 66, 0.08);
  border-radius: 8px;
`;
export const PaperHeader = styled.h3 `
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #42526E;
`;
export const PaperContent = styled.div `
  display: flex;
  flex-direction: row;
  gap: 24px;
`;
export const Separator = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 1px;
  color: #BFC4CF;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  
  & > span {
    background-color: #DADDE1;
    width: 1px;
    height: 50%;
  }
`;
export const SectionHeader = styled.h3 `
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #42526E;
`;
export const StyledDropdown = styled(Dropdown) `
  height: 48px;
  &&&& {
    display: flex;
    align-items: center;

    font-weight: 500;
    font-size: 14px;
    line-height: 20px; 

  }
  .dropdown.icon {
    top: 30% !important;
  }
  `;
export const StyledDiv = div `
  opacity: ${(props) => props.selected ? '1' : '0.3'};
  width: 100%;
`;
