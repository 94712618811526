import './styles.scss';
class SectionHeader extends HTMLElement {
    constructor(editor) {
        super();
        this.headerRow = document.createElement("div");
        this.toolBar = document.createElement("div");
        this.finishBar = document.createElement("div");
        this.editor = editor;
        this.headerRow.className = "headerRow";
        this.appendChild(this.headerRow);
        this.headerRow.appendChild(this.toolBar);
        this.toolBar.className = "flex align-center flex-grow-1 jc-flex-end";
        this.headerRow.appendChild(this.finishBar);
        this.finishBar.className = "flex align-center";
    }
}
export default SectionHeader;
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-section-header", SectionHeader));
