import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import MyProjectsDashboard from './MyProjectsDashboard';
import UniversalTemplate from 'templates/UniversalTemplate';
import EnhancerFeedback from 'enhancerFeedback/EnhancerFeedback';
import { getMyUserProfile } from 'api-operations/myExfluency/myExfluency';
function getRoleByPerspective(perspective) {
    switch (perspective) {
        case 'trustminer': return 'ROLE_TRUST_MINER';
        case 'redactor': return 'ROLE_REDACTOR';
        case 'enhancer': return 'ROLE_ENHANCER';
        case 'requester': return 'ROLE_BUYER';
    }
    console.warn("Unknown perspective: " + perspective);
    return 'ROLE_BUYER';
}
const ExecutorRoutes = ({ perspective }) => {
    const { path } = useRouteMatch();
    useEffect(() => {
        const profile = getMyUserProfile();
        // check if role is within profile.roles, if not redirect to /
        profile.then((profile) => {
            const { roles } = profile;
            const role = getRoleByPerspective(perspective);
            if (!roles.includes(role)) {
                window.location.href = '/'; // let system redirect to best page
            }
        });
    }, []);
    return React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: "/enhancer/feedback" },
            React.createElement(UniversalTemplate, null,
                React.createElement(EnhancerFeedback, null))),
        React.createElement(Route, { path: `${path}` },
            React.createElement(UniversalTemplate, null,
                React.createElement(MyProjectsDashboard, { perspective: perspective }))),
        React.createElement(Redirect, { from: `${path}/*`, to: `${path}` }));
};
export default ExecutorRoutes;
