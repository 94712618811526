import React from 'react';
export const TipsknowledgeIcon = React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", className: 'mutable' },
    React.createElement("g", { clipPath: "url(#clip0_193_347)" },
        React.createElement("path", { d: "M18.6269 12.4379C16.4503 13.1653 14.4069 13.0075 12.5461 11.9572C10.9312 11.0442 9.75011 9.61358 8.98593 8.62477L9.8485 7.95106C11.6056 10.2765 14.1458 12.7563 18.2603 11.3772L18.6269 12.4379Z", fill: "#42526E" }),
        React.createElement("path", { d: "M7.84193 17.6149L7.13389 17.2256C8.01062 16.5284 8.53228 15.8325 8.84255 15.1775C7.66381 14.6209 6.68931 13.8104 6.19528 12.9614C5.56155 11.8735 5.74721 10.794 6.69268 10.0039C7.13863 9.6373 7.72025 9.55911 8.28886 9.79164C9.27228 10.1911 10.0821 11.3718 10.289 12.7409C10.3631 13.1852 10.3866 13.7992 10.2311 14.5074C10.9252 14.7027 11.5949 14.7458 12.0631 14.5563C14.4389 13.6369 12.4632 6.47143 11.5962 4.03679L12.8371 4.58518C12.8538 4.62805 12.8057 4.15184 13.0198 4.84294C13.3249 5.82795 13.7417 7.32016 14.017 8.88851C14.6863 12.701 14.1724 14.9579 12.4784 15.6219C11.7835 15.8883 10.866 15.8824 9.88567 15.5956C9.63235 16.141 9.25635 16.7205 8.70546 17.305C8.59239 17.425 8.47196 17.5451 8.34372 17.6652C8.18193 17.8168 8.0295 17.464 7.84193 17.6149ZM7.53365 10.8008C7.47094 10.8194 7.43151 10.8528 7.39308 10.8739C6.86826 11.3073 6.7916 11.7988 7.13479 12.3953C7.5215 13.0587 8.31586 13.6761 9.18702 14.1017C9.27481 13.6238 9.24832 13.1968 9.19963 12.8923C9.03022 11.8141 8.4199 11.0406 7.89884 10.8254C7.74405 10.7722 7.62165 10.7723 7.53365 10.8008Z", fill: "#42526E" }),
        React.createElement("path", { d: "M17.7589 15.1468C17.6735 15.3129 17.6254 15.4956 17.6173 15.682L17.6166 15.6994L17.6171 15.7169L17.6201 15.8291L17.62 15.8292L17.6205 15.8401L17.8324 20.1812L17.8331 20.1946L17.8344 20.2079C17.9181 21.0219 17.452 21.8074 16.6818 22.146L16.5242 22.2069L14.3714 22.9543C13.5647 23.2344 12.6885 22.9643 12.2 22.3186L12.1005 22.1762L12.1006 22.1762L12.0977 22.1722L9.58277 18.6444C9.44976 18.4397 9.2613 18.2765 9.03889 18.1747L9.0383 18.1744C6.84564 17.1743 5.4058 15.7582 4.69271 13.9548L4.58675 13.6724L4.51223 13.4465C3.37697 9.77928 5.41174 5.80238 9.17403 4.49621C13.0158 3.16247 17.1669 5.12931 18.4614 8.85794C19.1346 10.797 18.9145 12.8954 17.7589 15.1468ZM17.7589 15.1468L18.2036 15.3753L17.7588 15.147C17.7588 15.1469 17.7589 15.1469 17.7589 15.1468Z", stroke: "#42526E" }),
        React.createElement("path", { d: "M10.9794 20.1562L17.4625 17.9055", stroke: "#42526E" }),
        React.createElement("path", { d: "M11 1V1.5", stroke: "#42526E", strokeWidth: "1.5", strokeLinecap: "round" }),
        React.createElement("path", { d: "M3.99609 4.16895L4.402 4.46091", stroke: "#42526E", strokeWidth: "1.5", strokeLinecap: "round" }),
        React.createElement("path", { d: "M1.00879 11.2227L1.50844 11.2413", stroke: "#42526E", strokeWidth: "1.5", strokeLinecap: "round" }),
        React.createElement("path", { d: "M19.0195 4.08984L18.6825 4.45915", stroke: "#42526E", strokeWidth: "1.5", strokeLinecap: "round" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_193_347" },
            React.createElement("rect", { width: "24", height: "24", fill: "white" }))));
