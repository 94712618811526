import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { getMyUserProfile, updateLastActivityDate } from 'api-operations/myExfluency/myExfluency';
import ExfluencyLoader from 'helpers/components/loader/ExfluencyLoader';
import { useDispatch } from 'react-redux';
import { saveUserProfile } from 'reduxStore/actions/actions';
import { useLogger } from 'logger/Logger';
const Loader = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ProfileProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const history = useHistory();
    const { logger } = useLogger();
    useEffect(() => {
        (async () => {
            try {
                const res = await getMyUserProfile();
                dispatch(saveUserProfile(res));
                logger.browserInfo();
                setActivityInterval();
                // (window as any).hj('identify', res.login, {}) // Uncomment in order to send login to hotjar as user attribute 
            }
            catch (error) {
                document.cookie = 'ExfluencyCookie=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                history.push('/');
            }
            setIsLoading(false);
        })();
    }, []);
    const setActivityInterval = () => {
        updateLastActivityDate();
        setInterval(() => updateLastActivityDate(), 300000);
    };
    if (isLoading) {
        return React.createElement(Loader, null,
            React.createElement(ExfluencyLoader, null));
    }
    return React.createElement(React.Fragment, null, children);
};
export default ProfileProvider;
