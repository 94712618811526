import React from 'react';
import styled from 'styled-components';
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  flex-grow: 1;
  min-height: 100vh;
  overflow: auto;
`;
const EditorTemplate = ({ children }) => React.createElement(Wrapper, null, children);
export default EditorTemplate;
