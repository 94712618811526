import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Header, Loader, Table } from 'semantic-ui-react';
const Wrapper = styled.div `
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TablePlaceholder = ({ isError, isLoading, noEntries }) => {
    const { t } = useTranslation('common');
    const getContent = () => {
        if (isError)
            return React.createElement(Header, null, t('notification.errorOnFetch'));
        if (isLoading)
            return React.createElement(Loader, { active: true, inline: true });
        if (noEntries)
            return React.createElement(Header, null, t('notification.noData'));
        return null;
    };
    return React.createElement(Table.Footer, { fullWidth: true },
        React.createElement(Table.Row, null,
            React.createElement(Table.HeaderCell, { colSpan: '16' },
                React.createElement(Wrapper, null, getContent()))));
};
export default TablePlaceholder;
