import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import UserService from 'UserService';
const Logout = () => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        UserService.doLogout({ redirectUri: `${window.location.origin}` });
        document.cookie = 'ExfluencyCookie=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        setLoading(false);
    }, []);
    return loading ? React.createElement(Loader, { active: true }) : React.createElement(Redirect, { to: "/" });
};
export default Logout;
