import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import JobOverviewRowInnerSection from './JobOverviewRowInnerSection';
const JobOverviewRowInner = ({ job, status }) => {
    const { t } = useTranslation('common');
    return React.createElement(Table, { celled: true, selectable: true, striped: true },
        React.createElement(Table.Header, null,
            React.createElement(Table.Row, null,
                React.createElement(Table.HeaderCell, null),
                React.createElement(Table.HeaderCell, null, t('dashboard.executor')),
                React.createElement(Table.HeaderCell, null, t('dashboard.progress')),
                status == 'DELIVERED' && React.createElement(Table.HeaderCell, null, t('dashboard.deliveryDate')),
                status == 'IN_PROGRESS' && React.createElement(Table.HeaderCell, null, t('dashboard.dueDate')),
                React.createElement(Table.HeaderCell, null, t('dashboard.projectType')),
                React.createElement(Table.HeaderCell, null, t('dashboard.estimatedPrice')),
                React.createElement(Table.HeaderCell, null, t('dashboard.projectStatus')),
                React.createElement(Table.HeaderCell, null, t('dashboard.actions')))),
        React.createElement(Table.Body, null, job.stages.map((stage) => stage.assignments.length == 0 ? null : stage.assignments
            .map(assignment => React.createElement(JobOverviewRowInnerSection, { key: assignment.id, job: job, assignment: assignment, stage: stage, status: status })))));
};
export default JobOverviewRowInner;
