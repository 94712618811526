import React from 'react'
import ReactDOM from 'react-dom'
import { Button, Icon, Input, Message, Modal } from 'semantic-ui-react'
import { WithContext as ReactTags } from 'react-tag-input'
import { queryTaxonomy } from '../../../api-operations/myExfluency/myExfluency'
import { withTranslation } from 'react-i18next'
import * as d3 from 'd3'
import styled from 'styled-components'
import Loaderr from '../loader/ExfluencyLoader'

import style from './TaxonomySelector.scss'
// eslint-disable-next-line no-unused-vars
import rawStyle from './TaxonomySelector.css'

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const radius = 50
const lang = 'en'

const KeyCodes = {
  comma: 188,
  enter: 13
}
const delimiters = [KeyCodes.comma, KeyCodes.enter]

class TaxonomySelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tags: [],
      value: props.value,
      width: 0,
      height: 0,
      dialogOpen: false,
      isLoading: false,
      foundNodes: null
    }
    this.modal = React.createRef()
  }

  componentDidMount() {
    this.setDialogVisible(this.props.dialogOpen)
  }

  setDialogVisible(visible) {
    this.setState({ dialogOpen: visible }, () => {
      if (visible) this.adjustSVGSize()
    })
    if (this.state.tags.length) {
      this.search(this.state.tags)
    }
  }

  adjustSVGSize() {
    const contentStyle = getComputedStyle(
      ReactDOM.findDOMNode(this.modal.current).querySelector('.content')
    )
    const width =
      parseFloat(contentStyle.width) -
      parseFloat(contentStyle.paddingLeft) -
      parseFloat(contentStyle.paddingRight)
    const height = window.innerHeight - 250

    this.setState({
      width: width,
      height: Math.min(height, 540)
    })

    const { tags, visible } = this.state
    if (visible && tags.length > 0) {
      this.search(tags)
    }
  }

  async search(tags) {
    if (tags.length == 0) {
      d3.select(ReactDOM.findDOMNode(this.modal.current))
        .select('svg')
        .selectAll('*')
        .remove()
      return
    }

    if (!tags.every(term => !term.text.includes('"'))) {
      alert('Invalid terms.')
      return
    }

    this.setState({ isLoading: true })
    const taxonomy = await queryTaxonomy(tags.map(tag => tag.text).join(','))
    if(taxonomy.results[0].data.length === 0) {
      this.setState({
        foundNodes: false
      })
    } else {
      this.setState({
        foundNodes: true
      })
    }
    this.setState({ isLoading: false })

    this.drawTaxonomy(taxonomy)
  }

  tagDeleted(i) {
    const { tags } = this.state
    const newTags = tags.filter((tag, index) => index !== i)
    this.setState({ tags: newTags })
    this.search(newTags)
  }

  tagAdded(tag) {
    if (this.state.tags.length === 3) {
      alert('remove any keyword before adding new')
      return
    }

    const { tags } = this.state
    const newTags = [...tags, tag]
    this.setState({ tags: newTags })
    this.search(newTags)
  }

  save() {
    const newTaxons = this.getSelectedTaxons().join(', ')
    this.setState({ value: newTaxons })
    this.setDialogVisible(false)
    this.props.onChange(newTaxons)
  }

  neo4j2d3(neo4j) {
    let nodeIds = {}
    let relationshipIds = {}
    let d3 = { nodes: [], links: [] }
    let n
    let r

    if (neo4j.results.length == 0) {
      return d3
    }

    for (let data in neo4j.results[0].data) {
      for (let node in neo4j.results[0].data[data].graph.nodes) {
        n = neo4j.results[0].data[data].graph.nodes[node]
        if (!nodeIds[n.id]) {
          // dont create duplicate nodes
          d3.nodes.push(n)
          nodeIds[n.id] = 1
        }
      }
      for (let relationship in neo4j.results[0].data[data].graph.relationships) {
        r = neo4j.results[0].data[data].graph.relationships[relationship]
        if (!relationshipIds[r.id]) {
          // don't create duplicate links
          r.source = r.startNode
          r.target = r.endNode
          d3.links.push(r)
          relationshipIds[r.id] = 1
        }
      }
    }
    return d3
  }

  drawTaxonomy(json) {
    const modal = ReactDOM.findDOMNode(this.modal.current)
    if(!modal) {
      return
    }
    const svgElement = modal.querySelector('svg')
    svgElement.viewBox.baseVal.x = 0
    svgElement.viewBox.baseVal.y = 0

    const graph = this.neo4j2d3(json)
    const svg = d3.select(svgElement),
      width = +svg.attr('width'),
      height = +svg.attr('height')
    svg.selectAll('*').remove()

    const simulation = d3
      .forceSimulation()
      .force(
        'link',
        d3.forceLink().id(function(d) {
          return d.id
        })
      )
      .force('charge', d3.forceManyBody())
      .force(
        'collide',
        d3.forceCollide(function(d) {
          if (d.id % 2 == 0) return radius * 1.2
          else return radius * 1.3
        })
      )
      .force('center', d3.forceCenter(width / 2, height / 2))

    // build the arrow.
    svg
      .append('svg:defs')
      .selectAll('marker')
      .data(['end']) // Different link/path types can be defined here
      .enter()
      .append('svg:marker') // This section adds in the arrows
      .attr('id', String)
      .attr('viewBox', '0 -5 10 10')
      .attr('refX', radius * 2) // offst to the target point
      .attr('refY', 0)
      .attr('markerWidth', 6)
      .attr('markerHeight', 6)
      .attr('orient', 'auto')
      .append('svg:path')
      .attr('d', 'M0,-5L10,0L0,5')

    const link = svg
      .append('g')
      .attr('class', 'links')
      .selectAll('line')
      .data(graph.links)
      .enter()
      .append('line')
      .attr('marker-end', 'url(#end)')

    const node = svg
      .append('g')
      .attr('class', 'nodes')
      .selectAll('g')
      .data(graph.nodes)
      .enter()
      .append('g')

    var self = this
    this.circles = node
      .append('circle')
      .attr('r', radius)
      .on('click', function(d) {
        if ( d.properties.title === 'Category:Main_topic_classifications' ) {
          return
        }

        if (self.props.limit && self.getSelectedTaxons().length >= self.props.limit) {
          alert(self.props.t('taxonomySelector.limitReached'))
          return
        }

        d3.select(this).classed('selected', !d3.select(this).classed('selected'))
        if (!d.selected) {
          d.selected = 1
          d3.select(this).attr('selected', 1)
        } else {
          delete d.selected
          d3.select(this).attr('selected', null)
        }
      })

    node
      .append('svg:image')
      .attr('xlink:href', d =>
        d.labels.find(function(e) {
          return e == 'ROOT'
        })
          ? require('assets/images/graph/exfluence.png')
          : undefined
      )
      .attr('x', -radius + 5)
      .attr('y', -radius + 5)
      .attr('height', radius * 2 - 10)
      .attr('width', radius * 2 - 10)
      .attr('pointer-events', 'none')

    const side = 2 * radius * Math.cos(Math.PI / 4)

    node
      .append('foreignObject')
      .attr('width', side)
      .attr('height', side)
      .attr('x', -side / 2)
      .attr('y', -side / 2)
      .attr('pointer-events', 'none')
      .append('xhtml:p')
      .text(d => (d.labels.find(e => e == 'ROOT') ? '' : d.properties[lang]))

    simulation.nodes(graph.nodes).on('tick', ticked)
    simulation.force('link').links(graph.links)

    function ticked() {
      link
        .attr('x1', function(d) {
          return d.source.x
        })
        .attr('y1', function(d) {
          return d.source.y
        })
        .attr('x2', function(d) {
          return d.target.x
        })
        .attr('y2', function(d) {
          return d.target.y
        })

      node.attr('transform', d => 'translate(' + d.x + ',' + d.y + ')')
    }
  }

  getSelectedTaxons() {
    return this.circles
      .filter(function(d) {
        return d3.select(this).attr('selected') == 1
      })
      .data()
      .map(function(d) {
        return d.properties.title
      })
  }

  svgMouseDown(event) {
    this.svg = ReactDOM.findDOMNode(this.modal.current).querySelector('svg')

    if (event.target !== this.svg || event.button !== 0) {
      return true
    }

    this.svgDrag = true
    this.offsetX = this.svg.viewBox.baseVal.x + event.clientX
    this.offsetY = this.svg.viewBox.baseVal.y + event.clientY
    return false
  }

  svgMouseUp(event) {
    if (!this.svgDrag || event.button !== 0) {
      return true
    }

    this.svgDrag = false
    return false
  }

  svgMouseMove(event) {
    if (!this.svgDrag) {
      return true
    }

    this.svg.viewBox.baseVal.x = this.offsetX - event.clientX
    this.svg.viewBox.baseVal.y = this.offsetY - event.clientY
    return false
  }

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace)
  }

  render() {
    const { onChange, placeholder, dialogHeader, value } = this.props
    const { dialogOpen, height, tags, width, isLoading, foundNodes } = this.state
    const { t } = this.props

    return (
      <div className={style.taxonomySelector}>
        <Input
          readOnly
          onClick={() => this.setDialogVisible(true)}
          value={this.replaceAll(value, 'Category:', '').replace(new RegExp('IQVIA:', 'g'), '')}
          placeholder={placeholder}
          action={
            onChange && (
              <Button basic icon onClick={() => this.setDialogVisible(true)}>
                <Icon name="edit" />
              </Button>
            )
          }
        ></Input>
        <Modal
          open={onChange && dialogOpen}
          onClose={() => this.setState({ dialogOpen: false })}
          ref={this.modal}
        >
          <Modal.Header>{dialogHeader}</Modal.Header>
          <Modal.Content className={style.modalContent}>
            <Message info>
              {t('taxonomySelector.infoMessage')}
              {foundNodes && !isLoading && <p>{t('taxonomySelector.click')}</p>}
            </Message>
            {(foundNodes !== null && !foundNodes) && !isLoading && <Message warning>{t('taxonomySelector.notFound')}</Message>}
            <ReactTags
              tags={tags}
              handleDelete={i => this.tagDeleted(i)}
              handleAddition={tag => this.tagAdded(tag)}
              handleDrag={(tag, currPos, newPos) => this.tagDragged(tag, currPos, newPos)}
              delimiters={delimiters}
              placeholder={t('taxonomySelector.searchForNodes')}
              classNames={{remove: style.inputRemove}}
            />
            {isLoading ? (
              <LoaderWrapper style={{ height: height }}>
                <Loaderr />
              </LoaderWrapper>
            ) : (
              <svg
                className="taxonomyGraph"
                width={width}
                height={height}
                viewBox={'0 0 ' + width + ' ' + height}
                onMouseDown={event => this.svgMouseDown(event)}
                onMouseUp={event => this.svgMouseUp(event)}
                onMouseMove={event => this.svgMouseMove(event)}
              ></svg>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.save()} color="green">
              {t('taxonomySelector.save')}
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}

export default withTranslation('common')(TaxonomySelector)
