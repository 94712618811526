import { getJobTemplates } from 'api-operations/requester/template';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';
const StyledDropdown = styled(Dropdown) `
  height: 48px;
  &&&& {
    display: flex;
    align-items: center;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px; 

  }
  .dropdown.icon {
    top: 30% !important;
  }
  input { 
    height: 100%;
  }
  `;
const TemplateDropdown = ({ jobTemplateId, setJobTemplateId, fluid, removeFromList = null }) => {
    const [jobTemplates, setJobTemplates] = useState([]);
    const { t } = useTranslation('common');
    useEffect(() => {
        (async () => {
            try {
                const response = await getJobTemplates();
                const templateOption = response.map(({ name, templateId }) => ({ key: templateId, value: templateId, text: name }));
                setJobTemplates(templateOption);
            }
            catch (error) {
                // TODO
            }
        })();
    }, []);
    useEffect(() => {
        if (removeFromList !== null) {
            setJobTemplates(prevState => prevState.filter(({ value }) => value !== removeFromList));
        }
    }, [removeFromList]);
    return React.createElement(StyledDropdown, { placeholder: t('jobUpload.chooseTemplate'), options: jobTemplates, onChange: (_, data) => setJobTemplateId(data.value), value: jobTemplateId, clearable: true, selection: true, search: true, fluid: fluid });
};
export default TemplateDropdown;
