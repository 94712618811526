import { downloadFile, getPaginationParamsString, paginationParamsSerializer } from "api-operations/common";
import axios from "axios";
export async function getCompanyInfo() {
    const response = await axios.get(`/api/groups_management/v2/company`);
    return response.data;
}
export async function downloadInvoice(companyId, invoiceNumber) {
    await downloadFile(`/api/groups_management/companies/${companyId}/invoices/${invoiceNumber}/download`);
}
export async function acceptInvoice(companyId, invoiceNumber) {
    await axios.put(`/api/groups_management/companies/${companyId}/invoices/${invoiceNumber}/accept`);
}
export async function getCompanyInvoices(id, paginationFilterOptions) {
    const response = await axios.get(`/api/groups_management/companies/${id}/invoices`, {
        params: { ...paginationFilterOptions },
        paramsSerializer: params => paginationParamsSerializer(params)
    });
    return response.data;
}
export async function getCompanyExpenses(id, paginationFilterOptions) {
    const response = await axios.get(`/api/groups_management/companies/${id}/expenses`, {
        params: { ...paginationFilterOptions },
        paramsSerializer: params => paginationParamsSerializer(params)
    });
    return response.data;
}
export async function getCompanyExpensesCsv(id, paginationFilterOptions) {
    await downloadFile(`/api/groups_management/companies/${id}/expenses/csv?${getPaginationParamsString(paginationFilterOptions)}`);
}
export async function getCompanyEarnings(id, paginationFilterOptions) {
    const response = await axios.get(`/api/groups_management/companies/${id}/earnings`, {
        params: { ...paginationFilterOptions },
        paramsSerializer: params => paginationParamsSerializer(params)
    });
    return response.data;
}
export async function getCompanyEarningsCsv(id, paginationFilterOptions) {
    await downloadFile(`/api/groups_management/companies/${id}/earnings/csv?${getPaginationParamsString(paginationFilterOptions)}`);
}
export async function addPaypalPaymentMethod(id, paymentData) {
    await axios.post(`/api/groups_management/companies/${id}/paymentTypes/paypal`, paymentData);
}
export async function addIbanPaymentMethod(id, paymentData) {
    await axios.post(`/api/groups_management/companies/${id}/paymentTypes/iban`, paymentData);
}
export async function addBankPaymentMethod(id, paymentData) {
    await axios.post(`/api/groups_management/companies/${id}/paymentTypes/bank`, paymentData);
}
export async function setDefaultPaymentMethod(id, paymentId) {
    await axios.put(`/api/groups_management/companies/${id}/defaultPayment`, undefined, {
        params: { paymentId }
    });
}
export async function getCompanyPaymentMethods(id) {
    const response = await axios.get(`/api/groups_management/companies/${id}/paymentTypes`);
    return response.data;
}
export async function getPaypalPaymentMethods(id) {
    const response = await axios.get(`/api/groups_management/companies/${id}/paymentTypes/paypal`);
    return response.data;
}
export async function getIbanPaymentMethods(id) {
    const response = await axios.get(`/api/groups_management/companies/${id}/paymentTypes/iban`);
    return response.data;
}
export async function getBankPaymentMethods(id) {
    const response = await axios.get(`/api/groups_management/companies/${id}/paymentTypes/bank`);
    return response.data;
}
export async function getContactPersonInfo(id) {
    const response = await axios.get(`/api/groups_management/companies/${id}/contact`);
    return response.data;
}
export async function setContactPersonInfo(id, contactPerson) {
    await axios.put(`/api/groups_management/companies/${id}/contact`, contactPerson);
}
export async function getFinancialInfo(id) {
    const response = await axios.get(`/api/groups_management/companies/${id}/financialInfo`);
    return response.data;
}
export async function getMinimumGlobalPaymentThresholdEur() {
    const response = await axios.get(`/api/groups_management/v2/company/minGlobalPaymentThresholdEur`);
    return response.data;
}
export async function setFinancialInfo(id, financialInfo) {
    await axios.put(`/api/groups_management/companies/${id}/financialInfo`, financialInfo);
}
export async function patchFinancialInfo(id, financialInfo) {
    await axios.patch(`/api/groups_management/v2/company/${id}`, financialInfo);
}
export async function removeUserFromCompany(orgId, userId) {
    await axios.delete(`/api/groups_management/companies/${orgId}/removeUser/${userId}`);
}
export async function changeUserOrganizationProfile(organizationId, userId, profile) {
    await axios.put(`/api/groups_management/companies/${organizationId}/changeProfile/${userId}/${profile}`);
}
export async function getUsersFromCompany(companyId) {
    const response = await axios.get(`/api/groups_management/companies/${companyId}/listUsers`);
    return response.data;
}
export async function addUserToCompany(companyId, users) {
    const response = await axios.post(`/api/groups_management/companies/${companyId}/addUsers`, users);
    return response.data;
}
export async function changeCompanyName(id, newName) {
    await axios.put(`/api/groups_management/v2/company/${id}/name`, newName);
}
