import axios from "axios";
export async function createCompany(name) {
    const response = await axios.post(`/api/groups_management/companies/new`, undefined, {
        params: { name }
    });
    return response.data;
}
export async function getUsersNotInCompany(pattern) {
    const response = await axios.get(`/api/groups_management/users/usersNotInCompany`, {
        params: { pattern }
    });
    return response.data;
}
