import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Table, Button, Checkbox, Rating, Dropdown, Modal } from 'semantic-ui-react';
import { addUserAllowedRoleInCommunity, changeUserGcProfile, changeUserMembershipStatusInGatedCommunity, deleteUserAllowedRoleInCommunity, removeUserFromGatedCommunity, setPriorityOfUserRoleInCommunity } from 'api-operations/community/admin';
import Avatar from 'helpers/components/Avatar';
import PublicProfile from 'helpers/components/PublicProfile';
import { getOptionsDropdownFromArray } from 'helpers/utils';
const ManageMembersRow = ({ communityMember, isAdmin }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTranslation('common');
    const userLogin = useSelector(state => state.userProfile.login);
    const params = useParams();
    const [member, setMember] = useState(communityMember);
    const [isDeleted, setIdDeleted] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const handleMemberSuspension = async (userId, membershipStatus) => {
        try {
            await changeUserMembershipStatusInGatedCommunity(params.id, userId, membershipStatus);
            setMember(prevState => ({ ...prevState, membershipStatus }));
            toast.success(t('notification.memberInformationUpdated'));
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
    };
    const handleProfileChange = async (userId, profile) => {
        try {
            await changeUserGcProfile(params.id, userId, profile);
            setMember(prevState => ({ ...prevState, profile }));
            toast.success(t('notification.memberInformationUpdated'));
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
    };
    const handleMemberDeletion = async (userId) => {
        setIsModalOpen(false);
        try {
            await removeUserFromGatedCommunity(params.id, userId);
            setIdDeleted(true);
            toast.success(t('notification.userRemoved'));
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
    };
    const handleMemberPriorityRoleChange = async (userId, userRole, priority) => {
        const newPriority = priority === 1 ? 0 : 1;
        try {
            await setPriorityOfUserRoleInCommunity(params.id, userId, userRole, newPriority);
            setMember((prevState) => {
                const newAllowedRoles = prevState.allowedRoles.map(allowedRole => allowedRole.role === userRole ? { role: userRole, priority: newPriority } : allowedRole);
                return ({ ...prevState, allowedRoles: newAllowedRoles });
            });
            toast.success(t('notification.memberInformationUpdated'));
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
    };
    const handleMemberAllowedRoleChange = async (userId, userRole) => {
        try {
            if (member.allowedRoles.find(({ role }) => role == userRole)) {
                await deleteUserAllowedRoleInCommunity(params.id, userId, userRole);
                setMember((prevState) => {
                    const newAllowedRoles = prevState.allowedRoles.filter(allowedRole => allowedRole.role !== userRole);
                    return ({ ...prevState, allowedRoles: newAllowedRoles });
                });
            }
            else {
                await addUserAllowedRoleInCommunity(params.id, userId, userRole);
                setMember((prevState) => {
                    const newAllowedRoles = prevState.allowedRoles;
                    newAllowedRoles.push({ priority: 0, role: userRole });
                    return ({ ...prevState, allowedRoles: newAllowedRoles });
                });
            }
            toast.success(t('notification.memberInformationUpdated'));
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
    };
    const { membershipStatus, allowedRoles, login, profile, userId, roles } = member;
    if (isDeleted)
        return null;
    return React.createElement(React.Fragment, null,
        React.createElement(Table.Row, { negative: membershipStatus === 'REJECTED' },
            isAdmin && React.createElement(Table.Cell, null,
                React.createElement(Button, { size: 'mini', icon: isExpanded ? "angle up" : "angle down", onClick: () => setIsExpanded(prevState => !prevState) })),
            React.createElement(Table.Cell, null,
                React.createElement(Avatar, { name: login, inlineAvatar: true }),
                login,
                React.createElement(PublicProfile, { userName: login })),
            React.createElement(Table.Cell, null, isAdmin ? React.createElement(Dropdown, { fluid: true, basic: true, selection: true, options: getOptionsDropdownFromArray(['GATED_COMMUNITY_ADMIN', 'GATED_COMMUNITY_MEMBER'], 'membershipProfile'), value: profile, onChange: (_, data) => handleProfileChange(userId, data.value), disabled: login === userLogin })
                : t(`membershipProfile.${profile}`)),
            React.createElement(Table.Cell, null, isAdmin ? React.createElement(Dropdown, { fluid: true, basic: true, selection: true, options: getOptionsDropdownFromArray(['MEMBER', 'SUSPENDED', 'REJECTED'], 'membershipStatus'), value: membershipStatus, onChange: (_, data) => handleMemberSuspension(userId, data.value), disabled: login === userLogin })
                : t(`membershipStatus.${membershipStatus}`)),
            isAdmin && React.createElement(Table.Cell, null, login !== userLogin
                && React.createElement(Button, { basic: true, negative: true, icon: "trash alternate", name: userId, title: t('groupsCommon.delete'), onClick: () => setIsModalOpen(true) }))),
        isExpanded && React.createElement(Table.Row, null,
            React.createElement(Table.Cell, { colSpan: "5" }, roles.map((availableRole) => {
                const roleParams = allowedRoles.find(({ role }) => role === availableRole);
                return React.createElement("div", { key: availableRole + userId },
                    React.createElement(Checkbox, { checked: !!roleParams, onChange: () => handleMemberAllowedRoleChange(userId, availableRole) }),
                    React.createElement(Rating, { disabled: roleParams === undefined, icon: 'star', maxRating: 1, rating: (roleParams === null || roleParams === void 0 ? void 0 : roleParams.priority) || 0, onRate: (() => handleMemberPriorityRoleChange(userId, availableRole, (roleParams === null || roleParams === void 0 ? void 0 : roleParams.priority) || 0)) }),
                    t(`userRoles.${availableRole}`));
            }))),
        React.createElement(Modal, { open: isModalOpen, onClose: () => setIsModalOpen(false) },
            React.createElement(Modal.Content, null,
                t('gatedCommunities.memberDeletion'),
                " ",
                React.createElement("b", null, login),
                " ",
                t('gatedCommunities.fromGc'),
                "?"),
            React.createElement(Modal.Actions, null,
                React.createElement(Button, { onClick: () => setIsModalOpen(false) }, t('common.cancel')),
                React.createElement(Button, { negative: true, onClick: () => handleMemberDeletion(userId) }, t('common.remove')))));
};
export default ManageMembersRow;
