import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Table } from 'semantic-ui-react';
import { parseTimestamp } from 'helpers/DateHandler';
import PagedAssignmentsXflView from './PagedAssignmentsXflView';
import { getAssignments } from 'api-operations/myExfluency/myExfluency';
import TablePlaceholder from 'helpers/components/TablePlaceholder';
import { parsePrice } from 'helpers/utils';
const displayedColumns = ['projectRole', 'type', 'filename', 'languages', 'deliveryDate', 'sentencesFixed', 'estimatedPrice', 'langCoin'];
const Assignments = () => {
    const [assignments, setAssignments] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const { t } = useTranslation('common');
    const fetchData = async (paginationFilterOptions) => {
        if (isError) {
            setIsError(false);
            setIsLoading(true);
        }
        try {
            const response = await getAssignments(paginationFilterOptions);
            setAssignments(response.results);
            setTotalCount(response.totalCount);
        }
        catch (error) {
            setIsError(true);
        }
        setIsLoading(false);
    };
    return React.createElement(PagedAssignmentsXflView, { fetchData: fetchData, totalResults: totalCount, tableIdentifier: "xflAssignments" },
        React.createElement(Table, { color: "green", celled: true, striped: true },
            React.createElement(Table.Header, null,
                React.createElement(Table.Row, null, displayedColumns.map(e => React.createElement(Table.HeaderCell, { key: e }, t(`myLangCoin.${e}`))))),
            isLoading || isError || !assignments.length ? React.createElement(TablePlaceholder, { isLoading: isLoading, isError: isError, noEntries: !assignments.length })
                : React.createElement(Table.Body, null, assignments.map(assignment => React.createElement(Table.Row, { key: assignment.id },
                    React.createElement(Table.Cell, null, t(`assignmentRole.${assignment.assignmentRole}`)),
                    React.createElement(Table.Cell, null, t(`stages.${assignment.type}`)),
                    React.createElement(Table.Cell, { width: '4', style: { wordBreak: 'break-word' } }, assignment.filename),
                    React.createElement(Table.Cell, null,
                        t(`languages.${(assignment.langFrom.toLowerCase())}`),
                        " ",
                        React.createElement(Icon, { name: "arrow right" }),
                        " ",
                        t(`languages.${assignment.langTo.toLowerCase()}`)),
                    React.createElement(Table.Cell, null, parseTimestamp(assignment.deliveryDate)),
                    React.createElement(Table.Cell, null, assignment.sentencesFixed),
                    React.createElement(Table.Cell, { textAlign: 'right' }, `${assignment.fiat} ` + t(`${parsePrice(assignment.price)}`)),
                    React.createElement(Table.Cell, null, assignment.xfl))))));
};
export default Assignments;
