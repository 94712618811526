import React from 'react';
import { Message, Button } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
export const StyledModal = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  height: calc(100% - 100px);
  margin: 100px 10%;
  text-align: right;
  z-index: 999;
`;
export const StyledMessageHeader = styled(Message.Header) `
  text-align: left;
`;
const ErrorModal = ({ text, url, status, onClose }) => {
    const history = useHistory();
    const list = [`${text || ''}`, `From Url: ${url || ''}`, `Code: ${status || ''}`];
    if (status == 401) {
        document.cookie = 'ExfluencyCookie=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        history.push('/');
    }
    return (React.createElement(StyledModal, null,
        React.createElement(Message, { error: true },
            React.createElement(StyledMessageHeader, { content: "Error occured" }),
            React.createElement(Message.List, { items: list })),
        React.createElement(Button, { onClick: onClose, color: "red", inverted: true }, "Close")));
};
export default ErrorModal;
