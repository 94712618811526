import React from "react";
import FileUploadItem from "./FileUploadItem";
import { Grid } from "@mui/material";
const FilesContainer = ({ files, handleRemoveFile, handleFileUpload, expectedLanguage, gridXs, gridJustifyContent, placing, uploadFiles }) => {
    return React.createElement(Grid, { container: true, spacing: 0.5, justifyContent: gridJustifyContent || "center", className: "file-upload files-wrapper" }, files.map(file => {
        var _a, _b;
        return React.createElement(Grid, { key: file.toUploadFile ? file.toUploadFile.id : (_a = file.uploadedFile) === null || _a === void 0 ? void 0 : _a.id, item: true, xs: gridXs || 8 },
            React.createElement(FileUploadItem, { key: file.toUploadFile ? file.toUploadFile.id : (_b = file.uploadedFile) === null || _b === void 0 ? void 0 : _b.id, file: file, onFileRemove: handleRemoveFile, onUpload: handleFileUpload, expectedLanguage: expectedLanguage, placing: placing, uploadFiles: uploadFiles }));
    }));
};
export default FilesContainer;
