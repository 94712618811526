import React, { useState, useEffect, useRef } from 'react';
import { Divider, Grid, Icon, Pagination, Search, Select } from 'semantic-ui-react';
import styled from 'styled-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import SelectMemo from 'helpers/SelectMemo';
import DatePicker from 'helpers/components/DatePicker';
import { getDashboardOptionsFromStorage, rowsOptions } from 'helpers/utils';
const PaginationWrapper = styled.div `
  text-align: center;
  padding: 20px 0;
`;
const SelectWrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
const StyledGrid = styled(Grid) `
  margin: 0 0 10px 0 !important;

  &&&& { 
  .row .column {
    padding-left: 0 !important;
    display: flex;
  }

  .row:not(:first-child) {
    padding-top: 0 !important;
  }

  .row .column:last-child {
    padding-right: 0 !important;
  }
}
`;
const StyledDivider = styled(Divider) `
  &.ui.horizontal.divider:after {
    background-position: left 1em top 0;
   }

  &.ui.horizontal.divider:before {
    background-position: right 1em top 0;
  }

  @-moz-document url-prefix() {
   &.ui.horizontal.divider:after {
    background-position: left 1em top 50%;
   }

   &.ui.horizontal.divider:before {
    background-position: right 1em top 50%;
   }
  }
`;
const FilterWrapper = styled.span `
  cursor: pointer;
`;
const PagedJobsCompanyView = ({ children, totalResults, orderOptions, fetchData, tableIdentifier, setPaginationOptions }) => {
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [searchInput, setSearchInput] = useState(undefined);
    const [orderBy, setOrderBy] = useState(getDashboardOptionsFromStorage(tableIdentifier, 'orderBy', orderOptions));
    const [langTo, setLangTo] = useState(undefined);
    const [langFrom, setLangFrom] = useState(undefined);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [showFilters, setShowFilters] = useState(false);
    const [limit, setLimit] = useState(getDashboardOptionsFromStorage(tableIdentifier, 'limit', rowsOptions));
    const { t } = useTranslation('common');
    const activePage = useRef(1);
    useEffect(() => {
        localStorage.setItem(`${tableIdentifier}PaginationOptions`, JSON.stringify({ limit, orderBy }));
    }, [limit, orderBy]);
    useEffect(() => {
        if (searchInput === undefined)
            return;
        setIsSearchLoading(true);
        const timeoutId = setTimeout(() => {
            activePage.current = 1;
            handePageChange();
            setIsSearchLoading(false);
        }, 1000);
        // eslint-disable-next-line consistent-return
        return () => {
            clearTimeout(timeoutId);
        };
    }, [searchInput]);
    useEffect(() => {
        activePage.current = 1;
        handePageChange();
    }, [limit, orderBy, langFrom, langTo, activePage, startDate, endDate]);
    const handePageChange = (active) => {
        const [sortBy, sortDirection] = orderBy.split(' ');
        const offset = (active ? active : activePage.current) * limit - limit;
        const options = { limit, offset, sortBy, sortDirection, search: searchInput,
            langFrom, langTo, startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined, endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined };
        setPaginationOptions(options);
        fetchData && fetchData(options);
        activePage.current = active ? active : activePage.current;
    };
    const totalPages = Math.ceil(totalResults / limit);
    return React.createElement(React.Fragment, null,
        React.createElement(StyledGrid, { verticalAlign: 'bottom', stackable: true, padded: true },
            React.createElement(Grid.Row, { columns: 16 },
                React.createElement(Grid.Column, { width: 1 },
                    React.createElement("label", null, t('pagination.rowsPerPage')),
                    React.createElement(Select, { name: 'rowsPerPage', onChange: (_, data) => { setLimit(data.value); }, options: rowsOptions, value: limit, fluid: true })),
                React.createElement(Grid.Column, { width: 4 },
                    React.createElement("label", null, t('pagination.sortBy')),
                    React.createElement(Select, { search: true, onChange: (_, data) => { setOrderBy(data.value); }, options: orderOptions, value: orderBy, fluid: true })),
                React.createElement(Grid.Column, { width: 4 },
                    React.createElement(SelectWrapper, null,
                        React.createElement(SelectMemo, { label: 'language_from', search: true, option: 'languages', setValue: setLangFrom, value: langFrom, clearable: true }))),
                React.createElement(Grid.Column, { width: 4 },
                    React.createElement(SelectWrapper, null,
                        React.createElement(SelectMemo, { label: 'language_to', search: true, option: 'languages', setValue: setLangTo, value: langTo, clearable: true }))),
                React.createElement(Grid.Column, { width: 3 },
                    React.createElement(Search, { open: false, loading: isSearchLoading, onSearchChange: (e) => { setSearchInput(e.target.value); }, value: searchInput, input: { fluid: true } }))),
            React.createElement(Grid.Row, { style: { paddingBottom: 0 } },
                React.createElement(Grid.Column, null,
                    React.createElement(StyledDivider, { horizontal: true },
                        React.createElement(FilterWrapper, { onClick: () => setShowFilters(prevState => !prevState) },
                            React.createElement(Icon, { name: 'filter' }),
                            t('pagination.moreFilters'),
                            React.createElement(Icon, { name: `arrow ${showFilters ? 'up' : 'down'}` }))))),
            showFilters && React.createElement(Grid.Row, { columns: 16 },
                React.createElement(Grid.Column, { width: 2 },
                    React.createElement("label", null, t('pagination.startDate')),
                    React.createElement(DatePicker, { isClearable: true, selected: startDate, onChange: date => setStartDate(date) })),
                React.createElement(Grid.Column, { width: 2 },
                    React.createElement("label", null, t('pagination.endDate')),
                    React.createElement(DatePicker, { isClearable: true, selected: endDate, onChange: date => setEndDate(date) })))),
        children,
        totalResults > 0 && React.createElement(PaginationWrapper, null,
            React.createElement(Pagination, { totalPages: totalPages, activePage: activePage.current, onPageChange: (_, data) => handePageChange(data.activePage) })));
};
export default PagedJobsCompanyView;
