import React, { useState, useEffect, useRef } from 'react';
import { Form, Icon, Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { getFinancialInfo, getMinimumGlobalPaymentThresholdEur, setFinancialInfo } from 'api-operations/company/admin';
import { countryList, currencies } from 'dictionaries/dictionaries';
import { getOptionsDropdownFromArray } from 'helpers/utils';
import { useDispatch } from 'react-redux';
import { saveCompanyCurrency, saveCompanyExchangeRate } from 'reduxStore/actions/actions';
import { getLatestExchangeRate } from 'api-operations/common';
const CountriesDropdown = ({ country, setCountry }) => {
    const { t } = useTranslation('common');
    return React.createElement(Form.Dropdown, { search: true, selection: true, options: countryList.map(country => ({ value: country, text: country, key: country })), value: country, onChange: (e, { value }) => setCountry(value), required: true, label: t('companies.country'), width: 4 });
};
const MemoCountriesDropdown = React.memo(CountriesDropdown);
const FinancialInfoComponent = () => {
    const [vat, setVat] = useState('');
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [street, setStreet] = useState('');
    const [streetNum, setStreetNum] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [state, setState] = useState('');
    const [currency, setCurrency] = useState('EUR');
    const [paymentThreshold, setPaymentThreshold] = useState('');
    const [minPaymentThresholdEur, setMinPaymentThresholdEur] = useState(0);
    const [isChanged, setIsChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const originalFinancialInfoObj = useRef(null);
    const params = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    useEffect(() => {
        (async () => {
            try {
                setMinPaymentThresholdEur(await getMinimumGlobalPaymentThresholdEur());
                const response = await getFinancialInfo(params.id);
                if (response) {
                    originalFinancialInfoObj.current = response;
                    setVat(response.vatNum);
                    setCurrency(response.currency);
                    setCountry(response.address.country);
                    setCity(response.address.city);
                    setStreet(response.address.street);
                    setStreetNum(response.address.streetNum);
                    setPostalCode(response.address.postalCode);
                    setState(response.address.state || '');
                    setPaymentThreshold(response.paymentThreshold || '');
                }
                setIsLoading(false);
            }
            catch (error) {
                // TODO how to display error on download info?
            }
        })();
    }, []);
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        if ([vat, country, city, street, streetNum, postalCode, currency].some(field => !field)) {
            setIsChanged(false);
            return;
        }
        if (((_a = originalFinancialInfoObj.current) === null || _a === void 0 ? void 0 : _a.vatNum) === vat
            && ((_b = originalFinancialInfoObj.current) === null || _b === void 0 ? void 0 : _b.currency) === currency
            && ((_c = originalFinancialInfoObj.current) === null || _c === void 0 ? void 0 : _c.address.country) === country
            && ((_d = originalFinancialInfoObj.current) === null || _d === void 0 ? void 0 : _d.address.city) === city
            && ((_e = originalFinancialInfoObj.current) === null || _e === void 0 ? void 0 : _e.address.street) === street
            && ((_f = originalFinancialInfoObj.current) === null || _f === void 0 ? void 0 : _f.address.streetNum) === streetNum
            && ((_g = originalFinancialInfoObj.current) === null || _g === void 0 ? void 0 : _g.address.postalCode) === postalCode
            && ((((_h = originalFinancialInfoObj.current) === null || _h === void 0 ? void 0 : _h.address.state) || '') === state)
            && ((((_j = originalFinancialInfoObj.current) === null || _j === void 0 ? void 0 : _j.paymentThreshold) || '') === paymentThreshold)) {
            setIsChanged(false);
            return;
        }
        setIsChanged(true);
    }, [vat, country, city, street, streetNum, postalCode, state, currency, paymentThreshold]);
    const handleSubmit = async () => {
        var _a;
        if ([vat, country, city, street, streetNum, postalCode, currency].some(field => !field))
            return;
        if (currency !== ((_a = originalFinancialInfoObj.current) === null || _a === void 0 ? void 0 : _a.currency)) {
            dispatch(saveCompanyCurrency(currency));
            if (currency !== 'EUR') {
                dispatch(saveCompanyExchangeRate(await getLatestExchangeRate('EUR', currency)));
            }
            else {
                dispatch(saveCompanyExchangeRate());
            }
        }
        const financialInfo = {
            vatNum: vat,
            currency,
            bankAccount: '',
            address: {
                city,
                country,
                postalCode,
                street,
                streetNum,
                state
            },
            paymentThreshold
        };
        try {
            await setFinancialInfo(params.id, financialInfo);
            originalFinancialInfoObj.current = financialInfo;
            setIsChanged(false);
            toast.success(t('notification.informationUpdated'));
        }
        catch (error) {
            if (error.response) {
                if (error.response.status == 400) { //bad request
                    toast.error(error.response.data.message);
                }
                else {
                    toast.error(t('notification.error'));
                }
            }
        }
    };
    return React.createElement(Form, { onSubmit: handleSubmit },
        React.createElement(Form.Group, null,
            React.createElement(Form.Input, { value: vat, onChange: (e, { value }) => setVat(value), required: true, label: t('companies.vat'), width: 8 }),
            React.createElement(Form.Input, { value: paymentThreshold, type: "number", step: 0.01, onChange: (e, { value }) => setPaymentThreshold(value), width: 4, label: React.createElement("label", null,
                    t('companies.paymentThreshold'),
                    React.createElement(Popup, { position: 'top center', hoverable: true, trigger: React.createElement(Icon, { name: 'question circle outline', color: "grey", style: { cursor: 'pointer' } }) },
                        React.createElement("div", null, t('dashboard.companyPaymentNotice').replace("GLOBAL_MINIMUM", "" + minPaymentThresholdEur)))) }),
            React.createElement(Form.Dropdown, { selection: true, options: getOptionsDropdownFromArray(currencies), value: currency, onChange: (e, { value }) => setCurrency(value), required: true, width: 2, label: React.createElement("label", null,
                    t('companies.currency'),
                    React.createElement(Popup, { position: 'top center', hoverable: true, trigger: React.createElement(Icon, { name: 'question circle outline', color: "grey", style: { cursor: 'pointer' } }) },
                        React.createElement("div", null,
                            t('dashboard.exchangeRateNotice'),
                            React.createElement("a", { href: "https://www.ecb.europa.eu/stats/policy_and_exchange_rates/euro_reference_exchange_rates/html/index.en.html", target: "_blank", rel: "noreferrer" }, t('dashboard.exchangeRateLink'))))) })),
        React.createElement(Form.Group, null,
            React.createElement(MemoCountriesDropdown, { country: country, setCountry: setCountry }),
            React.createElement(Form.Input, { value: city, onChange: (e, { value }) => setCity(value), required: true, label: t('companies.city'), width: 4 }),
            React.createElement(Form.Input, { value: street, onChange: (e, { value }) => setStreet(value), required: true, label: t('companies.street'), width: 4 }),
            React.createElement(Form.Input, { value: streetNum, onChange: (e, { value }) => setStreetNum(value), required: true, label: t('companies.streetNumber'), width: 4 })),
        React.createElement(Form.Group, null,
            React.createElement(Form.Input, { value: postalCode, onChange: (e, { value }) => setPostalCode(value), required: true, label: t('companies.postalCode'), width: 4 }),
            React.createElement(Form.Input, { value: state, onChange: (e, { value }) => setState(value), label: t('companies.state'), width: 4 })),
        React.createElement(Form.Group, { style: { textAlign: 'center' } },
            React.createElement(Form.Button, { primary: true, content: t('companies.updateBilling'), width: 16, disabled: isLoading || !isChanged, loading: isLoading })));
};
export default FinancialInfoComponent;
