import i18next from 'i18next';
import moment from 'moment';
export default class CommentsPanel extends HTMLElement {
    constructor(comments = [], root = true) {
        super();
        if (root) {
            const emptyState = document.createElement("div");
            emptyState.classList.add("emptyState");
            emptyState.textContent = i18next.t('commentsPanel.noComments');
            this.appendChild(emptyState);
        }
        this.className = "ui comments";
        this.comments = comments;
    }
    set comments(comments) {
        Array.from(this.querySelectorAll("xfl-new-comment")).forEach(c => c.remove());
        comments.forEach(comment => this.appendChild(new CommentElement(comment)));
    }
}
class CommentElement extends HTMLElement {
    constructor(comment) {
        super();
        this.className = "comment-box";
        this.id = comment.id;
        const content = document.createElement("div");
        this.appendChild(content);
        const author = document.createElement("span");
        author.className = "author";
        author.textContent = comment.author;
        content.appendChild(author);
        const metadata = document.createElement("div");
        metadata.className = "metadata";
        content.appendChild(metadata);
        const creationMoment = moment(comment.creationTime);
        const date = document.createElement("time");
        date.className = "date";
        date.dateTime = creationMoment.toISOString();
        date.textContent = creationMoment.fromNow();
        metadata.appendChild(date);
        content.appendChild(this.createCommentTextField(comment.text));
        this.appendChild(new CommentsPanel(comment.replies, false));
    }
    createCommentTextField(commentText) {
        const textContainer = document.createElement("div");
        textContainer.className = "text";
        const lines = commentText.split("\n");
        lines.forEach((line) => {
            if (line) {
                const commentLine = document.createElement("span");
                commentLine.textContent = line;
                textContainer.appendChild(commentLine);
            }
            textContainer.appendChild(document.createElement("br"));
        });
        return textContainer;
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-comments", CommentsPanel));
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-comment", CommentElement));
