import { addIbanPaymentMethod } from 'api-operations/company/admin';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, Button, Modal } from 'semantic-ui-react';
const IbanPayment = ({ open, setOpen }) => {
    const [iban, setIban] = useState(null);
    const [bankName, setBankName] = useState(null);
    const [bankHolderName, setBankHolderName] = useState(null);
    const [isIbanValid, setIsIbanValid] = useState(true);
    const [isBankNameValid, setIsBankNameValid] = useState(true);
    const [isBankHolderNameValid, setIsBankHolderNameValid] = useState(true);
    const { t } = useTranslation('common');
    const params = useParams();
    const handleSubmit = async () => {
        if (!iban) {
            setIsIbanValid(false);
        }
        else {
            setIsIbanValid(true);
        }
        if (!bankName) {
            setIsBankNameValid(false);
        }
        else {
            setIsBankNameValid(true);
        }
        if (!bankHolderName) {
            setIsBankHolderNameValid(false);
        }
        else {
            setIsBankHolderNameValid(true);
        }
        if ([iban, bankName, bankHolderName].some(ele => !ele))
            return;
        try {
            await addIbanPaymentMethod(params.id, { accountHolder: bankHolderName, bankName: bankName, iban: iban });
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
        setIban(null);
        setBankName(null);
        setBankHolderName(null);
        setOpen(null);
    };
    return React.createElement(Modal, { open: open },
        React.createElement(Modal.Content, null,
            React.createElement(Form, null,
                React.createElement(Form.Input, { value: iban, onChange: (e, { value }) => setIban(value), required: true, label: t('companies.payment.ibanNumber'), error: !isIbanValid && t('companies.invalid') }),
                React.createElement(Form.Input, { value: bankName, onChange: (e, { value }) => setBankName(value), required: true, label: t('companies.payment.bankName'), error: !isBankNameValid && t('companies.invalid') }),
                React.createElement(Form.Input, { value: bankHolderName, onChange: (e, { value }) => setBankHolderName(value), required: true, label: t('companies.payment.accountHolder'), error: !isBankHolderNameValid && t('companies.invalid') }))),
        React.createElement(Modal.Actions, null,
            React.createElement(Button, { onClick: () => setOpen(null) }, t('companies.payment.cancel')),
            React.createElement(Button, { primary: true, onClick: () => handleSubmit() }, t('companies.payment.create'))));
};
export default IbanPayment;
