import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react';
const Container = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
`;
const Paragraph = styled.p `
  font-size: 1.5rem;
`;
const Page404 = () => {
    const { t } = useTranslation('common');
    return (React.createElement(Container, null,
        React.createElement("div", null,
            React.createElement(Header, { as: "h1" }, t('404.header')),
            React.createElement(Paragraph, null, t('404.content')))));
};
export default Page404;
