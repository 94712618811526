import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Styled } from 'StyledComponents';
import moment from 'moment';
import { Popup } from 'semantic-ui-react';
import { Content, FileName, Stage, Username, DueDate, Footer, IconsWrapper, Card, CardContent, Progress, IconWrapper, ArrowWrapper, CancelAssignment, ResponseButton, FileNameOneLine } from './Assignment.styles';
import { confirmAssignmentCancellation, rejectAssignmentCancel } from 'api-operations/executor/executor';
import { remainingDateState, showDateCountdown, trimString } from 'helpers/utils';
import { StageAttachmentModal } from 'helpers/components/fileUpload/FileUploaderModal';
import { useLogger } from 'logger/Logger';
import { ClockIcon, AttachmentIcon, InformationIcon, ArrowRightIcon } from 'assets/icons';
import AssignmentInfo from './AssignmentInfo';
import AssignmentCancel from './AssignmentCancel';
import { toast } from 'react-toastify';
const REMAINING_TIME_ALERT_PERCENT = .1;
const Assignment = ({ assignment, refresh }) => {
    const [attachmentModalIdOpen, setAttachmentModalIdOpen] = useState(undefined);
    const [cancellationResponseView, setCancellationResponseView] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation('common');
    const history = useHistory();
    const { logger } = useLogger();
    const { jobName, assigner, executor, progress, stageType, id, dueDate, claimDate, attachments, cancellationRequest } = assignment;
    const openEditor = (id) => {
        if (!id)
            return;
        history.push({
            pathname: `/editor/${id}`,
            state: { id }
        });
    };
    const handleAttachmentsModalOpen = (id) => {
        setAttachmentModalIdOpen(id);
        logger.info('User - Opened attachments modal');
    };
    const handleToggleResponse = () => {
        setCancellationResponseView(prevState => !prevState);
    };
    const responseCancellation = useCallback(async (response) => {
        setIsLoading(true);
        if (!id)
            return;
        try {
            response === 'accept'
                ? await confirmAssignmentCancellation(id)
                : await rejectAssignmentCancel(id);
        }
        catch (e) {
            toast.error(t('notification.error'));
        }
        setCancellationResponseView(false);
        setIsLoading(false);
        refresh();
    }, []);
    const isCanelationRequested = !!(cancellationRequest === 'BY_REQUESTER' && assigner);
    return React.createElement(React.Fragment, null,
        React.createElement(Card, { error: isCanelationRequested },
            React.createElement(Progress, { max: 1, value: progress || 0 }),
            !cancellationResponseView ? React.createElement(CardContent, null,
                React.createElement(Content, null,
                    React.createElement("div", null,
                        React.createElement(FileName, null,
                            React.createElement(Popup, { pinned: true, hoverable: true, position: 'bottom center', trigger: isCanelationRequested
                                    ? React.createElement(FileNameOneLine, null, trimString(jobName || '', 20, 5))
                                    : React.createElement("div", null, trimString(jobName || '', 60, 15)) },
                                React.createElement("span", { style: { wordBreak: 'break-word' } }, jobName))),
                        React.createElement(Stage, null, stageType ? t(`stages.${stageType}`) : '')),
                    React.createElement("div", null,
                        React.createElement(Username, null, assigner || executor),
                        React.createElement(DueDate, { state: dueDate && claimDate ? remainingDateState(moment().toString(), claimDate, dueDate, REMAINING_TIME_ALERT_PERCENT) : 'left' },
                            React.createElement(ClockIcon, null),
                            showDateCountdown(dueDate, undefined, true, 1)))),
                React.createElement(Footer, null,
                    React.createElement(IconsWrapper, null,
                        React.createElement("div", null,
                            attachments && attachments.length > 0 && id && React.createElement(Popup, { basic: true, position: "top center", hoverable: true, on: ['hover', 'click'], content: t('dashboard.attachments'), trigger: React.createElement(IconWrapper, { "data-testid": "attachments", onClick: () => handleAttachmentsModalOpen(id) },
                                    React.createElement(AttachmentIcon, null)) }),
                            React.createElement(Popup, { flowing: true, hoverable: true, basic: true, position: "bottom center", key: assignment.id, trigger: React.createElement(IconWrapper, { "data-testid": "information" },
                                    React.createElement(InformationIcon, null)), offset: [0, 0] },
                                React.createElement(Styled.VerticalPopupContent, null,
                                    React.createElement(AssignmentInfo, { assignment: assignment })))),
                        React.createElement(IconWrapper, { "data-testid": "editor", onClick: () => openEditor(id) },
                            React.createElement(ArrowWrapper, null,
                                React.createElement(ArrowRightIcon, null))))),
                isCanelationRequested && React.createElement(CancelAssignment, null,
                    React.createElement("div", null, t('dashboard.executorCancellation')),
                    React.createElement(ResponseButton, { "data-testid": "response-btn", onClick: handleToggleResponse }, t('dashboard.respond'))))
                : React.createElement(AssignmentCancel, { handleClose: handleToggleResponse, responseCancellation: responseCancellation, isLoading: isLoading })),
        React.createElement(StageAttachmentModal, { stageId: assignment.stageId, open: attachmentModalIdOpen === assignment.id, setOpen: () => { setAttachmentModalIdOpen(undefined); }, stageAttachments: assignment.attachments || [], readonly: true }));
};
export default Assignment;
