export const exfluencyLogoSvg = `<svg class="svg-wrapper" version="1.1" id="Warstwa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 180.1 180.1" enable-background="new 0 0 180.1 180.1" xml:space="preserve">
<path fill="#F5BA57" d="M90.1,46c-24,0-43.5,19.7-43.5,44.1c0,24.2,19.5,44.1,43.5,44.1c23.9,0,43.5-19.8,43.5-44.1
C133.6,65.7,114,46,90.1,46z M127.4,90.1c0,4.7-0.9,9.4-2.5,13.6c-8-0.3-14.4-2.6-19.4-6.2c1.3-4.1,2.4-8.9,3.3-14.4
c1.4-9.5,1.8-18.7,2-24.5C120.7,65.4,127.4,76.9,127.4,90.1z M90.1,52.3c5.1,0,10,1.1,14.6,3c0,8-0.7,21.8-3.2,33.5
c-0.3,1.4-0.7,2.9-1,4.3c-6-6.3-9.6-14.5-12.2-22.3l-5.9,2c2.3,6.6,6,16.2,13,23.8c1,1,1.8,2,2.9,2.9c-2.4,5.8-5.7,9.8-10,10
c-2.9,0.1-6.4-1.2-9.7-3.5c2.1-3.4,3-6.7,3.5-9.3c1.3-7.6-0.9-15.2-5.3-19.1c-2.6-2.2-6.4-3-9.4-1.9c-5.8,2.3-8.9,7.9-7.5,14.9
c1.1,5.3,4.9,11.4,10.1,16.4c-2.2,2.2-5.1,4.4-9.1,6.3c-5.3-6.4-8.3-14.4-8.3-23.3C52.8,69.2,69.5,52.3,90.1,52.3z M75.8,95.7
c-0.3,1.8-1,4-2.3,6.4c-3.9-3.7-7.4-8.3-8.3-12.5c-1-4.7,2.2-10.1,7.1-7.6C75.2,83.3,76.9,89.7,75.8,95.7z M90.1,127.9
c-9.6,0-18.4-3.6-24.9-9.7c4-2.1,7.2-4.5,9.6-7.1c4.7,3.2,9.6,5,13.8,4.7c6.2-0.3,11.1-4.4,14.8-12.3c5.4,3.5,11.6,5.6,18.7,6.3
C115.4,120.6,103.5,127.9,90.1,127.9z"/>
<path class="rotate-anim" fill="none" stroke="#8ADCEE" stroke-width="7" d="M28.6,90.1c0,34,27.5,61.5,61.5,61.5c34,0,61.5-27.5,61.5-61.5
S124,28.6,90.1,28.6"/>
<g class="rotate-anim rotate-anim-2">
<circle opacity="0" fill="none" cx="90.1" cy="90.1" r="85.2"/>
<path fill="none" stroke="#EC5351" stroke-width="7" stroke-miterlimit="10" d="M151.5,31c14.7,15.3,23.8,36.1,23.8,59"/>
<path opacity="0.76" fill="none" stroke="#3A65F6" stroke-width="7" stroke-miterlimit="10" d="M29.8,150.3
   c-15.4-15.4-25-36.7-25-60.3c0-23.9,9.8-45.5,25.7-61"/>
<path opacity="0.36" fill="none" stroke="#F8D6CB" stroke-width="7" stroke-miterlimit="10" d="M149.8,150.8
   c-15.4,15.1-36.5,24.4-59.7,24.4"/>
</g>
</svg>`;
