import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Loader, Modal, Popup, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import AssignmentInfo from '../AssignmentInfo';
import { Styled } from 'StyledComponents';
import ErrorModal from 'errors/components/ErrorModal';
import Avatar from 'helpers/components/Avatar';
import { claimAssignment, passAssignment, getAssignments, perspectiveToStageList, getAssignmentSections } from 'api-operations/executor/executor';
import PriceWithExchangeRatePopup from 'dashboards/shared/PriceWithExchangeRatePopup';
import JobNamePopup from 'dashboards/shared/JobNamePopup';
import store from 'reduxStore/store';
import { StageAttachmentModal } from 'helpers/components/fileUpload/FileUploaderModal';
import { toast } from 'react-toastify';
import { useLogger } from 'logger/Logger';
import CommentsModal from 'comments/CommentsModal';
import ExpandedSectionTableRow from 'common/ExpandedSectionTableRow';
const ActionButtons = styled.div `
  display: flex;
  flex-direction: row;
`;
const NotYetAcceptedJobsRow = ({ assignment, perspective }) => {
    const [assignments, setAssignments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [optionChoosing, setOptionChoosing] = useState(false);
    const [claimBtnLoading, setClaimBtnLoading] = useState('');
    const [passBtnLoading, setPassBtnLoading] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const [sections, setSections] = useState([]);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [assignmentId, setAssignmentId] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [replyAssignmentType, setReplyAssignmentType] = useState(null);
    const [errorWindowMessage, setErrorWindowMessage] = useState(null);
    const [highlightAssignmentId, setHighlightAssignmentId] = useState(undefined);
    const [attachmentModalIdOpen, setAttachmentModalIdOpen] = useState(undefined);
    const [commentsModalOpen, setCommentsModalOpen] = useState(false);
    const [commentsModalAssignmentId, setCommentsModalAssignmentId] = useState(undefined);
    const { exchangeRate } = store.getState().userProfile;
    const intervalId = useRef();
    const history = useHistory();
    const { logger } = useLogger();
    const { t } = useTranslation('common');
    useEffect(() => {
        setHighlightAssignmentId(undefined);
        (async () => {
            await getData();
            intervalId.current = setInterval(async () => {
                await getData();
            }, 20000);
        })();
        return () => {
            clearInterval(intervalId.current);
        };
    }, [perspective]);
    const getData = async () => {
        try {
            if (document.visibilityState == "hidden")
                return;
            const response = await getAssignments(perspectiveToStageList(perspective), 'PENDING', { limit: 20 });
            setAssignments(response.results);
        }
        catch (error) {
            setErrorWindowMessage(error);
            logger.error('System - Failed to fetch pending assignments', error);
            return;
        }
        setIsLoading(false);
    };
    const fetchSectionData = async (assignmentId) => {
        try {
            const response = await getAssignmentSections(assignmentId);
            setSections(response);
        }
        catch (error) {
            logger.error('System - Failed to fetch sections', error);
        }
    };
    const claimJob = async (id) => {
        setClaimBtnLoading(id);
        const temp = assignments.filter(assignment => assignment.id === id);
        setAssignments(temp);
        if (optionChoosing)
            return;
        setOptionChoosing(true);
        try {
            await claimAssignment(id);
            logger.info('User - Claimed assignment');
            history.push({
                pathname: `/editor/${id}`,
                state: { id }
            });
        }
        catch (error) {
            if (error.response.status === 404) {
                logger.error('User - Missed assignment', error);
                toast.error(t('dashboard.projectTaken'));
            }
            else {
                logger.error('User - Failed to claim assignment', error);
                toast.error(t('notification.error'));
            }
        }
        await getData();
        setOptionChoosing(false);
    };
    const passJob = async (id) => {
        setPassBtnLoading(id);
        const temp = assignments.filter(assignment => assignment.id === id);
        setAssignments(temp);
        setOptionChoosing(true);
        try {
            await passAssignment(id);
            logger.info('User - Passed assignment');
        }
        catch (error) {
            logger.error('User - Failed to pass assignment', error);
            toast.error(t('notification.error'));
        }
        await getData();
        setIsConfirmationModalOpen(false);
        setOptionChoosing(false);
    };
    const previewJob = (id) => {
        history.push({
            pathname: `/editor/${id}`,
            state: { id: id }
        });
    };
    const openCommentsModal = (jobId) => {
        setCommentsModalAssignmentId(jobId);
        setCommentsModalOpen(true);
    };
    const handleOpenConfirmationModal = (assignmentId, fileName, type) => {
        setAssignmentId(assignmentId);
        setFileName(fileName);
        setReplyAssignmentType(type);
        setIsConfirmationModalOpen(true);
    };
    const handleCloseConfirmationModal = () => {
        setAssignmentId(null);
        setFileName(null);
        setReplyAssignmentType(null);
        setIsConfirmationModalOpen(false);
    };
    if (errorWindowMessage !== null)
        return React.createElement(ErrorModal, { ...errorWindowMessage, onClose: () => window.location.reload() });
    if (isLoading)
        return React.createElement(Loader, { inverted: true, content: "Loading" });
    return React.createElement(React.Fragment, null,
        React.createElement(Table.Row, { warning: moment(assignment.dueDate).isBefore() && !(highlightAssignmentId == assignment.id), positive: highlightAssignmentId == assignment.id },
            React.createElement(Table.Cell, null,
                React.createElement(Button, { size: "mini", icon: isExpanded ? "angle up" : "angle down", onClick: () => { setIsExpanded(prevState => !prevState); fetchSectionData(assignment.id); } })),
            React.createElement(Table.Cell, null,
                React.createElement(Avatar, { name: assignment.assigner, inlineAvatar: true })),
            React.createElement(Table.Cell, null,
                React.createElement(JobNamePopup, { name: assignment.jobName, charityMode: assignment.charity })),
            React.createElement(Table.Cell, null,
                moment(assignment.dueDate).fromNow(),
                moment(assignment.dueDate).isBefore() && React.createElement(Icon, { name: "attention" })),
            React.createElement(Table.Cell, null,
                t(`languages.${assignment.jobLangFrom.toLowerCase()}`),
                " ",
                React.createElement(Icon, { name: "arrow right" }),
                " ",
                t(`languages.${assignment.jobLangTo.toLowerCase()}`)),
            React.createElement(Table.Cell, { textAlign: 'right' },
                React.createElement(PriceWithExchangeRatePopup, { cost: assignment.price, customCurrencyCost: assignment.userCurrencyPrice, exchangeRate: exchangeRate }),
                assignment.stageType === 'CORRECTION' && React.createElement("p", { style: { color: 'red' } }, "(Correction)")),
            perspective === "redactor" && React.createElement(Table.Cell, null, t(`dashboard.${assignment.stageType}`)),
            React.createElement(Table.Cell, null,
                React.createElement(ActionButtons, null,
                    React.createElement(Popup, { flowing: true, hoverable: true, basic: true, position: "top center", key: assignment.id, trigger: (React.createElement(Button, { icon: "info" })), offset: [0, 0] },
                        React.createElement(Styled.VerticalPopupContent, null,
                            React.createElement(AssignmentInfo, { assignment: assignment, status: "PENDING" }))),
                    React.createElement(Popup, { basic: true, position: "top center", hoverable: true, on: ['hover', 'click'], content: t('dashboard.preview'), trigger: (React.createElement(Button, { icon: "eye", disabled: optionChoosing, onClick: () => previewJob(assignment.id) })) }),
                    React.createElement(Popup, { basic: true, position: "top center", hoverable: true, on: ['hover', 'click'], content: t('commentsModal.comments'), trigger: React.createElement(Button, { icon: "sticky note outline", onClick: () => openCommentsModal(assignment.id) }) }),
                    assignment.attachments.length > 0 && React.createElement(Popup, { basic: true, position: "top center", hoverable: true, on: ['hover', 'click'], content: t('dashboard.attachments'), trigger: (React.createElement(Button, { icon: "attach", color: "orange", disabled: optionChoosing, onClick: () => setAttachmentModalIdOpen(assignment.id) })) }),
                    React.createElement(Popup, { basic: true, position: "top center", hoverable: true, on: ['hover', 'click'], content: t('dashboard.accept'), trigger: (React.createElement(Button, { icon: "check", color: "green", loading: assignment.id === claimBtnLoading, disabled: optionChoosing, onClick: () => handleOpenConfirmationModal(assignment.id, assignment.jobName, 'ACCEPT') })) }),
                    React.createElement(Popup, { basic: true, position: "top center", hoverable: true, on: ['hover', 'click'], content: t('dashboard.reject'), trigger: (React.createElement(Button, { icon: "times", color: "red", loading: assignment.id === passBtnLoading, disabled: optionChoosing || assignment.stageType === 'CORRECTION', onClick: () => handleOpenConfirmationModal(assignment.id, assignment.jobName, 'REJECT') })) }),
                    React.createElement(StageAttachmentModal, { stageId: assignment.stageId, open: attachmentModalIdOpen === assignment.id, setOpen: () => { setAttachmentModalIdOpen(undefined); }, stageAttachments: assignment.attachments, readonly: true })))),
        isExpanded && React.createElement(ExpandedSectionTableRow, { sections: sections }),
        React.createElement(CommentsModal, { isOpen: commentsModalOpen, setIsOpen: setCommentsModalOpen, assignmentId: commentsModalAssignmentId, readOnly: true }),
        React.createElement(Modal, { open: isConfirmationModalOpen },
            React.createElement(Modal.Content, null,
                t('dashboard.projectAssurance'),
                " ",
                React.createElement("b", null, t(`dashboard.${replyAssignmentType}`)),
                " ",
                t('dashboard.project'),
                ": ",
                React.createElement("b", null, fileName)),
            React.createElement(Modal.Actions, null,
                React.createElement(Button, { disabled: optionChoosing, onClick: () => handleCloseConfirmationModal() }, t('dashboard.cancel')),
                React.createElement(Button, { disabled: optionChoosing, onClick: () => replyAssignmentType === 'ACCEPT' ? claimJob(assignmentId) : passJob(assignmentId), color: replyAssignmentType === 'ACCEPT' ? 'green' : 'red' }, t('dashboard.yes')))));
};
export default NotYetAcceptedJobsRow;
