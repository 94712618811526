import { downloadJobSectionSnapshot, generateJobSectionSnapshot, isJobSectionSnapshotReady } from "api-operations/editor/editor";
import i18next from "i18next";
import EditorModule from "../EditorModule";
import SnapshotGenerationStartedModal from "./SnapshotGenerationStartedModal";
export default class SnapshotModule extends EditorModule {
    constructor(editor) {
        super(editor);
        this.moduleId = "SnapshotModule";
        this.snapshotGroup = document.createElement("div");
        this.generateSnapshotButton = document.createElement("button");
        this.snapshotGenerationStartedModal = new SnapshotGenerationStartedModal();
        this.snapshotCheckInterval = null;
        this.generationRecentlyTriggered = false;
        editor.domNode.appendChild(this.snapshotGenerationStartedModal);
        this.snapshotGroup.classList.add("ui", "buttons");
        this.editor.sectionHeader.headerRow.insertBefore(this.snapshotGroup, this.editor.sectionHeader.finishBar);
        this.generateSnapshotButton.className = "ui button primary--light";
        this.generateSnapshotButton.textContent = i18next.t("editor.snapshots.generateSnapshot");
        this.generateSnapshotButton.addEventListener("click", this.generateSnapshot.bind(this));
        this.snapshotGroup.appendChild(this.generateSnapshotButton);
    }
    generateSnapshot() {
        const { assignmentId, section } = this.editor;
        if (!section)
            return;
        generateJobSectionSnapshot(assignmentId, section.id);
        this.snapshotGenerationStartedModal.open = true;
        this.generationRecentlyTriggered = true;
        this.startSnapshotInterval();
    }
    startSnapshotInterval() {
        this.clearSnapshotInterval();
        this.snapshotCheckInterval = window.setInterval(this.checkSnapshot.bind(this), 5000);
    }
    clearSnapshotInterval() {
        this.snapshotCheckInterval && window.clearInterval(this.snapshotCheckInterval);
    }
    async checkSnapshot() {
        const { assignmentId, section } = this.editor;
        if (!section)
            return;
        if (await isJobSectionSnapshotReady(assignmentId, section.id)) {
            this.clearSnapshotInterval();
            if (this.generationRecentlyTriggered) {
                downloadJobSectionSnapshot(assignmentId, section.id, section.name);
            }
        }
    }
    downloadSnapshot() {
        const { assignmentId, section } = this.editor;
        if (!section)
            return;
        downloadJobSectionSnapshot(assignmentId, section.id, section.name);
    }
    unload() {
        this.clearSnapshotInterval();
        this.snapshotGenerationStartedModal.remove();
        this.snapshotGroup.remove();
    }
}
