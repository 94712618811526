import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Popup, Button, Icon, Modal } from 'semantic-ui-react';
import { Styled } from 'StyledComponents';
import { toast } from 'react-toastify';
import { useRequesterJob } from './Dashboard';
import { deleteJob, reassignJob } from 'api-operations/requester/job';
import { assignExecutorAuto } from 'api-operations/requester/stage';
import { hasErrorCode } from 'logger/error-guards';
import { useLogger } from 'logger/Logger';
import { JobAttachmentModal } from 'helpers/components/fileUpload/FileUploaderModal';
const ActionButtons = ({ job }) => {
    const [isModalConfirmLoading, setIsModalConfirmLoading] = useState(false);
    const [isReassignJobModalOpened, setIsReassignJobModalOpened] = useState(false);
    const [isJobDeleteModalOpened, setIsJobDeleteModalOpened] = useState(false);
    const [isExecutorModalOpened, setIsExecutorModalOpened] = useState(false);
    const [addAttachmentsModalOpen, setAddAttachmentsModalOpen] = useState(false);
    const { fetchJobs } = useRequesterJob();
    const { t } = useTranslation('common');
    const history = useHistory();
    const { logger } = useLogger();
    const selectExecutorAutomatically = async (stageId) => {
        setIsModalConfirmLoading(true);
        try {
            await assignExecutorAuto(stageId, true);
            logger.info('User - Assigned executors automatically');
        }
        catch (error) {
            if (hasErrorCode(404, error)) {
                logger.info('User - No executors available automatically');
                toast.error(t('notification.noExecutors'));
            }
            else {
                logger.error('User - Failed to assign executor automatically', error);
                toast.error(t('notification.error'));
            }
        }
        await fetchJobs();
        setIsModalConfirmLoading(false);
        setIsExecutorModalOpened(false);
    };
    const selectExecutors = () => {
        const type = getActiveStageType();
        const stageId = getActiveStageId();
        history.push({
            pathname: '/select-executor',
            state: {
                jobId: job.id,
                type,
                stageId
            }
        });
    };
    const getActiveStageType = () => {
        var _a;
        return (_a = job.stages.find(s => ["STARTED", "PENDING_ACCEPTANCE", "IN_PROGRESS", "NO_EXECUTORS"].includes(s.stageStatus))) === null || _a === void 0 ? void 0 : _a.stageType;
    };
    const getActiveStageId = () => {
        var _a;
        return (_a = job.stages.find(s => ["STARTED", "PENDING_ACCEPTANCE", "IN_PROGRESS", "NO_EXECUTORS"].includes(s.stageStatus))) === null || _a === void 0 ? void 0 : _a.id;
    };
    const onDeleteJob = async () => {
        setIsModalConfirmLoading(true);
        try {
            await deleteJob(job.id);
            logger.info('User - Deleted job');
        }
        catch (error) {
            logger.error('User - Failed to delete job', error);
            toast.error(t('notification.error'));
        }
        setIsModalConfirmLoading(false);
        setIsJobDeleteModalOpened(false);
        await fetchJobs();
    };
    const onReassignJob = async () => {
        setIsModalConfirmLoading(true);
        try {
            const response = await reassignJob(job.id);
            logger.info('User - Reassigned job');
            if (!response) {
                toast.error(t('notification.noExecutors'));
            }
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
        setIsModalConfirmLoading(false);
        setIsReassignJobModalOpened(false);
        await fetchJobs();
    };
    const handleReassignJobModalOpen = () => {
        setIsReassignJobModalOpened(true);
        logger.info('User - Opened reassign job modal');
    };
    const handleExecutorModalOpen = () => {
        setIsExecutorModalOpened(true);
        logger.info('User - Opened select executor modal');
    };
    const handleDeleteJobModalOpen = () => {
        setIsJobDeleteModalOpened(true);
        logger.info('User - Opened delete job modal');
    };
    return React.createElement(React.Fragment, null,
        ((job.executorSelectionRequested || job.status == 'BLOCKED' && !job.reassignable) && getActiveStageId()) && React.createElement(React.Fragment, null,
            React.createElement(Popup, { basic: true, position: "top center", hoverable: true, on: "hover", trigger: React.createElement(Button, { color: "green", icon: job.status == 'BLOCKED' ? "group" : "external alternate", "data-testid": 'select_executor_btn', onClick: () => { handleExecutorModalOpen(); } }) },
                React.createElement(Popup.Content, null, t(`selectExecutor.header.${job.status == 'BLOCKED' ? 'check' : getActiveStageType()}`))),
            React.createElement(Modal, { open: isExecutorModalOpened, size: "small", onClose: () => { setIsExecutorModalOpened(false); } },
                React.createElement(Modal.Content, null, t(`selectExecutor.description.${getActiveStageType()}`)),
                React.createElement(Modal.Actions, null,
                    React.createElement(Button, { primary: true, onClick: () => selectExecutorAutomatically(getActiveStageId()), size: "tiny", loading: isModalConfirmLoading },
                        React.createElement(Icon, { name: "exclamation" }),
                        " ",
                        t('dashboard.chooseAuto')),
                    React.createElement(Button, { onClick: () => selectExecutors(), size: "tiny" },
                        React.createElement(Icon, { name: "hand point up outline" }),
                        " ",
                        t('dashboard.chooseManually'))))),
        job.reassignable && React.createElement(Popup, { basic: true, position: "top center", hoverable: true, on: ['hover'], trigger: React.createElement(Button, { icon: "share square", "data-testid": `reassign_more_executors_btn`, onClick: () => { handleReassignJobModalOpen(); } }) },
            React.createElement(Popup.Content, null, t('dashboard.sendToMoreExecutors'))),
        React.createElement(Popup, { basic: true, position: "top center", hoverable: true, on: ['hover'], trigger: React.createElement("span", null,
                React.createElement(Button, { basic: true, color: 'red', icon: "trash alternate outline", "data-testid": 'delete_job_btn', disabled: !job.deletable, onClick: () => { handleDeleteJobModalOpen(); } })) },
            React.createElement(Styled.VerticalPopupContent, null, job.deletable ? t('dashboard.deleteJobTooltip') : t('dashboard.deleteJobTooltipCannot'))),
        ['IN_PROGRESS', 'BLOCKED', 'ERROR'].includes(job.status) && React.createElement(Popup, { basic: true, position: "top center", hoverable: true, on: ['hover'], trigger: React.createElement(Button, { color: 'orange', icon: "attach", onClick: () => {
                    setAddAttachmentsModalOpen(true);
                } }) },
            React.createElement(Popup.Content, null, t('dashboard.addAttachments'))),
        React.createElement(Modal, { open: isJobDeleteModalOpened, size: "small", onClose: () => { setIsJobDeleteModalOpened(false); } },
            React.createElement(Modal.Content, null, t('dashboard.deleteJobConfirmation')),
            React.createElement(Modal.Actions, null,
                React.createElement(Button, { primary: true, size: "tiny", onClick: () => { setIsJobDeleteModalOpened(false); } }, t('dashboard.cancel')),
                React.createElement(Button, { onClick: () => onDeleteJob(), size: "tiny", loading: isModalConfirmLoading }, t('dashboard.delete')))),
        React.createElement(Modal, { open: isReassignJobModalOpened, size: "small", onClose: () => { setIsReassignJobModalOpened(false); } },
            React.createElement(Modal.Content, null, t('dashboard.reassignJobModalText')),
            React.createElement(Modal.Actions, null,
                React.createElement(Button, { primary: true, onClick: () => onReassignJob(), size: "tiny", loading: isModalConfirmLoading },
                    React.createElement(Icon, { name: "exclamation" }),
                    " ",
                    t('dashboard.chooseAuto')),
                React.createElement(Button, { onClick: () => selectExecutors(), size: "tiny" },
                    React.createElement(Icon, { name: "hand point up outline" }),
                    " ",
                    t('dashboard.chooseManually')))),
        React.createElement(JobAttachmentModal, { open: addAttachmentsModalOpen, setOpen: setAddAttachmentsModalOpen, jobId: job.id }));
};
export default ActionButtons;
