import { acceptInvoice, downloadInvoice } from "api-operations/company/admin";
import PriceWithExchangeRatePopup from "dashboards/shared/PriceWithExchangeRatePopup";
import { parseTimestamp, parseTimestampToDate } from "helpers/DateHandler";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button, Icon, Table } from "semantic-ui-react";
const InvoiceTableRow = ({ invoice, companyId }) => {
    const [accepted, setAccepted] = useState(invoice.accepted);
    const [disabled, setDisabled] = useState(false);
    const { t } = useTranslation('common');
    const handleAcceptInvoice = async (companyId, invoiceNumber) => {
        setDisabled(true);
        try {
            await acceptInvoice(companyId, invoiceNumber);
            setAccepted(true);
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
        setDisabled(false);
    };
    return React.createElement(Table.Row, { key: invoice.invoiceNumber },
        React.createElement(Table.Cell, null, invoice.invoiceType),
        React.createElement(Table.Cell, null, invoice.invoiceNumber),
        React.createElement(Table.Cell, null,
            React.createElement(PriceWithExchangeRatePopup, { cost: invoice.totalSum })),
        React.createElement(Table.Cell, null, parseTimestamp(invoice.creationDate)),
        React.createElement(Table.Cell, null, parseTimestampToDate(invoice.startDate)),
        React.createElement(Table.Cell, null, parseTimestampToDate(invoice.endDate)),
        React.createElement(Table.Cell, null, accepted
            ? React.createElement(Icon, { color: 'green', name: 'checkmark' })
            : React.createElement(Button, { primary: true, onClick: () => { handleAcceptInvoice(companyId, invoice.invoiceNumber); }, disabled: disabled }, t('dashboard.confirm'))),
        React.createElement(Table.Cell, null,
            React.createElement(Button, { primary: true, onClick: () => downloadInvoice(companyId, invoice.invoiceNumber), disabled: !accepted || !invoice.pdfReady }, t('dashboard.downloadPdf'))));
};
export default (InvoiceTableRow);
