import { isAlphanumeric, isScriptoContinua } from "./utils";
import SubAssetElement from "./SubAssetElement";
import TerminologyElement from "./TerminologyElement";
function findInNodes(nodes, textToReplace, nodeStartIndex, searchStartIndex, lang) {
    let startIndex = -1;
    let endIndex = -1;
    let foundNodeStart = -1;
    let foundNodeEnd = -1;
    for (let i = nodeStartIndex; i < nodes.length; i++) {
        const node = nodes[i];
        if (node instanceof Text) {
            const text = node.textContent || "";
            const lowerCaseText = text.toLocaleLowerCase(lang);
            const lowerCaseTextToReplace = textToReplace.toLocaleLowerCase(lang);
            const index = lowerCaseText.indexOf(lowerCaseTextToReplace, 0);
            if (index >= 0) {
                startIndex = index;
                endIndex = index + lowerCaseTextToReplace.length;
                foundNodeStart = i;
                foundNodeEnd = i;
                break;
            }
            const prefixLength = commonPrefixLenght(lowerCaseText, lowerCaseTextToReplace);
            if (prefixLength > 0) {
                let concatenatedText = lowerCaseText;
                for (let j = i + 1; j < nodes.length; j++) {
                    const nextNode = nodes[j];
                    if (nextNode instanceof Text) {
                        const nextText = nextNode.textContent || "";
                        const lowerCaseNextText = nextText.toLocaleLowerCase(lang);
                        concatenatedText += lowerCaseNextText;
                        const index = concatenatedText.indexOf(lowerCaseTextToReplace);
                        if (index >= 0) {
                            startIndex = index;
                            endIndex = index + lowerCaseTextToReplace.length - concatenatedText.length + lowerCaseNextText.length;
                            foundNodeStart = i;
                            foundNodeEnd = j;
                            break;
                        }
                        const prefixLength = commonPrefixLenght(concatenatedText, lowerCaseTextToReplace);
                        if (prefixLength === 0) {
                            break;
                        }
                    }
                }
            }
        }
    }
    if (startIndex >= 0 && endIndex >= 0 && foundNodeStart >= 0 && foundNodeEnd >= 0) {
        return {
            startNodeIndex: foundNodeStart,
            endNodeIndex: foundNodeEnd,
            startIndexInNode: startIndex,
            endIndexInNode: endIndex
        };
    }
    return null;
}
// Helper function to find the length of the common prefix to suffix of a and b
function commonPrefixLenght(a, b) {
    for (let i = 0; i < b.length; i++) {
        let bPrefix = b.substring(0, b.length - i);
        if (a.endsWith(bPrefix)) {
            return bPrefix.length;
        }
    }
    return 0;
}
export function getNestedNodes(nodes) {
    return nodes
        .filter(node => !(node instanceof SubAssetElement) && !(node instanceof TerminologyElement))
        .map(node => node.childNodes.length > 0 ? getNestedNodes(Array.from(node.childNodes)) : node);
}
export function transformNodes(nodes, text, subAsset, editor) {
    let lastNode = 0;
    let searchStartIndex = 0;
    let nodeRest;
    // eslint-disable-next-line no-constant-condition
    while (true) {
        const result = findInNodes(nodes, text, lastNode, searchStartIndex, editor.content.lang);
        if (result === null) {
            break;
        }
        const { startNodeIndex, endNodeIndex, startIndexInNode, endIndexInNode } = result;
        lastNode = endNodeIndex;
        searchStartIndex = endIndexInNode;
        const nodesSlice = nodes.slice(startNodeIndex, endNodeIndex + 1);
        if (startNodeIndex === endNodeIndex) {
            nodeRest = legacyAlgorithm(nodes[startNodeIndex], startIndexInNode, endIndexInNode, subAsset, editor);
            nodeRest && nodes.splice(lastNode + 1, 0, nodeRest);
        }
    }
}
function legacyAlgorithm(currentNode, startIndex, endIndex, subAsset, editor) {
    if (currentNode === null || currentNode.textContent === null) {
        return;
    }
    const nodeToReplace = currentNode.splitText(startIndex);
    const nodeRest = nodeToReplace.splitText(endIndex - startIndex);
    const subAssetElement = new SubAssetElement(subAsset, nodeToReplace.textContent || "", editor);
    nodeToReplace.replaceWith(subAssetElement);
    editor.modules.forEach(m => m.subassetCreated(subAssetElement));
    return nodeRest;
}
export function transformNodesForTerms(nodes, text, terminology, editor, lang, isSourceTextTerm, terms) {
    let lastNode = 0;
    let searchStartIndex = 0;
    let nodeRest;
    // eslint-disable-next-line no-constant-condition
    while (true) {
        const result = findInNodes(nodes, text, lastNode, searchStartIndex, editor.content.lang);
        if (result === null) {
            break;
        }
        const { startNodeIndex, endNodeIndex, startIndexInNode, endIndexInNode } = result;
        lastNode = endNodeIndex;
        searchStartIndex = endIndexInNode;
        const nodesSlice = nodes.slice(startNodeIndex, endNodeIndex + 1);
        if (startNodeIndex === endNodeIndex) {
            nodeRest = legacyAlgorithmForTerms(nodes[startNodeIndex], startIndexInNode, endIndexInNode, terminology, editor, lang, isSourceTextTerm, terms);
            nodeRest && nodes.splice(lastNode + 1, 0, nodeRest);
        }
        else {
            multipleNodesAlgorithm(nodesSlice, startIndexInNode, endIndexInNode, terminology, editor, lang, isSourceTextTerm, terms);
        }
    }
}
function legacyAlgorithmForTerms(firstNode, startIndex, endIndex, term, editor, lang, isSourceTextTerm, terms) {
    const nodeToReplace = firstNode.splitText(startIndex);
    const nodeRest = nodeToReplace.splitText(endIndex - startIndex);
    const nodesToReplace = [nodeToReplace];
    if (canCreateTerms(firstNode, nodeRest, lang)) {
        nodesToReplace.forEach((node) => {
            node.replaceWith(new TerminologyElement(term, node.textContent || "", editor, isSourceTextTerm, terms));
        });
    }
    return nodeRest;
}
function multipleNodesAlgorithm(nodes, startIndex, endIndex, term, editor, lang, isSourceTextTerm, terms) {
    const [firstNode] = nodes;
    const lastNode = nodes[nodes.length - 1];
    const nodesInBetween = nodes.slice(1, nodes.length - 1);
    const firstNodeToReplace = firstNode.splitText(startIndex);
    const nodeRest = lastNode.splitText(endIndex);
    const nodesToReplace = [firstNodeToReplace, ...nodesInBetween, lastNode];
    if (canCreateTerms(firstNode, nodeRest, lang)) {
        nodesToReplace.forEach((node) => {
            node.replaceWith(new TerminologyElement(term, node.textContent || "", editor, isSourceTextTerm, terms));
        });
    }
    return nodeRest;
}
function canCreateTerms(firstNode, lastNodeRest, lang) {
    const alphanumericBeforeTerm = isAlphanumeric(firstNode.textContent.charAt(firstNode.textContent.length - 1));
    const alphanumericAfterTerm = isAlphanumeric(lastNodeRest.textContent.charAt(0));
    return (!alphanumericBeforeTerm && !alphanumericAfterTerm) || isScriptoContinua(lang);
}
