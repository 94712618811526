import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Button, Form, Grid, Header, Image, Input, Message, Popup, Segment } from 'semantic-ui-react';
import { setNewPasswordAfterForgetting } from 'api-operations/auth';
import ErrorModal from 'errors/components/ErrorModal';
import Background from 'Home/components/Background';
import LegalFooter from 'Home/components/LegalFooter';
import { hasErrorCode } from 'logger/error-guards';
import { isSubDomainContains } from 'helpers/utils';
const ImgContainer = styled.div `
  display: flex;
  justify-content: center;
`;
const ReturnToLogin = styled(Link) `
display: flex;
justify-content: flex-start;
font-size: 0.8rem;
margin-top: 0.5rem;
cursor: pointer;
`;
const style = {
    color: 'red',
    borderColor: 'red'
};
const PasswordReset = () => {
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [invalidPasswordMsg, setInvalidPasswordMsg] = useState('');
    const [invalidPasswordConfirmationMsg, setInvalidPasswordConfirmationMsg] = useState('');
    const [badToken, setBadToken] = useState(false);
    const [incorrectNewPassword, setIncorrectNewPassword] = useState(false);
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [errorWindowMessage, setErrorWindowMessage] = useState(null);
    const { t } = useTranslation('common');
    const history = useHistory();
    const handleSubmit = async () => {
        setLoading(true);
        setBadToken(false);
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');
        const redirectURL = params.get('redirectURL');
        if (password !== '' && passwordConfirmation !== '') {
            try {
                await setNewPasswordAfterForgetting(token, password);
                setPasswordChanged(true);
                setTimeout(function () {
                    redirectURL !== null ? window.location.replace(redirectURL) : history.push('/');
                }, 3000);
            }
            catch (error) {
                if (hasErrorCode(401, error)) {
                    setBadToken(true);
                }
                else if (hasErrorCode(400, error)) {
                    setIncorrectNewPassword(true);
                }
                else {
                    setErrorWindowMessage(error);
                }
            }
            setLoading(false);
        }
    };
    useEffect(() => {
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\]{8,60}$/.test(password)
            ? setInvalidPasswordMsg('')
            : setInvalidPasswordMsg(t('accountDescriptions.password'));
    }, [password]);
    useEffect(() => {
        (password === passwordConfirmation && password !== '')
            ? setInvalidPasswordConfirmationMsg('')
            : setInvalidPasswordConfirmationMsg(t('accountDescriptions.passwordConfirmation'));
    }, [passwordConfirmation, password]);
    if (errorWindowMessage !== null) {
        return React.createElement(ErrorModal, { ...errorWindowMessage, onClose: () => window.location.reload() });
    }
    return (React.createElement(Background, null,
        React.createElement(Grid, { textAlign: "center", style: { height: '100vh' }, verticalAlign: "middle" },
            React.createElement(Grid.Column, { style: { maxWidth: 450 } },
                React.createElement(ImgContainer, null, isSubDomainContains(['ua']) ? React.createElement(Image, { src: require(`assets/images/ua/logo.svg`), alt: "Exfluency" }) : React.createElement(Image, { src: require(`assets/images/exfluency/logo.svg`), alt: "Exfluency" })),
                React.createElement(Header, { as: "h1", color: "black", textAlign: "center" }),
                React.createElement(Form, { size: "large", onSubmit: handleSubmit },
                    React.createElement(Segment, null,
                        React.createElement(Form.Field, { error: invalidPasswordMsg === '' ? false : true },
                            React.createElement("label", { htmlFor: "password" }, t('settings.password')),
                            React.createElement(Popup, { open: invalidPasswordMsg === '' ? false : true, size: "tiny", position: "right center", content: invalidPasswordMsg, style: style, trigger: React.createElement(Input, { icon: "key", iconPosition: "left", type: "password", id: "password", value: password, onChange: (e, data) => setPassword(data.value) }) })),
                        React.createElement(Form.Field, { error: invalidPasswordConfirmationMsg === '' ? false : true },
                            React.createElement("label", { htmlFor: "passwordConfirmation" }, t('settings.passwordConfirmation')),
                            React.createElement(Popup, { open: invalidPasswordConfirmationMsg === '' ? false : true, size: "tiny", position: "right center", content: invalidPasswordConfirmationMsg, style: style, trigger: React.createElement(Input, { icon: "key", iconPosition: "left", type: "password", value: passwordConfirmation, id: "passwordConfirmation", onChange: (e, data) => setPasswordConfirmation(data.value) }) })),
                        React.createElement(Button, { loading: loading, disabled: loading, primary: true, fluid: true, size: "large", type: "submit" }, t('signin.setPassword')),
                        React.createElement(ReturnToLogin, { to: "/" }, t('signin.signin')))),
                badToken && React.createElement(Message, { error: true },
                    " ",
                    t('signin.badToken'),
                    " "),
                incorrectNewPassword && React.createElement(Message, { error: true },
                    " ",
                    t('signin.incorrectPassword'),
                    " "),
                passwordChanged && React.createElement(Message, { info: true },
                    " ",
                    t('signin.passwordChanged'),
                    " "))),
        React.createElement(LegalFooter, null)));
};
export default PasswordReset;
