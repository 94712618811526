import React from "react";
import Alert from '@mui/material/Alert';
import { styled } from "@mui/material/styles";
import { AlertTitle, Tooltip, tooltipClasses } from "@mui/material";
import { AlertIcon, textColor } from "./FileUploadItem";
const borderColor = (severity) => {
    switch (severity) {
        case "error": return "#F8D0D0";
        case "warning": return "#FFDAAE";
        default: return "#E9ECF0";
    }
};
export const FileItemTooltipTitle = ({ description, severity, message }) => {
    return React.createElement(Alert, { severity: severity, icon: React.createElement(AlertIcon, { severity: severity }), className: "file-upload-tooltip" },
        React.createElement(AlertTitle, null, description),
        React.createElement("span", null, message));
};
export const FileItemTooltip = styled(({ className, ...props }) => (React.createElement(Tooltip, { ...props, arrow: true, classes: { popper: className } })))(({ severity }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#FFFFFF',
        fontSize: "large",
        '&::before': {
            border: '1px solid #F8D0D0'
        }
    },
    [`& .${tooltipClasses.tooltip}`]: {
        color: '#42526E',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 15px #00000026',
        border: `1px solid ${borderColor(severity)}`,
        opacity: 1,
        letterSpacting: '0px',
        textAlign: 'left',
        font: 'normal normal normal 10px/16px Inter',
        padding: '0px'
    },
    [`& .${tooltipClasses.tooltip} h3`]: {
        color: `${textColor(severity)}`
    }
}));
