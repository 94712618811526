import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Divider, Form, Grid, Header, Input, Message, Modal, Popup, Segment } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { availability, checkIfSignUpIsActive, signup } from 'api-operations/auth';
import { getMyUserProfile } from 'api-operations/myExfluency/myExfluency';
import ErrorModal from 'errors/components/ErrorModal';
import LegalFooter from 'Home/components/LegalFooter';
import SelectMemo from 'helpers/SelectMemo';
import Background from 'Home/components/Background';
import { emailRegex } from 'helpers/utils';
const StyledForm = styled(Form) `
  border: 0;
`;
const StyledButton = styled(Button) `
  width: 35%;
`;
const ButtonWrapper = styled.div `
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 40px 0 10px 0;
`;
const CheckboxContainer = styled.div `
  display: flex;
`;
const CheckboxLabel = styled.label `
  margin: 0 0 0 10px;
`;
const StyledLink = styled(Link) `
  text-decoration: underline;
`;
const RecaptchaWrapper = styled.div `
  display: flex;
  justify-content: center;
`;
const StyledModal = styled(Modal) `
  z-index: 999;
  padding: 20px 0;
`;
const ModalContentMessage = styled.p `
  text-align: center;
  font-size: 1.7rem;
`;
const ModalContentMessageDesc = styled.p `
  text-align: center;
  font-size: 1.3rem;
`;
const StyledReturnLink = styled(Link) `
display: flex;
justify-content: flex-start;
font-size: 0.8rem;
margin-top: 0.5rem;
cursor: pointer;
`;
const style = {
    color: 'red',
    borderColor: 'red'
};
const Signup = () => {
    var _a;
    const [login, setLogin] = useState('');
    const [firstName, setFirstName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    // const [ethereumAddress, setEthereumAddress] = useState<string>('')
    const [timezone, setTimezone] = useState('Europe/London');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [termsOfService, setTermsOfService] = useState(false);
    const [certifyAge, setCertifyAge] = useState(false);
    const [recaptcha, setRecaptcha] = useState(null);
    const [invalidLoginMsg, setInvalidLoginMsg] = useState('');
    const [invalidFirstNameMsg, setInvalidFirstNameMsg] = useState('');
    const [invalidSurnameMsg, setInvalidSurnameMsg] = useState('');
    const [invalidEmailMsg, setInvalidEmailMsg] = useState('');
    const [invalidPasswordMsg, setInvalidPasswordMsg] = useState('');
    const [invalidPasswordConfirmationMsg, setInvalidPasswordConfirmationMsg] = useState('');
    // const [invalidEthereumAddressMsg, setInvalidEthereumAddressMsg] = useState<string>('')
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [responseStatus, setResponseStatus] = useState('');
    const [errorWindowMessage, setErrorWindowMessage] = useState(null);
    const [signUpActive, setSignUpActive] = useState(true);
    const { t } = useTranslation('common');
    const history = useHistory();
    useEffect(() => {
        checkIfSignupIsPossible();
    }, [signUpActive]);
    useEffect(() => {
        const cookie = document.cookie.split(';').some(c => c.trim().startsWith('ExfluencyCookie='));
        if (cookie) {
            setProfile();
        }
    }, []);
    useEffect(() => {
        if (/^[A-Za-z0-9_-]{6,60}$/.test(login)) {
            setInvalidLoginMsg('');
        }
        else {
            setInvalidLoginMsg(t('accountDescriptions.login'));
        }
    }, [login]);
    useEffect(() => {
        if (/^[A-Za-z-'\s]{2,60}$/.test(firstName)) {
            setInvalidFirstNameMsg('');
        }
        else {
            setInvalidFirstNameMsg(t('accountDescriptions.name'));
        }
    }, [firstName]);
    useEffect(() => {
        if (/^[A-Za-z-'\s]{2,60}$/.test(surname)) {
            setInvalidSurnameMsg('');
        }
        else {
            setInvalidSurnameMsg(t('accountDescriptions.surname'));
        }
    }, [surname]);
    useEffect(() => {
        if (emailRegex.test(email)) {
            setInvalidEmailMsg('');
        }
        else {
            setInvalidEmailMsg(t('accountDescriptions.email'));
        }
    }, [email]);
    // useEffect(() => {
    //   if (web3.utils.isAddress(ethereumAddress)) {
    //     setInvalidEthereumAddressMsg('')
    //   } else {
    //     setInvalidEthereumAddressMsg('Ethereum address in invalid')
    //   }
    // }, [ethereumAddress])
    useEffect(() => {
        if (/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\]{8,60}$/.test(password)) {
            setInvalidPasswordMsg('');
        }
        else {
            setInvalidPasswordMsg(t('accountDescriptions.password'));
        }
    }, [password]);
    useEffect(() => {
        if (password === passwordConfirmation && password !== '') {
            setInvalidPasswordConfirmationMsg('');
        }
        else {
            setInvalidPasswordConfirmationMsg(t('accountDescriptions.passwordConfirmation'));
        }
    }, [passwordConfirmation, password]);
    const checkFieldsValidation = () => {
        if (invalidLoginMsg === ''
            && invalidFirstNameMsg === ''
            && invalidSurnameMsg === ''
            && invalidEmailMsg === ''
            && invalidPasswordMsg === ''
            && invalidPasswordConfirmationMsg === ''
            && termsOfService === true
            && certifyAge === true
            && recaptcha !== null) {
            return true;
        }
        return false;
    };
    const checkIfSignupIsPossible = async () => {
        const signupActive = await checkIfSignUpIsActive();
        setSignUpActive(JSON.parse(signupActive));
    };
    const addUser = async () => {
        setLoading(true);
        if (!checkFieldsValidation) {
            setLoading(false);
            return;
        }
        try {
            const res = await availability({ login: login.toLowerCase(), email: email.toLowerCase() });
            const { validLogin, validEmail, unavailableLogin, unavailableEmail } = res;
            if (!validLogin || !validEmail || unavailableLogin || unavailableEmail) {
                if (!validLogin) {
                    setInvalidLoginMsg(t('accountDescriptions.login'));
                }
                if (!validEmail) {
                    setInvalidEmailMsg(t('accountDescriptions.email'));
                }
                if (unavailableLogin) {
                    setInvalidLoginMsg(t('accountDescriptions.takenLogin'));
                }
                if (unavailableEmail) {
                    setInvalidEmailMsg(t('accountDescriptions.takenEmail'));
                }
                setLoading(false);
                return;
            }
        }
        catch (error) {
            setErrorWindowMessage(error);
            return;
        }
        const user = {
            login: login.toLowerCase(),
            name: firstName,
            surname: surname,
            email: email.toLowerCase(),
            timezone: timezone,
            password: password,
            termsOfService: termsOfService,
            recaptchaToken: recaptcha
        };
        try {
            await signup(user);
            setOpenModal(true);
            setTimeout(() => {
                history.push('/');
            }, 5000);
        }
        catch (error) {
            setResponseStatus('');
            return;
        }
        setLoading(false);
    };
    const setProfile = async () => {
        try {
            const profile = await getMyUserProfile();
            if (profile.roles.includes('ROLE_BUYER')) {
                history.push('/requester');
            }
            else if (profile.roles.includes('ROLE_ENHANCER')) {
                history.push('/enhancer');
            }
            else {
                history.push('/settings');
            }
        }
        catch (error) {
            setErrorWindowMessage(null);
        }
    };
    const handleSubmit = () => {
        addUser();
    };
    const handleRecaptcha = (token) => {
        setRecaptcha(token);
    };
    if (errorWindowMessage !== null) {
        return React.createElement(ErrorModal, { ...errorWindowMessage, onClose: () => window.location.reload() });
    }
    return (React.createElement(Background, null,
        React.createElement(StyledModal, { closeOnDimmerClick: false, open: openModal, dimmer: "inverted", size: "large" },
            React.createElement(Modal.Content, null,
                React.createElement(Modal.Description, null,
                    React.createElement(ModalContentMessage, null, t('accountDescriptions.sendEmailMessage')),
                    React.createElement(ModalContentMessageDesc, null, t('accountDescriptions.redirect'))))),
        React.createElement(Grid, { textAlign: "center", style: { height: '100vh' }, verticalAlign: "middle" },
            React.createElement(Grid.Column, { style: { maxWidth: 550 } },
                React.createElement(Header, { as: "h1", color: "black", textAlign: "center" }, t('settings.signup')),
                !signUpActive
                    ? React.createElement("div", null, t('settings.signupClosed'))
                    : React.createElement(StyledForm, { autoComplete: "off", onSubmit: handleSubmit },
                        React.createElement(Segment, { textAlign: "left" },
                            React.createElement(Form.Field, { error: invalidLoginMsg === '' ? false : true },
                                React.createElement("label", { htmlFor: "login" }, t('settings.login') + ' *'),
                                React.createElement(Popup, { open: invalidLoginMsg === '' ? false : true, size: "tiny", position: "right center", content: invalidLoginMsg, style: style, trigger: React.createElement(Input, { autoFocus: true, type: "text", id: "login", value: login, onChange: (e, data) => setLogin(data.value) }) })),
                            React.createElement(Form.Field, { error: invalidFirstNameMsg === '' ? false : true },
                                React.createElement("label", { htmlFor: "name" }, t('settings.name') + ' *'),
                                React.createElement(Popup, { open: invalidFirstNameMsg === '' ? false : true, size: "tiny", position: "right center", content: invalidFirstNameMsg, style: style, trigger: React.createElement(Input, { type: "text", id: "name", value: firstName, onChange: (e, data) => setFirstName(data.value) }) })),
                            React.createElement(Form.Field, { error: invalidSurnameMsg === '' ? false : true },
                                React.createElement("label", { htmlFor: "surname" }, t('settings.surname') + ' *'),
                                React.createElement(Popup, { open: invalidSurnameMsg === '' ? false : true, size: "tiny", position: "right center", content: invalidSurnameMsg, style: style, trigger: React.createElement(Input, { type: "text", id: "surname", value: surname, onChange: (e, data) => setSurname(data.value) }) })),
                            React.createElement(Form.Field, { error: invalidEmailMsg === '' ? false : true },
                                React.createElement("label", { htmlFor: "email" }, t('settings.email') + ' *'),
                                React.createElement(Popup, { open: invalidEmailMsg === '' ? false : true, size: "tiny", position: "right center", content: invalidEmailMsg, style: style, trigger: React.createElement(Input, { type: "email", id: "email", value: email, onChange: (e, data) => setEmail(data.value) }) })),
                            React.createElement(Form.Group, { widths: 2 },
                                React.createElement(Form.Field, null,
                                    React.createElement(SelectMemo, { search: true, value: timezone, setValue: setTimezone, option: "timezones", label: "timezone" }))),
                            React.createElement(Form.Field, { error: invalidPasswordMsg === '' ? false : true },
                                React.createElement("label", { htmlFor: "password" }, t('settings.password') + ' *'),
                                React.createElement(Popup, { open: invalidPasswordMsg === '' ? false : true, size: "tiny", position: "right center", content: invalidPasswordMsg, style: style, trigger: React.createElement(Input, { type: "password", id: "password", value: password, onChange: (e, data) => setPassword(data.value) }) })),
                            React.createElement(Form.Field, { error: invalidPasswordConfirmationMsg === '' ? false : true },
                                React.createElement("label", { htmlFor: "passwordConfirmation" }, t('settings.passwordConfirmation') + ' *'),
                                React.createElement(Popup, { open: invalidPasswordConfirmationMsg === '' ? false : true, size: "tiny", position: "right center", content: invalidPasswordConfirmationMsg, style: style, trigger: React.createElement(Input, { type: "password", value: passwordConfirmation, id: "passwordConfirmation", onChange: (e, data) => setPasswordConfirmation(data.value) }) })),
                            React.createElement(Divider, null),
                            React.createElement(Form.Field, { error: !termsOfService },
                                React.createElement(CheckboxContainer, null,
                                    React.createElement(Checkbox, { onChange: () => setTermsOfService(!termsOfService), checked: termsOfService }),
                                    React.createElement(CheckboxLabel, null,
                                        t('settings.acceptAndAgree'),
                                        React.createElement(StyledLink, { target: "_blank", to: "/terms-of-service" }, t('settings.termsOfService')),
                                        ' ',
                                        "*"))),
                            React.createElement(Form.Field, { error: !certifyAge },
                                React.createElement(CheckboxContainer, null,
                                    React.createElement(Checkbox, { onChange: () => setCertifyAge(!certifyAge), checked: certifyAge }),
                                    React.createElement(CheckboxLabel, null,
                                        t('settings.certifyAge'),
                                        " *"))),
                            React.createElement("br", null),
                            React.createElement(Form.Field, null,
                                React.createElement(RecaptchaWrapper, null,
                                    React.createElement(ReCAPTCHA, { sitekey: (_a = process.env.RECAPTCHA_SITE_KEY) !== null && _a !== void 0 ? _a : '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI', onChange: handleRecaptcha }))),
                            responseStatus !== '' && (React.createElement(Message, { className: responseStatus === 'ERROR' ? 'negative' : '', content: t('settings.ERROR') })),
                            React.createElement(StyledReturnLink, { to: "/" }, t('settings.haveAccount')),
                            React.createElement(ButtonWrapper, null,
                                React.createElement(StyledButton, { loading: loading, disabled: !checkFieldsValidation(), primary: true, content: t('settings.signup'), type: "submit" })))))),
        React.createElement(LegalFooter, null)));
};
export default Signup;
