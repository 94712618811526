import { SAVE_COMPANY_CURRENCY, SAVE_COMPANY_EXCHANGE_RATE, SAVE_USER_CURRENCY, SAVE_USER_EXCHANGE_RATE, SAVE_USER_OOO_STATUS, SAVE_USER_PROFILE } from '../types/types';
export function saveUserProfile(profile) {
    return {
        type: SAVE_USER_PROFILE,
        payload: profile
    };
}
export function saveUserCurrency(currency) {
    return {
        type: SAVE_USER_CURRENCY,
        payload: currency
    };
}
export function saveUserExchangeRate(exchangeRate) {
    return {
        type: SAVE_USER_EXCHANGE_RATE,
        payload: exchangeRate
    };
}
export function saveUserOOOStatus(status) {
    return {
        type: SAVE_USER_OOO_STATUS,
        payload: status
    };
}
export function saveCompanyExchangeRate(exchangeRate) {
    return {
        type: SAVE_COMPANY_EXCHANGE_RATE,
        payload: exchangeRate
    };
}
export function saveCompanyCurrency(currency) {
    return {
        type: SAVE_COMPANY_CURRENCY,
        payload: currency
    };
}
