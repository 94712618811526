import React, { useEffect, useState } from "react";
import { Modal } from "semantic-ui-react";
import { getJobComments, postJobComment } from "api-operations/requester/job";
import CommentsPanel from "./CommentsPanel";
import { useTranslation } from "react-i18next";
import { getJobAndStageCommentsForAssignment } from "api-operations/editor/editor";
import { useLogger } from "logger/Logger";
const CommentsModal = ({ isOpen, setIsOpen, jobId, assignmentId, readOnly }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [comments, setComments] = useState([]);
    const { t } = useTranslation('common');
    const { logger } = useLogger();
    // if(jobId && assignmentId || !jobId && !assignmentId) {
    //   logger.error(`Exactly one ID (job's or assignment's) has to be specified for comments modal.`)
    // }
    useEffect(() => {
        if (isOpen) {
            setIsLoading(true);
            (async () => {
                loadComments();
                setIsLoading(false);
            })();
        }
    }, [isOpen]);
    const loadComments = async () => {
        try {
            const comments = (jobId && await getJobComments(jobId))
                || (assignmentId && await getJobAndStageCommentsForAssignment(assignmentId))
                || [];
            setComments(comments);
        }
        catch (error) {
            logger.error(`Couldn't fetch comments for ${jobId ? `job ${jobId}` : ""}${assignmentId ? `assignment ${assignmentId}` : ""}`);
        }
    };
    const postComment = async (comment) => {
        if (!jobId || !comment.trim()) {
            return;
        }
        try {
            await postJobComment(jobId, comment);
        }
        catch (error) {
            logger.error(`Failed to post a comment for ${jobId ? `job ${jobId}` : ""}${assignmentId ? `assignment ${assignmentId}` : ""}`);
        }
        loadComments();
    };
    return React.createElement(Modal, { open: isOpen, onClose: () => setIsOpen(false), size: "tiny" }, !isLoading && React.createElement(React.Fragment, null,
        React.createElement(Modal.Header, null, t('commentsModal.comments')),
        React.createElement(Modal.Content, null,
            React.createElement(CommentsPanel, { comments: comments, postComment: readOnly ? undefined : postComment }))));
};
export default CommentsModal;
