import React, { useState } from 'react';
import { Modal, Input, Button } from 'semantic-ui-react';
const CompanyChangeNameModal = ({ open, setOpen, onChange }) => {
    const [companyName, setCompanyName] = useState('');
    const handleChange = () => {
        if (companyName.trim().length < 2)
            return;
        onChange(companyName);
        setCompanyName('');
        setOpen(false);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    return React.createElement(Modal, { open: open },
        React.createElement(Modal.Header, null, "Change Company Name"),
        React.createElement(Modal.Content, null,
            React.createElement(Input, { type: 'text', fluid: true, value: companyName, onChange: (_, data) => setCompanyName(data.value), placeholder: 'New Company Name' })),
        React.createElement(Modal.Actions, null,
            React.createElement(Button, { positive: true, disabled: companyName.trim().length < 2, onClick: handleChange }, "Change"),
            React.createElement(Button, { onClick: handleCancel }, "Cancel")));
};
export default CompanyChangeNameModal;
