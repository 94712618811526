import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal, Rating, Table } from 'semantic-ui-react';
import styled from 'styled-components';
import style from './SelectExecutor.scss';
import PublicProfile from 'helpers/components/PublicProfile';
import PriceWithExchangeRatePopup from 'dashboards/shared/PriceWithExchangeRatePopup';
import store from 'reduxStore/store';
const TableLoader = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;
const ExecutorSelection = ({ executors, selectExecutor, stageType, isLoading, refreshExecutorsList }) => {
    const [selectBest, setSelectBest] = useState(false);
    const [genreMatch, setGenreMatch] = useState(false);
    const [selectedPriceRange, setSelectedPriceRange] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [disableButtons, setDisableButtons] = useState(false);
    const [selectedExecutor, setSelectedExecutor] = useState(null);
    const { exchangeRate } = store.getState().userProfile;
    const history = useHistory();
    const { t } = useTranslation('common');
    useEffect(() => {
        const maxPrice = Math.ceil(Math.max(...executors.map(e => e.price.value)));
        setSelectedPriceRange(maxPrice);
        setMaxPrice(maxPrice);
    }, [executors, genreMatch, selectBest]);
    const handleExecutorSelection = async (executorId) => {
        setDisableButtons(true);
        setSelectedExecutor(executorId);
        selectExecutor(executorId, selectBest);
        setIsModalOpen(true);
    };
    const getCustomizationByType = () => {
        switch (stageType) {
            case 'REDACTION':
                return {
                    header: t('dashboard.redactorChoosingHeader'),
                    cellHeader: t('dashboard.redactorName'),
                    modalText: React.createElement("h4", null, t('dashboard.projectSentToRedactor'))
                };
            case 'ENHANCEMENT':
                return {
                    header: t('dashboard.enhancerChoosingHeader'),
                    cellHeader: t('dashboard.enhancerName'),
                    modalText: React.createElement("h4", null, t('dashboard.projectSentToEnhancer'))
                };
            case 'TRUST_MINING':
                return {
                    header: t('dashboard.trustMinerChoosingHeader'),
                    cellHeader: t('dashboard.trustMinerName'),
                    modalText: React.createElement("h4", null, t('dashboard.projectSentToTrustMiner'))
                };
            case 'FINAL_REDACTION':
                return {
                    header: t('dashboard.finalRedactionRedactorChoosingHeader'),
                    cellHeader: t('dashboard.redactorName'),
                    modalText: React.createElement("h4", null, t('dashboard.projectSentToRedactorForFinalRedaction'))
                };
            default:
                return {
                    header: '',
                    cellHeader: '',
                    modalText: ''
                };
        }
    };
    const getSelectExecutorButton = (executor) => {
        let buttonText = '';
        switch (stageType) {
            case 'REDACTION':
                buttonText = t('dashboard.redactorChoosingHeader');
                break;
            case 'ENHANCEMENT':
                buttonText = t('dashboard.enhancerChoosingHeader');
                break;
            case 'TRUST_MINING':
                buttonText = t('dashboard.trustMinerChoosingHeader');
                break;
            case 'FINAL_REDACTION':
                buttonText = t('dashboard.redactorChoosingHeader');
                break;
            default:
                break;
        }
        return React.createElement(Button, { "data-testid": executor.id, loading: selectedExecutor == executor.id, disabled: disableButtons, color: "blue", onClick: () => handleExecutorSelection(executor.id) },
            React.createElement(Icon, { name: 'paper plane' }),
            buttonText);
    };
    const customization = getCustomizationByType();
    return React.createElement("div", { className: style.SelectEnhancerView },
        React.createElement("h2", null, customization.header),
        React.createElement("div", { className: style.controls },
            React.createElement("div", { className: style.filters },
                React.createElement("h3", null, t('dashboard.filters')),
                React.createElement("div", null,
                    React.createElement("label", { className: style.description }, t('dashboard.maximumPrice')),
                    React.createElement("input", { type: "range", id: "maxprice", "data-testid": "maxPrice", min: "0", max: maxPrice, value: selectedPriceRange, onChange: event => setSelectedPriceRange(event.target.value) }),
                    React.createElement("label", { className: style.value }, selectedPriceRange)),
                React.createElement("div", null,
                    React.createElement("label", { htmlFor: "genrematching", className: style.description }, t('dashboard.genreMatching')),
                    React.createElement("input", { type: "checkbox", id: "genrematching", checked: genreMatch, onChange: (event) => { setGenreMatch(event.target.checked), refreshExecutorsList(event.target.checked); } })),
                React.createElement("div", null,
                    React.createElement("label", { htmlFor: "selectTheBest", className: style.description }, t('dashboard.selectTheBest')),
                    React.createElement("input", { type: "checkbox", id: "selectTheBest", checked: selectBest, onChange: (event) => { setSelectBest(event.target.checked), refreshExecutorsList(event.target.checked); } })))),
        React.createElement(Table, null,
            React.createElement(Table.Header, null,
                React.createElement(Table.Row, null,
                    React.createElement(Table.HeaderCell, { width: 2 }, t('dashboard.rank')),
                    React.createElement(Table.HeaderCell, null, customization.cellHeader),
                    React.createElement(Table.HeaderCell, null, t('dashboard.estimatedPrice')),
                    stageType == 'ENHANCEMENT' && React.createElement(Table.HeaderCell, null, t('dashboard.trustChain')),
                    React.createElement(Table.HeaderCell, { width: 4 }))),
            React.createElement(Table.Body, null, isLoading ? React.createElement(TableLoader, null,
                React.createElement(Icon, { loading: true, name: 'circle notch' })) : executors.filter(e => e.price.value <= selectedPriceRange).map(exec => React.createElement(Table.Row, { key: exec.id },
                React.createElement(Table.Cell, null, exec.rank),
                React.createElement(Table.Cell, null,
                    exec.username,
                    exec.preferred && React.createElement(Rating, { disabled: true, icon: 'star', maxRating: 1, rating: 1, "data-testid": exec.id + '_star' }),
                    React.createElement(PublicProfile, { userName: exec.username, showRoles: false, hideLinkedin: true })),
                React.createElement(Table.Cell, null,
                    React.createElement(PriceWithExchangeRatePopup, { cost: exec.price, customCurrencyCost: exec.userCurrencyPrice, exchangeRate: exchangeRate })),
                stageType == 'ENHANCEMENT' && React.createElement(Table.Cell, null, exec.trustChain === null ? '-' : exec.trustChain.toFixed(1)),
                React.createElement(Table.Cell, null, getSelectExecutorButton(exec)))))),
        React.createElement(Modal, { open: isModalOpen, closeOnEscape: true, closeOnDimmerClick: true, size: 'small' },
            React.createElement(Modal.Content, null, customization.modalText),
            React.createElement(Modal.Actions, null,
                React.createElement(Button, { color: 'blue', onClick: () => history.push('/requester') },
                    React.createElement(Icon, { name: 'checkmark' }),
                    " ",
                    t('dashboard.ok')))));
};
export default ExecutorSelection;
