import axios from 'axios';
export const sendLog = async (level, message, path, extraData) => {
    await axios.post(`/logs/user/activity`, { level, message, path, extraData });
};
export const sendUserInfo = async (data) => {
    await axios.post(`/logs/user/settings`, data);
};
export const sendExternalAssetModificationLog = async (assetModification) => {
    await axios.post(`/logs/assetModification`, assetModification);
};
