import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ExfluencyLoader from "helpers/components/loader/ExfluencyLoader";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
GlobalWorkerOptions.workerSrc = "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.8.335/build/pdf.worker.js";
const Container = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const LoaderContainer = styled.div `
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LegalDocument = ({ legalDocument }) => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        renderPages();
    }, []);
    const renderPages = async () => {
        const url = `/api/documents/${legalDocument}`;
        const pdf = await getDocument(url).promise;
        Array.from(Array(pdf.numPages).keys()).map(i => pdf.getPage(i + 1))
            .map((promise, i) => promise.then(page => renderPage(page, i + 1)));
        document.getElementById('legalDocument').style.visibility = 'hidden';
        setTimeout(() => { setIsLoading(false); document.getElementById('legalDocument').style.visibility = 'visible'; }, 1000);
    };
    const renderPage = (page, pageNumber) => {
        const canvas = document.createElement("canvas");
        canvas.id = `${pageNumber}_page`;
        canvas.className = "page";
        canvas.style.display = "block";
        const container = document.getElementById('legalDocument');
        const containerWidth = container.clientWidth < 1024 ? container.clientWidth : 1300; // prevent scalling too big
        const viewport = page.getViewport({
            scale: containerWidth / page.getViewport({
                scale: 1
            }).width
        });
        const context = canvas.getContext('2d', { alpha: false });
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        page.render({ canvasContext: context, viewport: viewport });
        container.appendChild(canvas);
    };
    return React.createElement(React.Fragment, null,
        isLoading && React.createElement(LoaderContainer, null,
            React.createElement(ExfluencyLoader, null)),
        React.createElement(Container, { id: "legalDocument" }));
};
export default LegalDocument;
