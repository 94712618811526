import React from 'react';
import { Button, Popup, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { parseTimestamp } from 'helpers/DateHandler';
import { Styled } from 'StyledComponents';
const CompanyJobInfo = ({ job }) => {
    const { t } = useTranslation('common');
    return React.createElement(Popup, { basic: true, position: "top center", hoverable: true, on: ['hover', 'click'], trigger: React.createElement(Button, { icon: "info" }), offset: [0, 0], flowing: true },
        React.createElement(Styled.VerticalPopupContent, null,
            React.createElement("h4", null, t(`dashboard.jobInfo`)),
            React.createElement(Table, { basic: "very", collapsing: true, compact: "very" },
                React.createElement(Table.Body, null,
                    React.createElement(Table.Row, null,
                        React.createElement(Table.Cell, null, t('dashboard.genre')),
                        React.createElement(Table.Cell, null, t(`genre.${job.genre}`))),
                    React.createElement(Table.Row, null,
                        React.createElement(Table.Cell, null, t('dashboard.taxonomy')),
                        React.createElement(Table.Cell, null, job.taxonomy.join(', '))),
                    React.createElement(Table.Row, null,
                        React.createElement(Table.Cell, null, t('dashboard.startDate')),
                        React.createElement(Table.Cell, null, parseTimestamp(job.uploadDate))),
                    React.createElement(Table.Row, null,
                        React.createElement(Table.Cell, null,
                            " ",
                            t('dashboard.dueDate')),
                        React.createElement(Table.Cell, null, parseTimestamp(job.dueDate))),
                    job.deliveryDate && React.createElement(Table.Row, null,
                        React.createElement(Table.Cell, null, t('dashboard.deliveryDate')),
                        React.createElement(Table.Cell, null, parseTimestamp(job.deliveryDate))),
                    job.stages.map(stage => React.createElement(Table.Row, { key: stage.id },
                        React.createElement(Table.Cell, null, t(`stages.${stage.stageType}`)),
                        React.createElement(Table.Cell, null,
                            t(`stageStatus.${stage.stageStatus}`),
                            "  ",
                            t(`stageSelection.${stage.parameters.ExecutorSelection || 'auto'}`)))),
                    !!job.visibility.gatedCommunities.length && React.createElement(Table.Row, null,
                        React.createElement(Table.Cell, null, t('dashboard.gatedCommunities')),
                        React.createElement(Table.Cell, null, job.visibility.gatedCommunities.join(', '))),
                    job.visibility.openCommunity && React.createElement(Table.Row, null,
                        React.createElement(Table.Cell, null, t('dashboard.openCommunity')),
                        React.createElement(Table.Cell, null, "True"))))));
};
export default CompanyJobInfo;
