import { CloseIcon } from 'assets/icons/close';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Buttons, CloseContainer, Content, Header, Message } from './AssignmentCancel.styles';
const AssignmentCancel = ({ handleClose, responseCancellation, isLoading }) => {
    const { t } = useTranslation('common');
    return React.createElement(React.Fragment, null,
        React.createElement(CloseContainer, { onClick: handleClose },
            React.createElement(CloseIcon, null)),
        React.createElement(Content, null,
            React.createElement(Header, null, t('dashboard.cancelView.header')),
            React.createElement(Message, null, t('dashboard.cancelView.message')),
            React.createElement(Buttons, null,
                React.createElement(Button, { variant: 'primary', "data-testid": "accept-btn", disabled: isLoading, onClick: () => responseCancellation('accept') }, t('dashboard.cancelView.abandonWork')),
                React.createElement(Button, { variant: 'secondary', "data-testid": "reject-btn", disabled: isLoading, onClick: () => responseCancellation('reject') }, t('dashboard.cancelView.rejectCancellation')))));
};
export default AssignmentCancel;
