import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Checkbox } from 'semantic-ui-react';
import BankPaymentComp from './BankPayment';
import IbanPaymentComp from './IbanPayment';
import PaypalPaymentComp from './PaypalPayment';
import { getCompanyPaymentMethods, getBankPaymentMethods, getIbanPaymentMethods, getPaypalPaymentMethods, setDefaultPaymentMethod } from 'api-operations/company/admin';
import { toast } from 'react-toastify';
const Card = styled.div `
  box-sizing: border-box;
  flex: 0 0 32.5%;
  min-height: 190px;
  padding: 10px;
  margin: 5px 3px;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
`;
const CardWrapper = styled.div `
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
`;
const CardHeaderWrapper = styled.div `
  display: flex;
  justify-content: space-between;
`;
const PaymentMethods = () => {
    const [openPaymentMethod, setOpenPaymentMethod] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [bankPaymentMethods, setBankPaymentMethods] = useState([]);
    const [ibanPaymentMethods, setIbanPaymentMethods] = useState([]);
    const [paypalPaymentMethods, setPaypalPaymentMethods] = useState([]);
    const [defaultPaymentId, setDefaultPaymentId] = useState('');
    const { t } = useTranslation('common');
    const params = useParams();
    useEffect(() => {
        if (openPaymentMethod === null) {
            (async () => {
                try {
                    const res = await getCompanyPaymentMethods(params.id);
                    setPaymentMethods(res);
                    setDefaultPayment(res.find(({ isDefault }) => isDefault));
                    const addedPaymentMethods = new Set();
                    res.forEach(({ method }) => {
                        addedPaymentMethods.add(method);
                    });
                    if (addedPaymentMethods.has('BANK')) {
                        const res = await getBankPaymentMethods(params.id);
                        setBankPaymentMethods(res);
                    }
                    if (addedPaymentMethods.has('IBAN')) {
                        const res = await getIbanPaymentMethods(params.id);
                        setIbanPaymentMethods(res);
                    }
                    if (addedPaymentMethods.has('PAYPAL')) {
                        const res = await getPaypalPaymentMethods(params.id);
                        setPaypalPaymentMethods(res);
                    }
                }
                catch (error) {
                    // TODO
                }
            })();
        }
    }, [openPaymentMethod]);
    const setDefaultPayment = (payment) => {
        if (!payment)
            return;
        setDefaultPaymentId(payment.paymentMethodId);
    };
    const handleCheckboxChange = async (paymentId) => {
        try {
            await setDefaultPaymentMethod(params.id, paymentId);
            setDefaultPaymentId(paymentId);
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
    };
    const showBankInfo = (bankInfo) => {
        if (!bankInfo)
            return null;
        return React.createElement(Card, null,
            React.createElement(CardHeaderWrapper, null,
                React.createElement("h3", null, t('companies.payment.bank')),
                React.createElement(Checkbox, { checked: defaultPaymentId === bankInfo.paymentMethodId, onChange: (_, data) => {
                        (data.checked && bankInfo.paymentMethodId !== defaultPaymentId) ? handleCheckboxChange(bankInfo.paymentMethodId) : null;
                    } })),
            React.createElement("p", null,
                t('companies.payment.accountNumber'),
                ": ",
                React.createElement("b", null, bankInfo.accountNumber)),
            React.createElement("p", null,
                t('companies.payment.bankName'),
                ": ",
                React.createElement("b", null, bankInfo.bankName)),
            React.createElement("p", null,
                t('companies.payment.bic'),
                ": ",
                React.createElement("b", null, bankInfo.bic)),
            bankInfo.routingNumber && React.createElement("p", null,
                t('companies.payment.routingNumber'),
                ": ",
                React.createElement("b", null, bankInfo.routingNumber)));
    };
    const showIbanInfo = (ibanInfo) => {
        if (!ibanInfo)
            return null;
        return React.createElement(Card, null,
            React.createElement(CardHeaderWrapper, null,
                React.createElement("h3", null, t('companies.payment.iban')),
                React.createElement(Checkbox, { checked: defaultPaymentId === ibanInfo.paymentMethodId, onChange: (_, data) => {
                        (data.checked && ibanInfo.paymentMethodId !== defaultPaymentId) ? handleCheckboxChange(ibanInfo.paymentMethodId) : null;
                    } })),
            React.createElement("p", null,
                t('companies.payment.bankName'),
                ": ",
                React.createElement("b", null, ibanInfo.bankName)),
            React.createElement("p", null,
                t('companies.payment.ibanNumber'),
                ": ",
                React.createElement("b", null, ibanInfo.iban)),
            React.createElement("p", null,
                t('companies.payment.accountHolder'),
                ": ",
                React.createElement("b", null, ibanInfo.accountHolder)));
    };
    const showPaypalInfo = (paypalInfo) => {
        if (!paypalInfo)
            return null;
        return React.createElement(Card, null,
            React.createElement(CardHeaderWrapper, null,
                React.createElement("h3", null, t('companies.payment.paypal')),
                React.createElement(Checkbox, { checked: defaultPaymentId === paypalInfo.paymentMethodId, onChange: (_, data) => {
                        (data.checked && paypalInfo.paymentMethodId !== defaultPaymentId) ? handleCheckboxChange(paypalInfo.paymentMethodId) : null;
                    } })),
            React.createElement("p", null,
                t('companies.payment.paypalEmail'),
                ": ",
                React.createElement("b", null, paypalInfo.paypalUser)));
    };
    return React.createElement("div", null,
        React.createElement(Button, { primary: true, onClick: () => setOpenPaymentMethod('PAYPAL') }, t('companies.payment.addPaypal')),
        React.createElement(Button, { primary: true, onClick: () => setOpenPaymentMethod('IBAN') }, t('companies.payment.addIban')),
        React.createElement(Button, { primary: true, onClick: () => setOpenPaymentMethod('BANK') }, t('companies.payment.addBank')),
        React.createElement(CardWrapper, null, paymentMethods.map((paymentMethod) => {
            if (paymentMethod.method == 'BANK') {
                return showBankInfo(bankPaymentMethods.find(({ paymentMethodId }) => paymentMethodId === paymentMethod.paymentMethodId));
            }
            else if (paymentMethod.method == 'IBAN') {
                return showIbanInfo(ibanPaymentMethods.find(({ paymentMethodId }) => paymentMethodId === paymentMethod.paymentMethodId));
            }
            else if (paymentMethod.method == 'PAYPAL') {
                return showPaypalInfo(paypalPaymentMethods.find(({ paymentMethodId }) => paymentMethodId === paymentMethod.paymentMethodId));
            }
            return null;
        })),
        React.createElement(PaypalPaymentComp, { setOpen: setOpenPaymentMethod, open: openPaymentMethod == 'PAYPAL' }),
        React.createElement(IbanPaymentComp, { setOpen: setOpenPaymentMethod, open: openPaymentMethod == 'IBAN' }),
        React.createElement(BankPaymentComp, { setOpen: setOpenPaymentMethod, open: openPaymentMethod == 'BANK' }));
};
export default PaymentMethods;
