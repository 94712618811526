import IconButton from "editorNextGen/components/IconButton";
import TerminologyPanel from "./TerminologyPanel";
import i18next from "i18next";
import EditorModule from "../EditorModule";
import './style.scss';
import { IconNgBuilder } from "editorNextGen/icons";
import { termsSvg } from "editorNextGen/icons/terms";
export default class TerminologyModule extends EditorModule {
    constructor(editor) {
        super(editor);
        this.moduleId = "TerminologyModule";
        this.terminologyPanel = new TerminologyPanel(this.editor.sourceLanguage, this.editor.targetLanguage, this.editor.sourceLanguageTextDirection, this.editor.targetLanguageTextDirection);
        this.showTerminologyButton = new IconButton("wordpress forms", i18next.t("editorHeader.showTerminologyPanel"));
        editor.leftSidePanel.appendChild(this.terminologyPanel);
        new IconNgBuilder(termsSvg, this.editor.header.toolBar)
            .setToggable(true)
            .setOnClick(() => { this.showTerminology = !this.showTerminology; })
            .isActive(true)
            .build();
    }
    unload() {
        this.terminologyPanel.remove();
        this.showTerminologyButton.remove();
    }
    keyDown() {
        this.editor.activeAsset && this.updateTerminology(this.editor.activeAsset);
    }
    input() {
        this.editor.activeAsset && this.updateTerminology(this.editor.activeAsset);
    }
    assetActivationChanged(asset, active) {
        active && this.updateTerminology(asset);
    }
    assetRefreshed(asset) {
        asset.active && this.updateTerminology(asset);
    }
    updateTerminology(asset) {
        var _a;
        this.terminologyPanel.currentText = ((_a = asset.contentDiv.textContent) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase()) || "";
        this.terminologyPanel.terms = asset.terms;
    }
    get showTerminology() {
        return this.terminologyPanel.style.display == "";
    }
    set showTerminology(showTerminology) {
        this.showTerminologyButton.selected = showTerminology;
        this.terminologyPanel.style.display = showTerminology ? "" : "none";
    }
}
