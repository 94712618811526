import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Icon, Popup } from 'semantic-ui-react';
const JobNameWrapper = styled.div `
  max-width: ${({ widthInRem }) => widthInRem + 'rem'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const JobNamePopup = ({ name, testMode, charityMode, widthInRem = 12.5 }) => {
    const { t } = useTranslation('common');
    const testModeIndicator = React.createElement(Popup, { trigger: React.createElement(Icon, { name: "attention", color: 'purple' }) },
        React.createElement("span", null, t('dashboard.testMode')));
    const charityModeIndicator = React.createElement(Popup, { trigger: React.createElement(Icon, { name: "heart", color: 'red' }) },
        React.createElement("span", null, t('dashboard.charityMode')));
    return React.createElement("span", { style: { display: 'flex' } },
        React.createElement("span", null,
            testMode && testModeIndicator,
            charityMode && charityModeIndicator),
        React.createElement(Popup, { pinned: true, hoverable: true, trigger: React.createElement(JobNameWrapper, { widthInRem: widthInRem },
                React.createElement(React.Fragment, null,
                    " ",
                    name)) },
            React.createElement("span", { style: { wordBreak: 'break-word' } }, name)));
};
export default JobNamePopup;
