import styled from 'styled-components'
import { Popup } from 'semantic-ui-react'

const exfluencyBlue = '#215592'
const exfluencyBlueBorder = '#90AAC8'
const blueHover = 'rgba(155, 218, 238, 1)'

const H1 = styled.h1`
  color: ${exfluencyBlue};
  text-align: center;
`

const H2 = styled.h2`
  color: ${exfluencyBlue};
  margin-top: 1rem;
  margin-bottom: .25rem;
  padding: 2rem 0;
`

const H3 = styled.h3`
  padding-top: 1rem;
`

const StatisticSecondary = styled.span`
  opacity: .5;
  font-size: 60%;
`

const TranslucentSpan = styled.span`
  opacity: .5
`

const VerticalPopupContent = styled(Popup.Content)`
  display: flex;
  flex-direction: column;

  h4 {
    text-align: center;
    margin-bottom: 0;
  }

  button {
    margin-top: .5rem !important;
  }
`

export const Styled = {
  H1,
  H2,
  H3,
  StatisticSecondary,
  TranslucentSpan,
  VerticalPopupContent
}