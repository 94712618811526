import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, Switch, Route, Redirect, NavLink } from 'react-router-dom';
import { Menu, Message } from 'semantic-ui-react';
import Assignments from './assignments/Assignments';
import Terminologies from './terminologies/Terminologies';
import ErrorModal from 'errors/components/ErrorModal';
import ExfluencyLoader from 'helpers/components/loader/ExfluencyLoader';
import Expense from './expenses/Expenses';
import { getLangCoinBalances } from 'api-operations/langCoinWallet/langCoinWallet';
import { formatLangCoin } from 'helpers/LangCoinHelper';
const StyledNavLink = styled(NavLink) `
  &&&&:hover {
   background-color: rgba(0,0,0,.03)
  }

  &&&&.active {
    background-color: rgba(0,0,0,.05);
  }
`;
const Counter = styled.span `
  padding: 20px 0 30px 0;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 500;
`;
const XflView = () => {
    const [loading, setLoading] = useState(true);
    const [langCoinBalance, setLangCoinBalance] = useState({ value: "0" });
    const [errorWindowMessage, setErrorWindowMessage] = useState(null);
    const { t } = useTranslation('common');
    const { path, url } = useRouteMatch();
    const fetchData = async () => {
        try {
            const response = await getLangCoinBalances();
            setLangCoinBalance(response.balance);
        }
        catch (error) {
            setErrorWindowMessage(null);
            return;
        }
        setLoading(false);
    };
    useEffect(() => {
        fetchData();
    }, []);
    if (errorWindowMessage !== null)
        return React.createElement(ErrorModal, { ...errorWindowMessage, onClose: () => window.location.reload() });
    if (loading)
        return React.createElement(ExfluencyLoader, null);
    return React.createElement(React.Fragment, null,
        React.createElement("h2", null, t('myLangCoin.myLangCoin')),
        React.createElement(Message, { warning: true, content: "Language Coins (LangCoin) earned now (pre-release) are stored as Ethereum test coins. They will be converted to LangCoin when the cryptocurrency goes live." }),
        React.createElement(Counter, null,
            t('myLangCoin.balanceLanguageCoins'),
            " ",
            formatLangCoin(langCoinBalance)),
        React.createElement(Menu, { stackable: true },
            React.createElement(Menu.Item, { as: StyledNavLink, to: `${url}`, exact: true }, t('myLangCoin.projects')),
            React.createElement(Menu.Item, { as: StyledNavLink, to: `${url}/terminology` }, t('myLangCoin.terminology')),
            React.createElement(Menu.Item, { as: StyledNavLink, to: `${url}/expenses` }, t('myLangCoin.expenses'))),
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: `${path}` },
                React.createElement(Assignments, null)),
            React.createElement(Route, { exact: true, path: `${path}/terminology` },
                React.createElement(Terminologies, null)),
            React.createElement(Route, { exact: true, path: `${path}/expenses` },
                React.createElement(Expense, null)),
            React.createElement(Redirect, { from: `${path}/*`, to: `${path}` })));
};
export default XflView;
