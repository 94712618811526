import axios from "axios";
export async function createJobTemplate(template) {
    await axios.post('/api/workflow/templates', template);
}
export async function deleteJobTemplate(templateId) {
    await axios.delete(`/api/workflow/templates/${templateId}`);
}
export async function getJobTemplate(templateId) {
    const response = await axios.get(`/api/workflow/templates/${templateId}`);
    return response.data;
}
export async function getJobTemplates() {
    const response = await axios.get('/api/workflow/templates');
    return response.data;
}
export async function editTemplate(template, templateId) {
    await axios.put(`/api/workflow/templates/${templateId}`, template);
}
export async function editTemplateName(templateName, templateId) {
    const response = await axios.put(`/api/workflow/templates/${templateId}/name`, templateName);
    return response.data;
}
