import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import { checkTranslationCertificate } from 'api-operations/public';
import ExfluencyLoader from 'helpers/components/loader/ExfluencyLoader';
import { hasErrorCode } from 'logger/error-guards';
const Container = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;
const Message = styled.span `
  font-size: 1.6rem;
`;
const TranslationCertificate = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [message, setMessage] = useState(null);
    const { t } = useTranslation('common');
    const params = useParams();
    useEffect(() => {
        (async () => {
            try {
                await checkTranslationCertificate(params.id);
                setMessage(t('translationCertificate.success'));
            }
            catch (error) {
                if (hasErrorCode(404, error)) {
                    setIsError(true);
                    setMessage(t('translationCertificate.notFound'));
                }
                else {
                    setIsError(true);
                    setMessage(t('translationCertificate.error'));
                }
            }
            setIsLoading(false);
        })();
    }, []);
    return React.createElement(Container, null, isLoading ? React.createElement(ExfluencyLoader, null)
        : React.createElement(React.Fragment, null,
            React.createElement(Icon, { name: !isError ? 'check circle' : 'times circle', color: !isError ? 'green' : 'red', size: "huge" }),
            React.createElement(Message, null, message)));
};
export default TranslationCertificate;
