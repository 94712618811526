import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, Modal, Popup } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { Styled } from 'StyledComponents';
import { useLogger } from 'logger/Logger';
import { downloadProjectSummary, downloadTCertFile } from 'api-operations/requester/download';
import DownloadFileButton from './DownloadingModal';
import DownloadingSuggestions from './DownloadingSuggestions';
import { getLastFinishedStage } from './utils';
import DownaloadStageContainer from './DownloadingStage';
import { downloadZip } from 'api-operations/requester/stage';
const FileDialogContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const FileDialogSection = styled.div `
  display: block;
  width: 100%;
  margin: 5px;
  padding: 10px;
  text-align: center;
`;
const DownloadingOptions = ({ job }) => {
    const [isModalDownloadOptions, setIsModalDownloadOptions] = useState(false);
    const [isAnyFileAvailable, setAnyFileAvailable] = useState(false);
    const [lastResult, setLastResult] = useState(null);
    const [tcertFile, setTcertFile] = useState(null);
    const [summaryFile, setSummaryFile] = useState(null);
    const { t } = useTranslation('common');
    const { logger } = useLogger();
    const anyFileForStageExists = (stage) => { var _a; return ((_a = job.stages.filter(s => s.stageType === stage)[0]) === null || _a === void 0 ? void 0 : _a.readyFileTypes.length) > 0; };
    const latestTranslationFile = () => {
        var _a;
        let stage = (_a = job.stages
            .filter(s => !['DOCUMENT_PARSING', 'TABLE_PARSING', 'FILE_PREPARATION', 'FILE_PARSING'].includes(s.stageType))
            .filter(s => s.readyFileTypes.includes('RESULT') || s.readyFileTypes.includes("RESULT_ANON")).slice(-1)[0]) !== null && _a !== void 0 ? _a : null;
        if (!stage)
            return null;
        return { stageId: stage.id, stageType: stage.stageType, isReady: true, fileType: 'RESULT' };
    };
    useEffect(() => {
        setAnyFileAvailable((anyFileForStageExists('RECYCLING')
            || anyFileForStageExists('ENHANCEMENT')
            || anyFileForStageExists('TRUST_MINING')
            || anyFileForStageExists('REDACTION')));
        setLastResult(latestTranslationFile());
        if (job.status === 'FINISHED' || job.status === 'CANCELLED') {
            setSummaryFile({ stageType: "OTHER", isReady: true, stageId: job.id, fileType: 'CSV' });
        }
        if (job.hasTranslationCertificate) {
            setTcertFile({ stageId: job.id, stageType: 'TCERT', isReady: true, fileType: 'TCERT' });
        }
    }, [job.stages]);
    const handleDownloadOptionsOpen = () => {
        setIsModalDownloadOptions(true);
        logger.info('User - Opened downloading modal');
    };
    const handleDonwloadCallback = async (stageId, fileType) => {
        if (fileType === 'CSV') {
            await downloadProjectSummary(stageId, job.name);
        }
        else if (fileType === 'TCERT') {
            await downloadTCertFile(stageId);
        }
        else if (fileType) {
            await downloadZip(stageId, fileType);
        }
    };
    const activeStage = getLastFinishedStage(job.stages);
    const downlandableStages = job.stages
        .filter(({ stageType }) => !['TABLE_PARSING', 'DOCUMENT_PARSING', 'ANONYMIZATION', 'FILE_PREPARATION', 'FILE_PARSING'].includes(stageType))
        .filter(({ stageStatus }) => stageStatus !== 'SUPERSEDED');
    const [redactionStage] = downlandableStages.filter(({ stageType }) => stageType === 'REDACTION');
    const [anonStage] = downlandableStages.filter(({ stageType }) => stageType === 'ANONYMIZATION');
    return React.createElement(React.Fragment, null, activeStage && (React.createElement(React.Fragment, null,
        React.createElement(Popup, { basic: true, position: "top center", hoverable: true, on: ['hover', 'click'], trigger: React.createElement(Button, { "data-testid": `download_files_btn`, icon: "download" }) },
            React.createElement(Styled.VerticalPopupContent, null,
                React.createElement("h4", null, t('dashboard.downloadFiles')),
                lastResult && (React.createElement(DownloadFileButton, { file: lastResult, activeStage: activeStage, downloadCallback: handleDonwloadCallback, downloadError: () => toast.error(t('notification.error')) })),
                isAnyFileAvailable && (React.createElement(Button, { fluid: true, basic: true, color: "blue", icon: "file outline", content: t('dashboard.moreDownloads'), onClick: () => handleDownloadOptionsOpen(), "data-testid": `${job.id}_download_modal` })),
                React.createElement(DownloadingSuggestions, { lastStage: activeStage, job: job, downloadCallback: handleDonwloadCallback }))),
        React.createElement(Modal, { open: isModalDownloadOptions, size: "tiny", onClose: () => setIsModalDownloadOptions(false) },
            React.createElement(Modal.Content, null,
                React.createElement(FileDialogContainer, null,
                    React.createElement(FileDialogSection, null,
                        (redactionStage !== undefined && anonStage !== undefined) && React.createElement("h3", null, "Source"),
                        redactionStage && React.createElement(DownaloadStageContainer, { stage: redactionStage, activeStage: activeStage, allowedFileTypes: ["RESULT_ANON", "XLIFF1_ANON", "BILANG_DOCX_ANON", "XLIFF2_ANON", "TRANSLATED_DOCX_ANON"] }),
                        anonStage && React.createElement(DownaloadStageContainer, { stage: anonStage, activeStage: activeStage, allowedFileTypes: ["RESULT_ANON", "XLIFF1_ANON", "BILANG_DOCX_ANON", "XLIFF2_ANON", "TRANSLATED_DOCX_ANON"] })),
                    React.createElement(FileDialogSection, null,
                        React.createElement("h3", null, "Translation"),
                        downlandableStages
                            .filter(({ stageType }) => !['REDACTION', 'ANONYMIZATION'].includes(stageType))
                            .map(stage => React.createElement(DownaloadStageContainer, { stage: stage, activeStage: activeStage }))),
                    (tcertFile || summaryFile) && (React.createElement(FileDialogSection, null,
                        React.createElement("h4", null, "Project files"),
                        tcertFile && (React.createElement(DownloadFileButton, { file: tcertFile, icon: 'file', activeStage: activeStage, downloadCallback: handleDonwloadCallback, downloadError: () => toast.error(t('notification.error')), datatestid: job.id })),
                        summaryFile && (React.createElement(DownloadFileButton, { file: summaryFile, icon: 'info', activeStage: activeStage, downloadCallback: handleDonwloadCallback, downloadError: () => toast.error(t('notification.error')), datatestid: job.id })))))),
            React.createElement(Modal.Actions, null,
                React.createElement(Button, { primary: true, size: "tiny", onClick: () => { setIsModalDownloadOptions(false); } }, t('dashboard.close')))))));
};
export default DownloadingOptions;
