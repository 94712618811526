import { Modal } from "./components/Modal";
import i18next from "i18next";
export default class AssignmentFinishConfirmationModal extends Modal {
    constructor() {
        super();
        this.callback = () => { };
        this.content = document.createElement("div");
        this.classList.add("tiny");
        this.content.classList.add("content", "ui");
        this.appendChild(this.content);
        this.actions = [{
                text: i18next.t("editor.assignmentFinishedConfirmationModal.no"),
                classList: ["secondary"],
                callback: () => { this.open = false; }
            }, {
                text: i18next.t("editor.assignmentFinishedConfirmationModal.yes"),
                classList: ["primary"],
                callback: () => { this.callback(); this.open = false; }
            }];
    }
    set message(message) {
        this.content.textContent = message;
    }
    get message() {
        return this.content.textContent;
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-assignment-finished-confirmation-modal", AssignmentFinishConfirmationModal));
