import axios from 'axios';
export async function uploadFile(file, uploadProgressCallback) {
    const form = new FormData();
    form.append('file', file);
    const response = await axios.post(`/api/workflow/expiring-files`, form, { onUploadProgress(progressEvent) {
            uploadProgressCallback(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        } });
    return response.data;
}
export async function uploadZipFile(file, uploadProgressCallback) {
    const form = new FormData();
    form.append('file', file);
    const response = await axios.post(`/api/workflow/expiring-files/unzip`, form, { onUploadProgress(progressEvent) {
            uploadProgressCallback(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        } });
    return response.data;
}
export async function deleteFile(fileId) {
    await axios.delete(`/api/workflow/expiring-files/${fileId}`);
}
