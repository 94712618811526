import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Message, Select, Table } from 'semantic-ui-react';
import { updateUserRoles } from 'api-operations/myExfluency/myExfluency';
import { allUserRoles } from 'dictionaries/dictionaries';
import ErrorModal from 'errors/components/ErrorModal';
import { getImagePathByRole } from 'helpers/utils';
import { toast } from 'react-toastify';
const StyledImg = styled.img `
  height: 3rem;
`;
const RolesTable = ({ userRoles }) => {
    const [roles, setRoles] = useState([...userRoles]);
    const [notAssignedRoles, setNotAssignedRoles] = useState([]);
    const [currentRole, setCurrentRole] = useState('');
    const [displayAddBnt, setDisplayAddBnt] = useState(true);
    const [errorWindowMessage, setErrorWindowMessage] = useState(null);
    const history = useHistory();
    const { t } = useTranslation('common');
    useEffect(() => {
        setNotAssignedRoles(restRoles());
    }, [roles]);
    const rolesOptions = allUserRoles.map(e => ({ key: e, value: e, text: t(`userRoles.${e}`) }));
    const restRoles = () => {
        return rolesOptions.filter(e => !roles.includes(e.key));
    };
    const addRole = async () => {
        if (currentRole === '' || null) {
            return;
        }
        const tmpRoles = roles.filter(e => e !== '');
        const currentRoles = [...tmpRoles, currentRole];
        try {
            await updateUserRoles(currentRoles);
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
        setRoles(currentRoles);
        history.push('/logout');
    };
    const deleteRole = async (roleToDelete) => {
        let tmpRoles = roles.filter((e) => {
            if (e !== roleToDelete && e !== '') {
                return true;
            }
            return false;
        });
        try {
            await updateUserRoles(tmpRoles);
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
        setRoles(tmpRoles);
        history.push('/logout');
    };
    const handleAddButton = () => {
        setRoles([...roles, '']);
        setDisplayAddBnt(false);
    };
    const handleDelete = (e) => {
        deleteRole(e.currentTarget.name);
    };
    const handleSubmit = () => {
        addRole();
    };
    const handleChange = (e, data) => {
        setCurrentRole(data.value);
    };
    if (errorWindowMessage !== null)
        return React.createElement(ErrorModal, { ...errorWindowMessage, onClose: () => window.location.reload() });
    return React.createElement(React.Fragment, null,
        React.createElement(Table, { celled: true, striped: true, textAlign: "center", columns: "10" },
            React.createElement(Table.Header, null,
                React.createElement(Table.Row, null,
                    React.createElement(Table.HeaderCell, null),
                    React.createElement(Table.HeaderCell, null, t('settings.role')),
                    React.createElement(Table.HeaderCell, null))),
            React.createElement(Table.Body, null,
                roles.map((role) => {
                    if (role === 'ROLE_PLAIN')
                        return null;
                    if (role === '') {
                        return React.createElement(Table.Row, { key: "new" },
                            React.createElement(Table.Cell, { width: "1" }),
                            React.createElement(Table.Cell, { width: "8" },
                                React.createElement(Select, { placeholder: "Select role", onChange: handleChange, options: notAssignedRoles })),
                            React.createElement(Table.Cell, { width: "1" },
                                React.createElement(Button, { basic: true, positive: true, type: "submit", icon: "save", title: "Save", onClick: handleSubmit })));
                    }
                    return React.createElement(Table.Row, { key: role },
                        React.createElement(Table.Cell, { width: "1" },
                            React.createElement(StyledImg, { src: getImagePathByRole(role) })),
                        React.createElement(Table.Cell, { width: "8" }, t(`userRoles.${role}`)),
                        React.createElement(Table.Cell, { width: "1" },
                            React.createElement(Button, { basic: true, negative: true, icon: "trash alternate", name: role, title: "Delete", onClick: handleDelete })));
                }),
                (notAssignedRoles.length !== 0 && displayAddBnt) && React.createElement(Table.Row, null,
                    React.createElement(Table.Cell, { width: "1" }),
                    React.createElement(Table.Cell, { width: "8" }),
                    React.createElement(Table.Cell, { width: "1" },
                        React.createElement(Button, { basic: true, icon: "plus", onClick: handleAddButton, title: "Add" }))))),
        React.createElement(Message, { warning: true, content: t('settings.rolesLogoutMessage') }),
        React.createElement(Message, { error: true, content: t('settings.ndaRolesTable') }));
};
export default RolesTable;
