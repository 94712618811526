import { isClientError, isServerError, isAxiosError } from "./error-guards";
// IF cascade. this function can be used in try..catche in application
export const getUserErrorMessage = (e) => {
    var _a, _b;
    // first check if error is not an error (this is possible in JS)
    // e: unknown
    if (!(e instanceof Error)) {
        return { type: "UNEXPECTED_ERROR" };
    }
    // TypeError check
    // e: TypeError
    if (e instanceof TypeError) {
        return { type: "TYPE_ERROR", message: e.message };
    }
    if (e instanceof SyntaxError) {
        return { type: "SYNTAX_ERROR", message: e.message };
    }
    if (e instanceof ReferenceError) {
        return { type: "REFERENCE_ERROR", message: e.message };
    }
    if (e instanceof RangeError) {
        return { type: "RANGE_ERROR", message: e.message };
    }
    // everything else besides not axios (not HTTP)
    // e: Error
    if (!isAxiosError(e)) {
        return { type: "UNEXPECTED_ERROR" };
    }
    // 3 IF's based on error type (connection, client, server)
    // no internet connection
    // Axios Network Error (see https://github.com/axios/axios/issues/383)
    // thrown when Axios is unable to receive any response from the server, as a consequence both status and response are undefined
    if (!e.response) {
        return { type: "CONNECTION_FAILURE" };
    }
    // now we can handle HTTP errors because we already handled other "exotic" erros 
    // Axios error 4xx
    if (isClientError(e)) {
        return { type: "CLIENT_ERROR", code: e.response.status, url: (_a = e.response.config) === null || _a === void 0 ? void 0 : _a.url };
    }
    // Axios Error 5xx
    if (isServerError(e)) {
        return { type: "SYSTEM_FAILURE", code: e.response.status, url: (_b = e.response.config) === null || _b === void 0 ? void 0 : _b.url };
    }
    return { type: "UNEXPECTED_ERROR" };
};
