// `unknown` because we're not guaranteed we'd get specifically errors from the backend.
// Could be a network timeout and the structure will be different.
export const isAxiosError = (e) => {
    return (Boolean(e.isAxiosError)) && typeof e.config == "object";
};
export const hasErrorCode = (status, error) => { var _a; return isAxiosError(error) && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === status; };
export const isServerError = (e) => {
    if (!e.response) {
        return false;
    }
    const { status } = e.response;
    return status >= 500;
};
export const isClientError = (e) => {
    if (!e.response) {
        return false;
    }
    const { status } = e.response;
    return status >= 400 && status < 500;
};
