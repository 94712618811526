import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Form, Divider, Input, Button, Icon, Message } from 'semantic-ui-react';
import { Styled } from 'StyledComponents';
import { changePassword } from 'api-operations/auth';
import { toast } from 'react-toastify';
const StyledForm = styled(Form) `
  border: 0;
`;
const StyledButton = styled(Button) `
  width: 35%;
`;
const ButtonWrapper = styled.div `
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 40px 0 10px 0;
`;
const StyledDivider = styled(Divider) `
  padding-top: 1.8rem;

  &.ui.horizontal.divider:after {
    background-position: left 1em top 0;
   }

  &.ui.horizontal.divider:before {
    background-position: right 1em top 0;
  }

  @-moz-document url-prefix() {
   &.ui.horizontal.divider:after {
    background-position: left 1em top 50%;
   }

   &.ui.horizontal.divider:before {
    background-position: right 1em top 50%;
   }
  }
`;
const PasswordForm = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [responseStatus, SetResponseStatus] = useState('');
    const [passwordMessage, setPasswordMessage] = useState('');
    const { t } = useTranslation('common');
    const changeUserPassword = async () => {
        try {
            await changePassword(currentPassword, newPassword);
            setPasswordMessage(t('settings.validPassword'));
            SetResponseStatus('SUCCESS');
            setCurrentPassword('');
            setNewPassword('');
            setConfirmNewPassword('');
        }
        catch (error) {
            toast.error(t('notification.error'));
            setPasswordMessage(t('settings.invalidPassword'));
            SetResponseStatus('ERRROR');
            setCurrentPassword('');
            setNewPassword('');
            setConfirmNewPassword('');
        }
    };
    const handleUserPasswordSubmit = () => {
        if (currentPassword === '' || newPassword === '' || confirmNewPassword === '') {
            setPasswordMessage(t('settings.emptyFields'));
            return;
        }
        else if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\]{8,60}$/.test(newPassword)) {
            setPasswordMessage(t('settings.newPasswordDesc'));
            return;
        }
        else if (newPassword !== confirmNewPassword) {
            setPasswordMessage(t('settings.differentPassFields'));
            return;
        }
        changeUserPassword();
    };
    return React.createElement(StyledForm, { onSubmit: handleUserPasswordSubmit },
        React.createElement(StyledDivider, { horizontal: true },
            React.createElement(Styled.H2, null,
                React.createElement(Icon, { name: "key" }),
                t('settings.security'))),
        React.createElement(Form.Group, { widths: 4 },
            React.createElement(Form.Field, null,
                React.createElement("label", { htmlFor: "currentPassword" }, t('settings.currentPassword')),
                React.createElement(Input, { type: "password", id: "currentPassword", name: "currentPassword", value: currentPassword, onChange: (e, data) => setCurrentPassword(data.value) })),
            React.createElement(Form.Field, null,
                React.createElement("label", { htmlFor: "newPassword" }, t('settings.newPassword')),
                React.createElement(Input, { type: "password", id: "newPassword", name: "newPassword", value: newPassword, onChange: (e, data) => setNewPassword(data.value) })),
            React.createElement(Form.Field, null,
                React.createElement("label", { htmlFor: "ConfirmNewPassword" }, t('settings.newPasswordConfirmation')),
                React.createElement(Input, { type: "password", id: "ConfirmNewPassword", name: "ConfirmNewPassword", value: confirmNewPassword, onChange: (e, data) => setConfirmNewPassword(data.value) }))),
        passwordMessage !== '' && React.createElement(Message, { className: responseStatus === 'SUCCESS' ? 'positive' : 'negative', content: passwordMessage }),
        React.createElement(ButtonWrapper, null,
            React.createElement(StyledButton, { primary: true, type: "submit", content: t('settings.changePassword') })));
};
export default PasswordForm;
