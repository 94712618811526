// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "xfl-new-editor-source-file-panel{box-sizing:border-box;display:flex;flex-direction:column;flex:1 1 auto;overflow-y:auto;padding:1rem;gap:1rem;max-width:40vw}xfl-new-editor-source-file-panel .page{box-shadow:.2rem .2rem .6rem rgba(0,0,0,.24)}xfl-new-editor-source-file-panel .bottomGuard{height:1rem}", "",{"version":3,"sources":["webpack://./src/editorNextGen/modules/sourceFilePreview/style.scss"],"names":[],"mappings":"AAAA,iCACE,qBAAA,CACA,YAAA,CACA,qBAAA,CACA,aAAA,CACA,eAAA,CACA,YAAA,CACA,QAAA,CACA,cAAA,CAEA,uCACE,4CAAA,CAGF,8CACE,WAAA","sourcesContent":["xfl-new-editor-source-file-panel {\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  flex: 1 1 auto;\n  overflow-y: auto;\n  padding: 1rem;\n  gap: 1rem;\n  max-width: 40vw;\n\n  .page {\n    box-shadow: .2rem .2rem .6rem rgba(0, 0, 0, 0.24);\n  }\n\n  .bottomGuard {\n    height: 1rem\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "page",
	"bottomGuard": "bottomGuard"
};
export default ___CSS_LOADER_EXPORT___;
