import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Message } from 'semantic-ui-react';
const StyledDiv = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
export const NdaNotification = () => {
    const { t } = useTranslation('common');
    return React.createElement(StyledDiv, null,
        React.createElement(Message, { error: true, size: "big", content: t('settings.ndaNotification') }));
};
export const OutOfOfficeNotification = () => {
    const { t } = useTranslation('common');
    return React.createElement(StyledDiv, null,
        React.createElement(Message, { warning: true, content: t('settings.outOfOfficeNotification') }));
};
