import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Segment } from 'semantic-ui-react';
import { getAssignmentsSummary, perspectiveToStageList } from 'api-operations/executor/executor';
const StyledSegment = styled(Segment) `
  min-height: 150px;
`;
const Summary = ({ perspective, paginationOptions }) => {
    const [assignmentsSummary, setAssignmentsSummary] = useState(null);
    const { t } = useTranslation();
    useEffect(() => {
        (async () => {
            try {
                const res = await getAssignmentsSummary(perspectiveToStageList(perspective), paginationOptions);
                setAssignmentsSummary(res);
            }
            catch (error) {
                toast.error(t('notification.error'));
            }
        })();
    }, []);
    return React.createElement("div", null,
        React.createElement("div", null,
            React.createElement("h3", null,
                t('pagination.filters'),
                ":"),
            !(paginationOptions.startDate || paginationOptions.endDate || paginationOptions.langFrom || paginationOptions.langTo || paginationOptions.search)
                && React.createElement("p", null, "None"),
            paginationOptions.startDate && React.createElement("p", null,
                t('pagination.startDate'),
                ": ",
                React.createElement("b", null,
                    paginationOptions.startDate,
                    " ")),
            paginationOptions.endDate && React.createElement("p", null,
                t('pagination.endDate'),
                ": ",
                React.createElement("b", null,
                    " ",
                    paginationOptions.endDate,
                    " ")),
            paginationOptions.langFrom && React.createElement("p", null,
                t('pagination.sourceLang'),
                ": ",
                React.createElement("b", null, t(`languages.${paginationOptions.langFrom}`))),
            paginationOptions.langTo && React.createElement("p", null,
                t('pagination.targetLang'),
                ": ",
                React.createElement("b", null, t(`languages.${paginationOptions.langTo}`))),
            paginationOptions.search && React.createElement("p", null,
                t('pagination.search'),
                ": ",
                React.createElement("b", null, paginationOptions.search))),
        React.createElement(StyledSegment, null, assignmentsSummary && React.createElement(React.Fragment, null,
            React.createElement("p", null,
                t('executor.summary.completedProjects'),
                " ",
                ' ',
                React.createElement("b", null, assignmentsSummary.completedProjects)),
            assignmentsSummary.averageScore !== null && React.createElement("p", null,
                t('executor.summary.averageScore'),
                ": ",
                ' ',
                React.createElement("b", null, assignmentsSummary.averageScore)),
            React.createElement("p", null,
                t('executor.summary.earnedLangCoin'),
                ": ",
                ' ',
                React.createElement("b", null, assignmentsSummary.earnedXfl)),
            React.createElement("p", null,
                t('executor.summary.earnings'),
                ": ",
                ' ',
                React.createElement("b", null, assignmentsSummary.earnings.length && assignmentsSummary.earnings.map(({ fiat, price }) => `${fiat} ${price}`).join(' '))))));
};
export default Summary;
