import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Form, Input, Segment, TextArea } from 'semantic-ui-react';
import { getOptionsDropdownFromArray } from 'helpers/utils';
import { toast } from 'react-toastify';
import { getJobsInProgress, sendSupportTicket } from 'api-operations/system-support/system-support';
import moment from 'moment';
const Container = styled.div `
  margin: 0 auto;
  width: 100%;
  max-width: 50rem;
`;
const TagsWrapper = styled.div `
  margin: .5rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: .3em;
`;
const Tag = styled.span `
  user-select: none;
  cursor: pointer;
  text-transform: uppercase;
  font-size: .7em;
  font-weight: bold;
  padding: .3em;
  border-radius: .2em;
  background-color: ${({ selected }) => selected ? 'rgba(16, 62, 214, 1)' : 'rgba(16, 62, 214, 0.4)'};
  color: white;
`;
const ErrorMsg = styled.span `
  color: red;
`;
const AnswersBnt = styled(Button) `
  margin-bottom: 1.5rem !important;
`;
const availableTags = ['LOGIN', 'PROFILE', 'CHANGE_DEADLINE', 'EDITOR', 'TRUST_CHAIN', 'REPUTATION_BOARD', 'GATED_COMMUNITY', 'ANONYMIZATION', 'LANGUAGES', 'SKILLS', 'PRICE', 'PROJECT_FEE', 'INVOICE', 'PAYMENT', 'LANGCOIN', 'PROJECT_CORRECTION', 'PROJECT_DELAYED', 'CHANGE_REQUESTER', 'PROJECT_CANCELLATION', 'PROJECT_SUBMISSION', 'PROJECT_FEEDBACK', 'PROJECT_REDACTION', 'REFERENCE_FILE', 'DISPUTE', 'STYLE_GUIDE', 'TERMINOLOGY', 'STUDY_TITLES', 'OTHER'];
const types = ['QUESTIONS', 'TECH_ISSUE', 'PROBLEMS', 'IDEA', 'COMPLAINT', 'TASK'];
const NO_PARTICULAR_FILE_KEY = "No particular file";
const JOB_SOMETHING_ELSE_KEY = "Something else";
const SystemSupport = () => {
    var _a;
    const [type, setType] = useState(undefined);
    const [jobUUID, setJobUUID] = useState("");
    const [filename, setFilename] = useState(NO_PARTICULAR_FILE_KEY);
    const [subject, setSubject] = useState(undefined);
    const [tags, setTags] = useState([]);
    const [description, setDescription] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({ type: false, subject: false, tags: false, description: false });
    const [jobsInProgress, setJobsInProgress] = useState([]);
    const [filenamesForSelectedJob, setFilenamesForSelectedJob] = useState([]);
    const { t } = useTranslation('common');
    const jobsReferenceToDropdownOptions = (jobs) => {
        function jobName(job) {
            return job.jobExternalId ? `${job.jobExternalId} - ${job.jobName}` : job.jobName;
        }
        const jobsForDropdown = sortJobsInProgress(jobs).map(job => ({ value: job.jobUUID, key: job.jobUUID, text: jobName(job) }));
        jobsForDropdown.unshift({ key: JOB_SOMETHING_ELSE_KEY, value: JOB_SOMETHING_ELSE_KEY, text: JOB_SOMETHING_ELSE_KEY });
        return jobsForDropdown;
    };
    const sortJobsInProgress = (jobs) => {
        return jobs.sort((j1, j2) => moment(j2.jobCreationDate).diff(moment(j1.jobCreationDate)));
    };
    const jobFilesToDropdownOptions = (jobs) => {
        const filenames = jobs.map(jobFile => ({ value: jobFile, key: jobFile, text: jobFile }));
        filenames.unshift({ key: NO_PARTICULAR_FILE_KEY, value: NO_PARTICULAR_FILE_KEY, text: NO_PARTICULAR_FILE_KEY });
        return filenames;
    };
    useEffect(() => {
        setErrors(prevState => ({ ...prevState, type: false }));
    }, [type]);
    useEffect(() => {
        setErrors(prevState => ({ ...prevState, tags: false }));
    }, [tags]);
    useEffect(() => {
        setErrors(prevState => ({ ...prevState, subject: false }));
    }, [subject]);
    useEffect(() => {
        setErrors(prevState => ({ ...prevState, description: false }));
    }, [description]);
    useEffect(() => {
        getJobsInProgress().then((jobs) => {
            setJobsInProgress(jobs);
            setJobUUID(defaultJobSelected(jobs));
        });
    }, []);
    useEffect(() => {
        const newJob = jobsInProgress.find(job => job.jobUUID === jobUUID);
        setFilenamesForSelectedJob((newJob === null || newJob === void 0 ? void 0 : newJob.jobFiles) || []);
        setFilename(NO_PARTICULAR_FILE_KEY);
    }, [jobUUID]);
    const defaultJobSelected = (jobs) => {
        const sortedJobs = sortJobsInProgress(jobs);
        return sortedJobs.length > 0 ? sortedJobs[0].jobUUID : JOB_SOMETHING_ELSE_KEY;
    };
    const handleTagsChange = (newTag) => {
        if (tags.includes(newTag))
            setTags([]);
        else
            setTags([newTag]);
    };
    const generateDashboardUrlForJob = (jobUUID) => `${process.env.DASHBOARDS_URL}/#/jobs/${jobUUID}/show`;
    const prepareJobReferenceForTicket = () => {
        const selectedJob = jobsInProgress.find(job => job.jobUUID === jobUUID);
        if (!selectedJob)
            return undefined;
        const jobReference = {
            jobUUID,
            filename,
            jobExternalId: selectedJob.jobExternalId,
            jobName: selectedJob.jobName,
            userRoleOrStage: selectedJob.userRoleOrStage,
            jobDashboardUrl: generateDashboardUrlForJob(selectedJob.jobUUID),
            languageCombination: selectedJob.languageCombination
        };
        return jobReference;
    };
    const handleSubmit = async () => {
        var _a;
        let newErrors = { type: false, subject: false, tags: false, description: false };
        if (!type) {
            newErrors = ({ ...newErrors, type: true });
        }
        if (tags.length === 0) {
            newErrors = ({ ...newErrors, tags: true });
        }
        if (!subject) {
            newErrors = ({ ...newErrors, subject: true });
        }
        if (!description) {
            newErrors = ({ ...newErrors, description: true });
        }
        setErrors(newErrors);
        if (Object.values(newErrors).some(e => e))
            return;
        try {
            setIsLoading(true);
            const ticket = {
                browserInfo: navigator.userAgent, description: description,
                tags, title: subject, type: type, version: (_a = process.env.EXFLUENCY_VERSION) !== null && _a !== void 0 ? _a : 'devel',
                jobReference: prepareJobReferenceForTicket()
            };
            await sendSupportTicket(ticket);
            setDescription('');
            setTags([]);
            setSubject('');
            setType(''); // can't set undefined cause visual value won't change
            setJobUUID(defaultJobSelected(jobsInProgress));
            toast.success(t('notification.supportTicketSend'));
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
        setIsLoading(false);
    };
    return React.createElement(Container, null,
        React.createElement("h2", null, t('systemSupport.header')),
        React.createElement("h4", null, t('systemSupport.frequentQuestionsHeader')),
        React.createElement(AnswersBnt, null,
            React.createElement("a", { href: process.env.KNOWLEDGE_URL || 'https://exfluencyecosystem.zendesk.com', rel: 'noreferrer', target: '_blank' }, t('systemSupport.frequentQuestionsButton'))),
        React.createElement(Segment, null,
            React.createElement("h3", null, t('systemSupport.unansweredHeader')),
            React.createElement(Form, { onSubmit: handleSubmit, loading: isLoading },
                React.createElement(Form.Field, null,
                    React.createElement("label", { htmlFor: "type" }, t('systemSupport.type')),
                    React.createElement(Dropdown, { selection: true, placeholder: t('systemSupport.typePlaceholder'), id: "type", options: getOptionsDropdownFromArray(types, 'systemSupport.ticketType'), onChange: (_, { value }) => setType(value), value: type }),
                    errors.type && React.createElement(ErrorMsg, null, t('systemSupport.typeError'))),
                React.createElement(Form.Field, null,
                    React.createElement("label", { htmlFor: "job" }, t('systemSupport.project')),
                    React.createElement(Dropdown, { search: true, selection: true, placeholder: t('systemSupport.jobPlaceholder'), id: "job", options: jobsReferenceToDropdownOptions(jobsInProgress), onChange: (_, { value }) => setJobUUID(value), value: jobUUID })),
                React.createElement(Form.Field, null,
                    React.createElement("label", { htmlFor: "filename" }, t('systemSupport.file')),
                    React.createElement(Dropdown, { search: true, selection: true, placeholder: t('systemSupport.filenamePlaceholder'), id: "filename", options: jobFilesToDropdownOptions(filenamesForSelectedJob), onChange: (_, { value }) => setFilename(value), value: filename })),
                React.createElement(Form.Field, null,
                    React.createElement("label", null, t('systemSupport.tags')),
                    React.createElement(TagsWrapper, null, availableTags.map(tag => React.createElement(Tag, { key: tag, selected: tags.includes(tag), onClick: () => handleTagsChange(tag) }, t(`systemSupport.ticketTag.${tag}`)))),
                    errors.tags && React.createElement(ErrorMsg, null, t('systemSupport.tagsError'))),
                React.createElement(Form.Field, null,
                    React.createElement("label", { htmlFor: "subject" }, t('systemSupport.subject')),
                    React.createElement(Input, { id: "subject", value: subject, onChange: (_, { value }) => setSubject(value) }),
                    errors.subject && React.createElement(ErrorMsg, null, t('systemSupport.subjectError'))),
                React.createElement(Form.Field, null,
                    React.createElement("label", { htmlFor: "description" }, t('systemSupport.description')),
                    React.createElement(TextArea, { id: "description", rows: 10, value: description, onChange: (_, { value }) => setDescription(value) }),
                    errors.description && React.createElement(ErrorMsg, null, t('systemSupport.descriptionError'))),
                React.createElement(Button, { primary: true, type: "submit", content: t('systemSupport.sendTicket') }))),
        React.createElement("span", null,
            t('signin.version'),
            " ", (_a = process.env.EXFLUENCY_VERSION) !== null && _a !== void 0 ? _a : 'devel'));
};
export default SystemSupport;
