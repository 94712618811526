import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Icon, Loader, Popup, Table } from "semantic-ui-react";
import { getPublicProfile } from "api-operations/myExfluency/myExfluency";
const PublicProfile = ({ showRoles = true, userName, hideLinkedin }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [publicProfile, setPublicProfile] = useState(null);
    const { t } = useTranslation('common');
    const getPublicProfileOfUser = async () => {
        if (publicProfile === null) {
            try {
                const res = await getPublicProfile(userName);
                setPublicProfile(res);
                setIsLoading(false);
            }
            catch (error) {
                // TODO
            }
        }
    };
    return React.createElement(Popup, { flowing: true, hoverable: true, basic: true, position: "bottom center", key: userName, trigger: (React.createElement("sup", null,
            React.createElement(Icon, { link: true, name: 'help', circular: true, size: 'tiny', onMouseOver: getPublicProfileOfUser }))) },
        React.createElement(Popup.Content, null, (isLoading || publicProfile === null) ? React.createElement(Loader, null)
            : React.createElement(React.Fragment, null,
                React.createElement(Table, { basic: "very", collapsing: true, compact: "very" },
                    React.createElement(Table.Body, null,
                        React.createElement(Table.Row, null,
                            React.createElement(Table.Cell, null, t(`publicProfile.name`)),
                            React.createElement(Table.Cell, null, publicProfile.name)),
                        React.createElement(Table.Row, null,
                            React.createElement(Table.Cell, null, t(`publicProfile.surname`)),
                            React.createElement(Table.Cell, null, publicProfile.surname)),
                        !hideLinkedin && React.createElement(Table.Row, null,
                            React.createElement(Table.Cell, null, t(`publicProfile.linkedin`)),
                            React.createElement(Table.Cell, null, publicProfile.linkedin || "")),
                        showRoles
                            && React.createElement(Table.Row, null,
                                React.createElement(Table.Cell, null, t(`publicProfile.roles`)),
                                React.createElement(Table.Cell, null, publicProfile.roles.filter(r => r !== 'ROLE_PLAIN').map((role) => React.createElement("p", { key: role }, t(`userRoles.${role}`))))))))));
};
export default PublicProfile;
