import React, { useState, useEffect, useRef } from 'react';
import { Grid, Pagination, Select } from 'semantic-ui-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import SelectMemo from 'helpers/SelectMemo';
import { getDashboardOptionsFromStorage, rowsOptions } from 'helpers/utils';
const PaginationWrapper = styled.div `
  text-align: center;
  padding: 20px 0;
`;
const InputsWrapper = styled.div `
  display: flex;
  justify-content: space-between;
`;
const SelectWrapper = styled.div `
  width: 48%;
  display: flex;
  flex-direction: column;
`;
const StyledGrid = styled(Grid) `
  margin: 0 0 10px 0 !important;

  &&&& { 
  .row .column {
    padding-left: 0 !important;
  }

  .row:not(:first-child) {
    padding-top: 0 !important;
  }

  .row .column:last-child {
    padding-right: 0 !important;
  }
}
`;
const PagedTerminologiesXflView = ({ children, totalResults, fetchData, tableIdentifier }) => {
    const { t } = useTranslation('common');
    const [langTo, setLangTo] = useState(undefined);
    const [langFrom, setLangFrom] = useState(undefined);
    const [limit, setLimit] = useState(getDashboardOptionsFromStorage(tableIdentifier, 'limit', rowsOptions));
    const activePage = useRef(1);
    useEffect(() => {
        localStorage.setItem(`${tableIdentifier}PaginationOptions`, JSON.stringify({ limit }));
    }, [limit]);
    useEffect(() => {
        activePage.current = 1;
        handlePageChange();
    }, [limit, langFrom, langTo, activePage, fetchData]);
    const handlePageChange = (active) => {
        const offset = (active ? active : activePage.current) * limit - limit;
        fetchData && fetchData({ limit, offset, langTo, langFrom });
        activePage.current = active ? active : activePage.current;
    };
    const totalPages = Math.ceil(totalResults / limit);
    return React.createElement(React.Fragment, null,
        React.createElement(StyledGrid, { verticalAlign: 'bottom', stackable: true, padded: true },
            React.createElement(Grid.Row, { columns: 16 },
                React.createElement(Grid.Column, { width: 1 },
                    React.createElement("label", null, t('pagination.rowsPerPage')),
                    React.createElement(Select, { name: 'rowsPerPage', onChange: (_, data) => { setLimit(data.value); }, options: rowsOptions, value: limit, fluid: true })),
                React.createElement(Grid.Column, { width: 8 },
                    React.createElement(InputsWrapper, null,
                        React.createElement(SelectWrapper, null,
                            React.createElement(SelectMemo, { label: 'language_from', search: true, option: 'languages', setValue: setLangFrom, value: langFrom, clearable: true })),
                        React.createElement(SelectWrapper, null,
                            React.createElement(SelectMemo, { label: 'language_to', search: true, option: 'languages', setValue: setLangTo, value: langTo, clearable: true })))))),
        children,
        totalResults > 0 && React.createElement(PaginationWrapper, null,
            React.createElement(Pagination, { totalPages: totalPages, activePage: activePage.current, onPageChange: (_, data) => handlePageChange(data.activePage) })));
};
export default PagedTerminologiesXflView;
