import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Table, Rating, Icon, Button } from 'semantic-ui-react';
import { getAssignmentSections } from 'api-operations/requester/job';
import JobAssignmentInfo from 'dashboards/shared/JobAssignmentInfo';
import PriceWithExchangeRatePopup from 'dashboards/shared/PriceWithExchangeRatePopup';
import Avatar from 'helpers/components/Avatar';
import { parseTimestamp } from 'helpers/DateHandler';
import { showDateCountdown } from 'helpers/utils';
import store from 'reduxStore/store';
import UnfinishedActionButtonsInner from './UnfinishedActionButtonsInner';
import { useLogger } from 'logger/Logger';
import ExpandedSectionTableRow from 'common/ExpandedSectionTableRow';
const ActionButtonsWrapper = styled.div `
  display: flex;
  flex-direction: row;
`;
const JobOverviewRowInnerSection = ({ job, assignment, stage, status }) => {
    const { exchangeRate } = store.getState().userProfile;
    const { t } = useTranslation('common');
    const { logger } = useLogger();
    const [isExpanded, setIsExpanded] = useState(false);
    const [sections, setSections] = useState([]);
    const fetchSectionData = async (jobId, assignmentId) => {
        try {
            const response = await getAssignmentSections(jobId, assignmentId);
            setSections(response);
        }
        catch (error) {
            logger.error('System - Failed to fetch sections', error);
        }
    };
    return React.createElement(React.Fragment, null,
        React.createElement(Table.Row, { key: assignment.id, positive: assignment.status == 'FINISHED' && moment(assignment.deliveryDate).isBefore(assignment.dueDate), warning: assignment.status == 'FINISHED' && (moment(assignment.deliveryDate).isAfter(assignment.dueDate) || (assignment.deliveryDate ? false : moment(assignment.dueDate).isBefore())), disabled: assignment.status == 'SUPERSEDED', error: assignment.status == 'REJECTED' || assignment.status == 'CANCELLED' },
            React.createElement(Table.Cell, null,
                React.createElement(Button, { size: "mini", icon: isExpanded ? "angle up" : "angle down", onClick: () => { setIsExpanded(prevState => !prevState); fetchSectionData(job.id, assignment.id); } })),
            React.createElement(Table.Cell, null,
                React.createElement(Avatar, { name: assignment.executor, inlineAvatar: true }),
                " ",
                assignment.executor,
                assignment.executorPreferred && React.createElement(Rating, { disabled: true, icon: 'star', maxRating: 1, rating: 1 }),
                " "),
            React.createElement(Table.Cell, null,
                Math.floor(assignment.progress * 100),
                "%"),
            " ",
            status === 'DELIVERED' && React.createElement(Table.Cell, null, assignment.deliveryDate && parseTimestamp(assignment.deliveryDate)),
            " ",
            status === 'IN_PROGRESS' && React.createElement(Table.Cell, null,
                showDateCountdown(assignment.dueDate, assignment.deliveryDate),
                moment(assignment.dueDate).isBefore(assignment.deliveryDate) && React.createElement(Icon, { name: "attention" })),
            " ",
            React.createElement(Table.Cell, null, t(`dashboard.${stage.stageType}`)),
            " ",
            React.createElement(Table.Cell, null,
                React.createElement(PriceWithExchangeRatePopup, { cost: assignment.price, customCurrencyCost: assignment.userCurrencyPrice, exchangeRate: exchangeRate })),
            " ",
            React.createElement(Table.Cell, null, t(`assignmentStatus.${assignment.status}`)),
            " ",
            React.createElement(Table.Cell, null,
                React.createElement(ActionButtonsWrapper, null,
                    status == 'IN_PROGRESS' && React.createElement(UnfinishedActionButtonsInner, { assignment: assignment, stage: stage }),
                    React.createElement(JobAssignmentInfo, { assignment: assignment })))),
        isExpanded && React.createElement(ExpandedSectionTableRow, { sections: sections }));
};
export default JobOverviewRowInnerSection;
