import React from "react";
import styled from "styled-components";
import { Button } from 'semantic-ui-react';
import { formatFileSize } from "helpers/utils";
const Content = styled.div `
  display: flex;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #EEEEEE;
`;
const Details = styled.div `
  margin: 0 10px;
  p {
    margin: 0;
    margin-bottom: 5px;
  }
`;
const Item = ({ file, onFileRemoved }) => {
    return React.createElement(Content, null,
        React.createElement(Details, null,
            React.createElement("p", null, file.name),
            React.createElement("p", { className: "hint" }, formatFileSize(file.size))),
        React.createElement(Button, { icon: 'remove', onClick: () => onFileRemoved(), style: { zIndex: 1000 }, size: "small" }));
};
export default Item;
