import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Loader, Table } from "semantic-ui-react";
import { useParams } from 'react-router';
import { getCommunityOverview } from "api-operations/community/admin";
const CommunityOverview = () => {
    const [communityOverview, setCommunityOverview] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const { t } = useTranslation('common');
    const params = useParams();
    useEffect(() => {
        (async () => {
            try {
                const res = await getCommunityOverview(params.id);
                setCommunityOverview(res);
            }
            catch (error) {
                // TODO
            }
            setIsLoading(false);
        })();
    }, []);
    if (isLoading)
        return React.createElement(Loader, null);
    return React.createElement(React.Fragment, null,
        React.createElement(Table, { celled: true },
            React.createElement(Table.Header, null,
                React.createElement(Table.Row, null,
                    React.createElement(Table.HeaderCell, null, t(`gcOverview.enhancedByRequester`)),
                    React.createElement(Table.HeaderCell, null, t(`gcOverview.allAssets`)),
                    React.createElement(Table.HeaderCell, null, t(`gcOverview.enhancedAssets`)),
                    React.createElement(Table.HeaderCell, null, t(`gcOverview.trustMinedAssets`)))),
            React.createElement(Table.Body, null, communityOverview !== null && React.createElement(Table.Row, null,
                React.createElement(Table.Cell, null, communityOverview === null || communityOverview === void 0 ? void 0 : communityOverview.assetsEnhancedByReq),
                React.createElement(Table.Cell, null, communityOverview === null || communityOverview === void 0 ? void 0 : communityOverview.numOfAllAssets),
                React.createElement(Table.Cell, null, communityOverview === null || communityOverview === void 0 ? void 0 : communityOverview.numOfEnhancedAssets),
                React.createElement(Table.Cell, null, communityOverview === null || communityOverview === void 0 ? void 0 : communityOverview.numOfTrustMinedAssets)))),
        React.createElement("h2", null, t(`gcOverview.assetsGroupedBy`)),
        React.createElement(Table, { celled: true },
            React.createElement(Table.Header, null,
                React.createElement(Table.Row, null,
                    React.createElement(Table.HeaderCell, null, t(`gcOverview.login`)),
                    React.createElement(Table.HeaderCell, null, t(`gcOverview.numOfAssetsByReq`)))),
            React.createElement(Table.Body, null, communityOverview !== null
                && (communityOverview === null || communityOverview === void 0 ? void 0 : communityOverview.assetsWithRequester.map(({ assetCount, requesterLogin }) => React.createElement(Table.Row, { key: requesterLogin },
                    React.createElement(Table.Cell, null, requesterLogin),
                    React.createElement(Table.Cell, null, assetCount)))))));
};
export default CommunityOverview;
