/* eslint-disable complexity */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Input, Checkbox, Segment, Dropdown, Divider, Header, Button, Message } from 'semantic-ui-react';
import TemplateDropdown from 'helpers/components/TemplateDropdown';
import SelectMemo from 'helpers/SelectMemo';
import TaxonomySelector from 'helpers/components/taxonomySelector/TaxonomySelector';
import StageSelect from 'dashboards/buyer/buyerDashboard/UploadJob/StageSelect';
import { getJobTemplate, editTemplateName, editTemplate, createJobTemplate, deleteJobTemplate } from 'api-operations/requester/template';
import { toast } from 'react-toastify';
import { hasErrorCode } from 'errors/error-guards';
import { useLogger } from 'logger/Logger';
const SkillsetNode = styled.div `
  display: inline-block;
    
  & > div {
    width: 20rem;
    align-self: center;
    padding: .3em;
    border: .1em solid $exfluency-blue;
    border-radius: .2em;
  }
`;
const GroupCheckbox = styled.div `
  display: inline-block;
  margin-top: .5rem;
  padding-left: .5rem;
`;
const CheckboxWrapper = styled.div `
  display: inline;
  padding-left: .5rem;
`;
const Options = styled.div `
  display: flex;
  justify-content: space-between;
`;
const AddBtnWrapper = styled.div `
  display: flex;
  justify-content: center;
`;
const StyledSegment = styled(Segment) `
  min-height: ${props => props.height ? '400px' : '350px'};
`;
const StyledRow = styled.div `
  margin-bottom: 14px;
`;
const StyledLabel = styled.label `
  display: inline-block;
  width: ${props => props.editMode ? '110px' : 'auto'};
  padding-right: ${props => props.editMode ? '0' : '5px'};
`;
const StyledDropdown = styled(Dropdown) `
  max-width: 17rem;
  display: inline-block !important;
`;
const Manager = ({ allCommunities, editOnly = false, showTemplateName = true, fieldsValues, getAllFieldsValues = () => { } }) => {
    const [jobTemplateId, setJobTemplateId] = useState('');
    const [jobTemplate, setJobTemplate] = useState(null);
    const [editMode, setEditMode] = useState(editOnly);
    const [jobTemplateToRemove, setJobTemplateToRemove] = useState(null);
    const [jobTemplateName, setJobTemplateName] = useState('');
    const [communities, setCommunities] = useState((fieldsValues === null || fieldsValues === void 0 ? void 0 : fieldsValues.communities) || []);
    const [isOpenCommunity, setIsOpenCommunity] = useState((fieldsValues === null || fieldsValues === void 0 ? void 0 : fieldsValues.isOpenCommunity) || false);
    const [genre, setGenre] = useState((fieldsValues === null || fieldsValues === void 0 ? void 0 : fieldsValues.genre) || '');
    const [langFrom, setLangFrom] = useState((fieldsValues === null || fieldsValues === void 0 ? void 0 : fieldsValues.langFrom) || '');
    const [langsTo, setLangsTo] = useState((fieldsValues === null || fieldsValues === void 0 ? void 0 : fieldsValues.langsTo) || []);
    const [taxonomy, setTaxonomy] = useState((fieldsValues === null || fieldsValues === void 0 ? void 0 : fieldsValues.taxonomy) || '');
    const [stages, setStages] = useState(new Set((fieldsValues === null || fieldsValues === void 0 ? void 0 : fieldsValues.stages) || ['RECYCLING']));
    const [manualStages, setManualStages] = useState(new Set((fieldsValues === null || fieldsValues === void 0 ? void 0 : fieldsValues.manualStages) || ['REDACTION', 'ENHANCEMENT', 'TRUST_MINING', 'TRUST_MINING_2']));
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const { t } = useTranslation('common');
    const { logger } = useLogger();
    useEffect(() => {
        if (jobTemplateId !== '') {
            (async () => {
                try {
                    const response = await getJobTemplate(jobTemplateId);
                    setJobTemplate(response);
                    setJobTemplateName(response.name);
                    const { gatedCommunities, genre, langFrom, langTo, langsTo, openCommunity, stages, taxonomy, manualStages } = response.template;
                    let tmpStages = stages;
                    let tmpManualStages = manualStages;
                    setCommunities(gatedCommunities);
                    setIsOpenCommunity(openCommunity);
                    setGenre(genre);
                    setLangFrom(langFrom);
                    setLangsTo(langsTo || [langTo]);
                    if (tmpStages.find(stage => stage === 'REDACTION')) {
                        tmpStages = tmpStages.filter(stage => stage !== 'REDACTION' && stage !== 'OCR');
                        tmpStages = [...tmpStages, 'OCR', 'REDACTION'];
                    }
                    setStages(new Set(tmpStages));
                    setTaxonomy(taxonomy);
                    setManualStages(new Set(tmpManualStages));
                }
                catch (error) {
                    logger.error('System - Failed to get job templates', error);
                    toast.error(t('notification.error'));
                }
            })();
        }
    }, [jobTemplateId]);
    useEffect(() => {
        getAllFieldsValues(genre, langFrom, langsTo, taxonomy, stages, manualStages, communities, isOpenCommunity);
    }, [genre, langFrom, langsTo, taxonomy, stages, manualStages, isOpenCommunity, communities]);
    const updateGroups = (section, id, name, checked) => {
        if (checked == undefined)
            return;
        switch (section) {
            case 'open':
                setIsOpenCommunity(() => checked);
                setCommunities([]);
                break;
            case 'gc':
                if (checked) {
                    setCommunities(prevState => [...prevState, { id: id, name: name }]);
                }
                else {
                    setCommunities(prevState => prevState.filter(e => e.id !== id));
                }
                break;
        }
    };
    const verifyIfFilled = () => {
        // eslint-disable-next-line no-useless-escape
        if (jobTemplateName === '' || (!/^[A-Za-z0-9!@#\>\<\$%\^\&*\:\ )\(+=._-]{2,60}$/.test(jobTemplateName))) {
            return { error: true, msg: t('jobUpload.errors.noTemplateName') };
        }
        if (langFrom === '' || langFrom === undefined || langsTo.length === 0) {
            return { error: true, msg: t('jobUpload.errors.noLanguages') };
        }
        if (langsTo.includes(langFrom)) {
            return { error: true, msg: t('jobUpload.errors.noLanguages') };
        }
        if (genre === '') {
            return { error: true, msg: t('jobUpload.errors.noGenre') };
        }
        if (taxonomy === null || taxonomy === undefined || taxonomy === '') {
            return { error: true, msg: t('jobUpload.errors.noTaxonomy') };
        }
        if (stages.size === 0) {
            return { error: true, msg: t('jobUpload.errors.noStages') };
        }
        if (!isOpenCommunity && communities.length == 0) {
            return { error: true, msg: t('jobUpload.errors.noGroups') };
        }
        return { error: false, msg: null };
    };
    const updateTemplate = async () => {
        const err = verifyIfFilled();
        if (err.error) {
            setIsError(true);
            setErrorMsg(err.msg);
            return;
        }
        if (jobTemplate.name !== jobTemplateName) {
            try {
                await editTemplateName(jobTemplateName, jobTemplateId);
                toast.success(t('notification.templateNameUpdated'));
                logger.info('User - Edited template name');
            }
            catch (error) {
                if (hasErrorCode(409, error)) {
                    toast.error(t('notification.templateExists'));
                    logger.error('User - Failed to edit template name because template with that name already exists', error);
                }
                else {
                    toast.error(t('notification.error'));
                    logger.error('User - Failed to edit template name', error);
                }
            }
        }
        const newTemplate = {
            gatedCommunities: communities,
            companies: [],
            openCommunity: isOpenCommunity,
            genre: genre,
            langFrom: langFrom,
            langsTo: langsTo,
            taxonomy: taxonomy,
            stages: Array.from(stages),
            manualStages: Array.from(manualStages)
        };
        try {
            await editTemplate(newTemplate, jobTemplateId);
            toast.success(t('notification.templateNameUpdated'));
            logger.info('User - Edited template');
        }
        catch (error) {
            toast.error(t('notification.error'));
            logger.error('User - Failed to edit template', error);
        }
        setJobTemplate(prevState => ({ ...prevState, name: jobTemplateName, template: newTemplate }));
    };
    const createTemplate = async () => {
        const err = verifyIfFilled();
        if (err.error) {
            setIsError(true);
            setErrorMsg(err.msg);
            return;
        }
        const template = {
            name: jobTemplateName,
            template: {
                genre: genre,
                langFrom: langFrom,
                langsTo: langsTo,
                openCommunity: isOpenCommunity,
                companies: [],
                gatedCommunities: communities,
                taxonomy: taxonomy,
                stages: Array.from(stages),
                manualStages: Array.from(manualStages)
            }
        };
        try {
            await createJobTemplate(template);
            logger.info('User - Created new template');
            toast.success(t('notification.templateCreated'));
        }
        catch (error) {
            if (hasErrorCode(409, error)) {
                toast.error(t('notification.templateExists'));
                logger.error('User - Failed to create template name because template with that name already exists', error);
            }
            else {
                toast.error(t('notification.error'));
                logger.error('User - Failed to create template', error);
            }
        }
        setCommunities([]);
        setIsOpenCommunity(false);
        setGenre('');
        setLangFrom('');
        setLangsTo([]);
        setStages(new Set(['RECYCLING']));
        setTaxonomy('');
        setManualStages(new Set());
        setJobTemplateName('');
        setIsError(false);
    };
    const deleteTemplate = async () => {
        try {
            await deleteJobTemplate(jobTemplateId);
            toast.success(t('notification.templateDeleted'));
            logger.info('User - Deleted template');
        }
        catch (error) {
            toast.error(t('notification.error'));
            logger.error('User - Failed to delete template', error);
            return;
        }
        setJobTemplateToRemove(jobTemplateId);
        setJobTemplateId('');
        setJobTemplate(null);
    };
    const genres = [
        { key: 'sports', value: 'sports', text: t('genre.sports'), image: { avatar: true, src: require(`assets/images/genres/exfluency_Sports.png`) } },
        { key: 'it', value: 'it', text: t('genre.it'), image: { avatar: true, src: require(`assets/images/genres/exfluency_IT.png`) } },
        { key: 'legal', value: 'legal', text: t('genre.legal'), image: { avatar: true, src: require(`assets/images/genres/exfluency_Legal.png`) } },
        { key: 'life_sciences', value: 'life_sciences', text: t('genre.life_sciences'), image: { avatar: true, src: require(`assets/images/genres/exfluency_LifeSciences.png`) } },
        { key: 'marketing', value: 'marketing', text: t('genre.marketing'), image: { avatar: true, src: require(`assets/images/genres/exfluency_Marketing.png`) } },
        { key: 'technical', value: 'technical', text: t('genre.technical'), image: { avatar: true, src: require(`assets/images/genres/exfluency_Technical.png`) } },
        { key: 'other', value: 'other', text: t('genre.other'), image: { avatar: true, src: require(`assets/images/genres/exfluency_Other.png`) } }
    ];
    return React.createElement("div", null,
        (!editOnly && showTemplateName) && React.createElement(Options, null,
            React.createElement(TemplateDropdown, { jobTemplateId: jobTemplateId, setJobTemplateId: setJobTemplateId, removeFromList: jobTemplateToRemove }),
            React.createElement(Checkbox, { toggle: true, label: t('jobTemplate.edit'), checked: editMode, onChange: (e, { checked }) => setEditMode(checked) })),
        React.createElement(StyledSegment, { height: showTemplateName }, (editOnly || fieldsValues || (jobTemplate !== null && jobTemplateId !== '')) && React.createElement(React.Fragment, null,
            showTemplateName ? !editMode
                ? React.createElement("h1", null, jobTemplateName)
                : React.createElement(StyledRow, null,
                    React.createElement(StyledLabel, { editMode: editMode },
                        t('jobTemplate.templateName'),
                        ":"),
                    React.createElement(Input, { value: jobTemplateName, onChange: (_, data) => setJobTemplateName(data.value) }),
                    !editOnly && editMode && React.createElement(Button, { basic: true, color: 'red', icon: "trash alternate outline", floated: 'right', onClick: () => deleteTemplate() }))
                : null,
            React.createElement(StyledRow, null,
                React.createElement(StyledLabel, { editMode: editMode },
                    t('enhancerFeedback.sourceLanguage'),
                    ":"),
                !editMode
                    ? React.createElement("b", null, t(`languages.${langFrom}`))
                    : React.createElement(SelectMemo, { search: true, value: langFrom, setValue: setLangFrom, option: 'languages', fluid: false })),
            React.createElement(StyledRow, null,
                React.createElement(StyledLabel, { editMode: editMode },
                    t('enhancerFeedback.targetLanguage'),
                    ":"),
                !editMode
                    ? React.createElement("b", null, langsTo.map(lang => t(`languages.${lang}`)).join(', '))
                    : React.createElement(SelectMemo, { search: true, multiple: true, value: langsTo, setValue: setLangsTo, option: 'languages', fluid: false })),
            React.createElement(StyledRow, null,
                React.createElement(StyledLabel, { editMode: editMode },
                    t('enhancerFeedback.genre'),
                    ":"),
                !editMode
                    ? React.createElement("b", null, t(`genre.${genre}`))
                    : React.createElement(StyledDropdown, { fluid: true, options: genres, placeholder: t('jobUpload.chooseGenre'), selection: true, value: genre, onChange: (_, data) => setGenre(data.value) })),
            React.createElement(StyledRow, null,
                React.createElement(StyledLabel, { editMode: editMode },
                    t('enhancerFeedback.taxonomyKeys'),
                    ":"),
                !editMode
                    ? React.createElement("b", null, taxonomy
                        .replace(new RegExp('Category:', 'g'), '')
                        .replace(new RegExp('IQVIA:', 'g'), ''))
                    : React.createElement(SkillsetNode, null,
                        React.createElement(TaxonomySelector, { value: taxonomy, onChange: (newTaxons) => setTaxonomy(newTaxons), dialogHeader: t('taxonomySelector.selectSubjectMatterNodes'), placeholder: t('taxonomySelector.selectSubjectMatterNodes') }))),
            React.createElement(StyledRow, null,
                t('jobUpload.stages'),
                ":",
                ' ',
                !editMode
                    ? React.createElement("b", null, Array.from(stages).filter(stage => stage !== 'OCR').map(stage => t(`stages.${stage}`)).reverse().join(', '))
                    : React.createElement(StageSelect, { isAnonEnabled: true, sourceLang: langFrom, manualStages: manualStages, setManualStages: setManualStages, stages: stages, setStages: setStages, compact: true })),
            React.createElement(Divider, { horizontal: true },
                React.createElement(Header, { as: 'h4' }, t('jobTemplate.visibility'))),
            React.createElement(StyledRow, null,
                t('jobUpload.gatedCommunities'),
                ":",
                ' ',
                !editMode
                    ? React.createElement("b", null, communities.map(({ name }) => name).join(', '))
                    : React.createElement(React.Fragment, null, communities !== null
                        && allCommunities.map(({ gatedCommunityName, gatedCommunityId }) => React.createElement(GroupCheckbox, { key: gatedCommunityId },
                            React.createElement(Checkbox, { disabled: isOpenCommunity, label: gatedCommunityName, styles: { marginTop: '.5rem' }, checked: communities.filter(e => e.id === gatedCommunityId).length > 0, onChange: (e, { checked }) => updateGroups('gc', gatedCommunityId, gatedCommunityName, checked) }))))),
            React.createElement(StyledRow, null,
                t('jobUpload.openCommunity'),
                ":",
                ' ',
                !editMode
                    ? React.createElement("b", null, isOpenCommunity ? 'Yes' : 'No')
                    : React.createElement(CheckboxWrapper, null,
                        React.createElement(Checkbox, { checked: isOpenCommunity, onChange: (e, { checked }) => updateGroups('open', null, null, checked) }))))),
        isError && React.createElement(Message, { negative: true },
            React.createElement("p", null, errorMsg)),
        editMode && jobTemplateId !== '' && React.createElement(AddBtnWrapper, null,
            React.createElement(Button, { onClick: updateTemplate, primary: true }, t('jobTemplate.save'))),
        showTemplateName && editOnly && React.createElement(AddBtnWrapper, null,
            React.createElement(Button, { onClick: createTemplate, primary: true }, t('jobTemplate.create'))));
};
export default Manager;
