/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Tab } from 'semantic-ui-react';
import Manager from './Manager';
import { getMyGatedComunities } from 'api-operations/community/member';
import { useLogger } from 'logger/Logger';
const CustomTabPaneA = styled.div `
  margin: 1rem 0;
`;
const CustomTabPaneB = styled.div ` // must be done in that way because of rendering
  margin: 1rem 0;
`;
const TemplateManager = () => {
    const [allCommunities, setAllCommunities] = useState([]);
    const { t } = useTranslation('common');
    const { logger } = useLogger();
    useEffect(() => {
        (async () => {
            await getMyGatedCommunitiesList();
        })();
    }, []);
    const getMyGatedCommunitiesList = async () => {
        try {
            const myGCommunities = await getMyGatedComunities(true);
            setAllCommunities(myGCommunities);
        }
        catch (error) {
            logger.error('System - Failed to get myGatedComunities', error);
        }
    };
    let panes = [
        {
            menuItem: t(`jobTemplate.manage`),
            render: () => React.createElement(CustomTabPaneA, null,
                React.createElement(Manager, { allCommunities: allCommunities }))
        },
        {
            menuItem: t(`jobTemplate.create`),
            render: () => React.createElement(CustomTabPaneB, null,
                React.createElement(Manager, { allCommunities: allCommunities, editOnly: true }))
        }
    ];
    return React.createElement(Tab, { panes: panes });
};
export default TemplateManager;
