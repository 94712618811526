import i18next from 'i18next';
import { errorIcon } from "editorNextGen/icons/error";
import { TerminologyPopup } from './TerminologyPopup';
class Column extends HTMLElement {
    constructor(textToUse, missing, dir, term, sourceLangCode, targetLangCode) {
        super();
        this.term = term;
        this.sourceLangCode = sourceLangCode;
        this.targetLangCode = targetLangCode;
        this.showDescription = textToUse === 'target';
        const contentText = document.createElement('span');
        contentText.dir = dir;
        contentText.innerText = textToUse === 'source' ? term.sourceText : term.targetText;
        contentText.classList.add('content-text');
        this.classList.add('column', 'column-content');
        this.dir = dir;
        this.appendChild(contentText);
        if (missing) {
            const icon = document.createElement('span');
            icon.className = 'error-icon';
            icon.innerHTML = errorIcon;
            icon.addEventListener("mouseenter", (e) => {
                this.getSimpleTooltip().showTooltip(icon, e, 'right');
                this.hideTooltip(e);
            });
            icon.addEventListener("mouseleave", (e) => {
                this.getSimpleTooltip().hide();
                this.showTooltip(e);
            });
            this.appendChild(icon);
        }
        this.addEventListener("mouseenter", this.showTooltip.bind(this));
        this.addEventListener("mouseleave", this.hideTooltip.bind(this));
    }
    getSimpleTooltip() {
        const simpleTooltip = document.querySelector("xfl-new-popup-ele");
        simpleTooltip.content = i18next.t('editorTerminologyPanel.missingTerm');
        return simpleTooltip;
    }
    getTooltip() {
        return document.querySelector("xfl-new-editor-tooltip");
    }
    hideTooltip(e) {
        const related = e.relatedTarget;
        const tooltip = document.querySelector('.editorTooltip');
        if (related !== tooltip)
            this.getTooltip().hideTooltip();
    }
    showTooltip(event) {
        this.getTooltip().showTooltip(this, this.getTooltipProps(event), event, true);
    }
    getTooltipProps(event) {
        return [{
                header: i18next.t(`editor.subAssets.${this.term.type == 'FORBIDDEN' ? this.term.type : this.term.creationSource}`),
                content: new TerminologyPopup(this.term, this.sourceLangCode, this.targetLangCode, this.showDescription)
            }];
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-terminology-panel-column", Column));
class ColumnHeader extends HTMLElement {
    constructor(text, langCode) {
        super();
        this.classList.add('column', 'column-header');
        this.innerText = text;
        const languageCode = document.createElement("span");
        languageCode.className = 'language-code';
        languageCode.innerText = langCode;
        this.appendChild(languageCode);
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-terminology-panel-column-header", ColumnHeader));
export default class TerminologyPanel extends HTMLElement {
    constructor(sourceLangCode, targetLangCode, sourceLanguageTextDirection, targetLanguageTextDirection) {
        super();
        this.termList = document.createElement("ul");
        this.sourceLanguageTextDirection = 'ltr';
        this.targetLanguageTextDirection = 'ltr';
        this._currentText = '';
        this.sourceLangCode = sourceLangCode;
        this.targetLangCode = targetLangCode;
        this.sourceLanguageTextDirection = sourceLanguageTextDirection;
        this.targetLanguageTextDirection = targetLanguageTextDirection;
        this.className = "widget-box";
        const header = document.createElement("h1");
        header.className = "widget-box--header";
        header.textContent = i18next.t('editorTerminologyPanel.termsToUse');
        const columnHeaders = document.createElement("span");
        columnHeaders.className = 'column-headers';
        columnHeaders.appendChild(new ColumnHeader('source', sourceLangCode));
        columnHeaders.appendChild(new ColumnHeader('target', targetLangCode));
        this.appendChild(header);
        this.appendChild(columnHeaders);
        this.appendChild(this.termList);
        const emptyState = document.createElement("div");
        emptyState.classList.add("emptyState");
        emptyState.textContent = i18next.t('editorTerminologyPanel.noTermsInThisAsset');
        this.appendChild(emptyState);
    }
    set currentText(text) {
        this._currentText = text;
    }
    set terms(terms) {
        this.termList.innerHTML = "";
        terms.forEach((term) => {
            const elem = document.createElement("li");
            const targetText = term.targetText.toLocaleLowerCase();
            const used = (this._currentText.match(new RegExp(this.escapeRegExp(targetText), 'g')) || []).length >= (term.count || 0);
            elem.appendChild(new Column('source', false, this.sourceLanguageTextDirection, term, this.sourceLangCode, this.targetLangCode));
            elem.appendChild(new Column('target', !used, this.targetLanguageTextDirection, term, this.sourceLangCode, this.targetLangCode));
            this.termList.appendChild(elem);
        });
    }
    escapeRegExp(text) {
        return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-terminology-panel", TerminologyPanel));
