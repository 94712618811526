export class TerminologyPopup extends HTMLElement {
    constructor(term, sourceLang, targetLang, showDescription = true) {
        super();
        const content = document.createElement('div');
        content.append(new TextWithLang(term.sourceText, sourceLang));
        content.append(new TextWithLang(term.targetText, targetLang));
        if (showDescription) {
            const description = document.createElement('div');
            description.className = 'term-description';
            description.innerText = term.description;
            content.append(description);
        }
        this.appendChild(content);
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-terminology-popup", TerminologyPopup));
class TextWithLang extends HTMLElement {
    constructor(text, langCode, dir = 'ltr') {
        super();
        this.dir = dir;
        const textSpan = document.createElement("span");
        textSpan.className = 'term-text';
        textSpan.innerText = text;
        textSpan.dir = dir;
        this.appendChild(textSpan);
        const languageCode = document.createElement("span");
        languageCode.className = 'term-language-code';
        languageCode.innerText = langCode;
        this.appendChild(languageCode);
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-terminology-popup-text", TextWithLang));
