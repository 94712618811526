import styled from 'styled-components';
export const CloseContainer = styled.div `
  background: #FFF8F8;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 4px 8px;
  cursor: pointer;
`;
export const Content = styled.div `
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 8px 16px 16px;
  gap: 8px;
  background: #FFF8F8;
`;
export const Header = styled.div `
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #42526E;
`;
export const Message = styled.div `
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #42526E;
`;
export const Buttons = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;
`;
export const Button = styled.button `
width: 100%;
  padding: 6px 12px;
  border-radius: 4px;
  color: ${({ variant }) => variant === 'secondary' ? '#42526E' : '#FFF'};
  background-color: ${({ variant }) => variant === 'secondary' ? '#ECEFFB' : '#3A65F6'};

  &:hover {
    background-color: ${({ variant }) => variant === 'secondary' ? '#DEE6FF' : '#254ED9'};
  }
`;
