import React from 'react';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
const ExpandedSectionTableRow = ({ sections }) => {
    const { t } = useTranslation('common');
    return React.createElement(Table.Row, null,
        React.createElement(Table.Cell, { colSpan: "11" },
            React.createElement(Table, { celled: true, selectable: true, striped: true },
                React.createElement(Table.Header, null,
                    React.createElement(Table.Row, null,
                        React.createElement(Table.HeaderCell, null, t('dashboard.fileName')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.totalAssets')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.completedAssets')))),
                React.createElement(Table.Body, null, sections.map(({ id, name, totalAssets, completedAssets }) => (React.createElement(Table.Row, { key: id },
                    React.createElement(Table.Cell, null, name),
                    React.createElement(Table.Cell, null, totalAssets),
                    React.createElement(Table.Cell, null, completedAssets))))))));
};
export default ExpandedSectionTableRow;
