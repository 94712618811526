import React, { useState, useEffect } from 'react';
import { Table, Button, Select } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { addGenre, deleteGenre } from 'api-operations/myExfluency/myExfluency';
import { genreDictionary } from 'dictionaries/dictionaries';
import { toast } from 'react-toastify';
const GenresTable = ({ userGenres }) => {
    const [genres, setGenres] = useState([...userGenres]);
    const [notAssignedgenres, setNotAssignedgenres] = useState([]);
    const [currentGenre, setCurrentGenre] = useState('');
    const [displayAddBnt, setDisplayAddBnt] = useState(true);
    const { t } = useTranslation('common');
    useEffect(() => {
        setNotAssignedgenres(restGenres());
    }, [genres]);
    const genresOptions = genreDictionary.map(e => ({ key: e, value: e, text: t(`genre.${e}`) }));
    const restGenres = () => {
        return genresOptions.filter(e => !genres.includes(e.key));
    };
    const addUserGenre = async () => {
        if (currentGenre === '' || null) {
            return;
        }
        try {
            await addGenre(currentGenre);
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
        const tmpGenres = genres.filter(e => e !== '');
        setGenres([...tmpGenres, currentGenre]);
        setDisplayAddBnt(true);
        setCurrentGenre('');
    };
    const deleteUserGenre = async (genreToDelete) => {
        try {
            await deleteGenre(genreToDelete);
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
        setGenres(genres.filter(e => e !== genreToDelete));
    };
    const handleAddButton = () => {
        setGenres([...genres, '']);
        setDisplayAddBnt(false);
    };
    const handleDelete = (e) => {
        deleteUserGenre(e.currentTarget.name);
    };
    const handleSubmit = () => {
        addUserGenre();
    };
    const handleChange = (_, data) => {
        setCurrentGenre(data.value);
    };
    return React.createElement(Table, { celled: true, striped: true, textAlign: "center", columns: "10" },
        React.createElement(Table.Header, null,
            React.createElement(Table.Row, null,
                React.createElement(Table.HeaderCell, null, t('settings.genre')),
                React.createElement(Table.HeaderCell, null))),
        React.createElement(Table.Body, null,
            genres.map((genre) => {
                if (genre === '') {
                    return React.createElement(Table.Row, { key: "new" },
                        React.createElement(Table.Cell, { width: "9" },
                            React.createElement(Select, { placeholder: "Select genre", onChange: handleChange, options: notAssignedgenres })),
                        React.createElement(Table.Cell, { width: "1" },
                            React.createElement(Button, { basic: true, positive: true, type: "submit", icon: "save", title: "Save", onClick: handleSubmit })));
                }
                return React.createElement(Table.Row, { key: genre },
                    React.createElement(Table.Cell, { width: "9" }, t(`genre.${genre}`)),
                    React.createElement(Table.Cell, { width: "1" },
                        React.createElement(Button, { basic: true, negative: true, icon: "trash alternate", name: genre, title: "Delete", onClick: handleDelete })));
            }),
            (notAssignedgenres.length !== 0 && displayAddBnt) && React.createElement(Table.Row, null,
                React.createElement(Table.Cell, { width: "9" }),
                React.createElement(Table.Cell, { width: "1" },
                    React.createElement(Button, { basic: true, icon: "plus", onClick: handleAddButton, title: "Add" })))));
};
export default GenresTable;
