import EditorModule from "../EditorModule";
export default class TokenRemovalModule extends EditorModule {
    constructor() {
        super(...arguments);
        this.moduleId = "TokenRemovalModule";
    }
    unload() {
        this.editor.content.querySelectorAll("xfl-new-editor-subasset i.remove")
            .forEach(i => i.remove());
    }
    subassetCreated(subAsset) {
        if (!["ANONYMIZED", "NO_TRANSLATION"].includes(subAsset.type)) {
            return;
        }
        const deleteButton = document.createElement("i");
        deleteButton.className = "small icon trash alternate outline action remove";
        deleteButton.addEventListener("click", event => this.editor.removeSubAsset(event, subAsset));
    }
}
