import React from 'react';
import { Button, Popup, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { parseTimestamp } from 'helpers/DateHandler';
import { Styled } from 'StyledComponents';
import { useTokenization } from 'providers/TokenizationProvider';
const JobInfo = ({ job }) => {
    const { t } = useTranslation('common');
    const { isTokenizationEnabled } = useTokenization();
    const sumCxfl = () => {
        let totalCxfl = 0;
        job.stages.filter(stage => stage.stageStatus == 'DONE' && stage.assignments.length).forEach((stage) => {
            stage.assignments.forEach(({ status, cxfl }) => {
                status == 'FINISHED' && (totalCxfl += cxfl || 0);
            });
        });
        return totalCxfl / 100;
    };
    return React.createElement(Popup, { basic: true, position: "top center", hoverable: true, on: ['hover', 'click'], trigger: React.createElement(Button, { icon: "info", "data-testid": 'info_btn' }), offset: [0, 0], flowing: true },
        React.createElement(Styled.VerticalPopupContent, null,
            React.createElement("h4", null, t(`dashboard.jobInfo`)),
            React.createElement(Table, { basic: "very", collapsing: true, compact: "very" },
                React.createElement(Table.Body, null,
                    React.createElement(Table.Row, null,
                        React.createElement(Table.Cell, null, t('dashboard.genre')),
                        React.createElement(Table.Cell, null, t(`genre.${job.genre}`))),
                    React.createElement(Table.Row, null,
                        React.createElement(Table.Cell, null, t('dashboard.taxonomy')),
                        React.createElement(Table.Cell, null, job.taxonomy.join(', '))),
                    React.createElement(Table.Row, null,
                        React.createElement(Table.Cell, null, t('dashboard.startDate')),
                        React.createElement(Table.Cell, null, parseTimestamp(job.uploadDate))),
                    React.createElement(Table.Row, null,
                        React.createElement(Table.Cell, null,
                            " ",
                            t('dashboard.dueDate')),
                        React.createElement(Table.Cell, null, parseTimestamp(job.dueDate))),
                    job.deliveryDate && React.createElement(Table.Row, null,
                        React.createElement(Table.Cell, null, t('dashboard.deliveryDate')),
                        React.createElement(Table.Cell, null, parseTimestamp(job.deliveryDate))),
                    isTokenizationEnabled && React.createElement(Table.Row, null,
                        React.createElement(Table.Cell, null, t('dashboard.earnedLangCoin')),
                        React.createElement(Table.Cell, null, sumCxfl())),
                    job.stages.map((stage) => React.createElement(Table.Row, { key: stage.id },
                        React.createElement(Table.Cell, null, t(`stages.${stage.stageName || stage.stageType}`)),
                        React.createElement(Table.Cell, null,
                            t(`stageStatus.${stage.stageStatus}`),
                            "  ",
                            t(`stageSelection.${stage.parameters.ExecutorSelection || 'auto'}`))))))));
};
export default JobInfo;
