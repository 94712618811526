import IconButton from "editorNextGen/components/IconButton";
import SourceFilePanel from "./SourceFilePanel";
import i18next from "i18next";
import EditorModule from "../EditorModule";
import "./style.scss";
import { IconNgBuilder } from "editorNextGen/icons";
import { documentSvg } from "editorNextGen/icons/document";
export default class SourceFilePreviewModule extends EditorModule {
    constructor(editor) {
        var _a;
        super(editor);
        this.moduleId = "SourceFilePreviewModule";
        this.sourceFilePanel = new SourceFilePanel();
        this.showSourceFileButton = new IconButton("file pdf outline", i18next.t("editorHeader.showOriginalFile"));
        this.button = document.createElement('div');
        new IconNgBuilder(documentSvg, this.button)
            .setOnClick(() => { this.showSourceFile = !this.showSourceFile; })
            .setToggable(true)
            .isActive(true)
            .build();
        this.editor.sectionHeader.toolBar.appendChild(this.button);
        this.editor.leftSidePanel.appendChild(this.sourceFilePanel);
        this.sourceFilePanel.assignmentId = this.editor.assignmentId;
        this.sourceFilePanel.sectionId = ((_a = this.editor.section) === null || _a === void 0 ? void 0 : _a.id) || '';
        this.showSourceFile = true;
    }
    unload() {
        this.button.remove();
        this.sourceFilePanel.remove();
    }
    assetActivationChanged(asset, active) { }
    get showSourceFile() {
        return this.sourceFilePanel.style.display == "";
    }
    set showSourceFile(showSourceFile) {
        this.sourceFilePanel.style.display = showSourceFile ? "" : "none";
    }
}
