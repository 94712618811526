import React, { useState, useEffect } from 'react';
import { Container } from 'semantic-ui-react';
import { getMyGenres } from 'api-operations/myExfluency/myExfluency';
import GenresTable from './GenresTable';
import ExfluencyLoader from 'helpers/components/loader/ExfluencyLoader';
const MyGenres = () => {
    const [userGenres, setUserGenres] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchData = async () => {
        try {
            const res = await getMyGenres();
            setUserGenres(res);
        }
        catch (error) {
            return;
        }
        setLoading(false);
    };
    useEffect(() => {
        fetchData();
    }, []);
    if (loading)
        return React.createElement(ExfluencyLoader, null);
    return React.createElement(Container, null,
        React.createElement(GenresTable, { userGenres: userGenres }));
};
export default MyGenres;
