import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Form, Button, Modal } from 'semantic-ui-react';
import { addPaypalPaymentMethod } from 'api-operations/company/admin';
import { toast } from 'react-toastify';
import { emailRegex } from 'helpers/utils';
const PaypalPayment = ({ open, setOpen }) => {
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const { t } = useTranslation('common');
    const params = useParams();
    const handleSubmit = async () => {
        if (!emailRegex.test(email)) {
            setIsEmailValid(false);
            return;
        }
        setIsEmailValid(true);
        try {
            await addPaypalPaymentMethod(params.id, { paypalUser: email });
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
        setEmail('');
        setOpen(null);
    };
    return React.createElement(Modal, { open: open },
        React.createElement(Modal.Content, null,
            React.createElement(Form, null,
                React.createElement(Form.Input, { value: email, onChange: (_, { value }) => setEmail(value), required: true, label: t('companies.payment.paypalEmail'), error: !isEmailValid && t('companies.emailValid') }))),
        React.createElement(Modal.Actions, null,
            React.createElement(Button, { onClick: () => setOpen(null) }, t('companies.payment.cancel')),
            React.createElement(Button, { primary: true, onClick: () => handleSubmit() }, t('companies.payment.create'))));
};
export default PaypalPayment;
