import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Table, Button } from "semantic-ui-react";
import { changeUserOrganizationProfile, getUsersFromCompany, removeUserFromCompany } from "api-operations/company/admin";
import PublicProfile from "helpers/components/PublicProfile";
import { toast } from 'react-toastify';
const MembersCount = styled.h3 `
  text-align: center;
`;
const ShowGroupMembers = ({ isAdmin }) => {
    const [groupMembers, setGroupMembers] = useState([]);
    const { t } = useTranslation('common');
    const params = useParams();
    const login = useSelector(state => state.userProfile.login);
    useEffect(() => {
        fetchUsers();
    }, []);
    const fetchUsers = async () => {
        try {
            const res = await getUsersFromCompany(params.id);
            setGroupMembers(res);
        }
        catch (error) {
            //
        }
    };
    const handleMemberDeletion = async (userId) => {
        try {
            await removeUserFromCompany(params.id, userId);
            fetchUsers();
            toast.success(t('notification.userRemoved'));
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
    };
    const handleProfileChange = async (userId, profile) => {
        try {
            await changeUserOrganizationProfile(params.id, userId, profile);
            setGroupMembers(prevState => prevState.map((user) => {
                if (user.userId === userId) {
                    return { ...user, profile: profile };
                }
                return user;
            }));
            toast.success(t('notification.memberInformationUpdated'));
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
    };
    return React.createElement(React.Fragment, null,
        React.createElement("h2", null, t('groupsCommon.members')),
        React.createElement(MembersCount, null,
            "Members Count: ",
            groupMembers.length),
        React.createElement(Table, { celled: true },
            React.createElement(Table.Header, null,
                React.createElement(Table.Row, null,
                    React.createElement(Table.HeaderCell, null, t('groupsCommon.name')),
                    React.createElement(Table.HeaderCell, null, t('groupsCommon.profile')),
                    isAdmin && React.createElement(Table.HeaderCell, null, t('groupsCommon.manage')))),
            React.createElement(Table.Body, null, groupMembers
                .map(({ userName, userId, profile, membershipStatus }, index) => (React.createElement(Table.Row, { key: userId },
                React.createElement(Table.Cell, null,
                    userName,
                    React.createElement(PublicProfile, { userName: userName })),
                React.createElement(Table.Cell, { id: `membershipProfile${index}` },
                    " ",
                    t(`membershipProfile.${profile}`)),
                membershipStatus && React.createElement(Table.Cell, { "data-status": membershipStatus, id: `membershipStatus${index}` }, t(`membershipStatus.${membershipStatus}`)),
                isAdmin && userName !== login
                    ? React.createElement(Table.Cell, null,
                        React.createElement(Button, { basic: true, negative: true, icon: "trash alternate", name: userId, title: t('groupsCommon.delete'), onClick: () => handleMemberDeletion(userId) }),
                        profile === 'ORGANIZATION_ADMIN'
                            ? React.createElement(Button, { basic: true, onClick: () => handleProfileChange(userId, 'ORGANIZATION_MEMBER'), icon: "arrow down", title: t('groupsCommon.removeAdminPrivilege') })
                            : React.createElement(Button, { basic: true, onClick: () => handleProfileChange(userId, 'ORGANIZATION_ADMIN'), icon: "arrow up", title: t('groupsCommon.addAdminPrivilege') }))
                    : isAdmin && React.createElement(Table.Cell, null)))))));
};
export default ShowGroupMembers;
