import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, Button, Modal } from 'semantic-ui-react';
import { addBankPaymentMethod } from 'api-operations/company/admin';
const bicRegexp = /^([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)$/;
const BankPayment = ({ open, setOpen }) => {
    const [accountNumber, setAccountNumber] = useState(null);
    const [bankName, setBankName] = useState(null);
    const [bic, setBic] = useState(null);
    const [routingNumber, setRoutingNumber] = useState(null);
    const [isAccountNumberValid, setIsAccountNumberValid] = useState(true);
    const [isBankNameValid, setIsBankNameValid] = useState(true);
    const [isBicValid, setIsBicValid] = useState(true);
    const { t } = useTranslation('common');
    const params = useParams();
    const handleSubmit = async () => {
        if (!accountNumber) {
            setIsAccountNumberValid(false);
        }
        else {
            setIsAccountNumberValid(true);
        }
        if (!bankName) {
            setIsBankNameValid(false);
        }
        else {
            setIsBankNameValid(true);
        }
        if (!bicRegexp.test(bic)) {
            setIsBicValid(false);
        }
        else {
            setIsBicValid(true);
        }
        if ([accountNumber, bankName, bicRegexp.test(bic)].some(ele => !ele))
            return;
        try {
            await addBankPaymentMethod(params.id, { accountNumber: accountNumber, bankName: bankName, bic: bic, routingNumber: routingNumber ? routingNumber : undefined });
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
        setAccountNumber(null);
        setBankName(null);
        setBic(null);
        setRoutingNumber(null);
        setOpen(null);
    };
    return React.createElement(Modal, { open: open },
        React.createElement(Modal.Content, null,
            React.createElement(Form, null,
                React.createElement(Form.Input, { value: accountNumber, onChange: (e, { value }) => setAccountNumber(value), required: true, label: t('companies.payment.accountNumber'), error: !isAccountNumberValid && t('companies.invalid') }),
                React.createElement(Form.Input, { value: bankName, onChange: (e, { value }) => setBankName(value), required: true, label: t('companies.payment.bankName'), error: !isBankNameValid && t('companies.invalid') }),
                React.createElement(Form.Input, { value: bic, onChange: (e, { value }) => setBic(value), required: true, label: t('companies.payment.bic'), error: !isBicValid && t('companies.invalid') }),
                React.createElement(Form.Input, { value: routingNumber, onChange: (e, { value }) => setRoutingNumber(value), label: t('companies.payment.routingNumber') }))),
        React.createElement(Modal.Actions, null,
            React.createElement(Button, { onClick: () => setOpen(null) }, t('companies.payment.cancel')),
            React.createElement(Button, { primary: true, onClick: () => handleSubmit() }, t('companies.payment.create'))));
};
export default BankPayment;
