import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
const FixedContainer = styled.div `
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 9999;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: #cccccc;
`;
const ButtonContainer = styled.div `
  padding: 0 0 0 100px;
`;
const Paragraph = styled.p `
  font-size: 1.1rem;
  margin: 0;
`;
const CookieNotification = () => {
    const [reload, setReload] = useState(false);
    const { t } = useTranslation('common');
    const handleCloseWindow = () => {
        document.cookie = 'CloseCookieNotification=; expires=Thu, 01 Jan 2100 00:00:00 UTC; path=/';
        setReload(true);
    };
    if (document.cookie.split(';').some(c => c.trim().startsWith('CloseCookieNotification=')))
        return null;
    if (reload)
        return null;
    return (React.createElement(FixedContainer, null,
        React.createElement(Paragraph, null,
            React.createElement(Icon, { size: "big", name: "info circle" }),
            t('cookie.content')),
        React.createElement(ButtonContainer, null,
            React.createElement(Button, { inverted: true, basic: true, icon: "close", onClick: handleCloseWindow }))));
};
export default CookieNotification;
