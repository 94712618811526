export class Modal extends HTMLElement {
    constructor() {
        super();
        this.dimmer = document.createElement("div");
        this.headerElement = null;
        this.actionsElement = document.createElement("div");
        this.classList.add("ui", "modal");
        this.style.width = '600px';
        this.actionsElement.classList.add("actions");
        this.actionsElement.style.backgroundColor = 'white';
        this.actionsElement.style.border = 'none';
    }
    connectedCallback() {
        if (this.parentElement === this.dimmer) {
            return;
        }
        this.dimmer.classList.add("ui", "page", "dimmer");
        this.parentElement.insertBefore(this.dimmer, this);
        this.dimmer.appendChild(this);
    }
    unload() {
        this.dimmer.remove();
        this.remove();
    }
    set header(header) {
        var _a;
        if (!header) {
            (_a = this.headerElement) === null || _a === void 0 ? void 0 : _a.remove();
            this.headerElement = null;
            return;
        }
        this.headerElement = this.headerElement || (() => {
            const header = document.createElement("div");
            header.classList.add("header");
            this.prepend(header);
            return header;
        })(); // Lazy creation on demand
        this.headerElement.textContent = header;
    }
    get header() {
        var _a;
        return ((_a = this.headerElement) === null || _a === void 0 ? void 0 : _a.textContent) || null;
    }
    set actions(actions) {
        this.actionsElement.innerHTML = "";
        actions.forEach((action) => {
            var _a;
            const button = document.createElement("button");
            button.textContent = action.text;
            button.classList.add("ui", "button");
            (_a = action.classList) === null || _a === void 0 ? void 0 : _a.forEach(cls => button.classList.add(cls));
            action.callback && button.addEventListener("click", action.callback);
            this.actionsElement.appendChild(button);
        });
        this.appendChild(this.actionsElement);
    }
    set open(open) {
        this.dimmer.classList.toggle("visible", open);
        this.classList.toggle("active", open);
    }
    get open() {
        return this.classList.contains("active");
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-modal", Modal));
