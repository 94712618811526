import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
import UserService from "UserService";
GlobalWorkerOptions.workerSrc = "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.8.335/build/pdf.worker.js";
export default class SourceFilePanel extends HTMLElement {
    constructor() {
        super();
        this.placeholder = document.createElement("div");
        this.currentPage = null;
        this.cleanPage = null;
        this._assignmentId = null;
        this.placeholder.style.width = "50vw";
        this.appendChild(this.placeholder);
    }
    async renderPages(jobSectionId) {
        const url = `/api/assignment/editor/${this._assignmentId}/sourceFile?jobSectionId=${jobSectionId}`;
        const pdf = await getDocument({ url: url, withCredentials: true,
            httpHeaders: { Authorization: `Bearer ${UserService.getToken()}` } }).promise;
        Array.from(Array(pdf.numPages).keys()).map(i => pdf.getPage(i + 1))
            .map((promise, i) => promise.then(page => this.renderPage(page, i + 1)));
        this.unlocked = true;
    }
    renderPage(page, pageNumber) {
        const canvas = document.createElement("canvas");
        canvas.id = `${pageNumber}_page`;
        canvas.className = "page";
        canvas.style.display = "block";
        const viewport = page.getViewport({ scale: 1.3 }); // TODO scale should be calculated but for now is fixed because container don't have fixed value so scaling based on container looks terrible  
        const context = canvas.getContext('2d', { alpha: false });
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        page.render({ canvasContext: context, viewport: viewport });
        this.placeholder.remove();
        this.appendChild(canvas);
    }
    saveCurrentPage(page) {
        if (this.currentPage === null)
            return;
        const canvas = document.getElementById(`${page}_page`);
        const backCanvas = document.createElement('canvas');
        backCanvas.width = canvas.width;
        backCanvas.height = canvas.height;
        backCanvas.getContext('2d').drawImage(canvas, 0, 0);
        this.cleanPage = backCanvas;
    }
    set assignmentId(id) {
        this._assignmentId = id;
    }
    set sectionId(id) {
        this.renderPages(id);
    }
    set unlocked(unlocked) {
        this.toggleAttribute('unlocked', unlocked);
    }
    get unlocked() {
        return this.hasAttribute('unlocked');
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-source-file-panel", SourceFilePanel));
