import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import UserService from 'UserService';
import { getMyUserProfile } from 'api-operations/myExfluency/myExfluency';
const RootPage = () => {
    const history = useHistory();
    useEffect(() => { setProfile(); }, []);
    const setProfile = async () => {
        if (!UserService.isLoggedIn())
            UserService.doLogin();
        const profile = await getMyUserProfile();
        if (profile.roles.includes('ROLE_BUYER')) {
            history.push('/requester');
        }
        else if (profile.roles.includes('ROLE_REDACTOR')) {
            history.push('/redactor');
        }
        else if (profile.roles.includes('ROLE_ENHANCER')) {
            history.push('/enhancer');
        }
        else if (profile.roles.includes('ROLE_TRUST_MINER')) {
            history.push('/trust-miner');
        }
        else if (profile.roles.includes('ROLE_EXFLUENCY_ADMIN')) {
            history.push('/admin');
        }
        else {
            history.push('/settings');
        }
    };
    return null;
};
export default RootPage;
