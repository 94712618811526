import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Dropdown, Icon, Popup, Table } from 'semantic-ui-react';
import { Styled } from 'StyledComponents';
import Avatar from 'helpers/components/Avatar';
import PagedAssignmentsCompanyView from './PagedAssignmentsCompanyView';
import CompanyAssignmentInfo from './CompanyAssignmentInfo';
import TablePlaceholder from 'helpers/components/TablePlaceholder';
import { toast } from 'react-toastify';
import { getCompanyEarnings, getCompanyEarningsCsv } from 'api-operations/company/admin';
import PriceWithExchangeRatePopup from 'dashboards/shared/PriceWithExchangeRatePopup';
import JobNamePopup from 'dashboards/shared/JobNamePopup';
import store from 'reduxStore/store';
const ActionButtons = styled.div `
  display: flex;
  flex-direction: row;
`;
const CompanyAssignments = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [assignments, setAssignments] = useState([]);
    const [isError, setIsError] = useState(false);
    const [allResultsCount, setAllResultsCount] = useState(0);
    const [paginationOptions, setPaginationOptions] = useState({});
    const { exchangeRate } = store.getState().company;
    const { t } = useTranslation('common');
    const params = useParams();
    const fetchData = async (paginationFilterOptions) => {
        if (isError) {
            setIsError(false);
            setIsLoading(true);
        }
        try {
            const response = await getCompanyEarnings(params.id, paginationFilterOptions);
            setAssignments(response.results);
            setAllResultsCount(response.totalCount);
        }
        catch (error) {
            setIsError(true);
        }
        setIsLoading(false);
    };
    const downloadCsv = async () => {
        try {
            await getCompanyEarningsCsv(params.id, paginationOptions);
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
    };
    const orderOptions = [
        { key: 'DUD', text: t('pagination.filter.uploadDateD'), value: 'uploadDate DESCENDING' },
        { key: 'AUD', text: t('pagination.filter.uploadDateA'), value: 'uploadDate ASCENDING' },
        { key: 'FD', text: t('pagination.filter.fileNameD'), value: 'fileName DESCENDING' },
        { key: 'FA', text: t('pagination.filter.fileNameA'), value: 'fileName ASCENDING' },
        { key: 'LFD', text: t('pagination.filter.langFromD'), value: 'langFrom DESCENDING' },
        { key: 'LFA', text: t('pagination.filter.langFromA'), value: 'langFrom ASCENDING' },
        { key: 'TFD', text: t('pagination.filter.langToD'), value: 'langTo DESCENDING' },
        { key: 'TFA', text: t('pagination.filter.langToA'), value: 'langTo ASCENDING' }
    ];
    return React.createElement(React.Fragment, null,
        React.createElement(Styled.H3, null, t('Assignments')),
        React.createElement(PagedAssignmentsCompanyView, { orderOptions: orderOptions, totalResults: allResultsCount, fetchData: fetchData, setPaginationOptions: setPaginationOptions, tableIdentifier: 'CompanyEarnings' },
            React.createElement(Table, { celled: true, selectable: true, striped: true, color: "green" },
                React.createElement(Table.Header, null,
                    React.createElement(Table.Row, null,
                        React.createElement(Table.HeaderCell, null,
                            React.createElement(Dropdown, { as: Button, compact: true, floating: true, color: 'blue', text: 'Actions' },
                                React.createElement(Dropdown.Menu, null,
                                    React.createElement(Dropdown.Item, { onClick: () => downloadCsv() },
                                        React.createElement(Icon, { name: "download" }),
                                        t('dashboard.downloadCsv'))))),
                        React.createElement(Table.HeaderCell, null, t('dashboard.projectName')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.requester')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.projectType')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.languages')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.estimatedPrice')),
                        React.createElement(Table.HeaderCell, null))),
                isLoading || isError || !assignments.length ? React.createElement(TablePlaceholder, { isError: isError, isLoading: isLoading, noEntries: !assignments.length })
                    : React.createElement(Table.Body, null, assignments.map(assignment => React.createElement(Table.Row, { key: assignment.id },
                        React.createElement(Table.Cell, null,
                            React.createElement(Avatar, { name: assignment.executor, inlineAvatar: true }),
                            assignment.executor),
                        React.createElement(Table.Cell, null,
                            React.createElement(JobNamePopup, { name: assignment.fileName })),
                        React.createElement(Table.Cell, null, assignment.requester),
                        React.createElement(Table.Cell, null,
                            t(`dashboard.${assignment.assignmentType}`),
                            " "),
                        React.createElement(Table.Cell, null,
                            t(`languages.${(assignment.langFrom.toLowerCase())}`),
                            " ",
                            React.createElement(Icon, { name: "arrow right" }),
                            " ",
                            t(`languages.${assignment.langTo.toLowerCase()}`)),
                        React.createElement(Table.Cell, null,
                            React.createElement(PriceWithExchangeRatePopup, { cost: assignment.cost, customCurrencyCost: assignment.companyCurrencyCost, exchangeRate: exchangeRate })),
                        React.createElement(Table.Cell, null,
                            React.createElement(ActionButtons, null,
                                React.createElement(Popup, { flowing: true, hoverable: true, basic: true, position: "top center", key: assignment.id, trigger: (React.createElement(Button, { icon: "info" })), offset: [0, 0] },
                                    React.createElement(Styled.VerticalPopupContent, null,
                                        React.createElement(CompanyAssignmentInfo, { assignment: assignment })))))))))));
};
export default CompanyAssignments;
