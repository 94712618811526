import axios from "axios";
import { paginationParamsSerializer, downloadFileWithName } from "api-operations/common";
export async function getJobs(paginationFilterOptions) {
    const response = await axios.get(`/api/workflow/requester/jobs`, {
        params: { ...paginationFilterOptions },
        paramsSerializer: params => paginationParamsSerializer(params)
    });
    return response.data;
}
export async function reopen(jobId) {
    const response = await axios.post(`/api/workflow/requester/jobs/${jobId}/reopen`);
    return response.data;
}
export async function reassignJob(jobId) {
    const response = await axios.post(`api/workflow/requester/jobs/${jobId}/reassign`);
    return response.data;
}
export async function shareAssets(jobId) {
    await axios.post(`/api/workflow/requester/jobs/${jobId}/shareAssets`);
}
export async function deleteJob(jobId) {
    await axios.delete(`/api/workflow/requester/jobs/${jobId}`);
}
export async function createJob(job) {
    await axios.post(`/api/v2/jobs`, job);
}
export async function getJobComments(jobId) {
    const response = await axios.get(`/api/workflow/requester/jobs/${jobId}/comments`);
    return response.data;
}
export async function postJobComment(jobId, text, replyTo = null) {
    await axios.post(`/api/workflow/requester/jobs/${jobId}/comments`, text, {
        params: { replyTo }
    });
}
export async function getAssignmentSections(jobId, assignmentId) {
    const response = await axios.get(`/api/workflow/requester/jobs/${jobId}/assignments/${assignmentId}/jobSections`);
    return response.data;
}
// removes attachment from job
export async function deleteJobAttachment(jobId, fileId) {
    const response = await axios.delete(`/api/workflow/attachments/jobs/${jobId}/attachments/${fileId}`);
    return response.data;
}
//upload new attachment to job
export async function uploadJobAttachment(jobId, file) {
    const data = new FormData();
    data.append('file', file);
    const response = await axios.post(`/api/workflow/attachments/jobs/${jobId}/attachments`, data);
    return response.data;
}
// returns all attachments descriptions (if stageId is specified, will include attachments from this stage)
export async function downloadAttachment(jobId, fileId, fileName) {
    await downloadFileWithName(`/api/workflow/attachments/jobs/${jobId}/attachments/${fileId}`, {}, fileName);
}
// returns all attachments descriptions (if stageId is specified, will include attachments from this stage)
export async function allAttachmentsDescription(jobId, stageId) {
    const response = await axios.get(`/api/workflow/attachments/jobs/${jobId}/attachments`, {
        params: { stageId }
    });
    return response.data;
}
