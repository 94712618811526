import { getAssetExtraData } from "api-operations/editor/editor";
import AssetSource from './AssetSource';
import SubAssetElement from './SubAssetElement';
import { getProblemTypeIconName, consumeEvent, isTouchscreen, getNodesToText, getSanitizedHTML } from './utils';
import { HTTPLogger } from 'logger/HTTPLoggerStatic';
import UniversalTagElement from './UniversalTag';
import { moveSvg } from './icons/move';
import i18next from 'i18next';
import IconNG from './icons';
import { infoSvg } from './icons/info';
import Tooltip from './components/tooltip/Tooltip';
import { tipSvg } from './icons/tip';
import { ghostFormattingSvg } from './icons/ghostFormatting';
import _ from 'lodash';
import { getNestedNodes, transformNodes, transformNodesForTerms } from './AssetPartition';
import TerminologyElement from './TerminologyElement';
export default class AssetElement extends HTMLElement {
    constructor(assetId, order, translationLevel, completed, recycled, modified, format, editor) {
        super();
        this.contentDiv = document.createElement("div");
        this.iconBar = document.createElement("div");
        this.actionBar = document.createElement("div");
        this.toolBar = document.createElement("div");
        this.problemIconContainer = document.createElement("div");
        this.problemIcon = document.createElement("i");
        this.macroBar = document.createElement("div");
        this.macroBarDescription = document.createElement("div");
        this.macroBarMacros = document.createElement("div");
        this.indicator = document.createElement("span");
        this._subAssets = []; // conider this private
        this._terms = [];
        this.sections = [];
        this._assetErrorType = null;
        this.indicator.className = 'indicator';
        this.editor = editor;
        this.assetId = assetId;
        this.order = order;
        this.format = format;
        this.translationLevel = translationLevel;
        this.contentEditable = 'false';
        this.contentDiv.tabIndex = 0; // this allows to move to next uncompleted asset if that asset is recycled
        let showWindowCallback;
        if (editor.assetSourceWindow) {
            showWindowCallback = () => {
                editor.assetSourceWindow.open = true;
                editor.assetSourceWindow.contentText = this.sourceText;
                editor.assetSourceWindow.subAssets = this.subAssets;
                editor.assetSourceWindow.terms = this.terms;
                this.showSource = false;
                editor.assetSourceWindow.onCloseCallback = () => {
                    this.showSource = true;
                };
            };
        }
        this.assetSource = new AssetSource(this.editor.sourceLanguageTextDirection, this.editor.content.lang, this.editor, showWindowCallback);
        this.addEventListener("click", this.onClick.bind(this));
        this.addEventListener("contextmenu", this.onContextMenu.bind(this));
        this.contentDiv.addEventListener("input", _.throttle(this._onContentChange.bind(this), 1000));
        this.actionBar.className = "actionBar";
        this.iconBar.contentEditable = "false";
        this.iconBar.className = "iconBar";
        this.toolBar.className = "toolBar";
        this.iconBar.appendChild(this.toolBar);
        this.iconBar.appendChild(this.problemIconContainer);
        this.actionBar.appendChild(this.iconBar);
        this.problemIconContainer.appendChild(this.problemIcon);
        this.macroBar.className = 'macroBar';
        this.macroBarDescription.className = 'description';
        this.macroBarDescription.innerHTML = moveSvg;
        this.macroBarDescription.appendChild(document.createTextNode(i18next.t('editor.formatting.macroBarText')));
        const tooltip = new Tooltip();
        tooltip.content = this._createTooltipContent();
        // eslint-disable-next-line no-unused-vars
        const icon = new IconNG(infoSvg, tooltip, 'fill');
        this.macroBarDescription.appendChild(tooltip);
        this.macroBarMacros.className = 'macros';
        this.macroBar.appendChild(this.macroBarDescription);
        this.macroBar.appendChild(this.macroBarMacros);
        this.appendChild(this.assetSource);
        this.appendChild(this.macroBar);
        this.contentDiv.className = "content";
        this.contentDiv.dir = this.editor.targetLanguageTextDirection;
        this.contentDiv.spellcheck = true;
        this.appendChild(this.contentDiv);
        this.appendChild(this.actionBar);
        const paid = translationLevel != "NO_TRANSLATION" && !recycled;
        this.toggleAttribute("paid", paid);
        this.toggleAttribute("recycled", recycled);
        this.toggleAttribute("locked", !paid && !modified);
        this._setCompleted(completed);
    }
    _createTooltipContent() {
        const wrapper = document.createElement('div');
        wrapper.className = "info";
        const tip1 = document.createElement('div');
        tip1.className = 'tip';
        const icon1 = document.createElement('div');
        icon1.innerHTML = tipSvg;
        const text1 = document.createElement('div');
        text1.className = 'text';
        text1.innerHTML = `This is a <b>placeholder</b>: ${ghostFormattingSvg} (placeholder numbers match the source text formatting numbers).`;
        tip1.appendChild(icon1);
        tip1.appendChild(text1);
        const tip2 = document.createElement('div');
        tip2.className = 'tip';
        const icon2 = document.createElement('div');
        icon2.innerHTML = tipSvg;
        const text2 = document.createElement('div');
        text2.className = 'text';
        text2.textContent = 'Placeholders can be placed anywhere in an asset except in the middle of words.';
        tip2.appendChild(icon2);
        tip2.appendChild(text2);
        wrapper.appendChild(tip1);
        wrapper.appendChild(tip2);
        return wrapper;
    }
    fetchExtraData() {
        getAssetExtraData(this.editor.assignmentId, this.assetId)
            .then((extraData) => {
            this.sections = extraData.jobSections;
            this.editor.modules.forEach(m => m.assetExtraDataReceived(this, extraData));
        })
            .catch((error) => {
            this.editor.setState({ error });
            HTTPLogger.error('System - Failed to get extra data', error);
        });
    }
    unlockClicked() {
        this.locked = false;
        this.completed = false;
    }
    doneClicked(event) {
        var _a, _b;
        consumeEvent(event);
        if (this.completed) {
            this.completed = true;
            return;
        }
        if (this.editor.assetMissingFormattingModal && this.getUsedTagsQuality() !== ((_a = this.format) === null || _a === void 0 ? void 0 : _a.tagsCount) && ((_b = this.format) === null || _b === void 0 ? void 0 : _b.tagsCount)) {
            this.editor.assetMissingFormattingModal.callback = () => this._completeAsset();
            this.editor.assetMissingFormattingModal.setOpen();
            return;
        }
        this._completeAsset();
    }
    restoreClicked(event) {
        if (this.editor.assetRestoreModal) {
            this.editor.assetRestoreModal.callback = () => {
                // restore asset
                this.currentText = this.incomingText || '';
                this.editor.onInput();
                this.editor.commit(this);
            };
            this.editor.assetRestoreModal.setOpen();
        }
    }
    _completeAsset() {
        this.completed = true;
        if (this.active) {
            this.editor.jumpToNextUnfinishedAsset();
        }
    }
    isFinished() {
        return this.completed;
    }
    refresh() {
        this.replaceTerminologies(this.terms, this.contentDiv);
        this.subAssets.forEach(this.replaceSubAsset.bind(this));
        this.assetSource.text = this.sourceText;
        this.assetSource.replaceSubAssets(this.subAssets);
        this.assetSource.replaceTerms(this.terms);
        this.insertCaretGuards();
        this.editor.modules.forEach(m => m.assetRefreshed(this));
    }
    insertCaretGuards() {
        if (this.contentDiv.querySelectorAll('span.caretGuard').length >= 2)
            return;
        this.contentDiv.prepend(createCaretGuard());
        this.contentDiv.appendChild(createCaretGuard());
        this.contentDiv.normalize();
    }
    replaceSubAsset(subAsset) {
        const nodes = getNestedNodes(Array.from(this.contentDiv.childNodes))
            .flat(20)
            .filter((node) => node instanceof Text);
        transformNodes(nodes, `XFL_TOKEN_${subAsset.id}`, subAsset, this.editor);
        this.contentDiv.normalize();
    }
    replaceTerminologies(terms, contentDiv) {
        [...terms].sort((t1, t2) => t2.targetText.length - t1.targetText.length).forEach(t => this.replaceTerminology(t, contentDiv));
    }
    replaceTerminology(term, contentDiv) {
        const nodes = getNestedNodes(Array.from(contentDiv.childNodes))
            .flat(20)
            .filter((node) => node instanceof Text);
        transformNodesForTerms(nodes, term.targetText, term, this.editor, this.editor.targetLanguage, false, this.terms);
        contentDiv.normalize();
    }
    isMacroBarRequired() {
        return this.getUnusedMacroTagsIds().length > 0;
    }
    getUnusedMacroTagsIds() {
        if (!this.format)
            return [];
        const usedTags = Array.from(this.contentDiv.querySelectorAll('universal-tag'));
        const usedMacroTagsIds = usedTags.filter(tag => tag.macro && tag.id).map(tag => tag.id);
        return Object.entries(this.format.formatTexts)
            .filter(([key, value]) => value === '' && !usedMacroTagsIds.includes(key))
            .map(([key]) => key);
    }
    createMacroTag(id) {
        const newTag = new UniversalTagElement(id);
        newTag.editor = this.editor;
        return newTag;
    }
    setUnusedMacroTags() {
        this.macroBarMacros.innerHTML = '';
        this.getUnusedMacroTagsIds().forEach((tagId) => {
            this.macroBarMacros.appendChild(this.createMacroTag(tagId));
        });
        this.macroBar.classList.toggle('visible', !!this.getUnusedMacroTagsIds().length);
        this.updateTagsState();
    }
    getUsedTagsQuality() {
        return Array.from(this.contentDiv.querySelectorAll('universal-tag'))
            .map(node => node.id)
            .filter(id => { var _a; return this.format && Object.keys((_a = this.format) === null || _a === void 0 ? void 0 : _a.formatTexts).includes(id); })
            .length;
    }
    getUnusedFormatting() {
        const usedFormatting = Array.from(this.contentDiv.querySelectorAll('universal-tag'))
            .filter(node => !node.macro);
        if (!this.format)
            return [];
        const sourceTextFormatting = Object.entries(this.format.formatTexts)
            .filter(([, value]) => value)
            .map(([key, value]) => ({ value: key, description: value }));
        return sourceTextFormatting.filter(({ value }) => !usedFormatting.find(node => node.id == value));
    }
    isAllFormattingTagsUsed() {
        var _a;
        const tagsInText = Array.from(this.contentDiv.querySelectorAll('universal-tag'));
        return tagsInText.length === ((_a = this.format) === null || _a === void 0 ? void 0 : _a.tagsCount);
    }
    updateTagsState() {
        var _a;
        if (!this.editor.formattingModule)
            return;
        this.editor.formattingModule.updateFormatting(((_a = this.format) === null || _a === void 0 ? void 0 : _a.tagsCount) || 0, this.getUsedTagsQuality());
        this.editor.formattingModule.unusedFormatting = this.getUnusedFormatting();
    }
    childrenWithoutCaretGuards() {
        return Array.from(this.contentDiv.childNodes)
            .filter(n => n instanceof Element ? !n.classList.contains("caretGuard") : true);
    }
    _setCompleted(completed) {
        this.toggleAttribute("completed", completed);
        this.editor.modules.forEach(m => m.assetInstanceCompletionChanged(this, completed));
    }
    _setLocked(locked) {
        this.toggleAttribute("locked", locked);
        this.editor.modules.forEach(m => m.assetInstanceLockChanged(this, locked));
    }
    set assetId(type) {
        this.setAttribute("assetId", type);
    }
    get assetId() {
        return this.getAttribute("assetId") || "";
    }
    set order(order) {
        this.setAttribute("order", order + "");
    }
    get order() {
        return parseInt(this.getAttribute("order") || "");
    }
    set problem(problem) {
        if (problem === null) {
            this.removeAttribute('problemType');
            this.removeAttribute('problemDescription');
        }
        else {
            this.setAttribute('problemType', problem.type);
            this.setAttribute('problemDescription', problem.description);
            this.problemIconContainer.title = problem.description;
            this.problemIconContainer.className = `problemIcon ${problem.type}`;
            this.problemIcon.className = `icon ${getProblemTypeIconName(problem.type)}`;
        }
    }
    get problem() {
        return this.getAttribute("problemType");
    }
    set translationLevel(level) {
        level ? this.setAttribute("translationLevel", level) : this.removeAttribute("translationLevel");
    }
    get translationLevel() {
        return this.getAttribute("translationLevel");
    }
    get paid() {
        return this.hasAttribute("paid");
    }
    set active(active) {
        if (active == this.hasAttribute("active")) {
            return;
        }
        this.toggleAttribute("active", active);
        this._updateAllFormatting();
        this.editor.modules.forEach(m => m.assetActivationChanged(this, active));
    }
    _updateAllFormatting() {
        this.setUnusedMacroTags();
        if (this.active && this.editor.formattingModule && this.editor.formattingModule.active === false && !this.isAllFormattingTagsUsed()) {
            this.editor.formattingModule.active = true;
        }
        this.macroBarVisible = this.active && this.isMacroBarRequired() && !this.isAllFormattingTagsUsed();
    }
    get active() {
        return this.hasAttribute("active");
    }
    set showSource(showSource) {
        if (!this.sourceText) {
            return;
        }
        this.toggleAttribute("showSource", showSource);
    }
    get showSource() {
        return this.hasAttribute("showSource");
    }
    set currentText(text) {
        this.contentDiv.innerHTML = this.generateContent(text);
        Array.from(this.contentDiv.querySelectorAll('universal-tag'))
            .forEach((node) => {
            if (node instanceof UniversalTagElement) {
                node.editor = this.editor;
            }
        });
        this.refresh();
    }
    generateContent(text) {
        const normalizedText = text.replaceAll("&#xa0;", " "); // change text space to normal space
        const content = getSanitizedHTML(normalizedText);
        return content;
    }
    get currentText() {
        return getNodesToText(Array.from(this.contentDiv.childNodes));
    }
    get currentPlainText() {
        return this.contentDiv.textContent || '';
    }
    set sourceText(text) {
        this.assetSource.text = text;
        this.assetSource.replaceSubAssets(this.subAssets);
        this.assetSource.replaceTerms(this.terms);
    }
    get sourceText() {
        return this.assetSource.text;
    }
    set incomingText(text) {
        this.assetError = this.editor.stage === 'TRUST_MINING' && !this.isContentEqual(text || "");
        text === null
            ? this.removeAttribute("incomingText")
            : this.setAttribute("incomingText", text);
    }
    get incomingText() {
        return this.getAttribute("incomingText");
    }
    set locked(locked) {
        this._setLocked(locked);
        this.editor.getAssetDuplicates(this).forEach((asset) => {
            if (asset.locked != locked) {
                asset._setLocked(!locked);
            }
        });
    }
    get locked() {
        return this.hasAttribute("locked");
    }
    set completed(completed) {
        var _a;
        if (this.currentText.trim() === '' && completed) {
            return;
        }
        this._setCompleted(completed);
        this.editor.getAssetDuplicates(this).forEach((asset) => {
            if (asset.completed != completed) {
                asset._setCompleted(completed);
            }
        });
        this.editor.modules.forEach(m => m.assetCompletionChanged(this, completed));
        if (this.editor.stage !== 'REDACTION' && this.sections.length > 1 && completed === false) {
            (_a = this.editor.assetMultipleOccurrencesModal) === null || _a === void 0 ? void 0 : _a.setOpen();
        }
    }
    get completed() {
        return this.hasAttribute("completed");
    }
    get completable() {
        return this.editor.modules.every(m => m.assetCompletable(this));
    }
    get modified() {
        return this.isContentEqual() === false;
    }
    isContentEqual(equalTo) {
        return this.incomingText === null || this.decodeHTML(this.incomingText) === this.decodeHTML(equalTo || this.currentText);
    }
    set subAssets(subAssets) {
        this._subAssets = subAssets;
        this.refresh();
    }
    get subAssets() {
        return this._subAssets;
    }
    get terms() {
        return this._terms;
    }
    set assetErrorType(assetErrorType) {
        this._assetErrorType = assetErrorType;
    }
    get assetErrorType() {
        return this._assetErrorType;
    }
    set assetError(error) {
        this.toggleAttribute("error", error);
    }
    get assetError() {
        return this.hasAttribute("error");
    }
    set macroBarVisible(visible) {
        this.macroBar.classList.toggle('visible', visible);
    }
    compareOrder(elem1, elem2) {
        if (elem1 === elem2)
            return 0;
        if (elem1.compareDocumentPosition(elem2) & Node.DOCUMENT_POSITION_FOLLOWING) {
            return -1;
        }
        return 1;
    }
    addDragEventListeners(elem) {
        elem.addEventListener("dragenter", () => {
            var _a;
            const draggedElement = this.querySelector("universal-tag.dragging");
            if (!draggedElement)
                return;
            const order = this.compareOrder(elem, draggedElement);
            if (!order)
                return;
            const baseElement = order === -1 ? elem : elem.nextSibling;
            if (!baseElement)
                return;
            (_a = elem.parentNode) === null || _a === void 0 ? void 0 : _a.insertBefore(this.indicator, baseElement);
        });
        elem.addEventListener('drop', () => {
            const draggedElement = this.querySelector("universal-tag.dragging");
            if (!draggedElement)
                return;
            const indicator = this.querySelector('.indicator');
            if (indicator === null || indicator === void 0 ? void 0 : indicator.closest('mark'))
                return; // prevents adding tag inside mark because of undesirable behavior
            indicator === null || indicator === void 0 ? void 0 : indicator.replaceWith(draggedElement);
            this._updateAllFormatting();
        });
    }
    set dragAndDropMode(mode) {
        const oldMode = this.dragAndDropMode;
        this.toggleAttribute("draganddrop", mode);
        if (mode === oldMode)
            return;
        const splitWordsIntoSpans = (nodes) => {
            nodes.forEach((node) => {
                if (node instanceof Text) {
                    const { data } = node;
                    const newNode = document.createElement('span');
                    newNode.className = "textContainer";
                    node.replaceWith(newNode);
                    const arr = [];
                    data
                        .split(" ")
                        .forEach((text, index, textArray) => {
                        arr.push(text);
                        if (textArray.length - 1 > index)
                            arr.push(" ");
                    });
                    arr.forEach((text) => {
                        const newTextNode = document.createElement('span');
                        newTextNode.className = "text";
                        newTextNode.style.display = 'inline';
                        newTextNode.textContent = text;
                        this.addDragEventListeners(newTextNode);
                        newNode.appendChild(newTextNode);
                    });
                }
                else if (node instanceof UniversalTagElement && node.macro) {
                    // nothing
                }
                else if (node instanceof SubAssetElement) {
                    // nothing
                }
                else if (node instanceof TerminologyElement) {
                    // nothing
                }
                else {
                    splitWordsIntoSpans(Array.from(node.childNodes));
                }
            });
        };
        if (mode) {
            splitWordsIntoSpans(Array.from(this.contentDiv.childNodes));
        }
        else {
            this.revertWordsSplitting(this.contentDiv);
        }
    }
    revertWordsSplitting(element) {
        element.querySelectorAll('.text').forEach((node) => {
            const textNode = document.createTextNode(node.textContent || '');
            node.replaceWith(textNode);
        });
        element.querySelectorAll('.textContainer').forEach((node) => {
            const textContainerNode = document.createDocumentFragment();
            const newNodesToInsert = [];
            let tmpTextNode = document.createTextNode('');
            node.childNodes.forEach((node) => {
                node.normalize();
                if (node instanceof Text) {
                    tmpTextNode.data += node.textContent;
                }
                else {
                    newNodesToInsert.push(tmpTextNode);
                    newNodesToInsert.push(node);
                    tmpTextNode = document.createTextNode('');
                }
            });
            if (tmpTextNode.textContent) {
                newNodesToInsert.push(tmpTextNode);
            }
            newNodesToInsert.forEach((node) => {
                textContainerNode.appendChild(node);
            });
            node.replaceWith(textContainerNode);
        });
        this.contentDiv.normalize();
    }
    get dragAndDropMode() {
        return this.hasAttribute("draganddrop");
    }
    decodeHTML(html) {
        const txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }
    onClick(event) {
        this.editor.activeAsset = this;
        if (event.ctrlKey || event.metaKey) {
            HTTPLogger.info(`User - ${!this.showSource ? 'Opened' : 'Closed'} source text`);
            this.showSource = !this.showSource;
        }
    }
    onContextMenu() {
        if (!isTouchscreen()) {
            return;
        }
        this.editor.activeAsset = this;
        this.showSource = !this.showSource;
    }
    _onContentChange() {
        setTimeout(() => {
            this._updateAllFormatting();
        }, 300);
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-asset", AssetElement));
function createCaretGuard() {
    const span = document.createElement("span");
    span.className = "caretGuard";
    span.contentEditable = 'false';
    span.textContent = "\u200B";
    return span;
}
export const removeCaretGuards = (text) => text.replace(/\u200B/g, "");
