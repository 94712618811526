import { paginationParamsSerializer } from 'api-operations/common';
import axios from 'axios';
export async function getExpensePayoffTransfers(paginationFilterOptions) {
    const response = await axios.get('/api/coin/transfers/expensePayOff', {
        params: { ...paginationFilterOptions },
        paramsSerializer: params => paginationParamsSerializer(params)
    });
    return response.data;
}
export async function getLangCoinBalances() {
    const response = await axios.get('/api/coin/balance');
    return response.data;
}
