import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Message } from 'semantic-ui-react';
import styled from 'styled-components';
import { StyledMessageHeader, StyledModal } from './ErrorModal';
const StyledMessage = styled.p `
    text-align: left;
`;
const PermissionErrorModal = ({ onClose, message }) => {
    const { t } = useTranslation('common');
    return (React.createElement(StyledModal, null,
        React.createElement(Message, { error: true },
            React.createElement(StyledMessageHeader, { content: t('errors.noAssignmentPermission.header') }),
            React.createElement(StyledMessage, null, message || t('errors.noAssignmentPermission.content'))),
        React.createElement(Button, { onClick: onClose, color: "red", inverted: true }, "Close")));
};
export default PermissionErrorModal;
