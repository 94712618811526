import React, { useState, createContext, useContext, useCallback } from 'react';
import { getJobs } from 'api-operations/requester/job';
import { useLogger } from 'logger/Logger';
const RequesterJobStateContext = createContext(undefined);
export const useRequesterJob = () => {
    const ctx = useContext(RequesterJobStateContext);
    if (!ctx) {
        throw new Error('Component beyond RequesterJobStateContext!');
    }
    return ctx;
};
const Dashboard = ({ status, children }) => {
    const [jobs, setJobs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [allResultsCount, setAllResultsCount] = useState(0);
    const [isError, setIsError] = useState(false);
    const [paginationOptions, setPaginationOptions] = useState({});
    const { logger } = useLogger();
    const fetchJobs = useCallback(async (paginationFilterOptions) => {
        if (document.visibilityState == "hidden")
            return;
        if (isError) {
            setIsError(false);
            setIsLoading(true);
        }
        if (paginationFilterOptions)
            setPaginationOptions(paginationFilterOptions);
        try {
            const stateStatues = status == 'DELIVERED' ? ['FINISHED', 'ERROR', 'CANCELLED'] : ['IN_PROGRESS', 'BLOCKED'];
            const response = await getJobs({ ...paginationFilterOptions || paginationOptions, statuses: stateStatues });
            setJobs(response.results);
            setAllResultsCount(response.totalCount);
        }
        catch (error) {
            setIsError(true);
            logger.error(`System - Failed to fetch ${status === 'DELIVERED' ? 'finished' : 'ongoing'} jobs`, error);
        }
        setIsLoading(false);
    }, [isLoading, isError, paginationOptions]);
    return React.createElement(RequesterJobStateContext.Provider, { value: { fetchJobs, allResultsCount, isError, isLoading, jobs } }, children);
};
export default Dashboard;
