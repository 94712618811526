/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { Route, useParams, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Switch, Redirect, NavLink } from 'react-router-dom';
import { Loader, Menu } from 'semantic-ui-react';
import { getMyGatedComunities } from 'api-operations/community/member';
import MembersPane from './membersPane/MembersPane';
import DefaultQaTable from "../common/DefaultQaTable";
import ErrorModal from 'errors/components/ErrorModal';
import CommunityOverview from "./overviewPane/CommunityOverview";
import ReputationBoard from 'reputationBoard/ReputationBoard';
const H2 = styled.h2 `
  text-align: center;
`;
const StyledNavLink = styled(NavLink) `
  &&&&:hover {
   background-color: rgba(0,0,0,.03)
  }

  &&&&.active {
    background-color: rgba(0,0,0,.05);
  }
`;
const CommunityPanel = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [groupName, setGroupName] = useState('');
    const [errorWindowMessage, setErrorWindowMessage] = useState(null);
    const params = useParams();
    const { t } = useTranslation('common');
    const { path, url } = useRouteMatch();
    useEffect(() => {
        (async () => {
            await getCompany();
            setIsLoading(false);
        })();
    }, []);
    const getCompany = async () => {
        try {
            const communities = await getMyGatedComunities(true);
            const community = communities.find((community) => community.gatedCommunityId === params.id);
            if (!community)
                return;
            setIsAdmin(community.profile.includes('ADMIN'));
            setGroupName(community.gatedCommunityName);
        }
        catch (error) {
            setErrorWindowMessage(error);
        }
    };
    if (errorWindowMessage !== null)
        return React.createElement(ErrorModal, { ...errorWindowMessage, onClose: () => window.location.reload() });
    if (isLoading)
        return React.createElement(Loader, null);
    return React.createElement(React.Fragment, null,
        React.createElement("h2", null,
            t('gatedCommunities.gatedCommunity'),
            " - ",
            groupName),
        React.createElement(Menu, { stackable: true },
            React.createElement(Menu.Item, { as: StyledNavLink, to: `${url}/members` }, t('groupsCommon.members')),
            React.createElement(Menu.Item, { as: StyledNavLink, to: `${url}/reputation-board` }, t('reputationBoard.reputationBoard')),
            isAdmin && React.createElement(Menu.Item, { as: StyledNavLink, to: `${url}/qa` }, t('qaTables.main')),
            isAdmin && React.createElement(Menu.Item, { as: StyledNavLink, to: `${url}/overview` }, t('gcOverview.title'))),
        React.createElement(Switch, null,
            React.createElement(Route, { path: `${path}/members` },
                React.createElement(MembersPane, { isAdmin: isAdmin })),
            React.createElement(Route, { path: `${path}/reputation-board` },
                React.createElement(ReputationBoard, { scope: 'community', groupId: params.id })),
            isAdmin && React.createElement(Route, { exact: true, path: `${path}/qa` },
                React.createElement(DefaultQaTable, null)),
            isAdmin && React.createElement(Route, { exact: true, path: `${path}/overview` },
                React.createElement(CommunityOverview, null)),
            React.createElement(Redirect, { from: `${path}/*`, to: `${path}/members` }),
            React.createElement(Redirect, { from: `${path}`, to: `${path}/members` })));
};
export default CommunityPanel;
