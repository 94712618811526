import { finishAssignment } from "api-operations/editor/editor";
import AssignmentFinishConfirmationModal from "editorNextGen/AssignmentFinishConfirmationModal";
import AssignmentFinishedModal from "editorNextGen/AssignmentFinishedModal";
import i18next from "i18next";
import { HTTPLogger } from "logger/HTTPLoggerStatic";
import EditorModule from "../EditorModule";
export default class DeliverAssetsModule extends EditorModule {
    constructor(editor) {
        super(editor);
        this.moduleId = "DeliverAssetsModule";
        this.assignmentFinishedModal = new AssignmentFinishedModal();
        this.assignmentFinishedConfirmationModal = new AssignmentFinishConfirmationModal();
        this.deliverButton = document.createElement("button");
        this.editor.domNode.appendChild(this.assignmentFinishedModal);
        this.editor.domNode.appendChild(this.assignmentFinishedConfirmationModal);
        this.deliverButton.textContent = i18next.t("editorHeader.finish");
        this.deliverButton.className = "ui button primary";
        this.deliverButton.disabled = true;
        this.deliverButton.addEventListener("click", () => this.finishAssignmentConfirmation());
        this.editor.header.finishBar.appendChild(this.deliverButton);
    }
    unload() {
        this.assignmentFinishedModal.remove();
        this.assignmentFinishedConfirmationModal.remove();
        this.deliverButton.remove();
    }
    progressChanged(progress) {
        this.deliverButton.disabled = (this.editor.requiresAssetCompletion && progress < 1)
            && !this.editor.sections.every(({ completedAssets, totalAssets, hasResultFile }) => completedAssets === totalAssets || hasResultFile);
        !this.deliverButton.disabled && HTTPLogger.info('System - Finish Assignment button is active');
    }
    finishAssignmentConfirmation() {
        const assets = Array.from(this.editor.content.querySelectorAll("xfl-new-editor-asset"));
        const completedAssetCount = assets.filter(asset => asset.isFinished()).length;
        this.assignmentFinishedConfirmationModal.header = i18next.t("editor.assignmentFinishedConfirmationModal.header");
        this.assignmentFinishedConfirmationModal.message = (assets.length - completedAssetCount === 0)
            ? null
            : i18next.t('editor.assignmentFinishedConfirmationModal.remainingAssets', { assets: assets.length - completedAssetCount });
        this.assignmentFinishedConfirmationModal.callback = () => { this.reportFinished(); this.finish(); };
        this.assignmentFinishedConfirmationModal.open = true;
        HTTPLogger.info('User - Opened finish assignment confirmation modal');
    }
    async finish() {
        try {
            await finishAssignment(this.editor.assignmentId);
            HTTPLogger.info('User - Delivered assignment');
            this.reportFinished();
        }
        catch (error) {
            HTTPLogger.error('User - Failed to deliver assignment', error);
        }
    }
    reportFinished() {
        const { history } = this.editor.props;
        this.assignmentFinishedModal.message = i18next.t("editor.assignmentFinishedModal.message");
        this.assignmentFinishedModal.callback = () => { history.push({ pathname: `/` }); };
        this.assignmentFinishedModal.open = true;
        HTTPLogger.info('User - Opened finish assignment modal');
    }
}
