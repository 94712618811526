import moment from "moment";
import "moment-timezone";
import UserService from "UserService";
export function getTimeFromNow(timestamp) {
    const today = new Date();
    const due = new Date(timestamp);
    const differenceInDays = parseInt((due.getTime() - today.getTime()) / (1000 * 3600 * 24) + '');
    const differenceInHours = parseInt((due.getTime() - today.getTime()) / (1000 * 3600) + '');
    const timeLeft = {
        days: Math.abs(differenceInDays),
        hours: Math.abs(differenceInHours),
        overdue: differenceInHours < 0 ? true : false
    };
    return timeLeft;
}
export const parseTimestamp = (timestamp) => { var _a; return `${(timestamp === null || timestamp === void 0 ? void 0 : timestamp.split('T')[0]) || ''} ${((_a = timestamp === null || timestamp === void 0 ? void 0 : timestamp.split('T')[1]) === null || _a === void 0 ? void 0 : _a.split(/[+-.zZ]/)[0]) || ''} ${getUserTimeZoneName()}`; };
const getUserTimeZoneName = () => {
    const profile = UserService.getTokenUserInfo();
    return (profile && profile.timezone)
        ? moment.tz(profile.timezone).zoneAbbr()
        : '';
};
export const parseTimestampToDate = (timestamp) => `${(timestamp === null || timestamp === void 0 ? void 0 : timestamp.split('T')[0]) || ''}`;
