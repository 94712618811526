import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDownloadSuggestions } from "./utils";
import DownloadFileButton from "./DownloadingModal";
const DownloadingSuggestions = ({ lastStage, job, downloadCallback }) => {
    const [recentFiles, setRecentFiles] = useState([]);
    const { t } = useTranslation('common');
    useEffect(() => {
        const entries = getDownloadSuggestions();
        if (lastStage && entries && entries.has(lastStage)) {
            const files = entries.get(lastStage);
            if (files)
                initRecentFiles(files);
        }
    }, []);
    const initRecentFiles = (files) => {
        files.forEach((f) => {
            if (f.fileType === 'TCERT' || f.fileType === 'CSV') {
                if (f.fileType === 'TCERT' && !job.hasTranslationCertificate)
                    return;
                setRecentFiles(r => [...r, { stageId: job.id, fileType: f.fileType, isReady: true, stageType: f.stageType }]);
            }
            else {
                const result = getFileInfo(f, job.stages);
                if (result)
                    setRecentFiles(r => [...r, result]);
            }
        });
    };
    const getFileInfo = (file, stages) => {
        const stage = stages.find(s => s.stageType === file.stageType);
        if (!stage)
            return null;
        const isReady = !!stage.readyFileTypes.find(type => type === file.fileType);
        return { stageId: stage.id, fileType: file.fileType, stageType: file.stageType, isReady };
    };
    return (React.createElement(React.Fragment, null, !!recentFiles.length && (React.createElement(React.Fragment, null,
        React.createElement("h4", null, t('dashboard.downloadRecentFiles')),
        recentFiles.map(f => React.createElement(DownloadFileButton, { activeStage: lastStage, showName: true, file: f, downloadCallback: downloadCallback }))))));
};
export default DownloadingSuggestions;
