import React from 'react';
import styled from 'styled-components';
const BackgroundWrapper = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(244,244,244);
  height: 100vh;
`;
const Background = ({ children }) => {
    return (React.createElement(BackgroundWrapper, null, children));
};
export default Background;
