import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Table, Button, Icon, Popup } from 'semantic-ui-react';
import JobNamePopup from 'dashboards/shared/JobNamePopup';
import PriceWithExchangeRatePopup from 'dashboards/shared/PriceWithExchangeRatePopup';
import { parseTimestamp } from 'helpers/DateHandler';
import { showDateCountdown } from 'helpers/utils';
import store from 'reduxStore/store';
import DownloadingOptions from './DownloadingOptions';
import FinishedActionButtons from './FinishedActionButtons';
import JobInfo from './JobInfo';
import UnfinishedActionButtons from './UnfinishedActionButtons';
import JobOverviewRowInner from './JobOverviewRowInner';
import JobPreviewButton from 'dashboards/shared/JobPreviewBtn';
import { useHistory } from 'react-router-dom';
import { useLogger } from 'logger/Logger';
const ActionButtonsWrapper = styled.div `
  display: flex;
  flex-direction: row;
`;
const JobOverviewRow = ({ job, status, setReopenJobId, openCommentsModal }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { t } = useTranslation('common');
    const { logger } = useLogger();
    const history = useHistory();
    const { exchangeRate } = store.getState().userProfile;
    const rowCanBeExpanded = (stages) => {
        const assignments = stages
            .map(stage => stage.assignments.length)
            .reduce((prev, current) => {
            return prev + current;
        });
        return assignments !== 0;
    };
    const openEditor = (assignmentId) => {
        logger.info(`User - Opening editor: ${assignmentId}`);
        history.push({
            pathname: `/editor/${assignmentId}`,
            state: { id: assignmentId }
        });
    };
    const showLastestStageDealine = (stages) => {
        var _a, _b;
        return ((_b = (_a = stages.find(({ assignments, stageStatus }) => assignments.length && stageStatus != 'DONE')) === null || _a === void 0 ? void 0 : _a.assignments[0]) === null || _b === void 0 ? void 0 : _b.dueDate) || '';
    };
    const stageInProgress = (stages) => {
        var _a;
        return (_a = stages
            .find(s => ["STARTED", "PENDING_ACCEPTANCE", "IN_PROGRESS", "NO_EXECUTORS"].includes(s.stageStatus))) === null || _a === void 0 ? void 0 : _a.stageType;
    };
    return React.createElement(Table.Body, null,
        React.createElement(Table.Row, { warning: status == 'IN_PROGRESS' ? moment(job.dueDate).isBefore() : false, "data-testid": `overview-row--${job.id}` },
            React.createElement(Table.Cell, { "data-testid": `expand` }, rowCanBeExpanded(job.stages) && React.createElement(Button, { size: "mini", icon: isExpanded ? "angle up" : "angle down", "data-testid": `${job.id}_expand`, onClick: () => setIsExpanded(prevState => !prevState) })),
            React.createElement(Table.Cell, { "data-testid": `ext-project-id` }, job.externalProjectId),
            React.createElement(Table.Cell, { "data-testid": `file-name`, width: "1" },
                React.createElement(JobNamePopup, { name: job.name, testMode: job.type == 'EXAMINATION', charityMode: job.type == 'CHARITY' })),
            React.createElement(Table.Cell, { "data-testid": `languages` },
                t(`languages.${job.langFrom.toLowerCase()}`),
                " ",
                React.createElement(Icon, { name: "arrow right" }),
                ' ',
                " ",
                t(`languages.${job.langTo.toLowerCase()}`)),
            status === 'IN_PROGRESS' && React.createElement(Table.Cell, { "data-testid": `due-date` },
                showDateCountdown(job.dueDate),
                moment(job.dueDate).isBefore() && React.createElement(Icon, { name: "attention" })),
            status == 'DELIVERED' && React.createElement(Table.Cell, { "data-testid": `delivery-date` }, job.deliveryDate ? parseTimestamp(job.deliveryDate) : job.status === 'CANCELLED' ? t(`jobStatus.${job.status}`) : ''),
            status == 'IN_PROGRESS' && React.createElement(Table.Cell, { "data-testid": `deadline` }, showLastestStageDealine(job.stages) ? showDateCountdown(showLastestStageDealine(job.stages)) : ''),
            status == 'IN_PROGRESS' && React.createElement(Table.Cell, { "data-testid": `status` },
                t(`jobStatus.${job.status}`),
                " ",
                stageInProgress(job.stages) ? `(${t(`stages.${stageInProgress(job.stages)}`)})` : ""),
            React.createElement(Table.Cell, { textAlign: 'right', "data-testid": `cost` },
                React.createElement(PriceWithExchangeRatePopup, { cost: job.totalPrice, customCurrencyCost: job.userCurrencyTotalPrice, exchangeRate: exchangeRate })),
            React.createElement(Table.Cell, { "data-testid": `actions` },
                React.createElement(ActionButtonsWrapper, null,
                    React.createElement(DownloadingOptions, { job: job }),
                    status == 'DELIVERED' && React.createElement(FinishedActionButtons, { job: job, setReopenJobId: setReopenJobId }),
                    status == 'IN_PROGRESS' && React.createElement(UnfinishedActionButtons, { job: job }),
                    React.createElement(Popup, { basic: true, position: "top center", hoverable: true, on: ['hover', 'click'], content: t('commentsModal.comments'), trigger: React.createElement(Button, { "data-testid": 'comment_btn', icon: "sticky note outline", onClick: () => openCommentsModal(job.id) }) }),
                    React.createElement(JobInfo, { job: job }),
                    React.createElement(JobPreviewButton, { job: job, onClick: openEditor })))),
        isExpanded && React.createElement(Table.Row, null,
            React.createElement(Table.Cell, { colSpan: "11" }, job.stages.length !== 1 && React.createElement(JobOverviewRowInner, { job: job, status: status }))));
};
export default JobOverviewRow;
