import axios from 'axios';
export async function getEthereumAddress() {
    const response = await axios.get('/api/langcoin/wallet_address');
    return response.data;
}
export async function updateEthereumAddress(ethereumAddress) {
    await axios.post('/api/langcoin/wallet_address', ethereumAddress);
}
export async function deleteEthereumAddress() {
    await axios.delete('/api/langcoin/wallet_address');
}
