/* eslint-disable react/display-name */
import React from 'react';
import { Tab, Container } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Styled } from 'StyledComponents';
import MyProfile from '../components/MyProfile/MyProfile';
import MyRoles from '../components/MyRoles/MyRoles';
import MyGenres from '../components/MyGenres/MyGenres';
import MySkills from '../components/MySkills/MySkills';
import Nda from '../components/Nda/Nda';
const style = { border: 0 };
const SettingsView = () => {
    const { t } = useTranslation('common');
    const panes = [{
            menuItem: t('settings.mainSettings'),
            render: () => (React.createElement(Tab.Pane, { style: style },
                React.createElement(MyProfile, null)))
        },
        {
            menuItem: t('settings.mySkills'),
            render: () => (React.createElement(Tab.Pane, { style: style },
                React.createElement(MySkills, null)))
        },
        {
            menuItem: t('settings.myRoles'),
            render: () => (React.createElement(Tab.Pane, { style: style },
                React.createElement(MyRoles, null)))
        },
        {
            menuItem: t('settings.myGenres'),
            render: () => (React.createElement(Tab.Pane, { style: style },
                React.createElement(MyGenres, null)))
        },
        {
            menuItem: t('settings.nda'),
            render: () => (React.createElement(Tab.Pane, { style: style },
                React.createElement(Nda, null)))
        }];
    return React.createElement(Container, null,
        React.createElement(Styled.H1, null, t('settings.myProfile')),
        React.createElement(Tab, { panes: panes }));
};
export default SettingsView;
