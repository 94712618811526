import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Loader, Table } from "semantic-ui-react";
import { getDefaultQaTable } from "api-operations/community/admin";
const DefaultQaTable = () => {
    const [qaTable, setQaTable] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { t } = useTranslation('common');
    useEffect(() => {
        (async () => {
            try {
                const res = await getDefaultQaTable();
                setQaTable(res);
                setIsLoading(false);
            }
            catch (error) {
                // TODO
            }
        })();
    }, []);
    if (isLoading)
        return React.createElement(Loader, null);
    return React.createElement(Table, { celled: true },
        React.createElement(Table.Header, null,
            React.createElement(Table.HeaderCell, null, t(`qaTables.error`)),
            React.createElement(Table.HeaderCell, null, t(`qaTables.severity`)),
            React.createElement(Table.HeaderCell, null, t(`qaTables.description`)),
            React.createElement(Table.HeaderCell, null, t(`qaTables.example`))),
        React.createElement(Table.Body, null, qaTable !== null
            && qaTable.map(({ error, severity, description, example }) => React.createElement(Table.Row, { key: error },
                React.createElement(Table.Cell, null, t(`qaTables.${error}`)),
                React.createElement(Table.Cell, null, severity),
                React.createElement(Table.Cell, null, description),
                React.createElement(Table.Cell, null, example)))));
};
export default DefaultQaTable;
