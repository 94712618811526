import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AdminIcon, CoinIcon, CommunityIcon, CompanyIcon, EnhancerIcon, ProfileIcon, RedactorIcon, ReputationBoardIcon, RequesterIcon, SignOutIcon, SupportIcon, TipsknowledgeIcon, TrustChainIcon, TrustMinerIcon, MenuCloseIcon, MenuOpenIcon, LogoIcon, LogoTextIcon } from 'assets/icons';
import { useTokenization } from 'providers/TokenizationProvider';
import MenuItem from './MenuItem';
import UserService from 'UserService';
const NavBarWrapper = styled.div `
  height: 100vh;
  top: 0;
  left: 0;
  box-shadow: 0 3px 12px rgba(7, 25, 57, 0.08);
  display: flex;
  flex-flow: column;
  overflow: auto;
  padding: 16px 8px;
  max-width: 250px;
`;
const Nav = styled.nav `
  position: relative;
  height: 100vh;
  z-index: 99;
  background-color: white;
  box-shadow: 0 3px 12px rgba(7, 25, 57, 0.08);
`;
const ToggleMenuSize = styled.div `
  position: absolute;
  bottom: 51px;
  right: -16px;
  cursor: pointer;
`;
const Divider = styled.hr `
  width: 100%;
  border: none;
  border-bottom: 1px solid #DADDE1;
  margin: 16px 0;
  align-self: center;
`;
const Spacer = styled.div `
  flex-grow: 1;
`;
const LogoWrapper = styled.div `
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 3px 12px;
`;
const NavBar = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState((localStorage.getItem('menuExpanded') || 'true') === 'true');
    const { isTokenizationEnabled } = useTokenization();
    const { t } = useTranslation('common');
    useEffect(() => {
        localStorage.setItem('menuExpanded', `${isDrawerOpen}`);
    }, [isDrawerOpen]);
    const checkRoles = (roles, exact) => {
        return exact
            ? UserService.hasRoles(roles)
            : UserService.hasAnyRole(roles);
    };
    const rolesList = [
        { to: '/requester', text: t('userRoles.ROLE_BUYER'), icon: RequesterIcon, roles: ['ROLE_BUYER'] },
        { to: '/redactor', text: t('userRoles.ROLE_REDACTOR'), icon: RedactorIcon, roles: ['ROLE_REDACTOR'] },
        { to: '/enhancer', text: t('userRoles.ROLE_ENHANCER'), icon: EnhancerIcon, roles: ['ROLE_ENHANCER'] },
        { to: '/trust-miner', text: t('userRoles.ROLE_TRUST_MINER'), icon: TrustMinerIcon, roles: ['ROLE_TRUST_MINER'] }
    ];
    const helpList = [
        { href: process.env.KNOWLEDGE_URL || 'https://exfluencyecosystem.zendesk.com', text: t('menu.knowledge'), icon: TipsknowledgeIcon, roles: ['ROLE_PLAIN'] },
        { to: '/system-support', text: t('menu.support'), icon: SupportIcon, roles: ['ROLE_PLAIN'] }
    ];
    const mainList = [
        { to: '/trust-chain', text: t('menu.trustChain'), icon: TrustChainIcon, roles: ['ROLE_PLAIN'] },
        { to: '/reputation-board', text: t('menu.reputationBoard'), icon: ReputationBoardIcon, roles: ['ROLE_PLAIN'] },
        ...(isTokenizationEnabled ? [{ to: '/langcoin', text: t('menu.langCoin'), icon: CoinIcon, roles: ['ROLE_PLAIN'] }] : []),
        { to: '/companies', toAlt: '/company', text: t('menu.company'), icon: CompanyIcon, roles: ['ROLE_PLAIN'] },
        { to: '/communities', toAlt: '/community', text: t('menu.communities'), icon: CommunityIcon, roles: ['ROLE_PLAIN'] }
    ];
    const restList = [
        { to: '/settings', text: t('menu.profile'), icon: ProfileIcon, roles: ['ROLE_PLAIN'] },
        { href: process.env.DASHBOARDS_URL || 'http://localhost:3000', text: t('menu.admin'), icon: AdminIcon, roles: ['ROLE_EXFLUENCY_ADMIN', 'ROLE_COMMUNITY_MANAGER', 'ROLE_PLATFORM_MANAGER', 'ROLE_SUPPORT', 'ROLE_GATED_COMMUNITY_MANAGER'] }
    ];
    const logoutList = [
        { to: '/logout', text: t('menu.signOut'), icon: SignOutIcon, roles: ['ROLE_PLAIN'] }
    ];
    const handleMenuToggle = () => {
        setIsDrawerOpen(() => !isDrawerOpen);
    };
    const renderList = (list) => {
        return React.createElement("div", null, list.filter(({ roles }) => checkRoles(roles)).map(options => React.createElement(MenuItem, { key: options.text, ...options, open: isDrawerOpen })));
    };
    return React.createElement(Nav, { id: 'app-navbar' },
        React.createElement(NavBarWrapper, null,
            React.createElement(LogoWrapper, null,
                LogoIcon,
                isDrawerOpen && LogoTextIcon),
            renderList(rolesList),
            React.createElement(Divider, null),
            renderList(helpList),
            React.createElement(Divider, null),
            renderList(mainList),
            React.createElement(Spacer, null),
            renderList(restList),
            React.createElement(Divider, null),
            renderList(logoutList)),
        React.createElement(ToggleMenuSize, { onClick: handleMenuToggle }, isDrawerOpen ? MenuCloseIcon : MenuOpenIcon));
};
export default NavBar;
