import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import ReactDOM from 'react-dom'
import axios from 'axios'

import 'semantic-ui-css/semantic.min.css'
import 'react-datepicker/dist/react-datepicker.css'

import AppProviders from 'AppProviders'

import ProfileProvider from 'auth/ProfileProvider'
import Signup from './auth/Signup'
import Logout from './auth/Logout'
import SendPasswordReset from './auth/SendPasswordReset'
import PasswordReset from './auth/PasswordReset'
import PreventWhenLogged from './auth/PreventWhenLogged'
import VerifyEmail from './auth/VerifyEmail'

import BuyerDashboard from './dashboards/buyer/buyerDashboard/BuyerDashboard'
import SettingsView from './settings/settingsView/SettingsView'
import SelectExecutor from './dashboards/buyer/selectExecutor/SelectExecutor'
import XflView from './xfl/XflView'
import Companies from './groups/companies/Companies'
import Communities from './groups/communities/Communities'
import CommunityPanel from './groups/communities/CommunityPanel'
import CompanyPanel from './groups/companies/CompanyPanel'
import Page404 from './Page404'
import CookieNotification from './Home/CookieNotification/CookieNotification'
import SystemSupport from './systemSupport/SystemSupport'
import TermsAndConditions from './termsAndConditions/TermsAndConditions'
import TrustChainView from './trustChainView/TrustChainView'
import ReputationBoard from './reputationBoard/ReputationBoard'
import LegalDocument from './public/LegalDocument'

import 'react-toastify/dist/ReactToastify.min.css'
import MainAppTemplate from 'templates/MainAppTemplate'
import UniversalTemplate from 'templates/UniversalTemplate'

import EditorTemplate from 'templates/EditorTemplate'
import ChooseEditor from './ChooseEditor'
import ExecutorRoutes from 'executor/routes'
import TranslationCertificate from 'public/TranslationCertificate'

import UserService from 'UserService'
import ResendLink from 'auth/ResendLink'
import RootPage from 'auth/RootPage'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.get['Content-Type'] = 'application/json'
axios.defaults.headers.put['Content-Type'] = 'application/json'
axios.interceptors.request.use(config =>
  UserService.unauthenticatedPath() ? Promise.resolve(config)

    : new Promise(resolve => UserService.updateToken(function () {
      const token = UserService.getToken()
      if (token) config.headers.Authorization = `Bearer ${token}`
      resolve(config)
    }))
)

UserService.initKeycloak( () =>
  ReactDOM.render(
    <AppProviders>
      <BrowserRouter>
        <div>
          {!document.cookie.split(';').some(c => c.trim().startsWith('CloseCookieNotification='))&&<CookieNotification />}
          <Switch>
            <Route exact path="/" component={RootPage} />
            <Route exact path="/signup"
              render={() => <PreventWhenLogged>
                <Signup />
              </PreventWhenLogged>}
            />
            <Route exact path="/forgot-password"
              render={() => <PreventWhenLogged>
                <SendPasswordReset />
              </PreventWhenLogged>}
            />
            <Route exact path="/reset-password"
              render={() => <PreventWhenLogged>
                <PasswordReset />
              </PreventWhenLogged>}
            />
            <Route exact path="/resend-link"
              render={() => <PreventWhenLogged>
                <ResendLink />
              </PreventWhenLogged>}
            />
            <Route exact path="/translation-certificate/:id" component={TranslationCertificate} />
            
            <Route exact path="/verify-email" component={VerifyEmail} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/sso/logout" component={Logout} />
            <Route exact path="/terms-of-service">
              <LegalDocument legalDocument='GENERAL_TERMS_SERVICE_PROVIDERS'/>
            </Route>
            <Route exact path="/privacy-notice">
              <LegalDocument legalDocument='PRIVACY_POLICY_REQUESTERS'/>
            </Route>

            <ProfileProvider>

              <MainAppTemplate>
                <Route path="/requester">
                  <UniversalTemplate>
                    <BuyerDashboard />
                  </UniversalTemplate>
                </Route>
                
                <Route path="/enhancer">
                  <ExecutorRoutes perspective='enhancer' />
                </Route>

                <Route path="/trust-miner">
                  <ExecutorRoutes perspective='trustminer' />
                </Route>

                <Route path="/redactor">
                  <ExecutorRoutes perspective='redactor' />
                </Route>

                <Route path="/correction">
                  <ExecutorRoutes perspective='correction' />
                </Route>

                <Route exact path="/editor" render={props =>
                  <EditorTemplate>
                    <ChooseEditor {...props} />
                  </EditorTemplate>} 
                />

                <Route exact path="/editor/:id" render={props =>
                  <EditorTemplate>
                    <ChooseEditor {...props} />
                  </EditorTemplate>} 
                />

                <Route path="/langcoin" >
                  <UniversalTemplate>
                    <XflView />
                  </UniversalTemplate>
                </Route>

                <Route exact path="/communities">
                  <UniversalTemplate>
                    <Communities />
                  </UniversalTemplate>
                </Route>

                <Route exact path="/companies" >
                  <UniversalTemplate>
                    <Companies />
                  </UniversalTemplate>
                </Route>

                <Route path="/company/:id">
                  <UniversalTemplate>
                    <CompanyPanel />
                  </UniversalTemplate>
                </Route>

                <Route path="/community/:id"
                  render={props => <UniversalTemplate>
                    <CommunityPanel {...props} />
                  </UniversalTemplate>}
                />

                <Route exact path="/trust-chain"
                  render={props => <UniversalTemplate>
                    <TrustChainView {...props} />
                  </UniversalTemplate>}
                />

                <Route exact path="/trust-chain/:userName"
                  render={props => <UniversalTemplate>
                    <TrustChainView {...props} />
                  </UniversalTemplate>}
                />

                <Route exact path="/reputation-board"
                  render={props => <UniversalTemplate>
                    <ReputationBoard scope="global" {...props} />
                  </UniversalTemplate>}
                />

                <Route path="/reputation-board/community/:groupId"
                  render={props => <UniversalTemplate>
                    <ReputationBoard scope="community" {...props} />
                  </UniversalTemplate>}
                />

                <Route exact path="/reputation-board/company/:groupId"
                  render={props => <UniversalTemplate>
                    <ReputationBoard scope="company" {...props} />
                  </UniversalTemplate>}
                />

                <Route exact path="/select-executor">
                  <UniversalTemplate>
                    <SelectExecutor />
                  </UniversalTemplate>
                </Route>

                <Route exact path="/settings">
                  <UniversalTemplate>
                    <SettingsView />
                  </UniversalTemplate>
                </Route>

                <Route exact path="/system-support">
                  <UniversalTemplate>
                    <SystemSupport />
                  </UniversalTemplate>
                </Route>
                <Route exact path="/terms-and-conditions" >
                  <UniversalTemplate>
                    <TermsAndConditions />
                  </UniversalTemplate>
                </Route>
              </MainAppTemplate>
            </ProfileProvider>

            <Redirect path="/buyer" exact to="/requester" />
            <Redirect path="/trustminer" exact to="/trust-miner" />
            <Route path="*" component={Page404} />
          </Switch>
        </div>
      </BrowserRouter>
    </AppProviders>,
    document.getElementById('root')
  )
)