import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import { getContactPersonInfo, setContactPersonInfo } from 'api-operations/company/admin';
import { emailRegex } from 'helpers/utils';
const ContactPersonComponent = () => {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [mail, setMail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isChanged, setIsChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const originalContactPersonObj = useRef(null);
    const params = useParams();
    const { t } = useTranslation('common');
    useEffect(() => {
        (async () => {
            try {
                const response = await getContactPersonInfo(params.id);
                if (response) {
                    setName(response.name);
                    setSurname(response.surname);
                    setMail(response.mail);
                    setPhoneNumber(response.phone);
                    originalContactPersonObj.current = response;
                }
                setIsLoading(false);
            }
            catch (error) {
                // TODO how to display error on download info?
            }
        })();
    }, []);
    useEffect(() => {
        var _a, _b, _c, _d;
        if ([name, surname, mail, phoneNumber].some(field => !field)) {
            setIsChanged(false);
            return;
        }
        if (((_a = originalContactPersonObj.current) === null || _a === void 0 ? void 0 : _a.name) === name
            && ((_b = originalContactPersonObj.current) === null || _b === void 0 ? void 0 : _b.surname) === surname
            && ((_c = originalContactPersonObj.current) === null || _c === void 0 ? void 0 : _c.mail) === mail
            && ((_d = originalContactPersonObj.current) === null || _d === void 0 ? void 0 : _d.phone) === phoneNumber) {
            setIsChanged(false);
            return;
        }
        setIsChanged(true);
    }, [name, surname, mail, phoneNumber]);
    const handleSubmit = async () => {
        if ([name, surname, mail, phoneNumber].some(field => !field))
            return;
        if (!emailRegex.test(mail)) {
            setIsEmailValid(false);
            return;
        }
        setIsEmailValid(true);
        const contactPerson = { name, surname, mail, phone: phoneNumber };
        try {
            await setContactPersonInfo(params.id, contactPerson);
            originalContactPersonObj.current = contactPerson;
            setIsChanged(false);
            toast.success(t('notification.informationUpdated'));
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
    };
    return React.createElement(Form, { onSubmit: handleSubmit },
        React.createElement(Form.Group, null,
            React.createElement(Form.Input, { value: name, onChange: (e, { value }) => setName(value), required: true, label: t('companies.firstName'), width: 8 }),
            React.createElement(Form.Input, { value: surname, onChange: (e, { value }) => setSurname(value), required: true, label: t('companies.lastName'), width: 8 })),
        React.createElement(Form.Group, null,
            React.createElement(Form.Input, { value: mail, onChange: (e, { value }) => setMail(value), required: true, type: "email", label: t('companies.mail'), width: 8, error: !isEmailValid && t('companies.emailValid') }),
            React.createElement(Form.Input, { value: phoneNumber, onChange: (e, { value }) => setPhoneNumber(value), required: true, label: t('companies.phoneNumber'), width: 8 })),
        React.createElement(Form.Group, { style: { textAlign: 'center' } },
            React.createElement(Form.Button, { primary: true, content: t('companies.updateContactPerson'), width: 16, disabled: isLoading || !isChanged, loading: isLoading })));
};
export default ContactPersonComponent;
