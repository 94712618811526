import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
const Container = styled.div `
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 10rem 0 0 0;
`;
const StyledLink = styled(Link) `
  color: #000000;
  font-size: 2rem;
  padding: 2rem;
  text-decoration: underline;

  &:visited {
    color: #000000;
  }

  &:hover {
    color: #363535;
  }
`;
const TermsAndConditions = () => {
    const { t } = useTranslation('common');
    return (React.createElement(Container, null,
        React.createElement(StyledLink, { target: "_blank", to: "/terms-of-service" }, t('termsAndConditions.termsOfService')),
        React.createElement(StyledLink, { target: "_blank", to: "/privacy-notice" }, t('termsAndConditions.privacyNotice'))));
};
export default TermsAndConditions;
