// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".taxonomySelector{display:flex;flex-direction:row;align-items:center;justify-content:center;padding-left:0 !important}.taxonomySelector .searchRow{display:flex;flex-direction:row}.taxonomySelector .searchRow input{flex-grow:1;margin-right:.5rem}.taxonomySelector>div{flex-grow:1}.taxonomySelector>div input{flex-shrink:1 !important}.modalContent{padding-bottom:0 !important}.modalContent svg{margin-bottom:-6px}.inputRemove{padding:0 2px;background-color:rgba(0,0,0,0);color:red}.inputRemove:hover{background-color:#a9a7a7}", "",{"version":3,"sources":["webpack://./src/helpers/components/taxonomySelector/TaxonomySelector.scss"],"names":[],"mappings":"AAAA,kBACC,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,sBAAA,CACA,yBAAA,CAEA,6BACC,YAAA,CACA,kBAAA,CAEA,mCACC,WAAA,CACA,kBAAA,CAIF,sBACC,WAAA,CAEA,4BACC,wBAAA,CAKH,cACC,2BAAA,CAEA,kBACC,kBAAA,CAIF,aACC,aAAA,CACA,8BAAA,CACA,SAAA,CAGD,mBACC,wBAAA","sourcesContent":[".taxonomySelector {\n\tdisplay: flex;\n\tflex-direction: row;\n\talign-items: center;\n\tjustify-content: center;\n\tpadding-left: 0 !important;\n\n\t.searchRow {\n\t\tdisplay: flex;\n\t\tflex-direction: row;\n\n\t\tinput {\n\t\t\tflex-grow: 1;\n\t\t\tmargin-right: .5rem;\n\t\t}\n\t}\n\n\t> div {\n\t\tflex-grow: 1;\n\n\t\tinput {\n\t\t\tflex-shrink: 1 !important;\n\t\t}\n\t}\n}\n\n.modalContent {\n\tpadding-bottom: 0 !important; /* There is a line at the bottom, so let's allow SVG viewport stick to it. Important, as Semantic's selector would override it otherwise. */\n\n\tsvg {\n\t\tmargin-bottom: -6px; /* For some reason browser adds extra space - this removes it */\n\t}\n}\n\n.inputRemove {\n\tpadding: 0 2px;\n\tbackground-color: transparent;\n\tcolor: red;\n}\n\n.inputRemove:hover{\n\tbackground-color: rgb(169, 167, 167);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"taxonomySelector": "taxonomySelector",
	"searchRow": "searchRow",
	"modalContent": "modalContent",
	"inputRemove": "inputRemove"
};
export default ___CSS_LOADER_EXPORT___;
