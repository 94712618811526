import { downloadStageAttachment } from 'api-operations/requester/stage';
import { IconNgBuilder } from 'editorNextGen/icons';
import { downloadSvg } from 'editorNextGen/icons/download';
import { HTTPLogger } from "logger/HTTPLoggerStatic";
export default class AttachmentsPanel extends HTMLElement {
    constructor(attachments = [], stageId) {
        super();
        this.stageId = stageId;
        this.className = "ui attachments";
        this.attachments = attachments;
    }
    set attachments(attachments) {
        Array.from(this.querySelectorAll("a")).forEach(a => a.remove());
        attachments.forEach(attachment => this.appendChild(new AttachmentElement(attachment, this.stageId)));
    }
}
class AttachmentElement extends HTMLElement {
    constructor(attachment, stageId) {
        super();
        this.className = "attachment-box";
        const label = document.createElement("div");
        label.textContent = attachment.name;
        this.appendChild(label);
        const onClick = () => {
            downloadStageAttachment(stageId, attachment.fileId, attachment.name);
            HTTPLogger.info(`User - download assignment attachment ${attachment.fileId}`);
        };
        const metadata = document.createElement("div");
        metadata.className = "flex jc-flex-end";
        new IconNgBuilder(downloadSvg, metadata).build();
        this.appendChild(metadata);
        this.addEventListener("click", onClick);
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-attachments", AttachmentsPanel));
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-attachment", AttachmentElement));
