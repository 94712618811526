import React, { useState, useEffect, useRef } from 'react';
import { Grid, Pagination, Search, Select } from 'semantic-ui-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getDashboardOptionsFromStorage, rowsOptions } from 'helpers/utils';
const PaginationWrapper = styled.div `
  text-align: center;
  padding: 20px 0;
`;
const StyledGrid = styled(Grid) `
  margin: 0 0 10px 0 !important;

  &&&& { 
  .row .column {
    padding-left: 0 !important;
    display: flex;
  }

  .row:not(:first-child) {
    padding-top: 0 !important;
  }

  .row .column:last-child {
    padding-right: 0 !important;
  }
}
`;
const PagedJobsAdminView = ({ children, totalResults, fetchData, tableIdentifier, statuses }) => {
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [searchInput, setSearchInput] = useState(undefined);
    const [limit, setLimit] = useState(getDashboardOptionsFromStorage(tableIdentifier, 'limit', rowsOptions));
    const { t } = useTranslation('common');
    const activePage = useRef(1);
    useEffect(() => {
        localStorage.setItem(`${tableIdentifier}PaginationOptions`, JSON.stringify({ limit }));
    }, [limit]);
    useEffect(() => {
        if (searchInput === undefined)
            return;
        setIsSearchLoading(true);
        const timeoutId = setTimeout(() => {
            activePage.current = 1;
            handePageChange();
            setIsSearchLoading(false);
        }, 1000);
        // eslint-disable-next-line consistent-return
        return () => {
            clearTimeout(timeoutId);
        };
    }, [searchInput]);
    useEffect(() => {
        activePage.current = 1;
        handePageChange();
    }, [limit, activePage]);
    const handePageChange = (active) => {
        const offset = (active ? active : activePage.current) * limit - limit;
        const options = { limit, offset, search: searchInput, statuses };
        fetchData && fetchData(options);
        activePage.current = active ? active : activePage.current;
    };
    const totalPages = Math.ceil(totalResults / limit);
    return React.createElement(React.Fragment, null,
        React.createElement(StyledGrid, { verticalAlign: 'bottom', stackable: true, padded: true },
            React.createElement(Grid.Row, { columns: 16 },
                React.createElement(Grid.Column, { width: 1 },
                    React.createElement("label", null, t('pagination.rowsPerPage')),
                    React.createElement(Select, { name: 'rowsPerPage', onChange: (_, data) => { setLimit(data.value); }, options: rowsOptions, value: limit, fluid: true })),
                React.createElement(Grid.Column, { width: 12 }),
                React.createElement(Grid.Column, { width: 3 },
                    React.createElement(Search, { open: false, loading: isSearchLoading, onSearchChange: (e) => { setSearchInput(e.target.value); }, value: searchInput, input: { fluid: true } })))),
        children,
        totalResults > 0 && React.createElement(PaginationWrapper, null,
            React.createElement(Pagination, { totalPages: totalPages, activePage: activePage.current, onPageChange: (_, data) => handePageChange(data.activePage) })));
};
export default PagedJobsAdminView;
