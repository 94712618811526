import { getJobAndStageCommentsForAssignment } from "api-operations/editor/editor";
import CommentsPanel from "./CommentsPanel";
import i18next from "i18next";
import EditorModule from "../EditorModule";
import "./style.scss";
import { HTTPLogger } from "logger/HTTPLoggerStatic";
import { IconNgBuilder } from "editorNextGen/icons";
import { commentSvg } from "editorNextGen/icons/comment";
export default class CommentsModule extends EditorModule {
    constructor(editor) {
        super(editor);
        this.moduleId = "CommentsModule";
        this.section = document.createElement("section");
        this.section.className = "commentsSection";
        editor.rightSidePanel.appendChild(this.section);
        const heading = document.createElement("div");
        heading.className = "flex align-center widget-header";
        new IconNgBuilder(commentSvg, heading).build();
        const title = document.createElement("span");
        title.textContent = i18next.t("editor.comments.comments");
        heading.appendChild(title);
        this.section.appendChild(heading);
        this.showCommentsBtn = new IconNgBuilder(commentSvg, this.editor.header.toolBar)
            .setOnClick(() => { this.showComments = !this.showComments; })
            .setToggable(true)
            .build();
        this.showComments = false;
        getJobAndStageCommentsForAssignment(editor.assignmentId)
            .then(comments => this.loadComments(comments))
            .catch(error => HTTPLogger.error(`Couldn't fetch job and stage comments for assignment ${editor.assignmentId}: ${error}`));
    }
    loadComments(comments) {
        const commentsPanel = new CommentsPanel(comments);
        this.section.appendChild(commentsPanel);
        if (comments && comments.length > 0) {
            this.showCommentsBtn.setActive();
            this.showComments = true;
        }
    }
    get showComments() {
        var _a, _b;
        return (_b = ((_a = this.section) === null || _a === void 0 ? void 0 : _a.style.display) == "") !== null && _b !== void 0 ? _b : false;
    }
    set showComments(showComments) {
        this.section.style.display = showComments ? "" : "none";
    }
    unload() {
        var _a;
        (_a = this.section) === null || _a === void 0 ? void 0 : _a.remove();
    }
}
