import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import { parseTimestamp } from 'helpers/DateHandler';
import PriceWithExchangeRatePopup from 'dashboards/shared/PriceWithExchangeRatePopup';
import store from 'reduxStore/store';
import { useTokenization } from 'providers/TokenizationProvider';
const AssignmentInfo = ({ status, assignment }) => {
    const { exchangeRate } = store.getState().userProfile;
    const { t } = useTranslation('common');
    const { isTokenizationEnabled } = useTokenization();
    function langugageString(sourceLang, targetLang) {
        return sourceLang == targetLang
            ? t(`languages.${sourceLang.toLowerCase()}`)
            : `${t(`languages.${sourceLang.toLowerCase()}`)} → ${t(`languages.${targetLang.toLowerCase()}`)}`;
    }
    return React.createElement(React.Fragment, null,
        React.createElement(Table, { basic: "very", collapsing: true, compact: "very" },
            React.createElement(Table.Body, null,
                assignment.assigner && React.createElement(Table.Row, null,
                    React.createElement(Table.Cell, null, t('dashboard.buyerName')),
                    React.createElement(Table.Cell, null, assignment.assigner)),
                React.createElement(Table.Row, null,
                    React.createElement(Table.Cell, null, t('dashboard.stageType')),
                    React.createElement(Table.Cell, null, t(`dashboard.${assignment.stageType}`))),
                React.createElement(Table.Row, null,
                    React.createElement(Table.Cell, null, t('dashboard.jobLanguages')),
                    React.createElement(Table.Cell, null, langugageString(assignment.jobLangFrom, assignment.jobLangTo))),
                React.createElement(Table.Row, null,
                    React.createElement(Table.Cell, null, t('dashboard.genre')),
                    React.createElement(Table.Cell, null, t(`genre.${assignment.genre}`))),
                React.createElement(Table.Row, null,
                    React.createElement(Table.Cell, null, t('dashboard.uploadDate')),
                    React.createElement(Table.Cell, null, parseTimestamp(assignment.uploadDate))),
                assignment.dueDate && React.createElement(Table.Row, null,
                    React.createElement(Table.Cell, null, t('dashboard.dueDate')),
                    React.createElement(Table.Cell, null, parseTimestamp(assignment.dueDate))),
                assignment.claimDate && React.createElement(Table.Row, null,
                    React.createElement(Table.Cell, null,
                        " ",
                        t('dashboard.claimDate')),
                    React.createElement(Table.Cell, null, parseTimestamp(assignment.claimDate))),
                assignment.deliveryDate && React.createElement(Table.Row, null,
                    React.createElement(Table.Cell, null, t('dashboard.deliveryDate')),
                    React.createElement(Table.Cell, null, parseTimestamp(assignment.deliveryDate))),
                React.createElement(Table.Row, null,
                    React.createElement(Table.Cell, null, t('dashboard.estimatedPrice')),
                    React.createElement(Table.Cell, null,
                        React.createElement(PriceWithExchangeRatePopup, { cost: assignment.price, customCurrencyCost: assignment.userCurrencyPrice, exchangeRate: exchangeRate }))),
                isTokenizationEnabled && assignment.cxfl !== null && assignment.cxfl !== undefined && React.createElement(Table.Row, null,
                    React.createElement(Table.Cell, null, t('dashboard.earnedLangCoin')),
                    React.createElement(Table.Cell, null, assignment.cxfl / 100)),
                status === 'ACCEPTED' && React.createElement(Table.Row, null,
                    React.createElement(Table.Cell, null, t('dashboard.doneIn')),
                    React.createElement(Table.Cell, null,
                        Math.floor(assignment.progress * 100),
                        " %")))));
};
export default AssignmentInfo;
