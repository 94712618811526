import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import WarningAmberOutlined from '@mui/icons-material/WarningAmberOutlined';
import { deleteFile } from "./expiringFiles";
import { MAX_FILE_SIZE, formatFileSize } from "./FilesUpload";
import Alert from '@mui/material/Alert';
import { styled } from "@mui/material/styles";
import { FileItemTooltip, FileItemTooltipTitle } from "./FileUploadItemTooltip";
import { useLogger } from "logger/Logger";
export const textColor = (severity) => {
    switch (severity) {
        case "error": return "#EC5351";
        case "warning": return "#EE9C3C";
        default: return "#555657";
    }
};
export const FileProgress = styled('span') `
  color: ${({ uploaded }) => uploaded ? '#3A65F6' : '#89A0EF'};
  align-self: center;
`;
export const Progress = styled('progress')({
    position: 'relative',
    top: 0,
    right: 0,
    appearance: 'none',
    width: '100%',
    borderRadius: '0px 16px 16px 0px',
    border: 'none',
    overflow: 'hidden',
    height: '2px',
    '&:indeterminate': {
        '@keyframes indeterminate-progress-animation': {
            'from': {
                marginLeft: '-2rem'
            },
            'to': {
                marginLeft: '100%'
            }
        },
        '&::-moz-progress-bar': {
            width: '2rem',
            animation: 'indeterminate-progress-animation 1s ease infinite',
            transition: 'none'
        },
        '&::-webkit-progress-value': {
            width: '2rem',
            animation: 'indeterminate-progress-animation 1s ease infinite',
            transition: 'none'
        }
    },
    '&::-moz-progress-bar': {
        backgroundColor: '#3A65F6',
        // borderRadius: '0px 16px 16px 0px',
        animation: 'indeterminate-progress-animation 0s ease 0'
        /* Seems like a bug in firefox – won't stop animating without this */
    },
    '&::-webkit-progress-value': {
        backgroundColor: '#3A65F6',
        // borderRadius: '0px 16px 16px 0px',
        animation: 'indeterminate-progress-animation 0s ease 0'
        /* Seems like a bug in firefox – won't stop animating without this */
    },
    '&::-webkit-progress-bar': {
        height: '2px',
        backgroundColor: 'transparent'
    }
});
export const TrashIcon = ({ severity }) => React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.2334 4C10.0508 4 9.88272 4.09954 9.79494 4.25966L8.70389 6.25H6.37488H4.5C4.22386 6.25 4 6.47386 4 6.75C4 7.02614 4.22386 7.25 4.5 7.25H5.87488V19.4991C5.87488 19.7752 6.09874 19.9991 6.37488 19.9991H17.6242C17.9003 19.9991 18.1242 19.7752 18.1242 19.4991V7.25H19.4991C19.7752 7.25 19.9991 7.02614 19.9991 6.75C19.9991 6.47386 19.7752 6.25 19.4991 6.25H17.6242H15.2988L14.2316 4.26339C14.1445 4.10119 13.9753 4 13.7912 4H10.2334ZM9.84437 6.24984L10.5295 5H13.4922L14.1636 6.24984H9.84437ZM6.87488 7.25H17.1242V18.9991H6.87488V7.25ZM10.499 9.99969C10.7752 9.99969 10.999 10.2236 10.999 10.4997V15.3744C10.999 15.6505 10.7752 15.8744 10.499 15.8744C10.2229 15.8744 9.99902 15.6505 9.99902 15.3744V10.4997C9.99902 10.2236 10.2229 9.99969 10.499 9.99969ZM14.0001 10.4997C14.0001 10.2236 13.7762 9.99969 13.5001 9.99969C13.2239 9.99969 13.0001 10.2236 13.0001 10.4997V15.3744C13.0001 15.6505 13.2239 15.8744 13.5001 15.8744C13.7762 15.8744 14.0001 15.6505 14.0001 15.3744V10.4997Z", fill: `${textColor(severity)}` }));
export const IconContainer = styled('span')({
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: 'whitesmoke',
        borderRadius: '20px'
    }
});
export const FileSize = styled('span')({
    whiteSpace: 'nowrap',
    padding: '0px 10px 0px 10px',
    display: 'flex'
});
export const FileName = styled('span')({});
export const FileDescription = styled('span')({});
export const AlertIcon = ({ severity }) => {
    switch (severity) {
        case "error": return React.createElement(ErrorOutlineOutlinedIcon, null);
        case "warning": return React.createElement(WarningAmberOutlined, null);
        default: return null;
    }
};
export const langMissmatch = (expectedLanguage, detectedLanguages) => {
    if (!expectedLanguage)
        return false;
    if (!detectedLanguages)
        return false;
    const [detectedLanguage] = detectedLanguages.filter(dl => dl.lang == expectedLanguage);
    return !detectedLanguage;
};
const ErrorWithPopup = ({ severity, validationError }) => {
    return React.createElement(FileItemTooltip, { arrow: true, severity: severity, title: React.createElement(FileItemTooltipTitle, { severity: severity, description: validationError.description, message: validationError.message }) },
        React.createElement(FileDescription, null, validationError.description));
};
const FileUploadItem = ({ file: { toUploadFile, uploadedFile }, onFileRemove, onUpload, expectedLanguage, placing, uploadFiles }) => {
    const { t } = useTranslation('filesUpload');
    const [progress, setProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [isError, setIsError] = useState(!!(uploadedFile === null || uploadedFile === void 0 ? void 0 : uploadedFile.typedValidationError));
    const [typedValidationError, setTypedValidationError] = useState(uploadedFile === null || uploadedFile === void 0 ? void 0 : uploadedFile.typedValidationError);
    const { logger } = useLogger();
    const languageMissmatch = langMissmatch(expectedLanguage, uploadedFile === null || uploadedFile === void 0 ? void 0 : uploadedFile.detectedLanguages);
    useEffect(() => {
        if (isUploading)
            return;
        handleUploadFile();
    }, []);
    const handleUploadFile = async () => {
        var _a;
        if (uploadedFile)
            return;
        if (!toUploadFile)
            return;
        if (!uploadFiles)
            return;
        const { file, id } = toUploadFile;
        setIsUploading(true);
        try {
            if (file.size > MAX_FILE_SIZE)
                throw new Error("File size too big");
            const response = await uploadFiles(file, setProgress);
            onUpload && onUpload(id, response, file.size);
        }
        catch (error) {
            setIsError(true);
            if ((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) {
                setTypedValidationError(error.response.data);
                const { name, size } = file;
                onUpload && onUpload(id, { id, name, size, typedValidationError: error.response.data }, size);
            }
        }
        setIsUploading(false);
    };
    const handleFileRemove = async () => {
        const fileId = uploadedFile === null || uploadedFile === void 0 ? void 0 : uploadedFile.id;
        if ((uploadedFile === null || uploadedFile === void 0 ? void 0 : uploadedFile.id) !== (toUploadFile === null || toUploadFile === void 0 ? void 0 : toUploadFile.id) && fileId) {
            try {
                await deleteFile(fileId);
            }
            catch (error) {
                logger.error(`User - error when removing file ${fileId} in ${placing} file upload`, error);
            }
        }
        onFileRemove(uploadedFile ? uploadedFile.id : (toUploadFile === null || toUploadFile === void 0 ? void 0 : toUploadFile.id) || '');
        logger.info(`User - removed file in ${placing} file upload: ${fileId}`);
    };
    const severity = isError ? 'error' : languageMissmatch ? 'warning' : 'info';
    const validationError = typedValidationError ? typedValidationError : languageMissmatch ? { description: t('verifySourceLanguageHeader'), message: t('verifySourceLanguageMessage') } : null;
    const fileSize = (uploadedFile === null || uploadedFile === void 0 ? void 0 : uploadedFile.size) || (toUploadFile === null || toUploadFile === void 0 ? void 0 : toUploadFile.file.size);
    return React.createElement(React.Fragment, null,
        progress !== 100 && progress !== 0 && React.createElement(Progress, { max: 100, value: progress }),
        React.createElement(Alert, { severity: severity, icon: React.createElement(AlertIcon, { severity: severity }), sx: { boxSizing: 'border-box', width: '100%' }, action: React.createElement(React.Fragment, null,
                validationError && React.createElement(ErrorWithPopup, { severity: severity, validationError: validationError }),
                !isError && !uploadedFile && progress !== 100 && React.createElement(FileProgress, { uploaded: !!uploadedFile },
                    progress,
                    "%"),
                fileSize && React.createElement(FileSize, null, formatFileSize(fileSize)),
                React.createElement(IconContainer, { onClick: handleFileRemove }, React.createElement(TrashIcon, { severity: severity }))) }, uploadedFile ? uploadedFile === null || uploadedFile === void 0 ? void 0 : uploadedFile.name : (toUploadFile === null || toUploadFile === void 0 ? void 0 : toUploadFile.file.name) || ''));
};
export default FileUploadItem;
