import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popup, Table } from 'semantic-ui-react';
import { parseTimestamp } from 'helpers/DateHandler';
import { Styled } from 'StyledComponents';
const AssignmentInfo = ({ assignment }) => {
    const { t } = useTranslation('common');
    return React.createElement(Popup, { flowing: true, hoverable: true, basic: true, position: "top center", key: assignment.id, trigger: (React.createElement(Button, { icon: "info" })), offset: [0, 0] },
        React.createElement(Styled.VerticalPopupContent, null,
            React.createElement(Table, { basic: "very", collapsing: true, compact: "very" },
                React.createElement(Table.Body, null,
                    React.createElement(Table.Row, null,
                        React.createElement(Table.Cell, null, t('dashboard.uploadDate')),
                        React.createElement(Table.Cell, null, parseTimestamp(assignment.uploadDate))),
                    React.createElement(Table.Row, null,
                        React.createElement(Table.Cell, null, t('dashboard.dueDate')),
                        React.createElement(Table.Cell, null, parseTimestamp(assignment.dueDate))),
                    ('claimDate' in assignment && assignment.claimDate) && React.createElement(Table.Row, null,
                        React.createElement(Table.Cell, null,
                            " ",
                            t('dashboard.claimDate')),
                        React.createElement(Table.Cell, null, parseTimestamp(assignment.claimDate))),
                    assignment.deliveryDate && React.createElement(Table.Row, null,
                        React.createElement(Table.Cell, null, t('dashboard.deliveryDate')),
                        React.createElement(Table.Cell, null, parseTimestamp(assignment.deliveryDate))),
                    'cxfl' in assignment && assignment.cxfl !== null && assignment.cxfl !== undefined && React.createElement(Table.Row, null,
                        React.createElement(Table.Cell, null, t('dashboard.earnedLangCoin')),
                        React.createElement(Table.Cell, null, assignment.cxfl / 100)),
                    'trustChainScore' in assignment && assignment.trustChainScore !== null && assignment.trustChainScore !== undefined && React.createElement(Table.Row, null,
                        React.createElement(Table.Cell, null, t('dashboard.enhancersScore')),
                        React.createElement(Table.Cell, null, Math.round(assignment.trustChainScore * 100) / 100))))));
};
export default AssignmentInfo;
