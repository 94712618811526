import React from 'react';
export const RequesterIcon = React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("rect", { width: "24", height: "24", rx: "12", fill: "#3A65F6" }),
    React.createElement("rect", { x: "5.48387", y: "5.48387", width: "12.0323", height: "12.0323", rx: "2.41936", stroke: "white", strokeWidth: "0.967745" }),
    React.createElement("line", { x1: "8.48387", y1: "8.87062", x2: "14.5161", y2: "8.87062", stroke: "white", strokeWidth: "0.967745", strokeLinecap: "round" }),
    React.createElement("line", { x1: "8.48387", y1: "11.2901", x2: "14.5161", y2: "11.2901", stroke: "white", strokeWidth: "0.967745", strokeLinecap: "round" }),
    React.createElement("line", { x1: "8.48387", y1: "13.71", x2: "14.5161", y2: "13.71", stroke: "white", strokeWidth: "0.967745", strokeLinecap: "round" }),
    React.createElement("circle", { cx: "15.3547", cy: "15.355", r: "4.51613", fill: "#3A65F6", stroke: "white", strokeWidth: "0.967745" }),
    React.createElement("line", { x1: "13.6164", y1: "15.4273", x2: "17.0931", y2: "15.4273", stroke: "white", strokeWidth: "0.967745", strokeLinecap: "round" }),
    React.createElement("line", { x1: "15.2834", y1: "13.6167", x2: "15.2834", y2: "17.0934", stroke: "white", strokeWidth: "0.967745", strokeLinecap: "round" }));
