import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Icon, Popup, Table } from "semantic-ui-react";
import { getMembersOverview } from "api-operations/community/admin";
import { useParams } from 'react-router';
import PagedMembersOverview from './PagedMembersOverview';
import TablePlaceholder from 'helpers/components/TablePlaceholder';
import { Styled } from 'StyledComponents';
import styled from 'styled-components';
const EmailWrapper = styled.div `
  max-width: ${({ widthInRem }) => widthInRem + 'rem'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const MembersOverview = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [membersOverview, setMembersOverview] = useState([]);
    const [resultsCount, setResultsCount] = useState(0);
    const [isError, setIsError] = useState(false);
    const [paginationOptions, setPaginationOptions] = useState({});
    const { t } = useTranslation('common');
    const params = useParams();
    const fetchData = async (paginationFilterOptions) => {
        if (isError) {
            setIsError(false);
            setIsLoading(true);
        }
        if (paginationFilterOptions)
            setPaginationOptions(paginationFilterOptions);
        try {
            const response = await getMembersOverview(params.id, { ...paginationFilterOptions || paginationOptions });
            setMembersOverview(response.results);
            setResultsCount(response.totalCount);
        }
        catch (error) {
            setIsError(true);
        }
        setIsLoading(false);
    };
    const orderOptions = [
        { key: 'ROLE_BUYER', text: t('userRoles.ROLE_BUYER'), value: 'ROLE_BUYER' },
        { key: 'ROLE_REDACTOR', text: t('userRoles.ROLE_REDACTOR'), value: 'ROLE_REDACTOR' },
        { key: 'ROLE_ENHANCER', text: t('userRoles.ROLE_ENHANCER'), value: 'ROLE_ENHANCER' },
        { key: 'ROLE_TRUST_MINER', text: t('userRoles.ROLE_TRUST_MINER'), value: 'ROLE_TRUST_MINER' }
    ];
    return React.createElement(React.Fragment, null,
        React.createElement(Styled.H3, null,
            "Members Overview (",
            resultsCount,
            ")"),
        React.createElement(PagedMembersOverview, { orderOptions: orderOptions, totalResults: resultsCount, fetchData: fetchData, tableIdentifier: 'membersOverview' },
            React.createElement(Table, { celled: true, selectable: true, striped: true },
                React.createElement(Table.Header, null,
                    React.createElement(Table.Row, null,
                        React.createElement(Table.HeaderCell, null, t(`gcUserOverview.login`)),
                        React.createElement(Table.HeaderCell, null, t(`gcUserOverview.name`)),
                        React.createElement(Table.HeaderCell, null, t(`gcUserOverview.surname`)),
                        React.createElement(Table.HeaderCell, null, t(`gcUserOverview.mail`)),
                        React.createElement(Table.HeaderCell, null, t(`gcUserOverview.roles`)),
                        React.createElement(Table.HeaderCell, null, t(`gcUserOverview.skills`)),
                        React.createElement(Table.HeaderCell, null, t(`gcUserOverview.workload`)))),
                isLoading || isError || !membersOverview.length ? React.createElement(TablePlaceholder, { isError: isError, isLoading: isLoading, noEntries: !membersOverview.length })
                    : React.createElement(Table.Body, null, membersOverview.map(({ email, login, name, roles, skills, surname, workload }) => React.createElement(React.Fragment, { key: login },
                        React.createElement(Table.Row, null,
                            React.createElement(Table.Cell, null, login),
                            React.createElement(Table.Cell, null, name),
                            React.createElement(Table.Cell, null, surname),
                            React.createElement(Table.Cell, { width: "2" },
                                React.createElement(Popup, { pinned: true, hoverable: true, trigger: React.createElement(EmailWrapper, { widthInRem: 12 }, email) },
                                    React.createElement("span", { style: { wordBreak: 'break-word' } }, email))),
                            React.createElement(Table.Cell, null, roles.map(role => React.createElement("div", null, t(`userRoles.${role}`)))),
                            React.createElement(Table.Cell, null, skills.map(({ langFrom, langTo }) => React.createElement("div", null,
                                t(`languages.${langFrom.toLowerCase()}`),
                                " ",
                                React.createElement(Icon, { name: "arrow right" }),
                                ' ',
                                " ",
                                t(`languages.${langTo.toLowerCase()}`)))),
                            React.createElement(Table.Cell, null, workload.map(({ count, stage, status }) => React.createElement("div", null,
                                t(`stages.${stage}`),
                                " ",
                                t(`stageStatus.${status}`),
                                " ",
                                count))))))))));
};
export default MembersOverview;
