/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Dropdown, Header, Icon, Segment, Tab, Table } from 'semantic-ui-react';
import { getReputationBoard } from 'api-operations/reputationBoard';
import ErrorModal from 'errors/components/ErrorModal';
import TaxonomySelector from 'helpers/components/taxonomySelector/TaxonomySelector';
import Star from 'helpers/components/Star';
import Loader from 'helpers/components/loader/ExfluencyLoader';
import { languagesDictionary } from 'dictionaries/dictionaries';
import { Styled } from 'StyledComponents';
const LanguageRow = styled.div `
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const dropdownStyle = {
    minWidth: '15rem',
    maxWidth: '15rem'
};
const arrowStyle = {
    margin: 'auto 4rem'
};
const ReputationBoard = ({ scope, groupId }) => {
    const { t } = useTranslation('common');
    const [languages] = useState(languagesDictionary.map(l => ({ key: l, value: l, text: t(`languages.${l}`) })));
    const [sourceLanguage, setSourceLanguage] = useState(null);
    const [targetLanguage, setTargetLanguage] = useState(null);
    const [skillsetNode, setSkillsetNode] = useState(null);
    const [loading, setLoading] = useState(true);
    const [tabLoading, setTabLoading] = useState(false);
    const [reputationData, setReputationData] = useState(null);
    const [groupName, setGroupName] = useState(null);
    const [errorWindowMessage, setErrorWindowMessage] = useState(null);
    const match = useParams();
    const currentTab = useRef(0);
    useEffect(() => {
        (async () => { await loadData(); })();
    }, []);
    useEffect(() => {
        if ([sourceLanguage, targetLanguage, skillsetNode].some(e => e !== null)) {
            loadData();
        }
    }, [sourceLanguage, targetLanguage, skillsetNode]);
    const loadData = async () => {
        if (currentTab.current == 1 && (sourceLanguage === null || targetLanguage === null)
            || currentTab.current == 2 && skillsetNode === null) {
            setReputationData(null);
            setLoading(false);
            setTabLoading(false);
            return;
        }
        try {
            const res = await getReputationBoard(scope, match.groupId || groupId || null, currentTab.current == 1 ? sourceLanguage : null, currentTab.current == 1 ? targetLanguage : null, currentTab.current == 2 ? skillsetNode : null);
            setReputationData(res);
            setLoading(false);
            setTabLoading(false);
            setGroupName(res.groupName || groupName);
        }
        catch (error) {
            setErrorWindowMessage(error);
            setLoading(false);
            setTabLoading(false);
        }
    };
    const handleTabChange = (e, data) => {
        currentTab.current = data.activeIndex;
        setTabLoading(true);
        loadData();
    };
    const renderTable = () => {
        return React.createElement(React.Fragment, null,
            (reputationData === null || reputationData.records.length == 0) && React.createElement(Segment, { placeholder: true },
                React.createElement(Header, { icon: true },
                    React.createElement(Icon, { name: "language" }),
                    t("reputationBoard.noEntries"))),
            reputationData !== null && reputationData.records.length > 0 && React.createElement(Table, null,
                React.createElement(Table.Header, null,
                    React.createElement(Table.Row, null,
                        React.createElement(Table.HeaderCell, { width: 2, textAlign: "center" }, t('reputationBoard.rank')),
                        React.createElement(Table.HeaderCell, { width: 10, textAlign: "center" }, t('reputationBoard.username')),
                        React.createElement(Table.HeaderCell, { width: 2, textAlign: "center" }, t('reputationBoard.assetsTranslated')),
                        React.createElement(Table.HeaderCell, { width: 2, textAlign: "center" }, t('reputationBoard.score')))),
                React.createElement(Table.Body, null, reputationData.records.map(({ rank, username, assetCount, score }) => React.createElement(Table.Row, { key: `${rank}` },
                    React.createElement(Table.Cell, { textAlign: "center" }, rank),
                    React.createElement(Table.Cell, { textAlign: "center" }, username),
                    React.createElement(Table.Cell, { textAlign: "center" }, assetCount),
                    React.createElement(Table.Cell, { textAlign: "center" },
                        React.createElement(Star, { value: score !== null ? score / 9.0 : null }),
                        "\u00A0",
                        score !== null ? score.toFixed(1) : "---",
                        score !== null && React.createElement(Styled.TranslucentSpan, null, "/9")))))));
    };
    if (loading)
        return React.createElement(Loader, null);
    if (errorWindowMessage !== null)
        return React.createElement(ErrorModal, { ...errorWindowMessage, onClose: () => window.location.reload() });
    return React.createElement(React.Fragment, null,
        scope == 'global' && React.createElement("h2", null, t("reputationBoard.globalReputationBoard")),
        React.createElement(Tab, { onTabChange: handleTabChange, panes: [
                { menuItem: t("reputationBoard.global"), render: () => React.createElement(Tab.Pane, { loading: tabLoading, style: { border: 'none' } }, renderTable()) },
                { menuItem: t("reputationBoard.languages"), render: () => {
                        return React.createElement(Tab.Pane, { loading: tabLoading, style: { border: 'none' } },
                            React.createElement(LanguageRow, null,
                                React.createElement(Dropdown, { style: dropdownStyle, fluid: true, search: true, selection: true, options: languages, placeholder: t('reputationBoard.sourceLanguage'), value: sourceLanguage || undefined, onChange: (e, { value }) => { setTabLoading(true); setSourceLanguage(value); } }),
                                React.createElement(Icon, { name: "arrow right", style: arrowStyle }),
                                React.createElement(Dropdown, { style: dropdownStyle, fluid: true, search: true, selection: true, options: languages, placeholder: t('reputationBoard.targetLanguage'), value: targetLanguage || undefined, onChange: (e, { value }) => { setTabLoading(true); setTargetLanguage(value); } })),
                            renderTable());
                    } },
                { menuItem: t("reputationBoard.skillsetNode"), render: () => {
                        return React.createElement(Tab.Pane, { loading: tabLoading, style: { border: 'none' } },
                            React.createElement(TaxonomySelector, { value: skillsetNode || '', dialogHeader: t('reputationBoard.skillsetNode'), placeholder: t('reputationBoard.skillsetNode'), onChange: (value) => { setTabLoading(true); setSkillsetNode(value); }, limit: 1 }),
                            renderTable());
                    } }
            ] }));
};
export default ReputationBoard;
