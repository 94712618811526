import { exfluencyLogoSvg } from "./logo.svg";
import './styles.scss';
export class ExfluencyLoader extends HTMLElement {
    constructor(fullscreen, background) {
        super();
        this.className = "loader-wrapper";
        if (background)
            this.style.backgroundColor = 'white';
        if (fullscreen)
            this.style.position = 'fixed';
        this.innerHTML = exfluencyLogoSvg;
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-exfluency-loader", ExfluencyLoader));
