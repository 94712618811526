import React from 'react';
import styled from 'styled-components';
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  margin: 0 ${(props) => props.marginless ? '0' : 'auto'};
  position: relative;
  flex-grow: 1;
  min-height: 100vh;
  max-width: ${(props) => props.marginless ? 'initial' : '90rem'};
  padding: 1rem .5rem;
`;
const UniversalTemplate = ({ children, marginless }) => React.createElement(Wrapper, { marginless: marginless }, children);
export default UniversalTemplate;
