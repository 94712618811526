import React, { useState } from "react";
import { Button, Comment as SemanticComment, Form } from "semantic-ui-react";
import moment from "moment";
import { useTranslation } from "react-i18next";
const CommentsPanel = ({ comments, postComment }) => {
    const [postCommentText, setPostCommentText] = useState("");
    const { t } = useTranslation('common');
    const postCommentClicked = () => {
        postComment && postComment(postCommentText);
        setPostCommentText("");
    };
    return React.createElement(SemanticComment.Group, null,
        comments.map(comment => React.createElement(SemanticComment, null,
            React.createElement(SemanticComment.Author, { as: "span" }, comment.author),
            React.createElement(SemanticComment.Metadata, null, moment(comment.creationTime).fromNow()),
            React.createElement(SemanticComment.Text, null, comment.text.split("\n").map(line => React.createElement("div", null,
                line,
                React.createElement("br", null)))),
            comment.replies && React.createElement(CommentsPanel, { comments: comment.replies }))),
        postComment && React.createElement(Form, { reply: true },
            React.createElement(Form.TextArea, { value: postCommentText, onChange: (_, data) => { setPostCommentText("" + data.value); }, placeholder: t('commentsPanel.addComment') }),
            React.createElement(Button, { content: t("commentsPanel.postComment"), onClick: postCommentClicked, labelPosition: 'left', icon: 'edit', primary: true })));
};
export default CommentsPanel;
