import React from 'react';
export const LogoTextIcon = React.createElement("svg", { width: "94", height: "20", viewBox: "0 0 94 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M6.26945 15.1576C5.15874 15.1576 4.15216 14.9127 3.31913 14.4579C2.48609 14.0031 1.82661 13.3385 1.34068 12.5339C0.854742 11.7292 0.646484 10.7847 0.646484 9.73523C0.646484 8.65076 0.889452 7.67125 1.34068 6.86665C1.7919 6.02706 2.41667 5.36239 3.215 4.90761C4.01332 4.41785 4.95048 4.17297 5.99177 4.17297C6.92893 4.17297 7.76196 4.41785 8.52557 4.87263C9.25448 5.3274 9.84454 5.95709 10.2611 6.72671C10.6776 7.49633 10.8858 8.40589 10.8511 9.42039L10.8164 10.295H3.28442C3.42326 11.0646 3.80506 11.6593 4.36042 12.0791C4.95048 12.5339 5.67938 12.7437 6.54712 12.7437C7.03306 12.7437 7.44957 12.6738 7.83138 12.5339C8.21319 12.3939 8.6297 12.1141 9.08093 11.7642L10.3999 13.6183C9.84454 14.1081 9.18506 14.5279 8.45615 14.7727C7.72725 15.0176 6.99835 15.1576 6.26945 15.1576ZM5.99177 6.51682C4.49925 6.51682 3.5968 7.18149 3.28442 8.54582H8.24789V8.47585C8.21318 7.91613 7.97022 7.42637 7.5537 7.07654C7.13719 6.72671 6.61654 6.51682 5.99177 6.51682Z", fill: "black" }),
    React.createElement("path", { d: "M11.5781 14.9475L15.5003 9.59517L11.6475 4.41772H14.945L17.2358 7.63614L19.1101 4.41772H22.2687L18.6589 9.59517L22.5811 14.9475H19.3531L16.9581 11.5892L14.8061 14.9475H11.5781Z", fill: "black" }),
    React.createElement("path", { d: "M24.9775 14.9476V7.00652H23.3809V4.66268H24.9775V3.61319C24.9775 2.94852 25.1163 2.3888 25.394 1.82907C25.6717 1.30433 26.0535 0.88454 26.5741 0.604678C27.0948 0.289833 27.6849 0.149902 28.3443 0.149902C28.7956 0.149902 29.2468 0.219868 29.6286 0.394781C30.0104 0.534712 30.3575 0.744609 30.6699 1.02447L29.8716 3.05347C29.698 2.94852 29.5245 2.84357 29.3509 2.77361C29.1774 2.70364 29.0038 2.66866 28.8303 2.66866C28.0667 2.66866 27.6848 3.05347 27.6848 3.82309V4.69766H29.9757V7.0415H27.6848V14.9476H24.9775Z", fill: "black" }),
    React.createElement("path", { d: "M31.7129 14.9478V0.185059H34.4203V14.9478H31.7129Z", fill: "black" }),
    React.createElement("path", { d: "M40.563 15.1574C39.4176 15.1574 38.5151 14.7726 37.8557 14.038C37.1962 13.3033 36.8838 12.2539 36.8838 10.9245V4.41772H39.5911V10.4348C39.5911 11.2044 39.7647 11.7991 40.1465 12.2189C40.4936 12.6387 41.0142 12.8835 41.639 12.8835C42.2638 12.8835 42.7844 12.6737 43.2009 12.2888C43.6174 11.904 43.8257 11.3793 43.8257 10.7496V4.45271H46.5331V14.9475H43.9645L43.8951 13.4783C43.548 13.968 43.0621 14.3878 42.472 14.7027C41.882 15.0175 41.2225 15.1574 40.563 15.1574Z", fill: "black" }),
    React.createElement("path", { d: "M54.0634 15.1576C52.9527 15.1576 51.9461 14.9127 51.1131 14.4579C50.28 14.0031 49.6206 13.3385 49.1346 12.5339C48.6487 11.7292 48.4404 10.7847 48.4404 9.73523C48.4404 8.65076 48.6834 7.67125 49.1346 6.86665C49.5858 6.02706 50.2106 5.36239 51.0089 4.90761C51.8073 4.41785 52.7444 4.17297 53.7857 4.17297C54.7229 4.17297 55.5559 4.41785 56.3195 4.87263C57.0484 5.3274 57.6385 5.95709 58.055 6.72671C58.4715 7.49633 58.6798 8.40589 58.6451 9.42039L58.6103 10.295H51.0784C51.2172 11.0646 51.599 11.6593 52.1544 12.0791C52.7444 12.5339 53.4733 12.7437 54.3411 12.7437C54.827 12.7437 55.2435 12.6738 55.6253 12.5339C56.0071 12.3939 56.4236 12.1141 56.8749 11.7642L58.1938 13.6183C57.6385 14.1081 56.979 14.5279 56.2501 14.7727C55.5212 15.0176 54.7923 15.1576 54.0634 15.1576ZM53.7857 6.51682C52.2932 6.51682 51.3907 7.18149 51.0784 8.54582H56.0418V8.47585C56.0071 7.91613 55.7642 7.42637 55.3476 7.07654C54.9311 6.72671 54.4105 6.51682 53.7857 6.51682Z", fill: "black" }),
    React.createElement("path", { d: "M60.5205 14.9478V4.41803H63.1237L63.1584 5.85232C63.5055 5.36256 63.9915 4.97775 64.5815 4.66291C65.1716 4.34806 65.7964 4.20813 66.4559 4.20813C68.8508 4.20813 70.0657 5.64242 70.0657 8.51101V14.9478H67.3583V8.7209C67.3583 7.21665 66.7335 6.48201 65.484 6.55197C64.8245 6.55197 64.3039 6.76187 63.8526 7.14668C63.4361 7.53149 63.1932 8.05623 63.1932 8.61596V14.9478H60.5205Z", fill: "black" }),
    React.createElement("path", { d: "M77.0761 15.1576C76.0695 15.1576 75.2018 14.9127 74.4035 14.4229C73.6052 13.9332 72.9804 13.3035 72.5292 12.4639C72.0779 11.6243 71.835 10.7147 71.835 9.66527C71.835 8.61578 72.0779 7.70623 72.5292 6.86665C72.9804 6.02706 73.6052 5.39737 74.4035 4.90761C75.2018 4.41785 76.0695 4.17297 77.0761 4.17297C78.0133 4.17297 78.881 4.34789 79.6446 4.69771C80.4082 5.04754 81.033 5.5373 81.4495 6.16699L79.957 7.95111C79.6793 7.5663 79.2975 7.25146 78.8116 6.97159C78.3257 6.69173 77.8397 6.5518 77.3191 6.5518C76.7637 6.5518 76.2778 6.69173 75.8266 6.97159C75.4101 7.25146 75.063 7.60128 74.82 8.09104C74.577 8.54582 74.4382 9.07056 74.4382 9.66527C74.4382 10.26 74.577 10.7847 74.82 11.2395C75.063 11.6943 75.4101 12.0791 75.8613 12.3589C76.3125 12.6388 76.7984 12.7787 77.3191 12.7787C77.8397 12.7787 78.3257 12.6738 78.7422 12.4289C79.1934 12.184 79.5752 11.8692 79.9223 11.4494L81.4148 13.2335C80.9636 13.8282 80.3388 14.283 79.5405 14.6328C78.8116 14.9826 77.9786 15.1576 77.0761 15.1576Z", fill: "black" }),
    React.createElement("path", { d: "M84.2607 19.5653L86.2044 15.0175L81.4492 4.41772H84.5037L87.1763 10.7146C87.2804 10.9945 87.4193 11.3093 87.5234 11.6941C87.5928 11.5542 87.6275 11.4143 87.6622 11.2743C87.6969 11.1344 87.7664 10.9945 87.8011 10.8196L90.0919 4.41772H93.2158L88.8077 14.9475L87.0028 19.5303H84.2607V19.5653Z", fill: "black" }));
