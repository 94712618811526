import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Dimmer, Loader, Modal } from 'semantic-ui-react';
import { deleteStageAttachment, uploadNewStageAttachment, downloadStageAttachment } from 'api-operations/requester/stage';
import DropzoneMultiple from 'helpers/components/DropzoneMultiple';
import FileRecord from './FileRecord';
import { allAttachmentsDescription, deleteJobAttachment, downloadAttachment, uploadJobAttachment } from 'api-operations/requester/job';
const FilesContainer = styled.div `
  background-color: whitesmoke;
  padding: .9rem;
`;
export const StageAttachmentModal = ({ stageAttachments, stageId, open, setOpen, readonly = false, header }) => {
    const [attachments, setAttachments] = useState(stageAttachments);
    const [uploadingFiles, setUploadingFiles] = useState([]);
    const { t } = useTranslation('common');
    const uploadNewFile = async (file) => {
        if (!stageId)
            return;
        if (stageAttachments.find(({ name }) => file.name == name) || uploadingFiles.find(fileName => file.name == fileName))
            return;
        setUploadingFiles(prevState => [...prevState, file.name]);
        try {
            const response = await uploadNewStageAttachment(stageId, file);
            setAttachments(prevState => [...prevState, response]);
            setUploadingFiles(prevState => prevState.filter(fileName => fileName !== file.name));
        }
        catch (error) {
            setUploadingFiles(prevState => prevState.filter(fileName => fileName !== file.name));
            toast.error(t('notification.error'));
        }
    };
    const deleteUploadedFile = async (fileId) => {
        if (!stageId)
            return;
        try {
            await deleteStageAttachment(stageId, fileId);
            setAttachments(prevState => prevState.filter(stageAttachment => stageAttachment.fileId !== fileId));
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
    };
    const downloadUploadedFile = async (fileId, fileName) => {
        if (!stageId)
            return;
        try {
            await downloadStageAttachment(stageId, fileId, fileName);
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
    };
    const modalHeader = React.createElement(Modal.Header, null, t(`attachmentsModal.stageAttachmentHeader.${header || "CORRECTION"}`));
    return (React.createElement(Modal, { open: open, size: "small", onClose: () => { setOpen(false); } },
        modalHeader,
        React.createElement(Modal.Content, null,
            !readonly && React.createElement(DropzoneMultiple, { acceptableFormats: "", placeholderText: t('dashboard.dragNdrop'), uploadNewFile: uploadNewFile }),
            React.createElement(FilesContainer, null,
                attachments.map(stageAttachment => React.createElement(FileRecord, { key: stageAttachment.fileId, readonly: readonly, stageAttachment: stageAttachment, deleteUploadedFile: deleteUploadedFile, downloadUploadedFile: downloadUploadedFile })),
                uploadingFiles.map(uploadingFile => React.createElement(FileRecord, { key: uploadingFile, uploadingFile: uploadingFile })))),
        React.createElement(Modal.Actions, null,
            React.createElement(Button, { size: "tiny", onClick: () => setOpen(false) }, t('common.close')))));
};
export const JobAttachmentModal = ({ jobId, open, setOpen, readonly = false }) => {
    const [attachments, setAttachments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadingFiles, setUploadingFiles] = useState([]);
    useEffect(() => {
        if (!jobId) {
            setAttachments([]);
            setIsLoading(true);
            return;
        }
        if (!open)
            return;
        setIsLoading(true);
        // fetch attachments
        allAttachmentsDescription(jobId)
            .then((response) => {
            setAttachments(response);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [jobId, open]);
    const { t } = useTranslation('common');
    const uploadNewFile = async (file) => {
        if (!jobId)
            return;
        if (attachments.find(({ name }) => file.name == name) || uploadingFiles.find(fileName => file.name == fileName))
            return;
        setUploadingFiles(prevState => [...prevState, file.name]);
        try {
            const response = await uploadJobAttachment(jobId, file);
            const asAttachment = response;
            setAttachments(prevState => [...prevState, asAttachment]);
            setUploadingFiles(prevState => prevState.filter(fileName => fileName !== file.name));
        }
        catch (error) {
            setUploadingFiles(prevState => prevState.filter(fileName => fileName !== file.name));
            toast.error(t('notification.error'));
        }
    };
    const deleteUploadedFile = async (fileId) => {
        if (!jobId)
            return;
        try {
            await deleteJobAttachment(jobId, fileId);
            setAttachments(prevState => prevState.filter(stageAttachment => stageAttachment.fileId !== fileId));
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
    };
    const downloadUploadedFile = async (fileId, fileName) => {
        if (!jobId)
            return;
        try {
            await downloadAttachment(jobId, fileId, fileName);
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
    };
    const attachmentsRender = React.createElement(React.Fragment, null, attachments.map(attachment => React.createElement(FileRecord, { key: attachment.fileId, readonly: readonly, stageAttachment: attachment, deleteUploadedFile: deleteUploadedFile, downloadUploadedFile: downloadUploadedFile })));
    const uploadRender = React.createElement(React.Fragment, null, uploadingFiles.map(uploadingFile => React.createElement(FileRecord, { key: uploadingFile, uploadingFile: uploadingFile })));
    const modalContent = React.createElement(React.Fragment, null,
        !readonly && React.createElement(DropzoneMultiple, { acceptableFormats: "", placeholderText: t('dashboard.dragNdrop'), uploadNewFile: uploadNewFile }),
        React.createElement(FilesContainer, null,
            attachmentsRender,
            uploadRender));
    const modalContentLoading = React.createElement(React.Fragment, null,
        React.createElement("div", { style: { height: "200px" } },
            React.createElement(Dimmer, { active: true, inverted: true },
                React.createElement(Loader, { text: true }, "Loading"))));
    return (React.createElement(Modal, { open: open, size: "small", onClose: () => { setOpen(false); } },
        React.createElement(Modal.Header, null, t("attachmentsModal.jobAttachmentHeader")),
        React.createElement(Modal.Content, null, isLoading ? modalContentLoading : modalContent),
        React.createElement(Modal.Actions, null,
            React.createElement(Button, { size: "tiny", onClick: () => setOpen(false) }, t('common.close')))));
};
