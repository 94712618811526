import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import queryString from 'query-string';
import ExfluencyLoader from 'helpers/components/loader/ExfluencyLoader';
import { verifyEmail } from 'api-operations/auth';
const Container = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;
const Message = styled.span `
  font-size: 1.6rem;
`;
const CookieNotification = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation('common');
    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            await verifyEmail(queryString.parse(location.search).token);
            setMessage(t('accountDescriptions.validActivationLink'));
        }
        catch (error) {
            setMessage(t('accountDescriptions.invalidActivationLink'));
            setError(true);
        }
        setLoading(false);
        setTimeout(() => {
            history.push('/');
        }, 7000);
    };
    if (loading) {
        return (React.createElement(Container, null,
            React.createElement(ExfluencyLoader, null)));
    }
    return (React.createElement(Container, null,
        React.createElement(Icon, { name: error ? 'times circle' : 'check circle', color: error ? 'red' : 'green', size: "huge" }),
        React.createElement(Message, null, message)));
};
export default CookieNotification;
