export default class IconButton extends HTMLElement {
    constructor(icon, popupContent) {
        super();
        this.onChangeFunc = null;
        this.onClickFunc = null;
        icon && (this.innerHTML = `<i class="${icon} icon"></i>`);
        this.className = "ui button icon";
        if (popupContent) {
            this.dataset.tooltip = popupContent;
            this.dataset.position = "bottom center";
        }
        this.tabIndex = -1;
        this.addEventListener("click", () => {
            this.togglable && (this.selected = !this.selected);
            this.onChange && this.onChange(this.selected);
            this.onClick && this.onClick();
        });
    }
    set onChange(onChange) {
        this.onChangeFunc = onChange;
    }
    get onChange() {
        return this.onChangeFunc;
    }
    set onClick(onClick) {
        this.onClickFunc = onClick;
    }
    get onClick() {
        return this.onClickFunc;
    }
    set selected(selected) {
        this.classList.toggle("active", selected);
    }
    get selected() {
        return this.classList.contains("active");
    }
    set togglable(togglable) {
        this.classList.toggle("toggle", togglable);
    }
    get togglable() {
        return this.classList.contains("toggle");
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-icon-button", IconButton));
