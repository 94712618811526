import FormattingPanel from "./FormattingPanel";
import EditorModule from "../EditorModule";
import i18next from "i18next";
import { IconNgBuilder } from "editorNextGen/icons";
import { formattingSvg } from "editorNextGen/icons/formatting";
import FormattingCreationModal from "./FormattingCreationModal";
import UniversalTagElement from "editorNextGen/UniversalTag";
export default class FormattingModule extends EditorModule {
    constructor(editor, active) {
        super(editor);
        this.moduleId = "FormattingModule";
        this._targetFormattingQuantity = 0;
        this._sourceFormattingQuantity = 0;
        this._selectedId = null;
        this.formattingPanel = new FormattingPanel();
        this.formattingCreationModal = new FormattingCreationModal();
        this.editor = editor;
        this.editor.domNode.appendChild(this.formattingCreationModal);
        this.active = active === undefined ? true : active;
        editor.leftSidePanel.appendChild(this.formattingPanel);
        this.formattingCreationModal.dropdown.onChange = (value) => { this.formattingId = value; };
        this.formattingShowButton = new IconNgBuilder(formattingSvg, this.editor.header.toolBar)
            .setToggable(true)
            .setOnClick(() => { this.active = !this.active; })
            .isActive(this.active)
            .build();
        this._createSelectionButton();
    }
    _createSelectionButton() {
        return new IconNgBuilder(formattingSvg, this.editor.selectionTools)
            .setOnClick(() => this.addFormatting())
            .setTooltip(i18next.t('editor.selectionTool.formatting'))
            .setIconFill("stroke").setId('formatting').build();
    }
    unload() {
        this.formattingPanel.remove();
    }
    addFormatting() {
        var _a, _b;
        const range = this.editor.selectionTools.lastRange;
        if (((_a = range === null || range === void 0 ? void 0 : range.startContainer) === null || _a === void 0 ? void 0 : _a.className) === 'caretGuard') {
            range.setStart(range.commonAncestorContainer, 0); // allow placing formatting at the start of asset
        }
        if (!range)
            return;
        (_b = this.editor.activeAsset) === null || _b === void 0 ? void 0 : _b.updateTagsState();
        this.formattingCreationModal.header = 'Mark formatting';
        this.formattingCreationModal.open = true;
        this.formattingCreationModal.callback = () => {
            var _a;
            if (!this._selectedId)
                return;
            const newTag = new UniversalTagElement(this._selectedId, undefined, true);
            newTag.editor = this.editor;
            newTag.appendChild(range.extractContents());
            range.insertNode(newTag);
            (_a = this.editor.activeAsset) === null || _a === void 0 ? void 0 : _a.updateTagsState();
            this.editor.activeAsset && this.editor.commit(this.editor.activeAsset);
            this._selectedId = null;
        };
    }
    isPanelShowable() {
        return this._sourceFormattingQuantity !== 0 && this._sourceFormattingQuantity !== this._targetFormattingQuantity && !!this._active;
    }
    updatePanelState() {
        this.formattingPanel.sourceQuantity = this._sourceFormattingQuantity;
        this.formattingPanel.targetQuantity = this._targetFormattingQuantity;
        this.formattingPanel.visible = this.isPanelShowable();
    }
    updateFormatting(sourceFormatting, targetFormatting) {
        this._targetFormattingQuantity = targetFormatting;
        this._sourceFormattingQuantity = sourceFormatting;
        this.updatePanelState();
    }
    set active(show) {
        var _a;
        this._active = show;
        this.editor.domNode.toggleAttribute('formatting', show);
        (_a = this.formattingShowButton) === null || _a === void 0 ? void 0 : _a.forceActiveState(show);
        this.updatePanelState();
    }
    get active() {
        return !!this._active;
    }
    set unusedFormatting(options) {
        this.formattingCreationModal.options = options;
    }
    set formattingId(id) {
        this._selectedId = id;
        const btn = this.formattingCreationModal.querySelector('.button.primary');
        if (!btn)
            return;
        btn.disabled = !id;
    }
}
