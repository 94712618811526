import { IconNgBuilder } from "editorNextGen/icons";
import { arrowDownSvg } from "editorNextGen/icons/arrowDown";
import i18next from "i18next";
export class ProjectDetails extends HTMLElement {
    constructor(projectName, language) {
        super();
        this.projectName = projectName;
        this.language = language;
        this.opened = true;
        this.panel = document.createElement("div");
        this.panel.className = "panel";
    }
    createDropdownPanel() {
        this.panel.appendChild(this.createEntry(`${i18next.t('dashboard.projectName')}: `, this.projectName));
        this.panel.appendChild(this.createEntry(`${i18next.t('dashboard.languages')}: `, this.language));
    }
    createEntry(label, value) {
        const container = document.createElement("div");
        const entryLabel = document.createElement("span");
        const entryValue = document.createElement("span");
        entryLabel.textContent = label;
        entryValue.textContent = value;
        entryValue.className = "value";
        container.appendChild(entryLabel);
        container.appendChild(entryValue);
        return container;
    }
    createDropdownButton() {
        const container = document.createElement("div");
        container.className = "flex align-center dropdown-base";
        const dropdownLabel = document.createElement("span");
        dropdownLabel.textContent = i18next.t('dashboard.projectDetails');
        container.appendChild(dropdownLabel);
        new IconNgBuilder(arrowDownSvg, container)
            .setToggable(true)
            .setIconFill('stroke')
            .setOnClick(() => { this.toggleDetails(); })
            .build();
        this.appendChild(container);
    }
    toggleDetails() {
        this.panel.classList.toggle("visible", this.opened);
        this.opened = !this.opened;
    }
    connectedCallback() {
        this.createDropdownButton();
        this.createDropdownPanel();
        this.appendChild(this.panel);
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-project-details", ProjectDetails));
