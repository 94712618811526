import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import PagedManageMembersCommunityView from './PagedManageMembersCommunityView';
import { getCommunityMembers } from 'api-operations/community/admin';
import TablePlaceholder from 'helpers/components/TablePlaceholder';
import ManageMembersRow from './ManageMembersRow';
import { useParams } from 'react-router';
const ManageMembers = ({ isAdmin }) => {
    const [communityMembers, setCommunityMembers] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const { t } = useTranslation('common');
    const params = useParams();
    const fetchData = async (paginationFilterOptions) => {
        if (isError) {
            setIsError(false);
            setIsLoading(true);
        }
        try {
            const response = await getCommunityMembers(params.id, paginationFilterOptions);
            setCommunityMembers(response.results);
            setTotalResults(response.totalCount);
        }
        catch (error) {
            setIsError(true);
        }
        setIsLoading(false);
    };
    return React.createElement(React.Fragment, null,
        React.createElement("h2", null,
            t(`gatedCommunities.members`),
            " (",
            totalResults,
            ")"),
        React.createElement(PagedManageMembersCommunityView, { fetchData: fetchData, tableIdentifier: 'communityManageMembers', totalResults: totalResults, statuses: ['MEMBER', 'SUSPENDED', 'REJECTED'] },
            React.createElement(Table, { celled: true },
                React.createElement(Table.Header, null,
                    React.createElement(Table.Row, null,
                        isAdmin && React.createElement(Table.HeaderCell, null),
                        React.createElement(Table.HeaderCell, null, t(`gatedCommunities.memberName`)),
                        React.createElement(Table.HeaderCell, null, t(`gatedCommunities.membershipProfile`)),
                        React.createElement(Table.HeaderCell, null, t(`gatedCommunities.membershipStatus`)),
                        isAdmin && React.createElement(Table.HeaderCell, null, t(`gatedCommunities.manage`)))),
                isLoading || isError || !communityMembers.length ? React.createElement(TablePlaceholder, { isError: isError, isLoading: isLoading, noEntries: !communityMembers.length })
                    : React.createElement(Table.Body, null, communityMembers.map(communityMember => React.createElement(ManageMembersRow, { communityMember: communityMember, key: communityMember.userId, isAdmin: isAdmin }))))));
};
export default ManageMembers;
