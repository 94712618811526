import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getExecutorsForStage } from 'api-operations/requester/stage';
import ExecutorSelection from './ExecutorSelection';
import ErrorModal from 'errors/components/ErrorModal';
import { assignExecutor } from 'api-operations/requester/stage';
import { useLogger } from 'logger/Logger';
const SelectExecutor = () => {
    const [executors, setExecutors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorWindowMessage, setErrorWindowMessage] = useState(null);
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation('common');
    const { logger } = useLogger();
    const timeoutId = useRef();
    useEffect(() => {
        logger.info('User - Entered select executor page');
        getExecutorsList();
        return () => {
            if (timeoutId.current)
                clearTimeout(timeoutId.current);
        };
    }, []);
    const getExecutorsList = async (selectBest = false) => {
        const { stageId } = location.state;
        try {
            const response = await getExecutorsForStage(stageId, selectBest);
            if (response.length == 0) {
                history.push('/requester');
                toast.info(t('notification.noExecutors'));
            }
            setExecutors(response);
            setIsLoading(false);
        }
        catch (error) {
            logger.error('System - Failed to get executor list');
            setErrorWindowMessage(error);
        }
    };
    const selectExecutor = async (executorId, selectBest) => {
        const { stageId } = location.state;
        try {
            await assignExecutor(stageId, executorId);
            logger.info('User - Selected executor');
            const id = setTimeout(() => {
                history.push('/requester');
            }, 4000);
            timeoutId.current = id;
        }
        catch (error) {
            logger.error('User - Failed to selecte executor');
            setErrorWindowMessage(error);
        }
    };
    if (errorWindowMessage !== null)
        return React.createElement(ErrorModal, { ...errorWindowMessage, onClose: () => window.location.reload() });
    return React.createElement(ExecutorSelection, { executors: executors, selectExecutor: selectExecutor, stageType: location.state.type, isLoading: isLoading, refreshExecutorsList: getExecutorsList });
};
export default SelectExecutor;
