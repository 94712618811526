import React from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
const AlignedIconGroup = styled(Icon.Group) `
  vertical-align: 20%
`;
class Star extends React.Component {
    clipPathForValue(value) {
        const angle = Math.max(Math.min(value, 1.0), 0.0) * 2 * Math.PI;
        return `50% 0, 50% 50%, ${50 + 100 * Math.sin(angle)}% ${50 - 100 * Math.cos(angle)}%`
            + (value > 0.875 ? ", 0% 0%" : "")
            + (value > 0.625 ? ", 0% 100%" : "")
            + (value > 0.375 ? ", 100% 100%" : "")
            + (value > 0.125 ? ", 100% 0" : "");
    }
    colorForValue(value) {
        if (value === null)
            return 'grey';
        if (value >= 1)
            return 'yellow';
        if (value >= 0.75)
            return 'green';
        if (value >= 0.5)
            return 'olive';
        if (value >= 0.25)
            return 'orange';
        return 'red';
    }
    render() {
        const { value } = this.props;
        const color = this.colorForValue(value);
        return React.createElement(AlignedIconGroup, { size: 'small' },
            React.createElement(Icon, { name: 'star', color: color, style: { clipPath: `polygon(${this.clipPathForValue(value || 0)})`, scale: '0.8', marginRight: '0' } }),
            React.createElement(Icon, { name: 'star outline', color: color }));
    }
}
export default Star;
