import React, { useState, useEffect, useRef } from 'react';
import { Button, Dropdown, Grid, Pagination, Search, Select } from 'semantic-ui-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import SelectMemo from 'helpers/SelectMemo';
import { getDashboardOptionsFromStorage, rowsOptions } from 'helpers/utils';
const PaginationWrapper = styled.div `
  text-align: center;
  padding: 20px 0;
`;
const InputsWrapper = styled.div `
  display: flex;
  justify-content: space-between;
`;
const SelectWrapper = styled.div `
  width: 48%;
  display: flex;
  flex-direction: column;
`;
const StyledGrid = styled(Grid) `
  margin: 0 0 10px 0 !important;

  &&&& { 
  .row .column {
    padding-left: 0 !important;
  }

  .row:not(:first-child) {
    padding-top: 0 !important;
  }

  .row .column:last-child {
    padding-right: 0 !important;
  }
}
`;
const Group = styled.div `
  display: flex;
  flex-direction: row;
`;
const StyledBtn = styled(Button) `
  background: none !important;
  padding-left: .3rem !important;
`;
const PaginationComp = ({ children, totalResults, orderOptions, fetchData, setFilterOptions, langs = false, interval, communityManagers = null, tableIdentifier, dates, statuses, setPaginationOptions }) => {
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [searchInput, setSearchInput] = useState(undefined);
    const [orderBy, setOrderBy] = useState(getDashboardOptionsFromStorage(tableIdentifier, 'orderBy', orderOptions));
    const [langTo, setLangTo] = useState(undefined);
    const [langFrom, setLangFrom] = useState(undefined);
    const [communityManager, setCommunityManager] = useState('');
    const [startDate, setstartDate] = useState('');
    const [endDate, setendDate] = useState('');
    const [limit, setLimit] = useState(getDashboardOptionsFromStorage(tableIdentifier, 'limit', rowsOptions));
    const { t } = useTranslation('common');
    const activePage = useRef(1);
    const intervalId = useRef();
    useEffect(() => {
        localStorage.setItem(`${tableIdentifier}PaginationOptions`, JSON.stringify({ limit, orderBy }));
    }, [limit, orderBy]);
    useEffect(() => {
        if (searchInput === undefined)
            return;
        setIsSearchLoading(true);
        const timeoutId = setTimeout(() => {
            activePage.current = 1;
            handePageChange();
            setIntervalFetch();
            setIsSearchLoading(false);
        }, 1000);
        // eslint-disable-next-line consistent-return
        return () => {
            clearTimeout(timeoutId);
            clearInterval(intervalId.current);
        };
    }, [searchInput]);
    useEffect(() => {
        activePage.current = 1;
        handePageChange();
        setIntervalFetch();
        return () => {
            clearInterval(intervalId.current);
        };
    }, [limit, orderBy, langFrom, langTo, activePage, communityManager, startDate, endDate]);
    useEffect(() => {
        setFilterOptions && setFilterOptions(startDate, endDate);
    }, [startDate, endDate]);
    const setIntervalFetch = () => {
        if (intervalId.current)
            clearInterval(intervalId.current);
        if (interval) {
            intervalId.current = setInterval(() => {
                handePageChange();
            }, interval);
        }
    };
    const handePageChange = (active) => {
        const [sortBy, sortDirection] = orderBy.split(' ');
        const offset = (active ? active : activePage.current) * limit - limit;
        setPaginationOptions && setPaginationOptions({ limit, offset, sortBy, sortDirection, search: searchInput, langFrom, langTo, communityManager, startDate, endDate, statuses });
        fetchData && fetchData({ limit, offset, sortBy, sortDirection, search: searchInput, langFrom, langTo, communityManager, startDate, endDate, statuses });
        activePage.current = active ? active : activePage.current;
    };
    const totalPages = Math.ceil(totalResults / limit);
    return React.createElement(React.Fragment, null,
        React.createElement(StyledGrid, { "data-testid": "overview--settings", verticalAlign: 'bottom', stackable: true, padded: true },
            React.createElement(Grid.Row, { columns: 16 },
                React.createElement(Grid.Column, { width: 1, "data-testid": "settings--rows" },
                    React.createElement("label", null, t('pagination.rowsPerPage')),
                    React.createElement(Select, { name: 'rowsPerPage', onChange: (_, data) => { setLimit(data.value); }, options: rowsOptions, value: limit, fluid: true })),
                React.createElement(Grid.Column, { width: 4, "data-testid": "settings--sort" },
                    React.createElement("label", null, t('pagination.sortBy')),
                    React.createElement(Select, { search: true, onChange: (_, data) => { setOrderBy(data.value); }, options: orderOptions, value: orderBy, fluid: true })),
                React.createElement(Grid.Column, { width: 8, "data-testid": "settings--filters" },
                    communityManagers && React.createElement(SelectWrapper, null,
                        React.createElement("label", null, t('pagination.communityManager')),
                        React.createElement(Dropdown, { selection: true, clearable: true, value: communityManager || '', onChange: (_, { value }) => setCommunityManager(value), options: communityManagers })),
                    langs && React.createElement(InputsWrapper, null,
                        React.createElement(SelectWrapper, null,
                            React.createElement(SelectMemo, { label: 'language_from', search: true, option: 'languages', setValue: setLangFrom, value: langFrom, clearable: true })),
                        React.createElement(SelectWrapper, null,
                            React.createElement(SelectMemo, { label: 'language_to', search: true, option: 'languages', setValue: setLangTo, value: langTo, clearable: true })))),
                React.createElement(Grid.Column, { width: 3, "data-testid": "settings--search" },
                    React.createElement(Search, { open: false, loading: isSearchLoading, onSearchChange: (e) => { setSearchInput(e.target.value); }, value: searchInput, input: { fluid: true } }))),
            dates && React.createElement(Grid.Row, null,
                React.createElement(Grid.Column, { width: 6 },
                    React.createElement(InputsWrapper, null,
                        React.createElement(SelectWrapper, null,
                            React.createElement("label", null, t('pagination.startDate')),
                            React.createElement(Group, null,
                                React.createElement("input", { type: "date", value: startDate, onChange: e => setstartDate(e.target.value) }),
                                React.createElement(StyledBtn, { icon: "cancel", onClick: () => setstartDate('') }))),
                        React.createElement(SelectWrapper, null,
                            React.createElement("label", null, t('pagination.endDate')),
                            React.createElement(Group, null,
                                React.createElement("input", { type: "date", value: endDate, onChange: e => setendDate(e.target.value) }),
                                React.createElement(StyledBtn, { icon: "cancel", onClick: () => setendDate('') }))))))),
        children,
        totalResults > 0 && React.createElement(PaginationWrapper, null,
            React.createElement(Pagination, { totalPages: totalPages, activePage: activePage.current, onPageChange: (_, data) => handePageChange(data.activePage) })));
};
export default PaginationComp;
