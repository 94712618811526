import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Menu, Popup } from 'semantic-ui-react';
import NewUpload from './UploadJob/NewUpload';
import TemplateManager from './templateManager/TemplateManager';
import RequesterDashboard from './dashboard/RequesterDashboard';
import Assignments from 'dashboards/shared/assignmentsInProgress';
const StyledNavLink = styled(NavLink) `
  &&&&:hover {
   background-color: rgba(0,0,0,.03)
  }

  &&&&.active {
    background-color: rgba(0,0,0,.05);
  }
`;
const BuyerDashboard = () => {
    const { t } = useTranslation('common');
    const company = useSelector(state => state.userProfile.company);
    const { path, url } = useRouteMatch();
    return React.createElement(React.Fragment, null,
        React.createElement(Menu, { stackable: true },
            React.createElement(Menu.Item, { as: StyledNavLink, to: `${url}`, exact: true }, t('dashboard.projects')),
            React.createElement(Menu.Item, { as: StyledNavLink, to: company ? `${url}/upload` : '#', disabled: !company }, company
                ? t('dashboard.newProject')
                : React.createElement(Popup, { basic: true, position: "bottom center", hoverable: true, on: ['hover', 'click'], content: t('dashboard.mustCompany'), trigger: React.createElement("span", null, t('dashboard.newProject')) })),
            React.createElement(Menu.Item, { as: StyledNavLink, to: `${url}/templates` }, t('dashboard.projectTemplates'))),
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: `${path}` },
                React.createElement(Assignments, { perspective: 'requester' }),
                React.createElement(RequesterDashboard, { status: "IN_PROGRESS" }),
                React.createElement(RequesterDashboard, { status: "DELIVERED" })),
            company && React.createElement(Route, { exact: true, path: `${path}/upload` },
                React.createElement(NewUpload, null)),
            React.createElement(Route, { exact: true, path: `${path}/templates` },
                React.createElement(TemplateManager, null)),
            React.createElement(Redirect, { from: `${path}/*`, to: `${path}` })));
};
export default BuyerDashboard;
