const ENTRY_NAME = "userRecentDownloads";
const MAX_SUGGESTIONS = 3;
export function getDownloadSuggestions() {
    const data = localStorage.getItem(ENTRY_NAME);
    return data
        ? new Map(JSON.parse(data))
        : new Map();
}
export function saveDownloadSuggestions(stage, file) {
    let entry = getDownloadSuggestions();
    let files = entry.get(stage) || [];
    if (files.findIndex(f => (f.stageType === file.stageType && f.fileType === file.fileType)) === -1) {
        files.push(file);
        if (files.length > MAX_SUGGESTIONS)
            files.shift();
        entry.set(stage, files);
        localStorage.setItem(ENTRY_NAME, JSON.stringify([...entry]));
    }
}
/**
 * Get Last Finished stage from Stage list
 *
 * @param stages
 * @returns Last DONE stage
 */
export function getLastFinishedStage(stages) {
    if (stages) {
        const finishedStages = stages
            .filter(s => !['TABLE_PARSING', 'DOCUMENT_PARSING', 'FILE_PREPARATION', 'FILE_PARSING'].includes(s.stageType))
            .filter(stage => stage.stageStatus === "DONE");
        if (finishedStages.length === stages.length)
            return "DONE";
        if (finishedStages.length > 0)
            return finishedStages.slice(-1)[0].stageType;
    }
    return null;
}
