import React, { useState } from 'react';
import { Button, Input, Message, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { addLanguageSkill, deleteLanguageSkill, changeLanguageSkillPrice } from 'api-operations/myExfluency/myExfluency';
import TaxonomySelector from 'helpers/components/taxonomySelector/TaxonomySelector';
import SelectMemo from 'helpers/SelectMemo';
import store from 'reduxStore/store';
const headers = ['language_from', 'language_to', 'taxonomy', 'hourlyRate', 'currency', ''];
const SkillsTable = ({ userLanguageSkills }) => {
    const [userSkills, setUserSkills] = useState([...userLanguageSkills]);
    const [taxonomy, setTaxonomy] = useState('');
    const [price, setPrice] = useState(0);
    const [sourceLanguage, setSourceLanguage] = useState('');
    const [targetLanguage, setTargetLanguage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [displayAddBnt, setDisplayAddBnt] = useState(true);
    const { currency } = store.getState().userProfile;
    const { t } = useTranslation('common');
    const updateSkillsPrice = (index, newPrice) => {
        setUserSkills(prevState => prevState.map((skill, i) => {
            if (i === index)
                return { ...skill, price: newPrice };
            return skill;
        }));
    };
    const addSkill = async () => {
        const compareSkills = (s1, s2) => ['language_from', 'language_to', 'taxonomy']
            .every(key => s1[key] == s2[key]);
        if (sourceLanguage === '' || targetLanguage === '') {
            setErrorMessage(t('settings.emptyLanguages'));
            return;
        }
        if (taxonomy === '') {
            setErrorMessage(t('settings.emptySkillsetNodes'));
            return;
        }
        if (price <= 0 || price === null) {
            setErrorMessage(t('settings.lowPrice'));
            return;
        }
        const newSkill = {
            language_from: sourceLanguage,
            language_to: targetLanguage,
            taxonomy: taxonomy,
            price: price,
            currency: currency
        };
        if (!userSkills.every((s) => !compareSkills(s, newSkill))) {
            setErrorMessage(t('settings.existedSkill'));
            return;
        }
        setErrorMessage('');
        try {
            await addLanguageSkill(newSkill);
        }
        catch (error) {
            return;
        }
        setUserSkills((prevState) => [...prevState, newSkill]);
        setDisplayAddBnt(true);
        setTaxonomy('');
        setSourceLanguage('');
        setTargetLanguage('');
        setPrice(0);
    };
    const deleteSkill = async (skillToDelete) => {
        await deleteLanguageSkill(userSkills[skillToDelete]);
        setUserSkills(userSkills.filter(e => e !== userSkills[skillToDelete]));
        setTaxonomy('');
    };
    const handleAddButton = () => {
        setDisplayAddBnt(false);
    };
    const handleDelete = (e) => {
        deleteSkill(e.currentTarget.name);
    };
    const handleSubmit = () => {
        addSkill();
    };
    const handleChangePrice = async (i) => {
        try {
            await changeLanguageSkillPrice(userSkills[i]);
        }
        catch (error) {
            toast.error(t('notification.error'));
            return;
        }
        toast.success(t('notification.skillUpdated'));
    };
    return React.createElement(React.Fragment, null,
        React.createElement(Table, { celled: true, striped: true, textAlign: "center", columns: "11" },
            React.createElement(Table.Header, null,
                React.createElement(Table.Row, null, headers.map(e => React.createElement(Table.HeaderCell, { key: e }, t(`settings.${e}`))))),
            React.createElement(Table.Body, null,
                userSkills.map((e, i) => React.createElement(Table.Row, { key: i },
                    React.createElement(Table.Cell, { width: "2" }, t(`languages.${e.language_from}`)),
                    React.createElement(Table.Cell, { width: "2" }, t(`languages.${e.language_to}`)),
                    React.createElement(Table.Cell, { width: "3" }, e.taxonomy.replace(new RegExp('Category:', 'g'), '').replace(new RegExp('IQVIA:', 'g'), '')),
                    React.createElement(Table.Cell, { width: "2", singleLine: true },
                        React.createElement(Input, { type: "number", step: "0.10", min: "0", defaultValue: e.price, onChange: (_, data) => updateSkillsPrice(i, data.value) }),
                        React.createElement(Button, { basic: true, icon: "save", title: "Save", positive: true, onClick: () => handleChangePrice(i) })),
                    React.createElement(Table.Cell, { width: "1" }, e.currency),
                    React.createElement(Table.Cell, { width: "1" },
                        React.createElement(Button, { basic: true, negative: true, icon: "trash alternate", name: i, title: "Delete", onClick: handleDelete })))),
                !displayAddBnt && React.createElement(Table.Row, null,
                    React.createElement(Table.Cell, { width: "2" },
                        React.createElement(SelectMemo, { search: true, value: sourceLanguage, setValue: setSourceLanguage, option: 'languages' })),
                    React.createElement(Table.Cell, { width: "2" },
                        React.createElement(SelectMemo, { search: true, value: targetLanguage, setValue: setTargetLanguage, option: 'languages' })),
                    React.createElement(Table.Cell, { width: "3" },
                        React.createElement(TaxonomySelector, { value: taxonomy, onChange: (newTaxons) => { setTaxonomy(newTaxons); }, dialogHeader: t('taxonomySelector.selectSubjectMatterNodes'), placeholder: t('taxonomySelector.selectSubjectMatterNodes') })),
                    React.createElement(Table.Cell, { width: "2" },
                        React.createElement(Input, { type: "number", step: "0.10", min: "0", value: price, onChange: (e, data) => setPrice(data.value) })),
                    React.createElement(Table.Cell, { width: "1" }, currency),
                    React.createElement(Table.Cell, { width: "1" },
                        React.createElement(Button, { basic: true, positive: true, type: "submit", icon: "save", title: "Save", onClick: handleSubmit }))),
                displayAddBnt && React.createElement(Table.Row, null,
                    React.createElement(Table.Cell, { width: "2" }),
                    React.createElement(Table.Cell, { width: "2" }),
                    React.createElement(Table.Cell, { width: "3" }),
                    React.createElement(Table.Cell, { width: "2" }),
                    React.createElement(Table.Cell, { width: "1" }),
                    React.createElement(Table.Cell, { width: "1" },
                        React.createElement(Button, { basic: true, icon: "plus", onClick: handleAddButton, title: "Add" }))))),
        errorMessage !== '' && React.createElement(Message, { negative: true, content: errorMessage }));
};
export default SkillsTable;
