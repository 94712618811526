import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Segment, Loader, Divider } from 'semantic-ui-react';
import { getAssignmentReport } from 'api-operations/executor/executor';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Styled } from '../StyledComponents';
import { hasErrorCode } from 'logger/error-guards';
const DividedBy = styled.span `
  color: #ccc;
`;
const FeedbackNotReady = styled.div `
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
`;
const EnhancerFeedback = () => {
    const [assignment, setAssignment] = useState();
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation('common');
    const history = useHistory();
    useEffect(() => {
        const assignemntId = history.location.state;
        fetchData(assignemntId.id);
    }, []);
    const fetchData = async (assignemntId) => {
        try {
            const res = await getAssignmentReport(assignemntId);
            setAssignment(res);
        }
        catch (error) {
            if (!hasErrorCode(404, error)) {
                return;
            }
            setLoading(false);
            return;
        }
        setLoading(false);
    };
    const returnToDashboard = () => {
        history.push({
            pathname: '/enhancer'
        });
    };
    const openEditor = () => {
        if (!assignment || !assignment.ratingAssignmentId) {
            return;
        }
        history.push({
            pathname: `/editor`,
            state: { id: assignment.ratingAssignmentId }
        });
    };
    const toFixed_norounding = (n, p) => {
        let result = n.toFixed(p);
        return result <= n ? result : (result - Math.pow(0.1, p)).toFixed(p);
    };
    if (loading)
        return React.createElement(Loader, { active: true, inline: "centered", content: "Loading" });
    return React.createElement("div", null,
        React.createElement(Styled.H1, null, t('enhancerFeedback.enhancerFeedback')),
        React.createElement(Segment, null, assignment ? React.createElement(React.Fragment, null,
            React.createElement("p", null,
                t('enhancerFeedback.deadlineMet'),
                ":",
                ' ',
                React.createElement("b", null, assignment.deadlineMet ? t('enhancerFeedback.yes') : t('enhancerFeedback.no'))),
            React.createElement("p", null,
                t('enhancerFeedback.sourceLanguage'),
                ":",
                ' ',
                React.createElement("b", null, t(`languages.${assignment.languageFrom.toLowerCase()}`))),
            React.createElement("p", null,
                t('enhancerFeedback.targetLanguage'),
                ": ",
                React.createElement("b", null, t(`languages.${assignment.languageTo.toLowerCase()}`))),
            React.createElement("p", null,
                t('enhancerFeedback.genre'),
                ": ",
                React.createElement("b", null, t(`genre.${assignment.genre}`))),
            React.createElement("p", null,
                t('enhancerFeedback.taxonomyKeys'),
                ":",
                ' ',
                React.createElement("b", null, assignment.taxonomyKey
                    .replace(new RegExp('Category:', 'g'), '')
                    .replace(new RegExp('IQVIA:', 'g'), ''))),
            React.createElement("p", null,
                t('enhancerFeedback.assetsCount'),
                ": ",
                React.createElement("b", null, assignment.assetCount)),
            React.createElement("p", null,
                t('enhancerFeedback.recycledAssetsCount'),
                ": ",
                React.createElement("b", null, assignment.recycledAssetCount)),
            assignment.ratingAssignmentId !== null
                && React.createElement(React.Fragment, null,
                    React.createElement(Divider, null),
                    React.createElement("p", null,
                        t('enhancerFeedback.totalScore'),
                        ":",
                        ' ',
                        React.createElement("b", null, toFixed_norounding(assignment.assignmentScore, 1)),
                        React.createElement(DividedBy, null, "/9"))))
            : React.createElement(FeedbackNotReady, null, t('enhancerFeedback.feedback'))),
        React.createElement(Button, { positive: true, onClick: returnToDashboard }, t('enhancerFeedback.returnToDashboard')),
        assignment && assignment.ratingAssignmentId !== null && React.createElement(Button, { onClick: openEditor }, t('enhancerFeedback.details')));
};
export default EnhancerFeedback;
