import i18next from 'i18next';
import Dropdown from 'editorNextGen/components/Dropdown';
import { consumeEvent, validateJustification } from 'editorNextGen/utils';
export default class AssetErrorElement extends HTMLElement {
    // eslint-disable-next-line no-unused-vars
    constructor(options, optionChanged, 
    // eslint-disable-next-line no-unused-vars
    justificationInput, 
    // eslint-disable-next-line no-unused-vars
    justificationChanged) {
        super();
        this.inputWrapper = document.createElement("div");
        this.input = document.createElement("textarea");
        this.errorBox = document.createElement("div");
        this.justificationRequired = false;
        this.dropdownOptions = [];
        this.dropdownOptions = options;
        this.dropdown = new Dropdown();
        this.dropdown.options = options;
        this.dropdown.onChange = (event) => {
            this.updateOnErrorChange(event || "");
            this.updateJustificationInputState(this.input.value);
            optionChanged(event);
        };
        this.dropdown.placeholder = i18next.t("asset.selectMostSevereError");
        this.appendChild(this.dropdown);
        this.input.placeholder = i18next.t("asset.justification");
        this.input.addEventListener("input", () => {
            this.updateJustificationInputState(this.input.value);
            justificationInput(this.input.value);
        });
        this.input.addEventListener("focusout", () => {
            this.updateJustificationInputState(this.input.value);
            justificationChanged(this.input.value);
        });
        this.input.tabIndex = 0;
        this.addEventListener("keydown", this.onKeyDown.bind(this));
        this.input.addEventListener("input", () => this.autoGrow(this.input));
        this.errorBox.className = "error";
        this.inputWrapper.className = `ui input inputWrapper`;
        this.inputWrapper.appendChild(this.input);
        this.inputWrapper.appendChild(this.errorBox);
        this.contentEditable = "false";
        this.appendChild(this.inputWrapper);
    }
    updateJustificationInputState(value) {
        const setError = (message) => {
            if (message && this.justificationRequired) {
                this.errorBox.innerText = message;
                this.errorBox.style.display = "block";
            }
            else {
                this.errorBox.innerText = "";
                this.errorBox.style.display = "none";
            }
        };
        // set error message
        const expertOpinion = validateJustification(value);
        switch (expertOpinion) {
            case "OK":
                setError();
                break;
            case "LENGTH":
                setError(i18next.t("asset.error.lenght"));
                break;
            case "EMPTY":
                setError(i18next.t("asset.error.empty"));
                break;
            case "NOT_VALID":
                setError(i18next.t("asset.error.notValid"));
                break;
        }
    }
    set isJustificationRequired(required) {
        this.justificationRequired = required;
        this.input.placeholder = required ? i18next.t("asset.justificationReq") : i18next.t("asset.justification");
    }
    autoGrow(el) {
        el.style.height = "10px";
        el.style.height = (el.scrollHeight + 14) + "px";
    }
    updateOnErrorChange(event) {
        var _a;
        const severity = ((_a = this.dropdownOptions.find(option => option.value === event)) === null || _a === void 0 ? void 0 : _a.weight) || 0;
        if (severity >= 9) {
            this.isJustificationRequired = true;
        }
        else {
            this.isJustificationRequired = false;
        }
    }
    set error(error) {
        this.errorType = (error === null || error === void 0 ? void 0 : error.type) || null;
        this.justification = (error === null || error === void 0 ? void 0 : error.justification) || null;
    }
    get error() {
        return this.errorType ? {
            type: this.errorType,
            justification: this.justification
        } : null;
    }
    set errorType(errorType) {
        this.updateOnErrorChange(errorType || "");
        this.dropdown.value = errorType;
    }
    get errorType() {
        return this.dropdown.value || null;
    }
    set justification(justification) {
        this.input.value = justification || "";
    }
    get justification() {
        return this.input.value || null;
    }
    set open(open) {
        this.toggleAttribute("open", open);
        this.autoGrow(this.input);
    }
    get open() {
        return this.hasAttribute("open");
    }
    set disabled(value) {
        this.dropdown.disabled = value;
        this.input.disabled = value;
    }
    get disabled() {
        return this.dropdown.disabled;
    }
    onKeyDown(event) {
        switch (event.key) {
            case "Tab":
                if (!event.shiftKey && this.dropdown.contains(event.target)) {
                    this.input.focus();
                }
                if (event.shiftKey && event.target == this.input) {
                    this.dropdown.open = true;
                    this.dropdown.content.focus();
                }
                consumeEvent(event);
                return false;
            case "Escape":
                event.target.blur();
                this.parentNode.editor.restoreSelectionRange();
                consumeEvent(event);
                return false;
            default:
                return true;
        }
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-asset-error", AssetErrorElement));
