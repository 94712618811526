import EditorModule from "../EditorModule";
import { downloadSourceFile } from "api-operations/editor/editor";
import { IconNgBuilder } from "editorNextGen/icons";
import { downloadFileSvg } from "editorNextGen/icons/downloadFile";
export default class SourceFileDownloadModule extends EditorModule {
    constructor(editor) {
        super(editor);
        this.moduleId = "SourceFileDownloadModule";
        this.button = document.createElement('div');
        new IconNgBuilder(downloadFileSvg, this.button)
            .setOnClick(() => {
            const { section, assignmentId } = this.editor;
            if (!section)
                return;
            const properFileName = section.hasSourceFile ? this.replaceExtension(section.name, 'pdf') : section.name;
            downloadSourceFile(assignmentId, section.id, properFileName);
        })
            .setIconFill('stroke')
            .setTooltip('Download source file')
            .build();
        this.editor.sectionHeader.toolBar.appendChild(this.button);
    }
    unload() {
        this.button.remove();
    }
    replaceExtension(name, extension) {
        const pos = name.lastIndexOf(".");
        return name.substring(0, pos < 0 ? name.length : pos) + `.${extension}`;
    }
}
