import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Table } from 'semantic-ui-react';
import { getTerminology } from 'api-operations/myExfluency/myExfluency';
import PagedTerminologiesXflView from './PagedTerminologiesXflView';
import TablePlaceholder from 'helpers/components/TablePlaceholder';
const displayedColumns = ['source', 'target', 'description', 'languages', 'taxonomy', 'langCoin'];
const Terminology = () => {
    const [terminologies, setTerminologies] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const { t } = useTranslation('common');
    const fetchData = useCallback(async (paginationFilterOptions) => {
        try {
            const response = await getTerminology(paginationFilterOptions);
            setTerminologies(response.results);
            setTotalCount(response.totalCount);
        }
        catch (error) {
            setIsError(true);
        }
        setIsLoading(false);
    }, []);
    return React.createElement(PagedTerminologiesXflView, { fetchData: fetchData, totalResults: totalCount, tableIdentifier: "myXfl" },
        React.createElement(Table, { color: "green", celled: true, striped: true },
            React.createElement(Table.Header, null,
                React.createElement(Table.Row, null, displayedColumns.map(e => React.createElement(Table.HeaderCell, { key: e }, t(`myLangCoin.${e}`))))),
            isLoading || isError || !terminologies.length ? React.createElement(TablePlaceholder, { isLoading: isLoading, isError: isError, noEntries: !terminologies.length })
                : React.createElement(Table.Body, null, terminologies.map(terminology => React.createElement(Table.Row, { key: terminology.id },
                    React.createElement(Table.Cell, null, terminology.source),
                    React.createElement(Table.Cell, null, terminology.target),
                    React.createElement(Table.Cell, null, terminology.description),
                    React.createElement(Table.Cell, null,
                        t(`languages.${(terminology.langFrom.toLowerCase())}`),
                        " ",
                        React.createElement(Icon, { name: "arrow right" }),
                        " ",
                        t(`languages.${(terminology.langTo.toLowerCase())}`)),
                    React.createElement(Table.Cell, null, terminology.taxonomy.join(', ')),
                    React.createElement(Table.Cell, null, terminology.xfl))))));
};
export default Terminology;
