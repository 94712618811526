import { Modal } from "editorNextGen/components/Modal";
import i18next from "i18next";
export default class SnapshotGenerationStartedModal extends Modal {
    constructor() {
        super();
        this.content = document.createElement("div");
        this.classList.add("small");
        this.content.classList.add("content", "ui");
        this.content.textContent = i18next.t("editor.snapshots.snapshotIsBeingGenerated");
        this.appendChild(this.content);
        this.actions = [{
                text: i18next.t("editor.snapshots.ok"),
                classList: ['primary'],
                callback: () => { this.open = false; }
            }];
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-snapshot-generation-started-modal", SnapshotGenerationStartedModal));
