import React from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
const getColor = (props) => {
    if (props === undefined)
        return '#eeeeee';
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#eeeeee';
};
const Container = styled.div ` /* any because of library errors */
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;
const DropzoneMultiple = ({ acceptableFormats, placeholderText, uploadNewFile }) => {
    const { getRootProps, getInputProps, isDragActive, isDragAccept } = useDropzone({
        onDrop: (acceptedFiles) => {
            acceptedFiles.forEach(file => uploadNewFile(file));
        },
        multiple: true,
        accept: acceptableFormats
    });
    return React.createElement("div", null,
        React.createElement(Container, { ...getRootProps({ isDragActive, isDragAccept }) },
            React.createElement("input", { ...getInputProps() }),
            React.createElement("p", null, placeholderText)));
};
export default DropzoneMultiple;
