import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import TablePlaceholder from 'helpers/components/TablePlaceholder';
import { getExpensePayoffTransfers } from 'api-operations/langCoinWallet/langCoinWallet';
import PagedExpenses from './PagedExpenses';
import { parseTimestamp } from 'helpers/DateHandler';
import styled from 'styled-components';
import { formatLangCoin } from 'helpers/LangCoinHelper';
const displayedColumns = ['date', 'fromAddress', 'toAddress', 'txHash', 'amount', 'txLink'];
const TxnHash = styled.div `
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
max-width: 130px;
`;
const Expense = () => {
    const [expenses, setExpenses] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const { t } = useTranslation('common');
    const fetchData = useCallback(async (paginationFilterOptions) => {
        try {
            const response = await getExpensePayoffTransfers(paginationFilterOptions);
            setExpenses(response.results);
            setTotalCount(response.totalCount);
        }
        catch (error) {
            setIsError(true);
        }
        setIsLoading(false);
    }, []);
    return React.createElement(PagedExpenses, { fetchData: fetchData, totalResults: totalCount, tableIdentifier: "expenses" },
        React.createElement(Table, { color: "green", celled: true, striped: true },
            React.createElement(Table.Header, null,
                React.createElement(Table.Row, null, displayedColumns.map(e => React.createElement(Table.HeaderCell, { key: e }, t(`expenseTransfer.${e}`))))),
            isLoading || isError || !expenses.length ? React.createElement(TablePlaceholder, { isLoading: isLoading, isError: isError, noEntries: !expenses.length })
                : React.createElement(Table.Body, null, expenses.map(expense => {
                    var _a, _b, _c, _d;
                    return React.createElement(Table.Row, { key: expense.transferId },
                        React.createElement(Table.Cell, null, parseTimestamp(expense.date)),
                        React.createElement(Table.Cell, null,
                            React.createElement(TxnHash, null, (_a = expense.linkedResource) === null || _a === void 0 ? void 0 : _a.fromAddress)),
                        React.createElement(Table.Cell, null,
                            React.createElement(TxnHash, null, (_b = expense.linkedResource) === null || _b === void 0 ? void 0 : _b.toAddress)),
                        React.createElement(Table.Cell, null,
                            React.createElement(TxnHash, null, (_c = expense.linkedResource) === null || _c === void 0 ? void 0 : _c.txHash)),
                        React.createElement(Table.Cell, null, formatLangCoin(expense.amount)),
                        React.createElement(Table.Cell, null,
                            React.createElement("a", { href: (_d = expense.linkedResource) === null || _d === void 0 ? void 0 : _d.txLink, target: "_blank", rel: "noreferrer" }, "Etherscan")));
                }))));
};
export default Expense;
