import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Checkbox, Form, Popup } from 'semantic-ui-react';
import { useLogger } from 'logger/Logger';
import { getAnonSupportedLanguages } from 'api-operations/anon';
const StageSelectorContainer = styled.div `
  display: flex;
  flex-direction: column;
  margin: auto;
`;
const StageSelectorMethodWrapper = styled.div `
  display: flex;
  justify-content: ${(props) => props.compact ? 'flex-start' : 'center'};
	align-items: ${(props) => props.compact ? 'flex-start' : 'center'};
`;
const StageSelectorMethod = styled.div `
  display: flex;
	flex-direction: row;
  margin-top: 1rem;
  padding-left: 1rem; 
  padding-right: 1rem; 
`;
const StageSelector = styled.div `
  display: ${(props) => props.compact ? 'inline-block' : 'flex'};
	justify-content: center;
	align-items:  center;
  margin-top: 1rem;
  padding-left: 0.5rem; 
  padding-right: 0.5rem; 
`;
const ANONYMIZATION = 'ANONYMIZATION';
const REDACTION = 'REDACTION';
const OCR = 'OCR';
const RECYCLING = 'RECYCLING';
const ENHANCEMENT = 'ENHANCEMENT';
const TRUST_MINING = 'TRUST_MINING';
const TRUST_MINING_2 = 'TRUST_MINING_2';
const FINAL_REDACTION = 'FINAL_REDACTION';
/**
 * @deprecated Should be removed in version 3.34.0
 */
const anonSupportedLangs = ['en', 'de', 'es', 'pl', 'fr', 'ru', 'uk', 'it', 'sk', 'he'];
const StageSelect = ({ setStages, setManualStages, manualStages, stages, sourceLang, isAnonEnabled, compact = false }) => {
    const [anonSupportedLanguages, setAnonSupportedLanguages] = useState([]);
    const { t } = useTranslation('common');
    const { logger } = useLogger();
    useEffect(() => {
        logger.info('User - Entered select stages step');
    }, []);
    useEffect(() => {
        fetchAnonSupportedLanguages();
    }, [sourceLang]);
    const fetchAnonSupportedLanguages = async () => {
        try {
            const response = await getAnonSupportedLanguages();
            setAnonSupportedLanguages(() => response);
            if (sourceLang && !response.includes(sourceLang.toUpperCase())) {
                setStages(new Set(Array.from(stages).filter(stage => stage !== ANONYMIZATION)));
            }
        }
        catch (error) {
            logger.error('System - Failed to get anon supported languages');
            setStages(new Set(Array.from(stages).filter(stage => stage !== ANONYMIZATION)));
        }
    };
    const handleChange = ({ name }) => {
        if (name === undefined)
            return;
        const tempStages = (name === REDACTION || name === ANONYMIZATION || name === FINAL_REDACTION) ? new Set(stages) : new Set([]);
        if (name === ANONYMIZATION) {
            if (stages.has(ANONYMIZATION)) {
                tempStages.delete(ANONYMIZATION);
            }
            else {
                tempStages.add(ANONYMIZATION);
            }
            setStages(tempStages);
        }
        if (name === FINAL_REDACTION) {
            if (stages.has(FINAL_REDACTION)) {
                tempStages.delete(FINAL_REDACTION);
            }
            else {
                tempStages.add(FINAL_REDACTION);
            }
            setStages(tempStages);
        }
        if (name === REDACTION) {
            if (stages.has(REDACTION)) {
                tempStages.delete(REDACTION);
                tempStages.delete(OCR);
                tempStages.delete(ANONYMIZATION);
            }
            else {
                tempStages.add(REDACTION);
                tempStages.add(OCR);
            }
        }
        else {
            switch (name) {
                case TRUST_MINING_2:
                    tempStages.add(TRUST_MINING_2);
                case TRUST_MINING:
                    tempStages.add(TRUST_MINING);
                case ENHANCEMENT:
                    tempStages.add(ENHANCEMENT);
                case RECYCLING:
                    tempStages.add(RECYCLING);
                case ANONYMIZATION:
                    stages.has(ANONYMIZATION) && tempStages.add(ANONYMIZATION);
                case FINAL_REDACTION:
                    stages.has(FINAL_REDACTION) && tempStages.add(FINAL_REDACTION);
                case REDACTION:
                    if (stages.has(REDACTION)) {
                        tempStages.add(REDACTION);
                        tempStages.add(OCR);
                    }
                default:
                    break;
            }
        }
        if (stages.has(name))
            tempStages.delete(name);
        setStages(tempStages);
    };
    const handlePickingMethodChange = (stage) => {
        const temp = new Set(manualStages);
        if (manualStages.has(stage)) {
            temp.delete(stage);
        }
        else {
            temp.add(stage);
        }
        setManualStages(temp);
    };
    return React.createElement(React.Fragment, null,
        React.createElement(StageSelector, { compact: compact },
            React.createElement(Form, null,
                React.createElement(Form.Group, null,
                    React.createElement(Form.Checkbox, { name: REDACTION, label: React.createElement(Popup, { content: t('stageDescriptions.redaction'), trigger: React.createElement("label", null, t('stages.REDACTION')) }), onChange: (_, data) => handleChange(data), checked: stages.has(REDACTION) }),
                    (isAnonEnabled && stages.has(REDACTION) && sourceLang && (anonSupportedLanguages.includes(sourceLang.toUpperCase()) || anonSupportedLangs.includes(sourceLang.substring(0, 2).toLowerCase()))) && React.createElement(Form.Checkbox, { name: ANONYMIZATION, label: React.createElement(Popup, { content: t('stageDescriptions.anonymization'), trigger: React.createElement("label", null, t('stages.ANONYMIZATION')) }), onChange: (_, data) => handleChange(data), checked: stages.has(ANONYMIZATION) }),
                    React.createElement(Form.Checkbox, { name: RECYCLING, label: React.createElement(Popup, { content: t('stageDescriptions.recycling'), trigger: React.createElement("label", null, t('stages.RECYCLING')) }), onChange: (_, data) => handleChange(data), checked: stages.has(RECYCLING) }),
                    React.createElement(Form.Checkbox, { name: ENHANCEMENT, label: React.createElement(Popup, { content: t('stageDescriptions.enhancement'), trigger: React.createElement("label", null, t('stages.ENHANCEMENT')) }), onChange: (_, data) => handleChange(data), checked: stages.has(ENHANCEMENT) }),
                    React.createElement(Form.Checkbox, { name: TRUST_MINING, label: React.createElement(Popup, { content: t('stageDescriptions.trustMining'), trigger: React.createElement("label", null, t('stages.TRUST_MINING')) }), onChange: (_, data) => handleChange(data), checked: stages.has(TRUST_MINING) }),
                    React.createElement(Form.Checkbox, { name: TRUST_MINING_2, label: React.createElement(Popup, { content: t('stageDescriptions.trustMining'), trigger: React.createElement("label", null, t('stages.TRUST_MINING_2')) }), onChange: (_, data) => handleChange(data), checked: stages.has(TRUST_MINING_2) }),
                    React.createElement(Form.Checkbox, { name: FINAL_REDACTION, label: React.createElement(Popup, { content: t('stageDescriptions.finalRedaction'), trigger: React.createElement("label", null, t('stages.FINAL_REDACTION')) }), onChange: (_, data) => handleChange(data), checked: stages.has(FINAL_REDACTION) })))),
        React.createElement(StageSelectorContainer, null,
            React.createElement(StageSelectorMethodWrapper, { compact: compact },
                stages.has(REDACTION)
                    && React.createElement(StageSelectorMethod, null,
                        React.createElement(Form, null,
                            React.createElement(Form.Field, null, t('dashboard.choosingRedactor')),
                            React.createElement(Form.Field, null,
                                React.createElement(Checkbox, { radio: true, label: t('dashboard.choosingMethodBestOne'), name: 'checkboxRadioGroup', checked: !manualStages.has(REDACTION), onChange: () => handlePickingMethodChange(REDACTION) })),
                            React.createElement(Form.Field, null,
                                React.createElement(Checkbox, { radio: true, label: t('dashboard.choosingMethodManual'), name: 'checkboxRadioGroup', checked: manualStages.has(REDACTION), onChange: () => handlePickingMethodChange(REDACTION) })))),
                stages.has(ENHANCEMENT)
                    && React.createElement(StageSelectorMethod, null,
                        React.createElement(Form, null,
                            React.createElement(Form.Field, null, t('dashboard.choosingEnhancer')),
                            React.createElement(Form.Field, null,
                                React.createElement(Checkbox, { radio: true, label: t('dashboard.choosingMethodBestOne'), name: 'checkboxRadioGroup', checked: !manualStages.has(ENHANCEMENT), onChange: () => handlePickingMethodChange(ENHANCEMENT) })),
                            React.createElement(Form.Field, null,
                                React.createElement(Checkbox, { radio: true, label: t('dashboard.choosingMethodManual'), name: 'checkboxRadioGroup', checked: manualStages.has(ENHANCEMENT), onChange: () => handlePickingMethodChange(ENHANCEMENT) })))),
                stages.has(TRUST_MINING)
                    && React.createElement(StageSelectorMethod, null,
                        React.createElement(Form, null,
                            React.createElement(Form.Field, null, t('dashboard.choosingTrustMiner')),
                            React.createElement(Form.Field, null,
                                React.createElement(Checkbox, { radio: true, label: t('dashboard.choosingMethodBestOne'), name: 'checkboxRadioGroup', checked: !manualStages.has(TRUST_MINING), onChange: () => handlePickingMethodChange(TRUST_MINING) })),
                            React.createElement(Form.Field, null,
                                React.createElement(Checkbox, { radio: true, label: t('dashboard.choosingMethodManual'), name: 'checkboxRadioGroup', checked: manualStages.has(TRUST_MINING), onChange: () => handlePickingMethodChange(TRUST_MINING) })))),
                stages.has(TRUST_MINING_2)
                    && React.createElement(StageSelectorMethod, null,
                        React.createElement(Form, null,
                            React.createElement(Form.Field, null, t('dashboard.choosingTrustMiner')),
                            React.createElement(Form.Field, null,
                                React.createElement(Checkbox, { radio: true, label: t('dashboard.choosingMethodBestOne'), name: 'checkboxRadioGroup', checked: !manualStages.has(TRUST_MINING_2), onChange: () => handlePickingMethodChange(TRUST_MINING_2) })),
                            React.createElement(Form.Field, null,
                                React.createElement(Checkbox, { radio: true, label: t('dashboard.choosingMethodManual'), name: 'checkboxRadioGroup', checked: manualStages.has(TRUST_MINING_2), onChange: () => handlePickingMethodChange(TRUST_MINING_2) })))),
                stages.has(FINAL_REDACTION)
                    && React.createElement(StageSelectorMethod, null,
                        React.createElement(Form, null,
                            React.createElement(Form.Field, null, t('dashboard.choosingFinalRedactor')),
                            React.createElement(Form.Field, null,
                                React.createElement(Checkbox, { radio: true, label: t('dashboard.choosingMethodBestOne'), name: 'checkboxRadioGroup', checked: !manualStages.has(FINAL_REDACTION), onChange: () => handlePickingMethodChange(FINAL_REDACTION) })),
                            React.createElement(Form.Field, null,
                                React.createElement(Checkbox, { radio: true, label: t('dashboard.choosingMethodManual'), name: 'checkboxRadioGroup', checked: manualStages.has(FINAL_REDACTION), onChange: () => handlePickingMethodChange(FINAL_REDACTION) })))))));
};
export default StageSelect;
