import './style.scss';
const ERROR_POPUP_VISIBLITY_TIME = 2000;
export default class SectionBar extends HTMLElement {
    constructor() {
        super();
        this.content = document.createElement("div");
        this._onChange = null;
        this._sequentiality = true;
        this._activeSectionId = null;
        this.content.className = "new-section-bar";
        this.appendChild(this.content);
    }
    set active(sectionId) {
        const tabs = document.querySelectorAll('.section-tab');
        tabs.forEach((tab) => {
            tab.classList.remove("active");
            if (tab.getAttribute('sectionID') === sectionId) {
                tab.classList.add("active");
                this._activeSectionId = sectionId;
            }
        });
    }
    get active() {
        return this._activeSectionId;
    }
    set sequentiality(value) {
        this._sequentiality = value;
    }
    handleClick(id) {
        this.onChange && this.onChange(id);
    }
    set onChange(onChange) {
        this._onChange = onChange;
    }
    get onChange() {
        return this._onChange;
    }
    updateSection(sectionId, totalAssets, completedAssets, hasResultFile) {
        const slider = this.querySelector('.sectionsContainer');
        if (slider) {
            this.updateSectionState(slider, sectionId, totalAssets, completedAssets, hasResultFile);
        }
        const dropdown = this.querySelector('.dropdown-tab');
        if (dropdown) {
            this.updateSectionState(dropdown, sectionId, totalAssets, completedAssets, hasResultFile);
        }
    }
    updateSectionState(node, sectionId, totalAssets, completedAssets, hasResultFile) {
        const section = node.querySelector(`.section-tab[sectionid='${sectionId}']`);
        if (!section)
            return;
        section.setAttribute('total', totalAssets + '');
        section.setAttribute('completed', completedAssets + '');
        const hasResultFileAttr = section.getAttribute('hasresultfile') === 'true' ? true : false;
        hasResultFile !== undefined && section.setAttribute('hasresultfile', hasResultFile + '');
        const icon = section.querySelector('.tab-icon');
        if (!icon)
            return;
        icon.src = this.getTabImage(totalAssets, completedAssets, section, hasResultFile !== undefined ? hasResultFile : hasResultFileAttr);
    }
    getTabImage(totalAssets, completedAssets, tab, hasResultFile) {
        if (completedAssets === totalAssets || hasResultFile) {
            return require('assets/images/sectionBar/tabDone.svg');
        }
        else if (completedAssets > 0 && completedAssets < totalAssets) {
            return require('assets/images/sectionBar/tabInProgress.svg');
        }
        tab.style.cursor = this._sequentiality ? "default" : "pointer";
        return require('assets/images/sectionBar/tabToDo.svg');
    }
    createTabs(values, dropdown) {
        const tabsWrapper = document.createElement('div');
        tabsWrapper.className = "tabsWrapper";
        if (dropdown) {
            tabsWrapper.classList.add('dropdown');
        }
        values.forEach(({ totalAssets, completedAssets, hasResultFile, id, name }) => {
            const text = document.createElement('span');
            text.className = "tab-text";
            text.textContent = name;
            const icon = document.createElement('img');
            icon.className = "tab-icon";
            const tab = document.createElement('div');
            tab.className = "section-tab";
            tab.title = name;
            tab.setAttribute('sectionid', id);
            tab.setAttribute('total', totalAssets + '');
            tab.setAttribute('completed', completedAssets + '');
            tab.setAttribute('hasresultfile', hasResultFile + '');
            tab.appendChild(icon);
            tab.appendChild(text);
            icon.src = this.getTabImage(totalAssets, completedAssets, tab, hasResultFile);
            tab.addEventListener("click", () => {
                const sections = Array.from(tabsWrapper.querySelectorAll(`.section-tab`));
                const firstUncompletedSection = () => {
                    return sections.find(item => parseInt(item.getAttribute('total') || '0') !== parseInt(item.getAttribute('completed') || '0')
                        && item.getAttribute('hasresultfile') !== 'true' ? true : false);
                };
                const nextUncompletedSection = firstUncompletedSection();
                nextUncompletedSection && (nextUncompletedSection.style.cursor = 'pointer');
                const hasResultFile = tab.getAttribute('hasresultfile') === 'true' ? true : false;
                const total = parseInt(tab.getAttribute('total') || '0');
                const completed = parseInt(tab.getAttribute('completed') || '0');
                if (this._sequentiality) {
                    if (total === completed || hasResultFile || (nextUncompletedSection === null || nextUncompletedSection === void 0 ? void 0 : nextUncompletedSection.getAttribute('sectionid')) === id) {
                        this.handleClick(id);
                    }
                    else {
                        if (!document.getElementById("error-tab")) {
                            this.content.appendChild(this.createErrorTabElement());
                        }
                    }
                }
                else {
                    this.handleClick(id);
                }
            });
            tabsWrapper.appendChild(tab);
        });
        return tabsWrapper;
    }
    createErrorTabElement() {
        const error = document.createElement('div');
        error.id = "error-tab";
        error.className = "error-tab";
        error.innerHTML = `<img src=${require('assets/images/sectionBar/Error.svg')} alt='icon error'><span>Complete the previous file in order to access this tab.</span>`;
        setTimeout(() => {
            error.remove();
        }, ERROR_POPUP_VISIBLITY_TIME);
        return error;
    }
    set sections(values) {
        const slider = document.createElement('div');
        slider.className = "slider";
        const sectionsContainer = document.createElement('div');
        sectionsContainer.className = "sectionsContainer";
        slider.appendChild(sectionsContainer);
        this.content.appendChild(slider);
        const tabsWrapperSlider = this.createTabs(values);
        const tabsWrapperDropdown = this.createTabs(values, true);
        sectionsContainer.appendChild(tabsWrapperSlider);
        const addSlider = () => {
            if (!slider.classList.contains('addSlider')) {
                slider.classList.add('addSlider');
                const prevBtn = document.createElement('div');
                prevBtn.className = "prev-btn slider-btn";
                prevBtn.innerHTML = `<img src=${require('assets/images/sectionBar/sectionBar_arrow.svg')} alt='prev icon'>`;
                const nextBtn = document.createElement('div');
                nextBtn.className = "next-btn slider-btn";
                nextBtn.innerHTML = `<img src=${require('assets/images/sectionBar/sectionBar_arrow.svg')} alt='next icon'>`;
                const dropdown = document.createElement('div');
                dropdown.className = "dropdown-tab slider-btn";
                const dropdownBtn = document.createElement('div');
                dropdownBtn.className = "dropdown-btn";
                dropdownBtn.innerHTML = `<img src=${require('assets/images/sectionBar/sectionBar_arrow.svg')} alt='dropdown icon'>`;
                dropdownBtn.addEventListener("click", () => dropdown.classList.toggle('active'));
                dropdown.appendChild(dropdownBtn);
                const popup = document.createElement('div');
                popup.className = "popup";
                dropdown.appendChild(popup);
                const popupTitle = document.createElement('div');
                popupTitle.className = "popup-title";
                popupTitle.textContent = "ALL FILES";
                popup.appendChild(popupTitle);
                popup.appendChild(tabsWrapperDropdown);
                window.addEventListener("click", (event) => {
                    if (dropdown.classList.contains('active') && event.target !== dropdownBtn && event.target !== popupTitle) {
                        dropdown.classList.remove('active');
                    }
                });
                this.content.appendChild(nextBtn);
                this.content.prepend(prevBtn);
                this.content.appendChild(dropdown);
                const slides = document.querySelectorAll('.section-tab');
                let curSlide = 0;
                let maxSlide = values.length - 1;
                let curSlideWidth = 0;
                nextBtn.addEventListener("click", (event) => {
                    event.preventDefault();
                    const sectionsContainerRight = sectionsContainer.getBoundingClientRect().right;
                    const sliderRight = slider.getBoundingClientRect().right;
                    if (!(curSlide === maxSlide || (sectionsContainerRight <= sliderRight))) {
                        curSlideWidth -= slides.item(curSlide).getBoundingClientRect().width;
                        curSlide++;
                    }
                    sectionsContainer.style.transform = `translateX(${curSlideWidth}px)`;
                });
                prevBtn.addEventListener("click", (event) => {
                    event.preventDefault();
                    if (curSlide !== 0) {
                        curSlide--;
                        curSlideWidth += slides.item(curSlide).getBoundingClientRect().width;
                    }
                    sectionsContainer.style.transform = `translateX(${curSlideWidth}px)`;
                });
            }
        };
        const removeSlider = () => {
            var _a;
            (_a = document.querySelector('.addSlider')) === null || _a === void 0 ? void 0 : _a.classList.remove('addSlider');
            const sliderBtns = document.querySelectorAll('.slider-btn');
            sliderBtns.forEach((btn) => {
                btn.remove();
            });
            sectionsContainer.style.transform = `translateX(0)`;
        };
        const checkVisible = () => {
            const sliderParent = this.querySelector('.new-section-bar');
            const sliderParentWidth = sliderParent.offsetWidth;
            const sectionsContainerWidth = sectionsContainer.offsetWidth;
            return sectionsContainerWidth > sliderParentWidth;
        };
        if (checkVisible()) {
            addSlider();
        }
        window.addEventListener('resize', () => {
            if (checkVisible()) {
                addSlider();
            }
            else {
                removeSlider();
            }
        });
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-section-bar", SectionBar));
