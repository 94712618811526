import { Modal } from "editorNextGen/components/Modal";
import { warningSvg } from "editorNextGen/icons/warning";
import i18next from "i18next";
import '../modules/uploadDocument/style.scss';
export default class AssetMissingFormattingModal extends Modal {
    constructor() {
        super();
        this.content = document.createElement("div");
        this.callback = () => { };
        this.classList.add("small");
        this.content.classList.add("content", "ui");
        const header = document.createElement('div');
        header.className = 'header';
        header.textContent = i18next.t("editor.assetMissingFormattingModal.header");
        this.appendChild(this.content);
        const description = document.createElement('div');
        description.className = 'description semibold';
        description.textContent = i18next.t("editor.assetMissingFormattingModal.description");
        const iconWrapper = document.createElement('div');
        iconWrapper.innerHTML = warningSvg;
        this.content.appendChild(iconWrapper);
        this.content.appendChild(header);
        this.content.appendChild(description);
        this.actions = [{
                text: i18next.t("editor.uploadDocumentModal.cancel"),
                classList: ['secondary'],
                callback: () => {
                    this.open = false;
                }
            },
            {
                text: i18next.t("editor.assetMissingFormattingModal.complete"),
                classList: ['negative'],
                callback: () => {
                    this.open = false;
                    this.callback();
                }
            }];
    }
    setOpen() {
        this.open = true;
        window.setTimeout(() => { var _a; return (_a = this.actionsElement.querySelector('button')) === null || _a === void 0 ? void 0 : _a.focus(); }, 100);
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-asset-missing-formatting-modal", AssetMissingFormattingModal));
