import React, { useState, useEffect } from 'react';
import { Container } from 'semantic-ui-react';
import { getMyProfile } from 'api-operations/myExfluency/myExfluency';
import UserInfoForm from './UserInfoForm';
import PasswordForm from './PasswordForm';
import ErrorModal from 'errors/components/ErrorModal';
import { getEthereumAddress } from 'api-operations/langCoinWalletAddress/langCoinWalletAddress';
import ExfluencyLoader from 'helpers/components/loader/ExfluencyLoader';
const MyProfile = () => {
    const [userInfo, setUserInfo] = useState();
    const [ethereumAddress, setEthereumAddress] = useState();
    const [ethereumAddressDisabled, setEthereumAddressDisabled] = useState(true);
    const [loading, setLoading] = useState(true);
    const [errorWindowMessage, setErrorWindowMessage] = useState(null);
    const fetchData = async () => {
        try {
            const res = await getMyProfile();
            try {
                setEthereumAddress(await getEthereumAddress());
                setEthereumAddressDisabled(false);
            }
            catch (error) {
                console.log("Ethereum address modification endpoint not available on backend - disabling input");
            }
            setUserInfo(res);
        }
        catch (error) {
            setErrorWindowMessage(null);
            return;
        }
        setLoading(false);
    };
    useEffect(() => {
        fetchData();
    }, []);
    if (errorWindowMessage !== null)
        return React.createElement(ErrorModal, { ...errorWindowMessage, onClose: () => window.location.reload() });
    if (userInfo === undefined || loading)
        return React.createElement(ExfluencyLoader, null);
    return React.createElement(Container, null,
        React.createElement(UserInfoForm, { userInfo: userInfo, initialEthereumAddress: ethereumAddress, ethereumAddressDisabled: ethereumAddressDisabled }),
        React.createElement(PasswordForm, null));
};
export default MyProfile;
