import i18next from "i18next";
import { Modal } from "editorNextGen/components/Modal";
import Dropdown from 'editorNextGen/components/Dropdown';
import { trimString } from 'helpers/utils';
import './style.scss';
export default class FormattingCreationModal extends Modal {
    constructor() {
        super();
        this.content = document.createElement("div");
        this.dropdown = new Dropdown();
        this.callback = () => { };
        this.classList.add("small");
        this.content.classList.add("content", "ui");
        const header = document.createElement('div');
        header.className = 'header';
        header.textContent = i18next.t('editor.formattingCreationModal.header');
        this.appendChild(this.content);
        this._setupPropertiesDropdown();
        this.content.appendChild(header);
        this.content.appendChild(this.dropdown);
        this.actions = [{
                text: i18next.t("editor.createTokenModal.cancel"),
                classList: ['secondary'],
                callback: () => {
                    this.open = false;
                    this.dropdown.value = null;
                    this.setPrimaryButtonDisable();
                }
            },
            {
                text: i18next.t("editor.createTokenModal.confirm"),
                classList: ['primary'],
                callback: () => {
                    this.open = false;
                    this.dropdown.value = null;
                    this.setPrimaryButtonDisable();
                    this.callback();
                }
            }];
        this.setPrimaryButtonDisable();
    }
    setPrimaryButtonDisable() {
        this.querySelector("button.primary").disabled = true;
    }
    _setupPropertiesDropdown() {
        this.dropdown.content.style.width = '100%';
        this.dropdown.title = i18next.t('editor.formattingCreationModal.header');
        this.dropdown.placeholder = i18next.t('editor.formattingCreationModal.placeholder');
        this.dropdown.error = false;
    }
    set options(formatting) {
        this.dropdown.value = null;
        this.dropdown.options = formatting.map(({ description, value }) => ({ value, text: `ID no. ${value}`, description: trimString(description, 40, 20) }));
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-formatting-creation-modal", FormattingCreationModal));
