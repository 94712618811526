import React, { useEffect, useState } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import { useLogger } from 'logger/Logger';
import { saveDownloadSuggestions } from './utils';
import { useTranslation } from 'react-i18next';
import { downloadZip, requestOutputFilesGeneration } from 'api-operations/requester/stage';
const StageRow = styled.div `
  display:flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
`;
const labelStyle = {
    margin: 0,
    textAlign: 'left'
};
const DownaloadStageContainer = ({ stage, activeStage, allowedFileTypes }) => {
    const [resultFile, setResultFile] = useState();
    const [otherFiles, setOtherFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const { logger } = useLogger();
    const { t } = useTranslation('common');
    useEffect(() => { init(); }, []);
    const init = () => {
        const { availableFileTypes, readyFileTypes, id, stageType } = stage;
        addFiles(id, stageType, readyFileTypes, true, 'file');
        const filtered = availableFileTypes.filter(i => !readyFileTypes.includes(i));
        addFiles(id, stageType, filtered, false, 'file outline');
    };
    const addFiles = (stageId, stageType, fileTypes, isReady, icon) => {
        const filtred = allowedFileTypes ? fileTypes.filter(f => allowedFileTypes.includes(f)) : fileTypes;
        filtred.forEach((fileType) => {
            const file = { stageId, stageType, isReady, fileType };
            if (fileType !== "RESULT") {
                setOtherFiles(o => [...o, { key: fileType, icon, file, text: t(`file.${fileType}`), value: fileType }]);
            }
        });
        if (filtred.includes("RESULT")) {
            setResultFile({ stageId, stageType, isReady, fileType: "RESULT" });
        }
        else if (filtred.includes("RESULT_ANON")) {
            setResultFile({ stageId, stageType, isReady, fileType: "RESULT_ANON" });
            setOtherFiles(o => o.filter(f => f.value !== "RESULT_ANON"));
        }
    };
    const getFile = async (value) => {
        var _a;
        const file = (_a = otherFiles.find(f => f.file.fileType == value)) === null || _a === void 0 ? void 0 : _a.file;
        if (!file)
            return;
        setLoading(true);
        if (file.isReady) {
            downloadFile(file);
        }
        else {
            await generateFile(file);
            setTimeout(() => {
                downloadFile(file);
            }, 5000);
        }
    };
    const downloadFile = async (file) => {
        const { fileType, stageId, stageType } = file;
        if (fileType === 'TCERT' || fileType === 'CSV')
            return;
        try {
            setLoading(true);
            await downloadZip(stageId, fileType);
            logger.info(`User - Downloaded ${fileType} file`);
            if (activeStage && (activeStage !== stageType || (fileType !== 'RESULT' && fileType !== 'RESULT_ANON'))) {
                saveDownloadSuggestions(activeStage, { stageType, fileType });
            }
        }
        catch (e) {
            logger.error(`User - failed to download ${fileType} file`, e);
        }
        finally {
            setLoading(false);
        }
    };
    const generateFile = async (file) => {
        if (file.fileType === 'TCERT' || file.fileType === 'CSV')
            return;
        try {
            await requestOutputFilesGeneration(file.stageId, file.fileType);
        }
        catch (e) {
            logger.error(`User - failed to generate file`, e);
        }
    };
    return (React.createElement(StageRow, null,
        React.createElement("p", { style: labelStyle }, t(`file.${stage.stageType}`)),
        resultFile && React.createElement(React.Fragment, null,
            React.createElement(Button.Group, null,
                React.createElement(Button, { color: resultFile.isReady ? 'blue' : 'grey', disabled: loading || !resultFile.isReady, onClick: () => { downloadFile(resultFile); }, loading: loading }, t(`file.${resultFile.fileType}`)),
                React.createElement(Dropdown, { disabled: loading || !resultFile.isReady || !otherFiles.length, className: 'button icon', floating: true, button: true, trigger: React.createElement(React.Fragment, null) },
                    React.createElement(Dropdown.Menu, null, otherFiles.map(f => React.createElement(Dropdown.Item, { icon: f.icon, text: f.text, onClick: () => getFile(f.value) }))))))));
};
export default DownaloadStageContainer;
