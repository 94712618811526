import { getIsTokenizationEnabled } from 'api-operations/token';
import React, { createContext, useContext, useEffect, useState } from 'react';
const initialValue = {
    isTokenizationEnabled: false
};
export const TokenizationContext = createContext(initialValue);
export const TokenizationProvider = ({ children }) => {
    const [isTokenizationEnabled, setIsTokenizationEnabled] = useState(false);
    useEffect(() => {
        fetchIsTokenizationEnabled();
    }, []);
    const fetchIsTokenizationEnabled = async () => {
        try {
            const response = await getIsTokenizationEnabled();
            setIsTokenizationEnabled(response);
        }
        catch (error) {
            setIsTokenizationEnabled(true); // In case data is missing tokenization should be enabled
        }
    };
    return React.createElement(TokenizationContext.Provider, { value: { isTokenizationEnabled } }, children);
};
export const useTokenization = () => useContext(TokenizationContext);
