import { EditorNextGen as Editor } from "./";
import { ancestorOfType, positionToRect } from "./utils";
export default class SelectionTools extends HTMLElement {
    constructor() {
        super();
    }
    getIconButton(id) {
        return this.querySelector(`#${id}`);
    }
    trigger(anchorElement) {
        var _a, _b;
        const selection = window.getSelection();
        const range = selection.rangeCount == 1 ? selection.getRangeAt(0) : null;
        try { // Firefox problem workaround
            (_a = range === null || range === void 0 ? void 0 : range.startContainer) === null || _a === void 0 ? void 0 : _a.parentElement;
        }
        catch (error) {
            return;
        }
        if (!selection || !range || !(range === null || range === void 0 ? void 0 : range.toString())) {
            this.open = false;
            return;
        }
        let assetUnusedFormattingQuantity = 0;
        if (range === null || range === void 0 ? void 0 : range.startContainer.parentNode) {
            const asset = ancestorOfType(range.startContainer.parentNode, 'xfl-new-editor-asset');
            if (!(asset === null || asset === void 0 ? void 0 : asset.active))
                return; // prevents displaying tools if asset is not active because we currently can't proper update that asset after creation
            assetUnusedFormattingQuantity = asset ? asset.getUnusedFormatting().length : 0;
        }
        const buttonTypes = {
            anonymize: 'ANONYMIZED',
            doNotTranslate: 'NO_TRANSLATION',
            formatting: 'FORMATTING',
            term: 'TERM',
            forbidden: 'FORBIDDEN'
        };
        const ids = Array.from(this.childNodes)
            .map(node => node.id)
            .filter(id => Object.keys(buttonTypes).find(key => key === id));
        ids.forEach((id) => {
            if (Editor.canCreateSubasset(buttonTypes[id])) {
                this.getIconButton(id).classList.remove('hidden');
                if (id === 'formatting' && assetUnusedFormattingQuantity === 0) {
                    this.getIconButton(id).classList.add('hidden');
                }
            }
            else {
                this.getIconButton(id).classList.add('hidden');
            }
        });
        if (Array.from(this.childNodes).every(node => node.classList.contains('hidden'))) {
            this.open = false;
            return;
        }
        const rects = range.getClientRects();
        positionToRect(this, rects[selection.anchorOffset == range.endOffset ? 0 : rects.length - 1], anchorElement);
        const defaultWindowSelection = 12; //Be default after clicking on the button the selection is deleted and returns to default value 
        if (this.lastRange === null || ((_b = this.lastRange) === null || _b === void 0 ? void 0 : _b.startOffset) !== defaultWindowSelection) {
            this.lastRange = range;
        }
        this.open = true;
    }
    set open(open) {
        this.toggleAttribute("open", open);
    }
    get open() {
        return this.hasAttribute("open");
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-selection-tools", SelectionTools));
