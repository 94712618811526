import { showEditorTooltip } from "editorNextGen/utils";
import { dividerSvg } from "./divider";
export function createIcon(icon, parentNode, stroke = false, onClick = () => { }) {
    const i = document.createElement("div");
    i.innerHTML = icon;
    i.className = `icon ${stroke ? 'stroke' : 'fill'}`;
    if (onClick) {
        i.addEventListener("click", () => {
            i.classList.toggle("active");
            onClick();
        });
    }
    parentNode.appendChild(i);
}
export function createDivider(parentNode) {
    const divider = document.createElement("div");
    divider.innerHTML = dividerSvg;
    divider.className = "divider";
    parentNode.appendChild(divider);
}
export default class IconNG extends HTMLElement {
    constructor(icon, parent, iconFill, onClick, toggable, tooltipText, tooltipKeyboardShortcut, iconActive, buttonId) {
        super();
        this.icon = icon;
        this.parent = parent;
        this.iconFill = iconFill;
        this.onClick = onClick;
        this.toggable = toggable;
        this.tooltipText = tooltipText;
        this.tooltipKeyboardShortcut = tooltipKeyboardShortcut;
        this.iconActive = iconActive;
        this.buttonId = buttonId;
        this.targetEle = document.createElement('button');
        this.parent.appendChild(this);
    }
    setActive() {
        if (this.onClick && this.toggable) {
            this.classList.add("active");
        }
    }
    forceActiveState(force) {
        this.classList.toggle("active", force);
    }
    setOnClick() {
        if (this.onClick) {
            this.classList.add("btn");
            this.targetEle.addEventListener("click", () => {
                if (this.disabled)
                    return;
                this.toggable && this.classList.toggle("active");
                this.onClick && this.onClick();
            });
        }
    }
    setIconSvg() {
        this.targetEle.innerHTML = this.icon;
        this.className = `icon ${this.iconFill}`;
    }
    addTooltip() {
        if (!this.tooltipText)
            return;
        this.addEventListener("mouseenter", () => {
            showEditorTooltip(this, this.tooltipText || '', this.tooltipKeyboardShortcut);
        });
    }
    set disabled(disabled) {
        this.classList.toggle("disabled", disabled);
    }
    get disabled() {
        return this.classList.contains("disabled");
    }
    connectedCallback() {
        if (this.onClick) {
            this.appendChild(this.targetEle);
        }
        else {
            this.targetEle = this;
        }
        this.setIconSvg();
        this.setOnClick();
        this.addTooltip();
        this.iconActive && this.setActive();
        this.id = this.buttonId || '';
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-icon", IconNG));
export class IconNgBuilder {
    /**
     * Icon NextGen Builder
     *
     * @param iconSvg - SVG icon code string
     * @param parentNode - Parent element where to append icon
     */
    constructor(iconSvg, parentNode) {
        this.iconSvg = iconSvg;
        this.parentNode = parentNode;
        this.iconFill = 'fill';
    }
    setToggable(toggable) {
        this.toggable = toggable;
        return this;
    }
    setOnClick(fn) {
        this.onClick = fn;
        return this;
    }
    /**
     * Set icon fill
     *
     * Some icons has background color to be changed
     * but other icons have just a stroke color.
     * Decide here what to modify when the state is active
     *
     * @param fill 'fill' | 'stroke'
     * @returns
     */
    setIconFill(fill) {
        this.iconFill = fill;
        return this;
    }
    setTooltip(text, keyboardShortcut = undefined) {
        this.tooltipText = text;
        this.tooltipKeyboarShortcut = keyboardShortcut;
        return this;
    }
    setId(id) {
        this.id = id;
        return this;
    }
    isActive(active) {
        this.iconActive = active;
        return this;
    }
    build() {
        return new IconNG(this.iconSvg, this.parentNode, this.iconFill, this.onClick, this.toggable, this.tooltipText, this.tooltipKeyboarShortcut, this.iconActive, this.id);
    }
}
