import { Modal } from "editorNextGen/components/Modal";
import i18next from "i18next";
import { subAssetSubtypeAllValuesGrouped } from "api-operations/editor/editor";
export default class CreateTokenModal extends Modal {
    constructor() {
        super();
        this.callback = () => { };
        this.content = document.createElement("div");
        this.justificationLabel = document.createElement("label");
        this.justificationInput = document.createElement("textarea");
        this.subtypesLabel = document.createElement("label");
        this.subtypesItems = document.createElement("div");
        this.activeSelection = null;
        this.classList.add("tiny");
        this.content.classList.add("content", "ui", "form");
        this.justificationLabel.textContent = i18next.t("editor.createTokenModal.explanation");
        this.justificationLabel.className = "label";
        this.justificationInput.rows = 7;
        this.justificationInput.placeholder = i18next.t("editor.createTokenModal.explanationPlaceholder");
        const justificationField = document.createElement("div");
        justificationField.classList.add("field");
        justificationField.appendChild(this.justificationLabel);
        justificationField.appendChild(this.justificationInput);
        justificationField.addEventListener("input", this.validate.bind(this));
        this.content.appendChild(justificationField);
        this.subtypesLabel.textContent = i18next.t('editor.createTokenModal.category');
        this.subtypesLabel.classList.add("label");
        const subtypesField = document.createElement("div");
        subtypesField.classList.add("field");
        subtypesField.appendChild(this.subtypesLabel);
        subtypesField.appendChild(this.subtypesItems);
        this.subtypesItems.classList.add('container');
        this.content.appendChild(subtypesField);
        this.appendChild(this.content);
        Array.from(subAssetSubtypeAllValuesGrouped)
            .forEach((({ subTypes, header }) => {
            const section = document.createElement("div");
            const headerEle = document.createElement("div");
            headerEle.textContent = i18next.t(`editor.createTokenModal.${header}`);
            section.appendChild(headerEle);
            const subAssetsSubtypeList = document.createElement("div");
            subAssetsSubtypeList.classList.add("subtypes");
            subTypes.map(type => new SubAssetSubtypeItem(type, i18next.t(`editor.subAssets.subtypes.${type}`), this))
                .sort((a, b) => a.text.localeCompare(b.text))
                .forEach((elem) => {
                elem.addEventListener(SUBASSET_CLICK, ((event) => this.toggleSelection(event.detail)));
                subAssetsSubtypeList.appendChild(elem);
            });
            section.appendChild(subAssetsSubtypeList);
            this.subtypesItems.appendChild(section);
        }));
        this.actions = [{
                text: i18next.t("editor.createTokenModal.cancel"),
                classList: ["secondary"],
                callback: () => { this.clear(); this.open = false; }
            }, {
                text: i18next.t("editor.createTokenModal.confirm"),
                classList: ["primary"],
                callback: () => { this.callback(); this.clear(); this.open = false; }
            }];
        this.validate();
    }
    clear() {
        this.justification = "";
        this.subtypes = new Set();
        this.clearSelection();
        this.validate();
    }
    toggleSelection(newSelection) {
        if (this.activeSelection && this.activeSelection !== newSelection) {
            this.clearSelection();
        }
        this.activeSelection = newSelection;
        this.validate();
    }
    clearSelection() {
        if (!this.activeSelection)
            return;
        const subtypeItem = this.getSubtypeItem(this.activeSelection, this);
        if (subtypeItem) {
            subtypeItem.selected = false;
        }
    }
    validate() {
        this.querySelector("button.primary").disabled
            = (this.justificationVisible && !this.justification)
                || (this.subtypeVisible && this.subtypes.size == 0 || this.subtypes.size > 1);
        return this.subtypes.size == 0 || this.subtypes.size > 1;
    }
    get justification() {
        return this.justificationInput.value;
    }
    set justification(text) {
        this.justificationInput.value = text;
    }
    set justificationVisible(visible) {
        this.justificationLabel.style.display = visible ? "" : "none";
        this.justificationInput.style.display = visible ? "" : "none";
    }
    get justificationVisible() {
        return this.justificationInput.style.display !== "none";
    }
    set subtypeVisible(visible) {
        this.subtypesLabel.style.display = visible ? "" : "none";
        this.subtypesItems.style.display = visible ? "" : "none";
    }
    get subtypeVisible() {
        return this.subtypesItems.style.display !== "none";
    }
    set subtypes(subtypes) {
        Array.from(this.subtypesItems.children).forEach((elem) => {
            elem.selected = subtypes.has(elem.code);
        });
    }
    get subtypes() {
        return new Set(Array.from(this.querySelectorAll('xfl-new-editor-subasset-subtype-item'))
            .filter(item => item.selected)
            .map(item => item.code));
    }
    getSubtypeItem(code, el) {
        return el.querySelector(`xfl-new-editor-subasset-subtype-item[code='${code}']`);
    }
}
const SUBASSET_CLICK = "subasset-click";
export class SubAssetSubtypeItem extends HTMLElement {
    constructor(code, text, createTokenModal) {
        super();
        this.code = code;
        this.text = text;
        this.createTokenModal = createTokenModal;
        this.addEventListener("click", () => {
            this.selected = !this.selected;
            this.dispatchEvent(new CustomEvent(SUBASSET_CLICK, { detail: this.code }));
        });
    }
    set code(code) {
        this.setAttribute("code", code);
    }
    get code() {
        return this.getAttribute("code");
    }
    set text(text) {
        this.textContent = text;
    }
    get text() {
        return this.textContent || "";
    }
    set selected(selected) {
        this.toggleAttribute("selected", selected);
    }
    get selected() {
        return this.hasAttribute("selected");
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-subasset-subtype-item", SubAssetSubtypeItem));
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-create-token-modal", CreateTokenModal));
