import { Modal } from "editorNextGen/components/Modal";
import { warningSvg } from "editorNextGen/icons/warning";
import i18next from "i18next";
import './style.scss';
export default class UploadDocumentModal extends Modal {
    constructor(fileInput) {
        super();
        this.content = document.createElement("div");
        this.files = document.createElement("div");
        this.newFileNameContainer = document.createElement("div");
        this.oldFileNameContainer = document.createElement("div");
        this.callback = () => { };
        this.hasWarning = false;
        this.fileInput = null;
        this.createItem = (title, fileName) => {
            const wrapper = document.createElement('div');
            const header = document.createElement("div");
            header.textContent = title;
            header.className = 'title';
            wrapper.appendChild(header);
            wrapper.appendChild(fileName);
            return wrapper;
        };
        fileInput = fileInput;
        this.classList.add("small");
        this.content.classList.add("content", "ui");
        const header = document.createElement('div');
        header.className = 'header';
        header.textContent = i18next.t("editor.uploadDocumentModal.swapped");
        this.appendChild(this.content);
        const iconWrapper = document.createElement('div');
        iconWrapper.innerHTML = warningSvg;
        this.files.className = 'files';
        this.newFileNameContainer.className = 'name';
        this.oldFileNameContainer.className = 'name';
        this.files.appendChild(this.createItem(i18next.t("editor.uploadDocumentModal.uploadedFile"), this.newFileNameContainer));
        this.files.appendChild(this.createItem(i18next.t("editor.uploadDocumentModal.oldFile"), this.oldFileNameContainer));
        this.content.appendChild(iconWrapper);
        this.content.appendChild(header);
        this.content.appendChild(this.files);
        this.actions = [{
                text: i18next.t("editor.uploadDocumentModal.cancel"),
                classList: ['secondary'],
                callback: () => {
                    this.open = false;
                    fileInput.value = '';
                }
            },
            {
                text: i18next.t("editor.uploadDocumentModal.uploadFile"),
                classList: ['primary'],
                callback: () => {
                    this.open = false;
                    this.callback();
                    fileInput.value = '';
                }
            }];
    }
    set newFileName(name) {
        this.newFileNameContainer.textContent = name;
    }
    set oldFileName(name) {
        this.oldFileNameContainer.textContent = name;
    }
    get warning() {
        return this.hasWarning;
    }
    set errorMessage(message) {
        const newFileError = document.createElement('div');
        newFileError.textContent = message;
        newFileError.className = "error";
        this.newFileNameContainer.innerHTML = '';
        this.newFileNameContainer.appendChild(newFileError);
        this.open = true;
    }
    set warningMessage(message) {
        this.hasWarning = true;
        const newFileError = document.createElement('div');
        newFileError.textContent = message;
        newFileError.className = "warn";
        this.newFileNameContainer.innerHTML = '';
        this.newFileNameContainer.appendChild(newFileError);
        this.open = true;
        this.actions = [{
                text: i18next.t("editor.uploadDocumentModal.cancel"),
                classList: ['secondary'],
                callback: () => {
                    this.open = false;
                    if (this.fileInput)
                        this.fileInput.value = '';
                }
            },
            {
                text: i18next.t("editor.uploadDocumentModal.uploadFileAnyway"),
                classList: ['primary'],
                callback: () => {
                    this.open = false;
                    this.callback();
                    if (this.fileInput)
                        this.fileInput.value = '';
                }
            }];
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-upload-document-modal", UploadDocumentModal));
