import React, { useState, useEffect, useRef } from 'react';
import { Grid, Pagination, Search, Select } from 'semantic-ui-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getDashboardOptionsFromStorage, rowsOptions } from 'helpers/utils';
const PaginationWrapper = styled.div `
  text-align: center;
  padding: 20px 0;
`;
const StyledGrid = styled(Grid) `
  margin: 0 0 10px 0 !important;

  &&&& { 
  .row .column {
    padding-left: 0 !important;
  }

  .row:not(:first-child) {
    padding-top: 0 !important;
  }

  .row .column:last-child {
    padding-right: 0 !important;
  }
}
`;
const PagedAssignmentsXflView = ({ children, totalResults, fetchData, tableIdentifier }) => {
    const { t } = useTranslation('common');
    const orderOptions = [
        { key: 'DDD', text: t('pagination.filter.deliveryDateD'), value: 'deliveryDate DESCENDING' },
        { key: 'ADD', text: t('pagination.filter.deliveryDateA'), value: 'deliveryDate ASCENDING' },
        { key: 'FD', text: t('pagination.filter.fileNameD'), value: 'fileName DESCENDING' },
        { key: 'FA', text: t('pagination.filter.fileNameA'), value: 'fileName ASCENDING' },
        { key: 'XD', text: t('pagination.filter.langCoinD'), value: 'xfl DESCENDING' },
        { key: 'XA', text: t('pagination.filter.langCoinA'), value: 'xfl ASCENDING' },
        { key: 'ARD', text: t('pagination.filter.assignmentRoleD'), value: 'assignmentRole DESCENDING' },
        { key: 'ARA', text: t('pagination.filter.assignmentRoleA'), value: 'assignmentRole ASCENDING' },
        { key: 'TD', text: t('pagination.filter.typeD'), value: 'type DESCENDING' },
        { key: 'TA', text: t('pagination.filter.typeA'), value: 'type ASCENDING' },
        { key: 'LFD', text: t('pagination.filter.langFromD'), value: 'langFrom DESCENDING' },
        { key: 'LFA', text: t('pagination.filter.langFromA'), value: 'langFrom ASCENDING' },
        { key: 'TFD', text: t('pagination.filter.langToD'), value: 'langTo DESCENDING' },
        { key: 'TFA', text: t('pagination.filter.langToA'), value: 'langTo ASCENDING' }
    ];
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [searchInput, setSearchInput] = useState(undefined);
    const [orderBy, setOrderBy] = useState(getDashboardOptionsFromStorage(tableIdentifier, 'orderBy', orderOptions));
    const [assignmentTypes, setAssignmentTypes] = useState(['REDACTION', 'ENHANCEMENT', 'TRUST_MINING']);
    const [roles, setRoles] = useState(['ASSIGNER', 'EXECUTOR']);
    const [limit, setLimit] = useState(getDashboardOptionsFromStorage(tableIdentifier, 'limit', rowsOptions));
    const activePage = useRef(1);
    useEffect(() => {
        localStorage.setItem(`${tableIdentifier}PaginationOptions`, JSON.stringify({ limit, orderBy }));
    }, [limit, orderBy]);
    useEffect(() => {
        if (searchInput === undefined)
            return;
        setIsSearchLoading(true);
        const timeoutId = setTimeout(() => {
            activePage.current = 1;
            handlePageChange();
            setIsSearchLoading(false);
        }, 1000);
        // eslint-disable-next-line consistent-return
        return () => {
            clearTimeout(timeoutId);
        };
    }, [searchInput]);
    useEffect(() => {
        activePage.current = 1;
        handlePageChange();
    }, [limit, orderBy, assignmentTypes, roles, activePage]);
    const handlePageChange = (active) => {
        const [sortBy, sortDirection] = orderBy.split(' ');
        const offset = (active ? active : activePage.current) * limit - limit;
        fetchData && fetchData({ limit, offset, sortBy, sortDirection, roles, search: searchInput, types: assignmentTypes });
        activePage.current = active ? active : activePage.current;
    };
    const totalPages = Math.ceil(totalResults / limit);
    return React.createElement(React.Fragment, null,
        React.createElement(StyledGrid, { verticalAlign: 'bottom', stackable: true, padded: true },
            React.createElement(Grid.Row, { columns: 16 },
                React.createElement(Grid.Column, { width: 1 },
                    React.createElement("label", null, t('pagination.rowsPerPage')),
                    React.createElement(Select, { name: 'rowsPerPage', onChange: (_, data) => { setLimit(data.value); }, options: rowsOptions, value: limit, fluid: true })),
                React.createElement(Grid.Column, { width: 4 },
                    React.createElement("label", null, t('pagination.sortBy')),
                    React.createElement(Select, { search: true, onChange: (_, data) => { setOrderBy(data.value); }, options: orderOptions, value: orderBy, fluid: true })),
                React.createElement(Grid.Column, { width: 8 },
                    React.createElement("label", null, t('pagination.assignmentTypes')),
                    React.createElement(Select, { multiple: true, onChange: (_, data) => { setAssignmentTypes(data.value); }, options: ['REDACTION', 'ENHANCEMENT', 'TRUST_MINING'].map(ele => ({ value: ele, text: t(`stages.${ele}`), key: ele })), value: assignmentTypes, fluid: true })),
                React.createElement(Grid.Column, { width: 3 },
                    React.createElement(Search, { open: false, loading: isSearchLoading, onSearchChange: (e) => { setSearchInput(e.target.value); }, value: searchInput, input: { fluid: true } }))),
            React.createElement(Grid.Row, { columns: 16 },
                React.createElement(Grid.Column, { width: 4 },
                    React.createElement("label", null, t('pagination.roles')),
                    React.createElement(Select, { multiple: true, onChange: (_, data) => { setRoles(data.value); }, options: ['ASSIGNER', 'EXECUTOR'].map(ele => ({ value: ele, text: t(`assignmentRole.${ele}`), key: ele })), value: roles, fluid: true })))),
        children,
        totalResults > 0 && React.createElement(PaginationWrapper, null,
            React.createElement(Pagination, { totalPages: totalPages, activePage: activePage.current, onPageChange: (_, data) => handlePageChange(data.activePage) })));
};
export default PagedAssignmentsXflView;
