import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Divider, Header, Icon } from 'semantic-ui-react';
import FinancialInfo from './FinancialInfo';
import ContactPerson from './ContactPerson';
import PaymentMethods from './payments/PaymentMethods';
const StyledDivider = styled(Divider) `
  padding-top: 1.8rem;

  &.ui.horizontal.divider:after {
    background-position: left 1em top 0;
   }

  &.ui.horizontal.divider:before {
    background-position: right 1em top 0;
  }

  @-moz-document url-prefix() {
   &.ui.horizontal.divider:after {
    background-position: left 1em top 50%;
   }

   &.ui.horizontal.divider:before {
    background-position: right 1em top 50%;
   }
  }
`;
const Wrapper = styled.div `
  width: 80%;
  margin-left: auto;
  margin-right: auto;
`;
const Contact = () => {
    const { t } = useTranslation('common');
    return React.createElement(React.Fragment, null,
        React.createElement(Wrapper, null,
            React.createElement(StyledDivider, { horizontal: true },
                React.createElement(Header, { as: 'h4' },
                    React.createElement(Icon, { name: "info circle" }),
                    t('companies.billingInfo'))),
            React.createElement(FinancialInfo, null),
            React.createElement(StyledDivider, { horizontal: true },
                React.createElement(Header, { as: 'h4' },
                    React.createElement(Icon, { name: "info circle" }),
                    t('companies.contactPerson'))),
            React.createElement(ContactPerson, null),
            React.createElement(StyledDivider, { horizontal: true },
                React.createElement(Header, { as: 'h4' },
                    React.createElement(Icon, { name: "info circle" }),
                    t('companies.payYou'))),
            React.createElement(PaymentMethods, null)));
};
export default Contact;
