import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TemplateDropdown from 'helpers/components/TemplateDropdown';
import Dropzone from 'helpers/components/dropzone/index';
import { useLogger } from 'logger/Logger';
import { Paper, PaperContent, PaperHeader, SectionHeader, Separator, StyledDiv, StyledDropdown } from 'dashboards/Paper';
import FilesUpload, { acceptableFileFormats, acceptableFileTypes, acceptableZipFileTypes, isZipFile } from './droparea/FilesUpload';
import { uploadJobInputFile, uploadJobInputZipFile } from './droparea/expiringFiles';
const DropFile = ({ files, setFiles, setGenre, jobTemplateId, setJobTemplateId, genre, csvFile, setCsvFile, languageFrom }) => {
    const [selected, setSelected] = useState(null);
    const { t } = useTranslation('common');
    const { logger } = useLogger();
    const genres = [
        { key: 'sports', value: 'sports', text: t('genre.sports'), image: { avatar: true, src: require(`assets/images/genres/exfluency_Sports.png`) } },
        { key: 'it', value: 'it', text: t('genre.it'), image: { avatar: true, src: require(`assets/images/genres/exfluency_IT.png`) } },
        { key: 'legal', value: 'legal', text: t('genre.legal'), image: { avatar: true, src: require(`assets/images/genres/exfluency_Legal.png`) } },
        { key: 'life_sciences', value: 'life_sciences', text: t('genre.life_sciences'), image: { avatar: true, src: require(`assets/images/genres/exfluency_LifeSciences.png`) } },
        { key: 'marketing', value: 'marketing', text: t('genre.marketing'), image: { avatar: true, src: require(`assets/images/genres/exfluency_Marketing.png`) } },
        { key: 'technical', value: 'technical', text: t('genre.technical'), image: { avatar: true, src: require(`assets/images/genres/exfluency_Technical.png`) } },
        { key: 'other', value: 'other', text: t('genre.other'), image: { avatar: true, src: require(`assets/images/genres/exfluency_Other.png`) } }
    ];
    useEffect(() => {
        logger.info('User - Entered drop file step');
    }, []);
    useEffect(() => {
        if (jobTemplateId !== '') {
            setSelected('TEMPLATE');
        }
        else if (genre !== '') {
            setSelected('GENRE');
        }
        else if (csvFile !== null) {
            setSelected('CSV');
        }
        else {
            setSelected(null);
        }
    }, [jobTemplateId, genre, csvFile]);
    const handleGenreChange = (genre) => {
        setGenre(genre);
        logger.info('User - Selected genre');
    };
    const uploadFiles = async (file, setProgress) => {
        return isZipFile(file)
            ? await uploadJobInputZipFile(file, setProgress)
            : await uploadJobInputFile(file, setProgress);
    };
    return React.createElement("div", null,
        React.createElement(FilesUpload, { uploadedFiles: files, setFiles: setFiles, fileFormats: [...acceptableFileFormats, '.zip'], fileTypes: [...acceptableFileTypes, ...acceptableZipFileTypes], placing: 'Job input files', languageFrom: languageFrom, uploadFiles: uploadFiles }),
        React.createElement(Paper, null,
            React.createElement(PaperHeader, null, t('jobUpload.configureProject')),
            React.createElement(PaperContent, null,
                React.createElement(StyledDiv, { selected: selected === 'TEMPLATE' || selected === null },
                    React.createElement(SectionHeader, null, t('jobUpload.chooseTemplateDesc')),
                    React.createElement(TemplateDropdown, { jobTemplateId: jobTemplateId, setJobTemplateId: setJobTemplateId, fluid: true })),
                React.createElement(Separator, null,
                    React.createElement("span", null),
                    "or",
                    React.createElement("span", null)),
                React.createElement(StyledDiv, { selected: selected === 'CSV' || selected === null },
                    React.createElement(SectionHeader, null, t('jobUpload.csvUpload')),
                    React.createElement(Dropzone, { file: csvFile, setFile: setCsvFile, acceptableFormats: '.csv, text/csv, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values', supportedFormats: ['.csv'] })),
                React.createElement(Separator, null,
                    React.createElement("span", null),
                    "or",
                    React.createElement("span", null)),
                React.createElement(StyledDiv, { selected: selected === 'GENRE' || selected === null },
                    React.createElement(SectionHeader, null, t('jobUpload.chooseGenreDesc')),
                    React.createElement(StyledDropdown, { fluid: true, options: genres, placeholder: t('jobUpload.chooseGenre'), selection: true, clearable: true, value: genre, onChange: (_, data) => handleGenreChange(data.value) })))));
};
export default DropFile;
