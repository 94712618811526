import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button } from "semantic-ui-react";
import { formatFileSize } from "helpers/utils";
import FileUploadDrop from "./DropLabel";
import FileUploadItem from "./Item";
import FileUploadLabel from "./Label";
const Container = styled.div `
  box-sizing: border-box;
  position: relative;
  margin: 12px;
  width: 100%;
  height: 140px;
  background-color: #F9F9F9;
  border: 2px dashed #3A65F6;
  border-radius: 4px;

  label {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;
const Description = styled.div `
  position: absolute;
  padding: 0 30px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 8px;
`;
const UploadButton = styled(Button) `
  background-color: #ECEFFB !important;
  color: #42526E !important;
`;
const MAX_FILE_SIZE = 100 * 1000 * 1000;
const consumeEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
};
const Dropzone = ({ file, setFile, acceptableFormats, supportedFormats }) => {
    const [isDragOver, setDragOver] = useState(false);
    const { t } = useTranslation('common');
    const handleChange = (e) => {
        const { files } = e.target;
        if (files && files.length >= 1) {
            readFile(files[0]);
        }
    };
    const clearFile = () => {
        setFile(null);
    };
    const renderTitle = () => {
        if (isDragOver)
            return React.createElement(FileUploadDrop, { maxFileSize: formatFileSize(MAX_FILE_SIZE), supportedFormats: supportedFormats });
        return file
            ? React.createElement(FileUploadItem, { file: file, onFileRemoved: clearFile })
            : React.createElement(FileUploadLabel, { maxFileSize: formatFileSize(MAX_FILE_SIZE), supportedFormats: supportedFormats });
    };
    const readFile = (file) => {
        if (file.size > MAX_FILE_SIZE)
            return;
        setFile(file);
    };
    const onDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { files } = e.dataTransfer;
        // eslint-disable-next-line prefer-destructuring
        const file = files[0];
        if (file) {
            setDragOver(false);
            if (!acceptableFormats.includes(file.type))
                return;
            readFile(file);
        }
    };
    const onDragEnter = (e) => {
        consumeEvent(e);
    };
    const onDragOver = (e) => {
        consumeEvent(e);
        setDragOver(true);
    };
    const onDragLeave = (e) => {
        consumeEvent(e);
        setDragOver(false);
    };
    return React.createElement(Container, null,
        React.createElement("input", { type: "file", onChange: handleChange, id: "file-upload-single", hidden: true, accept: acceptableFormats }),
        React.createElement(Description, null,
            renderTitle(),
            !file && React.createElement(UploadButton, null, t('jobUpload.uploadFile'))),
        React.createElement("label", { htmlFor: "file-upload-single", style: { ...(isDragOver ? { border: '1px solid black' } : {}) }, onDrop: onDrop, onDragOver: onDragOver, onDragEnter: onDragEnter, onDragLeave: onDragLeave }));
};
export default Dropzone;
