import { downloadFile, downloadFileWithName } from "api-operations/common";
import axios from "axios";
export async function assignExecutor(stageId, executorId) {
    const response = await axios.post(`api/workflow/requester/stages/${stageId}/assign`, undefined, {
        params: {
            executorId
        }
    });
    return response.data;
}
export async function assignExecutorAuto(stageId, selectTheBest) {
    const response = await axios.post(`api/workflow/requester/stages/${stageId}/autoAssign`, undefined, {
        params: {
            ignoreErrorsReliability: !selectTheBest,
            ignoreDeadlineReliability: !selectTheBest,
            allowEmptyTrustChain: !selectTheBest
        }
    });
    return response.data;
}
export async function uploadNewStageAttachment(stageId, file) {
    const data = new FormData();
    data.append('file', file);
    const response = await axios.post(`/api/workflow/attachments/stages/${stageId}`, data);
    return response.data;
}
export async function deleteStageAttachment(stageId, fileId) {
    await axios.delete(`/api/workflow/attachments/stages/${stageId}/attachments/${fileId}`);
}
export async function downloadStageAttachment(stageId, fileId, fileName) {
    await downloadFileWithName(`/api/workflow/attachments/stages/${stageId}/attachments/${fileId}`, {}, fileName);
}
export async function getExecutorsForStage(stageId, selectTheBest) {
    const response = await axios.post(`/api/workflow/requester/stages/${stageId}/executors`, undefined, {
        params: {
            ignoreErrorsReliability: !selectTheBest,
            ignoreDeadlineReliability: !selectTheBest,
            allowEmptyTrustChain: !selectTheBest
        }
    });
    return response.data;
}
export async function requestOutputFilesGeneration(stageId, type) {
    const response = await axios.post(`/api/stage/${stageId}/requestOutputFilesGeneration`, undefined, {
        params: { fileType: type }
    });
    return response.data;
}
export async function downloadZip(stageId, type) {
    await downloadFile(`/api/stage/${stageId}/outputZip`, {
        fileType: type
    });
}
