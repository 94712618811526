import * as rxjs from 'rxjs';
import { isForwardScrollHasPriority, isScrollThresholdReached, roundPosition } from './utils';
const SCROLL_THROTTLE_TIME = 1000;
const SCROLL_THRESHOLD_FORWARD_PERCENT = 0.9;
const SCROLL_THRESHOLD_BACKWARD_PERCENT = 0.05;
export class InfiniteScroll {
    /**
   * Constructor
   *
   * @param container Scroll container
   * @param sectionId Section Id
   * @param totalCount Total number of items
   * @param lastPosition Position to start with
   * @param batchSize Size of one call
   * @param maxDisplayed Size of maximum displayed items
   * @param fetchData Fetch data funtion. Return promise
   * @param onThresholdReach Callback on threshold reach
   * @param updateLoaderState Callback update laoder state
   */
    constructor(container, sectionId, totalCount, lastPosition, batchSize, maxDisplayed, fetchData, onThresholdReach, updateLoaderState) {
        this.cache = {};
        this.loadingBackward = false;
        this.loadingForward = false;
        this.updateLoaderState = () => { };
        this.onThresholdReach = () => { };
        this.fetchData = () => Promise.resolve([]);
        this.disabledScroll = false;
        this.fetchData = fetchData;
        this.container = container;
        this._sectionId = sectionId;
        this.totalCount = totalCount;
        this.batchSize = batchSize;
        this.maxDisplayed = maxDisplayed;
        this.updateLoaderState = updateLoaderState;
        this.onThresholdReach = onThresholdReach;
        const startPosition = this._adjustRoundedOrder(lastPosition, batchSize);
        this.forwardVector = startPosition;
        this.backwardVector = startPosition;
        this.pageByManual$ = new rxjs.BehaviorSubject({ offset: startPosition, forward: true });
        this.loadingFinishedSubject = new rxjs.BehaviorSubject(undefined);
        if (startPosition - batchSize > 0) {
            updateLoaderState(false, false, () => {
                this.pageByManual$.next({ offset: startPosition - batchSize, forward: false });
                this.backwardVector -= batchSize;
            });
        }
    }
    moveTo(order, clearPreviousAssets) {
        this.disabledScroll = true;
        const offset = this._adjustRoundedOrder(order, this.batchSize);
        if (Math.abs(offset - this.forwardVector) > this.batchSize)
            clearPreviousAssets();
        this.updateLoaderState(false, offset <= 0, () => { this.pageByManual$.next({ offset: offset - this.batchSize, forward: false }); });
        if (Math.abs(this.backwardVector - offset) >= this.maxDisplayed)
            this.backwardVector += this.batchSize;
        this.backwardVector = offset;
        this.forwardVector = offset;
        this.pageByManual$.next({ offset, forward: true });
    }
    _adjustRoundedOrder(position, size) {
        return (position % size === 0 && position > 0) ? roundPosition(position - 1, size) : roundPosition(position, size);
    }
    pageByScroll$() {
        const getOffset = (forward) => {
            return forward ? this.forwardVector + this.batchSize
                : this.backwardVector - this.batchSize;
        };
        const checkNewOffset = (offset, forward) => {
            return forward ? offset < this.totalCount : offset >= 0;
        };
        const checkIfLoading = (forward) => forward ? this.loadingForward : this.loadingBackward;
        const updateVectors = (forward, offset) => {
            if (forward) {
                if (offset < this.totalCount) {
                    if (Math.abs(this.backwardVector - offset) >= this.maxDisplayed)
                        this.backwardVector += this.batchSize;
                    this.forwardVector += this.batchSize;
                }
            }
            else {
                if (offset >= 0) {
                    if (Math.abs(offset - this.forwardVector) >= this.maxDisplayed)
                        this.forwardVector -= this.batchSize;
                    this.backwardVector -= this.batchSize;
                }
            }
        };
        return rxjs.fromEvent(this.container, 'scroll').pipe(rxjs.filter(() => !this.disabledScroll), rxjs.map(() => this.container.scrollHeight), rxjs.filter(height => isScrollThresholdReached(height, this.container.scrollTop, this.container.clientHeight, SCROLL_THRESHOLD_FORWARD_PERCENT, SCROLL_THRESHOLD_BACKWARD_PERCENT)), rxjs.map(height => isForwardScrollHasPriority(height, this.container.scrollTop, this.container.clientHeight, SCROLL_THRESHOLD_FORWARD_PERCENT, SCROLL_THRESHOLD_BACKWARD_PERCENT)), rxjs.filter(forward => !checkIfLoading(forward)), rxjs.map(forward => ({ forward, offset: getOffset(forward) })), rxjs.tap(({ forward, offset }) => forward && this.updateLoaderState(true, offset >= this.totalCount)), rxjs.filter(({ forward, offset }) => checkNewOffset(offset, forward)), rxjs.throttleTime(SCROLL_THROTTLE_TIME), rxjs.tap(({ forward, offset }) => {
            updateVectors(forward, offset);
        }));
    }
    pageToLoad$() {
        return rxjs.merge(this.pageByManual$, this.pageByScroll$());
    }
    shouldRemoveItems() {
        //TODO: Fix this condition to remove prevoius items
        return Math.abs(this.forwardVector - this.backwardVector + this.batchSize) == this.maxDisplayed;
    }
    subscribe(options) {
        this.itemResults$.subscribe(options);
    }
    updateAssetsState(assetId, content, completed = false) {
        Object.entries(this.cache).forEach(([, value]) => {
            value.forEach((asset) => {
                if (asset.id === assetId) {
                    asset.currentText = content;
                    asset.completed = completed;
                }
            });
        });
    }
    updateAssetError(assetId, assetError) {
        Object.entries(this.cache).forEach(([, value]) => {
            value.forEach((asset) => {
                if (asset.id === assetId) {
                    asset.error = assetError || undefined;
                }
            });
        });
    }
    setStateAfterDataFetch(forward, offset) {
        this.setLoading(forward, false);
        if (!forward) {
            if (this.backwardVector >= 0) {
                this.updateLoaderState(false, this.backwardVector - this.batchSize < 0, () => {
                    if (Math.abs(offset - this.batchSize - this.forwardVector) >= this.maxDisplayed)
                        this.forwardVector -= this.batchSize;
                    this.backwardVector = offset - this.batchSize;
                    this.pageByManual$.next({ offset: offset - this.batchSize, forward: false });
                });
            }
        }
        if (this.shouldRemoveItems()) {
            forward ? this.onThresholdReach(this.backwardVector - this.batchSize, this.backwardVector)
                : this.onThresholdReach(this.forwardVector + this.batchSize + 1, this.forwardVector + this.batchSize * 2);
        }
    }
    setLoading(forward, state) {
        forward ? this.loadingForward = state
            : this.loadingBackward = state;
    }
    async getAssets(offset, forward, cache = false) {
        const assets = cache
            ? this.cache[offset]
            : await this.fetchData(offset, this.batchSize);
        this.setStateAfterDataFetch(forward, offset);
        if (!cache)
            this.cache[offset] = assets;
        return assets;
    }
    get itemResults$() {
        return this.pageToLoad$().pipe(
        // @ts-ignore // https://github.com/ReactiveX/rxjs/issues/5599
        rxjs.filter(({ forward, offset }) => forward ? offset < this.totalCount : offset >= 0), rxjs.tap(({ forward }) => this.setLoading(forward, true)), rxjs.mergeMap(({ offset, forward }) => {
            return rxjs.from(this.getAssets(offset, forward, !!this.cache[offset]));
        }), rxjs.map(e => ({ assets: e, sectionId: this._sectionId })));
    }
    set disabled(value) {
        this.disabledScroll = value;
    }
}
