import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { Styled } from 'StyledComponents';
import PagedInvoicesView from './PagedInvoicesView';
import TablePlaceholder from 'helpers/components/TablePlaceholder';
import { getCompanyInvoices } from 'api-operations/company/admin';
import InvoiceTableRow from './InvoiceTableRow';
const Invoices = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [invoices, setInvoices] = useState([]);
    const [isError, setIsError] = useState(false);
    const [allResultsCount, setAllResultsCount] = useState(0);
    const [paginationOptions, setPaginationOptions] = useState({});
    const { t } = useTranslation('common');
    const params = useParams();
    const fetchData = async (paginationFilterOptions) => {
        if (isError) {
            setIsError(false);
            setIsLoading(true);
        }
        try {
            const response = await getCompanyInvoices(params.id, paginationFilterOptions);
            setInvoices(response.results);
            setAllResultsCount(response.totalCount);
        }
        catch (error) {
            setIsError(true);
        }
        setIsLoading(false);
    };
    const orderOptions = [
        { key: 'CDD', text: t('pagination.filter.creationDateD'), value: 'creationDate DESCENDING' },
        { key: 'CDA', text: t('pagination.filter.creationDateA'), value: 'creationDate ASCENDING' },
        { key: 'SDD', text: t('pagination.filter.startDateD'), value: 'startDate DESCENDING' },
        { key: 'SDA', text: t('pagination.filter.startDateA'), value: 'startDate ASCENDING' },
        { key: 'EDD', text: t('pagination.filter.endDateD'), value: 'endDate DESCENDING' },
        { key: 'EDA', text: t('pagination.filter.endDateA'), value: 'endDate ASCENDING' },
        { key: 'IND', text: t('pagination.filter.invoiceNumberD'), value: 'invoiceNumber DESCENDING' },
        { key: 'INA', text: t('pagination.filter.invoiceNumberA'), value: 'invoiceNumber ASCENDING' },
        { key: 'TSD', text: t('pagination.filter.totalSumD'), value: 'totalSum DESCENDING' },
        { key: 'TSA', text: t('pagination.filter.totalSumA'), value: 'totalSum ASCENDING' },
        { key: 'AD', text: t('pagination.filter.acceptedD'), value: 'accepted DESCENDING' },
        { key: 'AA', text: t('pagination.filter.acceptedA'), value: 'accepted ASCENDING' }
    ];
    return React.createElement(React.Fragment, null,
        React.createElement(Styled.H3, null, t('Invoices')),
        React.createElement(PagedInvoicesView, { orderOptions: orderOptions, totalResults: allResultsCount, fetchData: fetchData, setPaginationOptions: setPaginationOptions, tableIdentifier: 'Invoices' },
            React.createElement(Table, { celled: true, selectable: true, striped: true, color: "green" },
                React.createElement(Table.Header, null,
                    React.createElement(Table.Row, null,
                        React.createElement(Table.HeaderCell, null, t('dashboard.invoiceType')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.invoiceNumber')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.totalSum')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.creationDate')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.startDate')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.endDate')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.accepted')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.downloadPdf')))),
                isLoading || isError || !invoices.length ? React.createElement(TablePlaceholder, { isError: isError, isLoading: isLoading, noEntries: !invoices.length })
                    : React.createElement(Table.Body, null, invoices.map(invoice => React.createElement(InvoiceTableRow, { invoice: invoice, companyId: params.id, key: invoice.invoiceNumber }))))));
};
export default Invoices;
