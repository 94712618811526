import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
const StyledMenuItem = styled.div `
  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #42526E;
    gap: 8px;
    padding: 8px 4px;
    border-radius: 4px;

    &:hover {
      color: #215592;
      background-color: #F4F5F7;
    }

    &.active {
      background-color: #F4F5F7;
      color: #3A65F6;

      svg.mutable path {
        fill: #3A65F6;
      }

      svg.mutable stroke {
        stroke: #3A65F6;
      }

      svg.mutable circle {
        stroke: #3A65F6;
      }

      svg.mutable .stroke {
        stroke: #3A65F6;
      }

      svg.mutable .transparent {
        fill: white;
      }
    }

      svg.mutable stroke {
        stroke: #42526E;
      }

      svg.mutable circle {
        stroke: #42526E;
      }
  }
`;
const MenuItem = ({ text, toAlt, href, icon, open, to }) => {
    const renderBody = () => {
        return React.createElement(React.Fragment, null,
            icon && icon,
            open && React.createElement("span", null, text));
    };
    return React.createElement(StyledMenuItem, null,
        to && React.createElement(NavLink, { to: to, isActive: (_, location) => location.pathname.startsWith(to) || !!(toAlt && location.pathname.startsWith(toAlt)) }, renderBody()),
        href && React.createElement("a", { href: href, target: '_blank', rel: "noreferrer" }, renderBody()));
};
export default MenuItem;
