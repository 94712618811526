import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Input, Table } from 'semantic-ui-react';
import { createCompany } from 'api-operations/company/member';
import ExfluencyLoader from 'helpers/components/loader/ExfluencyLoader';
import { getCompanyInfo } from 'api-operations/company/admin';
const MyCompanyView = styled.div `
  display: flex;
  flex-direction: column;

  h2 {
    margin-top: 0;
  }
`;
const CreateCompanyDiv = styled.div `
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
`;
const CreateCompanyInput = styled(Input) `
  margin-right: .5rem;
`;
const Companies = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isCreating, setIsCreating] = useState(false);
    const [myCompanyId, setMyCompanyId] = useState(undefined);
    const [companyName, setCompanyName] = useState('');
    const { t } = useTranslation('common');
    useEffect(() => {
        (async () => {
            await getMyCompaniesList();
            setIsLoading(false);
        })();
    }, []);
    const getMyCompaniesList = async () => {
        try {
            const company = await getCompanyInfo();
            setMyCompanyId(company.companyId);
        }
        catch (error) {
            //
        }
    };
    const handleCreateCompany = async () => {
        if (companyName !== '' && !isCreating) {
            try {
                setIsCreating(true);
                await createCompany(companyName);
                window.location.reload();
            }
            finally {
                setIsCreating(false);
            }
        }
    };
    if (isLoading)
        return React.createElement(ExfluencyLoader, null);
    if (myCompanyId)
        return React.createElement(Redirect, { exact: true, to: `/company/${myCompanyId}/info` });
    return React.createElement(MyCompanyView, null,
        React.createElement("h2", null, t('companies.myCompanies')),
        React.createElement(CreateCompanyDiv, null,
            React.createElement(CreateCompanyInput, { placeholder: t('companies.name'), onChange: (e) => setCompanyName(e.target.value) }),
            React.createElement(Button, { primary: true, disabled: myCompanyId !== undefined || isCreating, onClick: () => handleCreateCompany() }, t('companies.create'))),
        React.createElement("h4", null, t('companies.warning')),
        React.createElement("div", null,
            React.createElement(Table, { celled: true },
                React.createElement(Table.Header, null,
                    React.createElement(Table.Row, null,
                        React.createElement(Table.HeaderCell, null, t('companies.name')),
                        React.createElement(Table.HeaderCell, null, t('companies.myProfile')),
                        React.createElement(Table.HeaderCell, null, t('companies.moreOptions')))))));
};
export default Companies;
