import { getAvatar } from 'api-operations/myExfluency/myExfluency';
import React, { useState, useEffect } from 'react';
import { Image } from 'semantic-ui-react';
const Avatar = ({ name, inlineAvatar = false, customImg = null }) => {
    var _a;
    const [imgSrc, setImgSrc] = useState(null);
    useEffect(() => {
        if (customImg !== null) {
            setImgSrc(null);
        }
        else
            getAvatar(name, inlineAvatar ? 'SMALL' : 'BIG').then((response) => {
                setImgSrc(`data:${response.headers['content-type']};base64,${Buffer.from(response.data, 'binary').toString('base64')}`);
            });
    }, [customImg]);
    let size = 150;
    if (inlineAvatar) {
        size = 28;
    }
    const onError = () => {
        setImgSrc(null);
    };
    return React.createElement(Image, { src: (_a = imgSrc !== null && imgSrc !== void 0 ? imgSrc : customImg) !== null && _a !== void 0 ? _a : `https://eu.ui-avatars.com/api/?background=0D8ABC&color=fff&size=${size}&name=${name}`, avatar: inlineAvatar, circular: !inlineAvatar, onError: onError });
};
export default Avatar;
