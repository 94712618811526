import React from 'react';
import styled from 'styled-components';
import { Logo } from './svg';
const LogoWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

.svg-wrapper {
  height: 170px;
}

.rotate-anim {
  transform-box: fill-box;
  transform-origin: center;
  animation: rotateElem;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.rotate-anim-2 {
  animation-duration: 2.5s;
  animation-direction: reverse;
}

@keyframes rotateElem {
  0% { transform: rotate(0); }
  100% { transform: rotate(-360deg); }
}
`;
const ExfluencyLoader = () => React.createElement(LogoWrapper, null,
    React.createElement(Logo, null));
export default ExfluencyLoader;
