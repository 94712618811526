import { Modal } from "editorNextGen/components/Modal";
import i18next from "i18next";
export default class CreateTermModal extends Modal {
    constructor() {
        super();
        this.callback = () => { };
        this.content = document.createElement("div");
        this.sourceTextInput = document.createElement("input");
        this.descriptionLabel = document.createElement("label");
        this.descriptionTextarea = document.createElement("textarea");
        this.classList.add("tiny");
        this.content.classList.add("content", "ui", "form");
        const sourceTextLabel = document.createElement("label");
        sourceTextLabel.classList.add('label');
        sourceTextLabel.textContent = i18next.t("editor.createTermModal.sourceText");
        const sourceTextField = document.createElement("div");
        sourceTextField.classList.add("field");
        sourceTextField.appendChild(sourceTextLabel);
        this.sourceTextInput.placeholder = i18next.t("editor.createTermModal.sourceTextPlaceholder");
        sourceTextField.appendChild(this.sourceTextInput);
        sourceTextField.addEventListener("input", this.validate.bind(this));
        this.content.appendChild(sourceTextField);
        this.descriptionLabel.textContent = i18next.t("editor.createTermModal.description");
        this.descriptionLabel.classList.add('label');
        this.descriptionTextarea.rows = 5;
        this.descriptionTextarea.placeholder = i18next.t("editor.createTermModal.explanationPlaceholder");
        const descriptionField = document.createElement("div");
        descriptionField.classList.add("field");
        descriptionField.appendChild(this.descriptionLabel);
        descriptionField.appendChild(this.descriptionTextarea);
        descriptionField.addEventListener("input", this.validate.bind(this));
        this.content.appendChild(descriptionField);
        this.appendChild(this.content);
        this.actions = [{
                text: i18next.t("editor.createTermModal.cancel"),
                classList: ["secondary"],
                callback: () => { this.clear(); this.open = false; }
            }, {
                text: i18next.t("editor.createTermModal.confirm"),
                classList: ["primary"],
                callback: () => { this.callback(); this.clear(); this.open = false; }
            }];
        this.validate();
    }
    clear() {
        this.sourceText = "";
        this.description = "";
        this.validate();
    }
    validate() {
        const btn = this.querySelector("button.primary");
        if (!btn)
            return;
        btn.disabled = !this.sourceText || !this.description;
    }
    get sourceText() {
        return this.sourceTextInput.value;
    }
    set sourceText(text) {
        this.sourceTextInput.value = text;
    }
    get descriptionLabelText() {
        var _a;
        return (_a = this.descriptionLabel.textContent) !== null && _a !== void 0 ? _a : "";
    }
    set descriptionLabelText(text) {
        this.descriptionLabel.textContent = text;
    }
    get description() {
        return this.descriptionTextarea.value;
    }
    set description(text) {
        this.descriptionTextarea.value = text;
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-create-term-modal", CreateTermModal));
