import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { languagesDictionary, currencies, timezones } from '../dictionaries/dictionaries'

const Select = ({ search = false, label = '', setValue, value, option, clearable = false, fluid = true, multiple = false, labelIcon = null }) => {
  const { t } = useTranslation('common')
  let options = []

  switch (option) {
  case 'currencies':
    options = currencies.map(e => ({
      key: e,
      value: e,
      text: e
    }))
    break
  case 'languages':
    options = languagesDictionary.map(e => ({
      key: e,
      value: e,
      text: t(`languages.${e}`)
    }))
    break
  case 'timezones':
    options = timezones.map(e => ({
      key: e,
      value: e,
      text: e
    }))
    break

  default:
    break
  }

  return (
    <>
      {label !== '' && <label>{t(`settings.${label}`)}{labelIcon}</label>}
      <Dropdown
        search={search}
        fluid={fluid}
        placeholder=''
        selection
        onChange={(e, data) => setValue(data.value)}
        value={value}
        options={options}
        clearable={clearable}
        multiple={multiple}
      />
    </>
  )
}
const SelectMemo = React.memo(Select)

export default SelectMemo
