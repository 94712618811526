import i18next from 'i18next';
import { warningSvg } from 'editorNextGen/icons/warning';
import './style.scss';
export default class FormattingPanel extends HTMLElement {
    constructor() {
        super();
        this.targetQuantityDiv = document.createElement('div');
        this.sourceQuantityDiv = document.createElement('div');
        const header = document.createElement("h1");
        header.className = "header";
        header.textContent = i18next.t('editorFormattingPanel.header');
        const icon = document.createElement('span');
        icon.innerHTML = warningSvg;
        header.prepend(icon);
        const content = document.createElement("div");
        content.className = 'formatting';
        content.innerHTML = `
        <b>NOTE</b>: Please double-check the number of attributes and insert placeholders accordingly – use the source text as your reference.
        It is <b>important</b> that all placeholders are placed correctly.`;
        const quantityWrapper = document.createElement('div');
        quantityWrapper.className = 'quantity';
        quantityWrapper.appendChild(this.sourceQuantityDiv);
        quantityWrapper.appendChild(this.targetQuantityDiv);
        this.appendChild(header);
        this.appendChild(quantityWrapper);
        this.appendChild(content);
    }
    set visible(value) {
        this.classList.toggle('visible', value);
    }
    get visible() {
        return this.classList.contains('visible');
    }
    set targetQuantity(count) {
        this.targetQuantityDiv.innerHTML = `Attributes, target: <b>${count}</b>`;
    }
    set sourceQuantity(count) {
        this.sourceQuantityDiv.innerHTML = `Attributes, source: <b>${count}</b>`;
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-formatting-panel", FormattingPanel));
